import moment from 'moment';
import React from 'react';
import Spacer from '../../../components/Spacer';
import { roundNumber, isInvalidNumber, getLanguage, userLocale } from '../../../utils/utils';

const CustomTooltip = ({ active, payload, label, chartData, isShift, dateRange }) => {
    const language = getLanguage();
    const locale = userLocale();

    
    let currentDot = chartData?.filter((item) => item?.ts === label);
    if (!active) return null;
    let incomplete = false;

    currentDot = currentDot?.[0] || {};
    if (currentDot?.net_production === null) return null;

    function validateNumber(number) {
        let validNumber = number
        if(isInvalidNumber(number)) validNumber = 0;
        if(number > 100) validNumber = 100;

        return validNumber;
    }
    
    let togoal = (currentDot?.net_production) / currentDot?.target * 100;
    let toGoalPeriod = (currentDot?.period_production) / currentDot?.target_period * 100;
    let scrapPercent = currentDot?.scrap_acc * 100 / currentDot.net_production;
    let toGoalColor = 'value-default';
    let toGoalPeriodColor = 'value-default';
    let toGoalText = language?.of_goal || 'of goal';
    let toGoalPeriodText = language?.of_goal || 'of goal';

    let toGoalPeriodFormat = '';
    let toGoalFormat = '';

    if (togoal >= 100) {
        toGoalColor = 'value-success';
        toGoalFormat = togoal.toFixed() + '%';
    } else {
        toGoalColor = 'value-danger';
        toGoalFormat = togoal.toFixed() + '%';
    }
    if (toGoalPeriod >= 100) {
        toGoalPeriodColor = 'value-success';
        toGoalPeriodFormat = toGoalPeriod.toFixed() + '%';
    } else {
        toGoalPeriodColor = 'value-danger';
        toGoalPeriodFormat = toGoalPeriod.toFixed() + '%';
    }

    let format = 'dddd DD/MM';
    let showHour = false;
    let formatedDate = moment(label).utc().format(format);
    if (dateRange === 'day' || dateRange === 'today') {
        format = locale.hour;
        if (!locale.isAmPm) showHour = true;
        formatedDate = `${moment(label).format(format)} ${(showHour) ? 'h' : ''}`; 
        
    } 

    const Shifts = ({data}) => {
        return(
            <div className="tooltip-column m-1">
            {data?.map((item, index) => (
                <div className="tooltip-items">
                    <span>{language?.shift || 'Shift'} {item?.shift}</span>
                    <span className="tooltip-item-value">{roundNumber(item?.net) }</span>
                </div>

                
                ))}
            </div>
        );
    }

    if (moment(label).minutes() !== 0 && moment(label).seconds() !== 0) {
        incomplete = true;
    }
    
    return (
        <div className="chart-tooltip">
            <Spacer size={10} isVertical />
            <span className="tooltip-title m-3"> { formatedDate } 
                <span className='tooltip-incomplete'> { incomplete ? `(${language?.incomplete || 'incomplete'})` : '' } </span> 
            </span>
            {
                (isShift) ?
                    <Shifts data={currentDot?.shifts} />
                    
                 :

                <div className="tooltip-column m-1">
                    <div className="tooltip-items">
                        <span> {language?.period_production || 'Period Production'}</span>
                        <span className="tooltip-item-value">{roundNumber(currentDot?.period_production) }</span>
                        <span className={`tooltip-value ${toGoalPeriodColor}`}>{toGoalPeriodFormat} {toGoalPeriodText}</span>
                    </div>

                    <div className="tooltip-divider" />

                    <div className="tooltip-items">
                        <span>{language?.total_production || 'Total Production'}</span>
                        <span className="tooltip-item-value"> { roundNumber(currentDot?.net_production) } </span>
                        <span className={`tooltip-value ${toGoalColor}`}>{toGoalFormat} {toGoalText}</span>
                    </div>
                    <div className="tooltip-items">
                        <span>{language?.scrap || 'Scrap'}</span>
                        <span className="tooltip-item-value"> {scrapPercent?.toFixed()}% </span>
                        <span className="tooltip-value value-default">({ roundNumber(currentDot?.scrap_acc) })</span>
                    </div>
                </div>
            }

        </div>
    )
  
};
export default CustomTooltip;
