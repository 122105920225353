import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ReferenceLine, Cell, } from 'recharts';

import {Card, CardContent, Typography, } from '@mui/material';

function Chart({ data, dataArr12H, target }) {
    const [valuee, setValuee] = useState('');

    const CustomizedLabel = () => ({ render () { const {x, y, value} = this.props; return <text x={x + 40} y={Math.round(y)} dy={-4} fontSize='20' fontWeight={1000} fontFamily='Source Sans Pro' fill='#2D3748' textAnchor='middle'>{value}</text> } });

    useEffect(() => {
        // let sumData12h = dataArr12H?.reduce((a, b) => a + b, 0);
        // let media = sumData12h / dataArr12H?.length
        // setValuee( media )
    // console.log( Math.round(media) )

    setValuee(target?.production_targets?.[0]?.vl_hour)
}, [dataArr12H, target])
    
    return (
        <Card sx={{ mx:1, }} elevation={3} >

            <CardContent sx={{display:'flex', alignContent: 'flex-start', mt:2 }}>
                {/* <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 36 }} >
                    Últimas 12h 
                </Typography> */}
            
                <ResponsiveContainer aspect={3.5} width="100%" height="100%" >
                    <BarChart data={data} margin={{top:15, right:30, left:0, bottom:5}} >
                        
                        <ReferenceLine y={valuee} stroke="#FD9729" strokeWidth={2} 
                            label={{ value:Math.round(valuee), position: 'left', fill: '#FD9729', fontSize: 20, fontWeight: 1000  }}
                            ifOverflow="extendDomain" 
                        />

                        <CartesianGrid strokeDasharray="3 2" vertical={false} />

                        <XAxis dataKey="time" tick={{stroke:'#2D3748', strokeWidth:.8, fontSize:'20' }} />
                        <YAxis tick={{stroke:'#2D3748', strokeWidth:.8, fontSize:'20' }} />
                        
                        <Bar dataKey="production" label={ <CustomizedLabel /> } >
                            {data?.map((entry, index)=> {
                                if (entry.production >= valuee) { 
                                    return <Cell key={`cell-${index}`} fill={'#318F29'}/>
                                } else {
                                    return <Cell key={`cell-${index}`} fill={'#C13939'}/>
                                }
                            })}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </CardContent>

        </Card>
    );
}

export default Chart;