import React, { useContext, useState, useEffect } from "react";
import { VariablesContext } from '../../Context/VariablesContext';
import { useHistory } from "react-router-dom";
import './breadcrumb.css';
import '../../components/PackDropdown/pack-dropdown.css';
import { useQuery, useSubscription } from '@apollo/client';
import { BreadcrumbQuery } from './Query';
import moment from 'moment';
import {Box, } from '@mui/material';
import { getLanguage } from '../../utils/utils';

export default function HomeBreadCrumb() {
  const { setLine, setArea, setSite, setRange, setTs_start, setTs_end } = useContext(VariablesContext);
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedLine, setSelectedLite] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedRange, setSelectedRange] = useState("none");
  const [selectedRangeStart, setSelectedRangeStart] = useState(null);
  const [selectedRangeEnd, setSelectedRangeEnd] = useState(null);
  const language = getLanguage();


  const { loading, error, data } = useQuery(BreadcrumbQuery);

  const history = useHistory();

  const today_start = moment().startOf('day').format('YYYY-MM-DD HH:mm')
  const today_end = moment().endOf('day').startOf('day').format('YYYY-MM-DD HH:mm')
  const weekStart = moment().startOf('week').format('YYYY-MM-DD HH:mm');
  const weekEnd = moment().endOf('week').startOf('day').format('YYYY-MM-DD HH:mm');
  const monthStart = moment().startOf('month').format('YYYY-MM-DD HH:mm')
  const monthEnd = moment().endOf('month').startOf('day').format('YYYY-MM-DD HH:mm')
  const lastMonthStart = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD HH:mm')
  const lastMonthEnd = moment().subtract(1, 'months').endOf('month').startOf('day').format('YYYY-MM-DD HH:mm')
  const yearStart = moment().startOf('year').format('YYYY-MM-DD HH:mm');
  const yearEnd = moment().endOf('year').startOf('day').format('YYYY-MM-DD HH:mm');

  const handleSearch = (e) => {
    setSite(selectedSite);
    setArea(selectedArea);
    setLine(selectedLine);
    
    setTs_start(selectedRangeStart);
    setTs_end(selectedRangeEnd);

    history.push("/total-production");
  }
  
  const handleSiteSelection = (e) => {
    setSelectedSite(e.target.options[e.target.selectedIndex].value);
    setSelectedArea(null);
    setSelectedLite(null);
  }

  const handleAreaSelection = (e) => {
    setSelectedArea(e.target.options[e.target.selectedIndex].value);
    setSelectedLite(null);
  }

  const handleLineSelection = (e) => {
    setSelectedLite(e.target.options[e.target.selectedIndex].value);
  }

  const handleRangeSelection = (e) => {
    const tempRange = e.target.options[e.target.selectedIndex].value;
    setSelectedRange(tempRange);
    switch (tempRange) {
      case "today":
        setSelectedRangeStart(today_start);
        setSelectedRangeEnd(today_end);
        break;
      case "thisWeek":
        setSelectedRangeStart(weekStart);
        setSelectedRangeEnd(weekEnd);
        break;
      case "thisMonth":
        setSelectedRangeStart(monthStart);
        setSelectedRangeEnd(monthEnd);
        break;
      case "lastMonth":
        setSelectedRangeStart(lastMonthStart);
        setSelectedRangeEnd(lastMonthEnd);
        break;
      case "year":
        setSelectedRangeStart(yearStart);
        setSelectedRangeEnd(yearEnd);
        break;
      default:
        setSelectedRangeStart(null);
        setSelectedRangeEnd(null);
        break;
    }

  }

  return (

    <Box sx={{height:67, minWeight:1051,
      // mr:"212px",
      mr:5,
    }}>
    <div class="combo-search mt-2">
      <div class="grid-container gap-16">
        
      <Box sx={{mt:"8px",ml:"16px",mb:"16px"}}>
        <div class="grid-item">
          <label for="select-1" class="form-label"> {language?.site || 'Site'}*</label>
          <select id="select-1" class="form-select" aria-label="Default select example" onChange={(e) => handleSiteSelection(e)}>
            <option value={null} selected> {language?.choose_site || 'Choose Site'}</option>
            {
              data?.sites?.map((site, index_site) => {
                return (
                  <option value={site.id_site} selected={selectedSite === site.id_site}>{site.nm_site}</option>
                )
              })}
          </select>
        </div>
      </Box>
        
      <Box sx={{mt:"8px",ml:"16px",mb:"16px" }}>
        <div class="grid-item">
          <label for="select-2" class="form-label"> {language?.area || 'Area'} </label>
          <select id="select-2" class="form-select" aria-label="Default select example" onChange={(e) => handleAreaSelection(e)}>
            <option value={null} selected>{ language?.choose_area || 'Choose Area' }</option>
            {data?.areas.filter(item => item.id_site == selectedSite).map((area, index_area) => {       //TODO FILTER
              return (
                <option value={area.id_area} selected={selectedArea === area.id_area}>{area.nm_area}</option>
              )
            })}

          </select>
        </div>
        </Box>
        
        <Box sx={{mt:"8px",ml:"16px",mb:"16px" }}>
        <div class="grid-item">
          <label for="select-3" class="form-label">{language?.line || 'Line'}</label>
          <select id="select-3" class="form-select" aria-label="Default select example" onChange={(e) => handleLineSelection(e)}>
            <option value={null} selected>{language?.choose_line || 'Choose Line'}</option>
            {data?.equipments?.filter(item => item.id_area == selectedArea).map((equipment, index_equipment) => {
              return (
                <option value={equipment.id_equipment} selected={selectedLine === equipment.id_equipment}>{equipment.nm_equipment}</option>
              )
            })}
          </select>
        </div>
        </Box>
        
        <div class="grid-item">
          <label for="select-4" class="form-label">{language?.period || 'Period'}*</label>
          <select id="select-4" class="form-select" aria-label="Default select example" onChange={(e) => handleRangeSelection(e)}>
            <option value="none" selected={selectedRange === "none"}> {language?.choose_period || 'Choose Period'}</option>
            <option value="today" selected={selectedRange === "today"}> {language?.today || 'Today'}</option>
            <option value="thisWeek" selected={selectedRange === "thisWeek"}> {language?.this_week || 'This Week'}</option>
            <option value="thisMonth" selected={selectedRange === "thisMonth"}>{language?.this_month || 'This Month'}</option>
            <option value="lastMonth" selected={selectedRange === "lastMonth"}> {language?.last_month || 'Last Month'}</option>
          </select>
        </div>
        <div class="grid-item">
          <button class={`btn btn-primary ${(selectedSite&&selectedRange&&(selectedRange!="none"))?"":"disabled"}`} onClick={(e) => handleSearch(e)}>
            <i class="ri-search-line me-1"></i>
            <span> {language?.search || 'Search'}</span>
          </button>
        </div>
      </div>
    </div>
   </Box>
  );
}