import React, { useState, useEffect, useContext } from "react";
import moment from 'moment';
import { Tabs, Tab } from 'react-bootstrap';
import 'font-awesome/css/font-awesome.min.css';
import './styles.css';
import { RiFlagLine, RiCheckboxCircleLine, RiThumbUpLine, RiArrowUpCircleLine, RiFilterLine } from 'react-icons/ri';
import { useQuery, useSubscription, useLazyQuery } from '@apollo/client';
import { getLanguage } from '../../utils/utils';

import '../../components/StatusCard/status-card.css';
import '../../components/AreaCard/area-card.css';
import '../../components/Progress/progress.css';
import '../../components/Tooltip/tooltip.css';
import '../../components/Grid/grid.css';

import { OEEMonthQuery, OEEWeekQuery, OEEDayQuery, OEEShiftQuery, OEE_PROGRESS, Oee_Func, OEE_STATUS } from './Query';

import EntityFilter from '../../components/EntityFilters';

import ShiftFilter from '../../components/ShiftFilter';
import Breadcrumb from '../../components/Breadcrumb';
import OeeScore from './components/OeeScore';
import EquipmentSelector from "../../components/EquipmentSelector";
//import { DataFilter } from "./components/DataFilter";
import OeeProgress from "./components/OeeProgress";
import FilterButtonContainer from "../../components/FilterButtonContainer";
import MobileFilters from "../../components/MobileFilters";
import { VariablesContext } from "../../Context/VariablesContext";


export default function OEE() {
  const language = getLanguage();
  moment.locale('en');
  const userLanguage = localStorage.getItem('user_language')?.toLowerCase();
  if (userLanguage !== 'en-us') moment.locale(userLanguage);
  const [data, setData] = useState([]);
  const [newData, setNewData] = useState([]);

  const [tab, setTab] = useState('score');
  const [filter, setDataFilter] = useState(null);
  const [showFilters, setShowFilters] = useState(false);

  const [getOeeStatus, { loading: statusLoading, error: statusError, data: statusData }] = useLazyQuery(OEE_STATUS,
    { onCompleted: () => setNewData(statusData?.h_piot_oee_score_fix1), fetchPolicy: 'no-cache' });

  const [getOee, { loading: progressLoading, error: progressError, data: progressData }] = useLazyQuery(OEE_PROGRESS, { fetchPolicy: 'no-cache' });
  const { ts_start, ts_end, area, line, site, filterShift, filterSite, filterArea, filterLine, enterprise, visualizationLevel } = useContext(VariablesContext);

  useEffect(() => {
    let time_grain = 'DAY';
    let nav_level = 'EQUIPMENT';
    let in_id_areas = [];
    let in_id_equipments = [];
    let in_id_sites = [];

    if (site) {
      if (area) {
        if (line) {
          nav_level = 'EQUIPMENT';
          in_id_sites = site;
          in_id_areas = area;
          in_id_equipments = line;
        } else {
          nav_level = 'AREA';
          in_id_sites = site;
          in_id_areas = area;
          in_id_equipments = filterLine.toString();
        }
      } else {
        nav_level = 'SITE';
        in_id_sites = site;
        in_id_areas = filterArea.toString();
        in_id_equipments = filterLine.toString();
      }
    }else{
      nav_level = 'SITE';
      in_id_sites = filterSite.toString();
      in_id_areas = filterArea.toString();
      in_id_equipments = filterLine.toString();
    }

    

    getOee({
      variables: {
        begin_time: ts_start,
        end_time: ts_end,
        in_id_areas: `{${in_id_areas}}`,
        in_id_enterprise: enterprise,
        time_grain,
        in_id_shifts: `{${filterShift.toString()}}`,
        in_id_sites: `{${filterSite.toString()}}`,
        in_id_equipments: `{${in_id_equipments}}`,
        is_shift: false,
        nav_level
      }
    });

    getOeeStatus({
      variables: {
        in_id_enterprise: enterprise,
        in_ids_shifts: `{${filterShift.toString()}}`,
        in_id_sites: `{${in_id_sites}}`,
        in_id_equipments: `{${in_id_equipments}}`,
        in_id_areas: `{${in_id_areas}}`,
        in_begin_time: ts_start,
        in_end_time: ts_end,
        is_shift_filtered: false,
        time_grain,
        nav_level,
      }
    });


  }, [site, line, area, ts_start, ts_end, filterShift, filterLine, filterArea, filterSite]);



  const getStatus = (status) => {
    switch (status) {
      case 'running':
        return 'success';
      case 'lowSpeed':
        return 'warning';
      case 'stopped':
        return 'danger';
      default:
        return 'primary';
    }
  }

  function filterControl(filterType) {
    if (filterType === filter) {
      setDataFilter(null);
    } else {
      setDataFilter(filterType);
    }
  }

  return (
    // <PageModel>
    <div className="ps-4 pe-4">
      <Breadcrumb />
      <div className="col-12 oee-page-title mt-4">
        <h1 className="pb-1 fw-bold ml-4" style={{ fontSize: 32 }}>OEE</h1>
        <div style={{ width: '32px' }}></div>
        { /*<EntityFilter  hideSector={true}/>*/}
        <button className="oee-data-filters" onClick={() => console.log('show modal filters')}> <RiFilterLine /> Filtros</button>
        { /*<MobileFilters show={showFilters} onHide={() => setShowFilters(false)} /> */}

        {
          (tab === 'progresss') ?
            <div className="oee-filter-container">
              <FilterButtonContainer width={300}>
                <div className="oee-filter-btn-container">
                  <button onClick={() => filterControl('total')} className={`${(filter === 'total') ? 'filter-button-active' : ''}`}> <RiFlagLine size={22} /> </button>
                  <span className={`${(filter === 'total') ? 'filter-span-active' : ''}`}>OEE Total</span>
                </div>

                <div className="oee-filter-btn-container">
                  <button onClick={() => filterControl('quality')} className={`${(filter === 'quality') ? 'filter-button-active' : ''}`}> <RiCheckboxCircleLine size={22} /> </button>
                  <span className={`${(filter === 'quality') ? 'filter-span-active' : ''}`}>Qualidade</span>
                </div>

                <div className="oee-filter-btn-container">
                  <button onClick={() => filterControl('availability')} className={`${(filter === 'availability') ? 'filter-button-active' : ''}`}> <RiThumbUpLine size={22} /> </button>
                  <span className={`${(filter === 'availability') ? 'filter-span-active' : ''}`}>Disponib.</span>
                </div>

                <div className="oee-filter-btn-container">
                  <button onClick={() => filterControl('performance')} className={`${(filter === 'performance') ? 'filter-button-active' : ''}`}> <RiArrowUpCircleLine size={22} /> </button>
                  <span className={`${(filter === 'performance') ? 'filter-span-active' : ''}`}>Desemp.</span>
                </div>

              </FilterButtonContainer>
            </div> : ''

        }

      </div>


      {/* <p>N. America - All Areas</p> */}
      {/* <p>Todas as Areas</p> */}

      <Tabs
        id="oee-tabs"
        className="oee-tab-nav"
        activeKey={tab}
        onSelect={(key) => setTab(key)}
      >
        <Tab eventKey="score" title={language?.score || 'Score'}>
          <div className="row gy-4">
            {newData?.map((equipment, index_equipment) => {
              //if (index_equipment >= (currentPage - 1) * cardsPerPage && index_equipment < (currentPage) * cardsPerPage) {
              return <OeeScore equipment={equipment} />;
              //} else { return; }
            })}
          </div>
        </Tab>

        <Tab eventKey="progress" title={language?.progress || 'Progress'}>
          <div className="row">
            {progressData?.h_piot_oee_progress_data_teste1?.map((equipment, index_equipment) => {
              //if (index_equipment >= (currentPage - 1) * cardsPerPage && index_equipment < (currentPage) * cardsPerPage) {
              return <OeeProgress data={equipment} />;
              //} else { return; }
            })
            }
          </div>
        </Tab>
      </Tabs>

      {/*

          <ul className="nav nav-tabs oee-tab-nav" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active fs-h-medium " id="namerica-tab" data-bs-toggle="tab" data-bs-target="#namerica" 
              type="button" role="tab" aria-controls="namerica" aria-selected="true" onClick={() => setTab('score')} >
                Score
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link fs-h-medium" id="samerica-tab" data-bs-toggle="tab" data-bs-target="#samerica" 
              type="button" role="tab" aria-controls="samerica" aria-selected="false" onClick={() => setTab('progress')}>
                Progress
              </button>
            </li> 
            {
              (tab === 'score') ?
              <div className="icon-info hidden">
                <div className="dropdown">
                  <button className="btn btn-primary dropdown-toggle me-3" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span className="d-flex me-2"><i className="ri-arrow-up-down-line"></i></span>
                    <span>Sort</span>
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item" href="#">Action</a>
                    <a className="dropdown-item" href="#">Another action</a>
                    <a className="dropdown-item" href="#">Something else here</a>
                  </div>
                </div>
                <i className="ri-information-line"></i>
              </div> : 

              <div className="oee-filter-container">
                  <FilterButtonContainer width={300}>
                    <div className="oee-filter-btn-container">
                      <button onClick={() => filterControl('total')} className={`${(filter === 'total') ? 'filter-button-active': ''}`}> <RiFlagLine size={22} /> </button>
                      <span className={`${(filter === 'total') ? 'filter-span-active': ''}`}>OEE Total</span>
                    </div>

                    <div className="oee-filter-btn-container">
                      <button onClick={() => filterControl('quality')} className={`${(filter === 'quality') ? 'filter-button-active': ''}`}> <RiCheckboxCircleLine size={22} /> </button>
                      <span className={`${(filter === 'quality') ? 'filter-span-active': ''}`}>Qualidade</span>
                    </div>

                    <div className="oee-filter-btn-container">
                      <button onClick={() => filterControl('availability')} className={`${(filter === 'availability') ? 'filter-button-active': ''}`}> <RiThumbUpLine size={22} /> </button>
                      <span className={`${(filter === 'availability') ? 'filter-span-active': ''}`}>Disponib.</span>
                    </div>

                    <div className="oee-filter-btn-container">
                      <button onClick={() => filterControl('performance')} className={`${(filter === 'performance') ? 'filter-button-active': ''}`}> <RiArrowUpCircleLine size={22} /> </button>
                      <span className={`${(filter === 'performance') ? 'filter-span-active': ''}`}>Desemp.</span>
                    </div>
               
                  </FilterButtonContainer>
              </div>
            }

          </ul>
          
          
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="namerica" role="tabpanel" aria-labelledby="namerica-tab">
              <div className="row gy-4">
                  {
                  data.map((equipment, index_equipment) => {
                    //if (index_equipment >= (currentPage - 1) * cardsPerPage && index_equipment < (currentPage) * cardsPerPage) {
                    return <OeeScore equipment={equipment} />;
                    //} else { return; }
                  })
                }
              </div>
            </div>

            <div className="tab-pane fade show active" id="samerica" role="tabpanel" aria-labelledby="namerica-tab">
              <div className="row">
                {
                  data.map((equipment, index_equipment) => {
                    //if (index_equipment >= (currentPage - 1) * cardsPerPage && index_equipment < (currentPage) * cardsPerPage) {
                    return <OeeProgress index={(index_equipment)} />;
                    //} else { return; }
                  })
                }
              </div>
              
            </div>

            <div className="tab-pane fade" id="samerica" role="tabpanel" aria-labelledby="samerica-tab">...</div>
            <div className="tab-pane fade" id="europe" role="tabpanel" aria-labelledby="europe-tab">...</div>
              </div>*/}
    </div>
    // </PageModel>
  );
}