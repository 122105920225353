import React from 'react';
import styled from 'styled-components';
import Logo from '../../assets/Logo_Black_Transparent_Background 2.png';
import PackiotImage from '../../assets/Logo_Black_Transparent_Background 1.png'

export const Container = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: transparent;

  p {
      color: var(--grey-medium-dark);
  }
`;


const Footer = () => {
    const year = new Date().getFullYear();
    
    return (
        <Container>
            <img src={Logo} />
            <img src={PackiotImage} />
            <p>&nbsp;</p>
            <p>&copy; {year} - All rights reserved</p>
        </Container>
    )
  
};

export default Footer;