import React, { useState, useEffect } from 'react';
import Loading from '../../components/Loading';
import Fab from '@mui/material/Fab';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

import './style.css';

import api from '../../services/api';

export default function ReportsPowerBI(props) {
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(null);
    const [reportId, setReportId] = useState('');
    const [dataset, setDataset] = useState('');
    const [embedUrl, setEmbedUrl] = useState(null);
    const [error, setError] = useState(false);

    let temp_URL = "";
    let temp_token = "";

    // props?.location?.state?.reportId?()=>localStorage.setItem('reportId', props.location.state.reportId):()=>{};
    // props?.location?.state?.reportId?alert(props.location.state.reportId):()=>{};

    useEffect(() => {
        props?.location?.state?.reportId ? localStorage.setItem('reportId', props.location.state.reportId) : () => { };
        props?.location?.state?.dataset ? localStorage.setItem('dataset', props.location.state.dataset) : () => { };
    }, [props.location.state]);

    useEffect(() => {
        let temp = localStorage.getItem('reportId')
        let temp2 = localStorage.getItem('dataset')

        if (temp) {
            setReportId(localStorage.getItem('reportId'));
            setDataset(localStorage.getItem('dataset'));

            api.post('/getEmbedToken', { reportId: temp })
                .then((response) => {
                    setEmbedUrl(response?.data?.embedUrl?.[0]?.embedUrl)
                    setToken(response?.data?.accessToken)
                    temp_URL = response?.data?.embedUrl?.[0]?.embedUrl;
                    temp_token = response?.data?.accessToken;
                    // setLoading(false);
                })
                .catch((error) => {
                    setError(true)
                }
                )
            // .finally(() => {
            //     setLoading(false)
            // });
        }



        // }, [props.location.state]);
    }, [reportId]);

    // useEffect(() => {
    //     if (embedUrl!=null) {
    //         if (token!=null) {
    //             console.log(embedUrl,token);
    //             setLoading(false)
    //         }
    //     }
    // }, [embedUrl, token]);

    // if (error) { return <div><h1>Error</h1></div> }
    // if (loading) { return <Loading /> }

    // return <><div>ok </div><div>{token} </div><div>{embedUrl}</div></>
    if (token != null && embedUrl != null) {
        console.log(token, embedUrl)
        const embedConfig = {
            type: 'report',   // Supported types: report, dashboard, tile, visual and qna


            id: reportId,
            embedUrl: embedUrl,
            accessToken: token,

            tokenType: models.TokenType.Embed,
            settings: {
                panes: {
                    filters: {
                        expanded: false,
                        visible: false
                    },
                    pageNavigation: {
                        visible: true
                    }
                },
                background: models.BackgroundType.Transparent,
                navContentPaneEnabled: false
            }
        }
        console.log(embedConfig);
        return (
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        {embedUrl ? <PowerBIEmbed
                            embedConfig={embedConfig}
                            eventHandlers={
                                new Map([
                                    ['loaded', function () { console.log('Report loaded'); }],
                                    ['rendered', function () { console.log('Report rendered'); }],
                                    ['error', function (event) { console.log(event.detail); }]
                                ])
                            }
                            cssClassName={"embedded-report-container"}
                            getEmbeddedComponent={(embeddedReport) => {
                                window.report = embeddedReport;
                            }}
                        /> : <></>}
                    </div>
                </div>
                <Fab color="primary" aria-label="refreshDataset"
                    sx={{
                        position: 'absolute',
                        bottom: 16,
                        right: 16
                    }
                    }
                    onClick={() => {
                        api.post('/refreshDataset', { reportId: reportId, dataset: dataset })
                    }}>
                    <AutorenewIcon />
                </Fab>
            </div>
        );
    }
    else {
        return <Loading />
    }
}