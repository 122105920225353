import { gql } from '@apollo/client';

export const TOTAL_PRODUCTION_EQUIPMENT = gql`
query GET_TOTAL_PRODUCTION_EQUIPMENT_DAY($ts_start: timestamp, $ts_end: timestamp, $id_enterprise: Int, $id_area: String, $id_site: String, $grain: String, $shifts: String, $id_equipment: String) {
  h_piot_total_production_equipment_from_runtime:h_piot_total_production_equipment_from_runtime_test(args: {in_begin_time: $ts_start, in_end_time: $ts_end, in_id_enterprise: $id_enterprise, in_id_areas: $id_area, in_id_sites: $id_site, time_grain: $grain, in_id_shifts: $shifts, in_id_equipments: $id_equipment}) {
    gross_production_acc
    net_production_acc
    net_production_incr
    scrap
    scrap_acc
    target
    togoal
    trendline1
    ts
    shift_net_prod
    target_period
  }
}
`;

export const OEE_TOTAL = gql`
  query OeeTotal($time_grain: String, $nav_level: String, $is_shift_filtered: Boolean = false, $in_ids_shifts: String, $in_id_sites: String, $in_id_equipments: String, $in_id_enterprise: Int, $in_id_areas: String , $in_end_time: timestamptz, $in_begin_time: timestamptz) {
    h_piot_oee_score_fix1:h_piot_oee_score_fix1a(args: {in_begin_time: $in_begin_time, in_end_time: $in_end_time, in_id_areas: $in_id_areas, in_id_enterprise: $in_id_enterprise, in_id_equipments: $in_id_equipments, in_id_sites: $in_id_sites, in_ids_shifts: $in_ids_shifts, is_shift_filtered: $is_shift_filtered, nav_level: $nav_level, time_grain: $time_grain}) {
      oee_componentes
    }
  }
`;