import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';

import { Container, Header, Row, Spacer } from './styles';
import logo from '../../../../assets/white-logo.svg';

import { GET_JOB_INFO, GET_SHIFT_PROD, GET_DAY_PROD, GET_MONTH_PROD, GET_PRODUCTION_CHART, GET_EVENTS } from './queries';
//import { GET_SUB_SHIFT_PROD, GET_SUB_EVENTS } from './subscriptions';

import Line from './components/Line';
import Production from './components/Production';
import Shift from './components/Shift';
import Chart from './components/Chart';
import Downtime from './components/Downtime';
import Loading from '../../../../components/Loading';

function C30Overview() {
  const { lineId } = useParams();

  const { data: jobData, loading } = useQuery(GET_JOB_INFO, { variables: { id: lineId }, pollInterval: 55000, fetchPolicy: 'no-cache' });
  const { data: monthData  } = useQuery(GET_MONTH_PROD, { variables: { id: lineId }, pollInterval: 60000, fetchPolicy: 'no-cache', displayName: 'Month' });
  const { data: dayData  } = useQuery(GET_DAY_PROD, { variables: { id: lineId }, pollInterval: 60000, fetchPolicy: 'no-cache', displayName: 'DAY' });
  const { data: shiftData  } = useQuery(GET_SHIFT_PROD, { variables: { id: lineId }, pollInterval: 60000, fetchPolicy: 'no-cache', displayName: 'Shift' });
  const { data: prodData} = useQuery(GET_PRODUCTION_CHART, { variables: { id: lineId }, pollInterval: 60000, fetchPolicy: 'no-cache' });
  const { data: eventsData } = useQuery(GET_EVENTS, { variables: { id: lineId }, pollInterval: 60000, fetchPolicy: 'no-cache', displayName: 'Events' });

  /*
  useEffect(() => {
    subscribeShift({
      document: GET_SUB_SHIFT_PROD,
      variables: { id: lineId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newShiftData = subscriptionData?.data?.h_piot_overview_i_shift_production;
        return Object.assign({}, prev, {
          h_piot_overview_i_shift_production: newShiftData
        });
      }
    });

    subscribeEvents({
      document: GET_SUB_EVENTS,
      variables: { id: lineId },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newEvents = subscriptionData?.data?.h_piot_overview_i_get_events;
        return Object.assign({}, prev, {
          h_piot_overview_i_get_events: newEvents
        });
      }
    });

  }, []);*/

  if (loading) return <Loading />;

  return (
      <Container>
        <Header> <img className="image" src={logo} height="26 px" alt="avatar" /> </Header>
        <Spacer size={15} />

        <Row classname="row">
          <Line name={jobData?.equipments?.[0]?.nm_equipment} />
          <Production data={jobData?.h_piot_overview_i_get_job_info?.[0]}/>
        </Row>
        <Spacer size={25} />

        <Row>
          <Shift data={shiftData?.uns_equipment_current_shift?.[0]} monthData={monthData?.uns_equipment_current_month?.[0]} dayData={dayData?.uns_equipment_current_day?.[0]}/>
          <Chart data={prodData?.h_piot_overview_i_production_chart}/>
        </Row>
        <Spacer size={25} />

        <Row>
          <Downtime data={eventsData?.h_piot_overview_i_get_events} />
        </Row>
          
      </Container>
  );
}

export default C30Overview;