import React, { useEffect, useContext, useState } from 'react';
import moment from 'moment';
import Chart from './components/Chart';
import { RiDownloadLine } from 'react-icons/ri';
import { useLazyQuery } from '@apollo/client';
import { TOTAL_PRODUCTION_EQUIPMENT, OEE_TOTAL } from './queries';
import './style.css';
import Spacer from '../../components/Spacer';

import Breadcrumb from '../../components/Breadcrumb';

import { VariablesContext } from '../../Context/VariablesContext';
import ShiftFilter from '../../components/ShiftFilter';
import EntityFilters from '../../components/EntityFilters';
import { getLanguage, userLocale } from '../../utils/utils';
import { Grid, Box } from '@mui/material';
import BarChart from './components/BarChart';
import LoadingTotalProduction from './components/LoadingTotalProduction';

function TotalProductionContainer() {
    const locale = userLocale()
    moment.locale(locale.tag);
    let hideShift = false;
    const { ts_start, ts_end, enterprise, site, area, line, filterShift, dateRange, filterSite, filterArea, filterLine } = useContext(VariablesContext);
    const [isShift, setShift] = useState(false);
    const[getTotalProductionEquipment, { data, loading }] = useLazyQuery(TOTAL_PRODUCTION_EQUIPMENT, { fetchPolicy: 'no-cache' });
    const [getOee, { data: oeeData }] = useLazyQuery(OEE_TOTAL, { fetchPolicy: 'no-cache' });

    const language = getLanguage();

    const scrapLegend = [
      {
        title: `${language?.good_parts || 'Good Parts'}`,
        color: '#4175F5'
      },
      {
        title: `${language?.scrap || 'Scrap' }`,
        color: '#C13939'
      }
    ];

    const oeeLegend = [{ title: `${language?.oee_total || 'OEE Total'}`, color: '#4175F5' }];

    useEffect(() => {
      let grain = 'DAY';
      let start = ts_start;
      let end = ts_end;
      let shifts = filterShift?.join();
      let nav_level = 'EQUIPMENT';
      if (shifts) {
        setShift(true);
      } else {
        shifts = '';
        setShift(false);
      }

      if (dateRange === 'today' || dateRange === 'day') {
        grain = 'HOUR';
      } 
      let equipment = null;
      if (line !== 'null') equipment = line;



    let in_id_areas = [];
    let in_id_equipments = [];
    let in_id_sites = [];

    if (site) {
      if (area) {
        if (line) {
          nav_level = 'EQUIPMENT';
          in_id_sites = site;
          in_id_areas = area;
          in_id_equipments = line;
        } else {
          nav_level = 'AREA';
          in_id_sites = site;
          in_id_areas = area;
          in_id_equipments = filterLine.toString();
        }
      } else {
        nav_level = 'SITE';
        in_id_sites = site;
        in_id_areas = filterArea.toString();
        in_id_equipments = filterLine.toString();
      }
    }else{
      nav_level = 'SITE';
      in_id_sites = filterSite.toString();
      in_id_areas = filterArea.toString();
      in_id_equipments = filterLine.toString();
    }

      getTotalProductionEquipment({
        variables: { 
            id_enterprise: enterprise,
            grain: grain,
            shifts: `{${shifts}}`,
            id_site: `{${in_id_sites.toString()}}`,
            id_area: `{${in_id_areas.toString()}}`,
            id_equipment: `{${in_id_equipments.toString()}}`,
            ts_start: start,
            ts_end: end }
      });

      getOee({
        variables: {
          in_id_enterprise: enterprise,
          in_ids_shifts: `{${shifts}}`,
          in_id_sites: `{${in_id_sites.toString()}}`,
          in_id_equipments: `{${in_id_equipments.toString()}}`,
          in_id_areas: `{${in_id_areas.toString()}}`,
          in_begin_time: start,
          in_end_time: end,
          is_shift_filtered: false,
          time_grain: grain,
          nav_level,
        }
      });
    
      

    },[enterprise, site, area, line, ts_end, filterShift, filterSite, filterArea, filterLine]);

  let scrapData = [];
  data?.h_piot_total_production_equipment_from_runtime?.forEach((item, index) => {
        if (item.net_production_acc !== null) {
          scrapData = data.h_piot_total_production_equipment_from_runtime[index]
        }
      });

      if (dateRange === 'day' || dateRange === 'today') hideShift = true;


  return (
      <div className="container-fluid">
          <Breadcrumb />

            <div className="row m-3">
             
                <div className="d-flex flex-row align-items-end justify-content-between">

                    <div className="d-flex flex-row align-items-end justify-content-between">
                        <span style={{ lineHeight: 1, fontSize: 32 }} className="bold color-black">{ language?.total_production || 'Total Production' } </span>
                        <Spacer size={25} />
                        {/* <ShiftFilter /> */}
                        <EntityFilters hideShift={hideShift}  hideSector={true}/>
                    </div>
                  

                  <div className="d-flex flex-row">
                    <button className="icon-btn" style={{ visibility: 'hidden' }}> <RiDownloadLine size={24} /> </button>
                  </div>
                </div>
        
            </div>

            <div className="row m-3">
              {
                (loading) ? <LoadingTotalProduction /> :
                <>
                  <Chart width={1700} height={400} dateRange={dateRange} isShift={isShift} 
                    data={data?.h_piot_total_production_equipment_from_runtime} />
                  {
                    (dateRange !== 'custom') ? 
                    <Grid container sx={{ mt: 10 }} >
                      <Grid sx={{ justifyContent: 'center', alignItems: 'center' }} item xs={6}>
                          <BarChart data={scrapData} title={language?.scrap || 'Scrap' } legend={scrapLegend} />
                      </Grid>

                      <Grid sx={{ justifyContent: 'center', alignItems: 'center' }} item xs={6}>
                        <BarChart data={oeeData?.h_piot_oee_score_fix1} type='oee' title="OEE" legend={oeeLegend} />
                      </Grid>

                    </Grid> :  <Box sx={{ width: '100%', height: '50px' }} />
                  }
                  
                
                </>
              }
                 
            </div>
        
      </div>
  );
}

export default TotalProductionContainer;