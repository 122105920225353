import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import SignIn from './components/SignIn';
import MainContainer from './pages/MainContainer';

export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/login" component={SignIn}></Route>
                <Route path="/" component={MainContainer}></Route>
            </Switch>
        </BrowserRouter>
    );
}