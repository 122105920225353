import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Box, Typography, Skeleton } from '@mui/material';
import Breadcrumb from '../../components/Breadcrumb';
import EntityFilter from '../../components/EntityFilters';
import Spacer from '../../components/Spacer';
import NoData from '../../components/NoData';
import { getLanguage } from '../../utils/utils';
import GrainSelector from '../../components/GrainSelector';

import { VariablesContext } from '../../Context/VariablesContext';
import SinglePeriodChart from './components/Chart';
import { GET_SINGLE_PERIOD_DATA } from './queries';
import SinglePeriodShiftChart from './components/ChartShift';

function SinglePeriod() {
    const language = getLanguage();
    const [isShift, setShift] = useState(false);
    const { ts_start, ts_end, enterprise, site, area, line, filterShift, grain, dateRange, filterSite, filterArea, filterLine } = useContext(VariablesContext);
    const [getProductionData, { data, error, loading }] = useLazyQuery(GET_SINGLE_PERIOD_DATA, { 
        fetchPolicy: 'no-cache',
     });

    useEffect(() => {
        let shifts = filterShift?.join();
        let in_id_areas = [];
        let in_id_equipments = [];
        let in_id_sites = [];
        let grainSize = grain;
        if (dateRange === 'day' || dateRange === 'today') {
          grainSize = 'HOUR';
        }
        if (dateRange === 'week') {
          grainSize = 'DAY';
        }

        if (shifts) {
          setShift(true);
        } else {
          shifts = '';
          setShift(false);
        }
  
      if (site) {
        if (area) {
          if (line) {
            in_id_sites = site;
            in_id_areas = area;
            in_id_equipments = line;
          } else {
            in_id_sites = site;
            in_id_areas = area;
            in_id_equipments = filterLine.toString();
          }
        } else {
          in_id_sites = site;
          in_id_areas = filterArea.toString();
          in_id_equipments = filterLine.toString();
        }
      }else{
        in_id_sites = filterSite.toString();
        in_id_areas = filterArea.toString();
        in_id_equipments = filterLine.toString();
      }
  
    getProductionData({
        variables: {
            id_enterprise: enterprise,
            shifts: `{${shifts}}`,
            sites: `{${in_id_sites.toString()}}`,
            equipments: `{${in_id_equipments.toString()}}`,
            areas: `{${in_id_areas.toString()}}`,
            ts_start,
            ts_end,
            grain: grainSize,
        }
    });
      
    },[enterprise, site, area, line, ts_end, filterShift, filterSite, filterArea, filterLine, grain]);

  return (
        <Box  sx={{ mr: 3, ml: 3 }}>
            <Breadcrumb />
            {/** Header */}
            <Box sx={{ m: 3, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography sx={{ lineHeight: 1, fontSize: 32, fontWeight: 'bold' }}>{language?.single_period || 'Single Period' }</Typography>
                    <Spacer size={20} />
                    <EntityFilter hideSector />
                </Box>
                {
                    (dateRange === 'month' || dateRange === 'last month' ) && <GrainSelector />
                }
            </Box>

            {/** Content */ }
            <Box sx={{ m:3, mt: 4 }}>
              {
                loading ? <Skeleton variant='rect' height={600} /> :

                <Box sx={{ width: '100%', height: '600px', display: 'flex', justifyContent: 'center', background: '#fff', boxShadow: '-4px 6px 13px rgba(0, 16, 34, 0.2)', borderRadius: '6px' }}>
                  
                  {
                    (data?.h_piot_single_period_equipment_chart_3?.length) ? (
                    isShift ? <SinglePeriodShiftChart data={data?.h_piot_single_period_equipment_chart_3} dateRange={dateRange} /> :
                    <SinglePeriodChart data={data?.h_piot_single_period_equipment_chart_3} dateRange={dateRange} />) : <NoData />
                  }
                        
                </Box>
              }
                
            </Box>

        </Box>
    );
}

export default SinglePeriod;