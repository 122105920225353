import React from 'react';
import { Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getLanguage } from '../../src/utils/utils';

function NoData() {
    const translate = getLanguage();
  return (
    <Typography  sx={{color:'#757575', display: 'flex', alignItems: 'center', justifyContent: "center" }} variant="h6" >
        <InfoOutlinedIcon sx={{ mr:1 }} />
        {translate?.no_data|| "There is no data to display here."}
    </Typography>
  );
}

export default NoData;