import React, { useState, useContext, useEffect } from 'react';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import '../../components/PackDropdown/pack-dropdown.css';
import '../../components/Breadcrumb/breadcrumb.css';

import './style.css'
import { VariablesContext } from '../../Context/VariablesContext';
import { getLanguage } from '../../utils/utils';

export default function EquipmentFilter() {
    const { areaOptions, filterArea, setFilterArea, site } = useContext(VariablesContext);
    const [itemsToDisplay, setItemsToDisplay] = useState([]);
    const language = getLanguage();

    useEffect(() => {
        if(areaOptions){let temp = [...areaOptions];
        temp = temp.filter(item => {
            return (site? site == item.id_site : true);
        });
        setItemsToDisplay(temp);
    }
    }, [areaOptions, site]);

    const handleChange = (e) => {
    //    let temp = filterShift;
       const temp = [...filterArea]
        if (e?.target?.checked) {
            if (!filterArea.includes(e?.target?.value)) {
                temp.push(e?.target?.value);
                setFilterArea(temp);
            }
        }
        else {
            if (filterArea.includes(e?.target?.value)) {
                temp.splice(temp.indexOf(e?.target?.value), 1);
                setFilterArea(temp);
            }
        }
    }


    return (
        <>
            <div id="areaSelector" className='list-group mx-0  rounded bg-transparent'>
                <ul className="rounded p-0 border">
                    {itemsToDisplay.length > 0 ? itemsToDisplay.map((area, index) => {
                        return (
                            <li key={index} className="list-group-item d-flex gap-2">
                                <CheckBoxComponent label={area.nm_area}
                                    value={area.id_area}
                                    checked={filterArea.includes(area.id_area)}
                                    cssClass="e-info e-round form-check-input flex-shrink-0 width-fit-content"
                                    name="area"
                                    onChange={handleChange}
                                />
                            </li>
                        )
                    }) : <li key={0}><p>{language?.no_areas_available || 'No areas available'}</p></li>}
                </ul>
            </div>

            <DropDownButtonComponent
                // beforeOpen={event => beforeOpen(event)}
                target="#areaSelector"
                cssClass="align-items-center border mx-2"
                className="d-flex rounded e-grey"
            >
                <div className="element-selector shift-selector">
                    <p><i className="ri-filter-line" size={"1em"}></i>{language?.filter_area || 'Filter Area'} </p>
                </div>
            </DropDownButtonComponent>
        </>
    );
};