import React, { useState, useEffect, useRef, useContext } from "react";
import 'font-awesome/css/font-awesome.min.css';
import { useQuery } from '@apollo/client';
import { numbertoString, secondsToDurationString } from '../../utils/utils';
import Loading from '../../components/Loading';
import LegendaMissionControlG from '../../assets/LegendaMissionControlG.svg';
import LegendaMissionControlIngles from '../../assets/LegendaMissionControlIngles.svg'
import { getLanguage } from '../../utils/utils';
import { VariablesContext } from '../../Context/VariablesContext';
import EntityFilter from '../../components/EntityFilters'

import { Typography, Box, Dialog, DialogContent, IconButton, } from "@mui/material"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import '../../components/StatusCard/status-card.css';
import '../../components/AreaCard/area-card.css';
import '../../components/Progress/progress.css';
import '../../components/Tooltip/tooltip.css';
import '../../components/Grid/grid.css';
import '../../components/Scrollbar/scrollbar.css';
import Breadcrumb from '../../components/Breadcrumb';

import { MissionControlInitialQuery, MissionControlTimeline } from './Query';

export default function MCVini() {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  let where = window.localStorage.getItem('user_language');

  const language = getLanguage();
  const [cardsPerPage, setCardsPerPage] = useState(4);
  const [totalLines, setTotalLines] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortByElement, setSortByElement] = useState('cd_equipment');
  const [sortDirection, setSortDirection] = useState('');
  const [equipmentsArray, setEquipmentsArray] = useState([]);
  const [areasData, setAreasData] = useState([]);
  const [showInfo, setShowInfo] = useState(false);


  const statusText = {
    running: `${language?.running || 'Running' }`,
    lowSpeed: `${language?.low_speed || 'Low Speed'}`,
    stopped: `${language?.stopped || 'Stopped'}`
  }

  const colors = {
    running: '#318F29',
    lowSpeed: '#ECBC13',
    stopped: '#C13939',

    changeOver: '#C139394d', //30% opaco
    planned: '#C1393999',    //60% opaco
    unplanned: '#C13939',   //100% opaco

    unknown_1: '#808080',
    unknown_2: '#80808099',
    unknown_3: '#8080804d'
  }

  var pager = useRef(null);

  let onClickPreventDefault = e => {
    e.preventDefault();
    alert('Call the filter now');
  };
  let beforeOpen = args => {
    args.element
      .getElementsByTagName('form')[0]
      .addEventListener('submit', onClickPreventDefault);
  };

  const { enterprise, site, area, line, filterArea, filterLine } = useContext(VariablesContext);

  const { loading: linesLoadingInitial, error: linesErrorInitial, data: linesDataInitial } = useQuery(MissionControlInitialQuery, { variables: { in_id_enterprise: enterprise, in_ids_sites: `{${site?site:""}}`, in_ids_equipments: `{${line?line:filterLine.toString()}}`, in_ids_areas: `{${area?area:filterArea.toString()}}` }, pollInterval: 55000, fetchPolicy: 'no-cache' });
  const { loading: timelineLoading, error: timelineError, data: timelineData } = useQuery(MissionControlTimeline, { variables: { in_id_enterprise: enterprise, in_ids_sites: `{${site?site:""}}`, in_ids_equipments: `{${line?line:filterLine.toString()}}`, in_ids_areas: `{${area?area:filterArea.toString()}}` }, pollInterval: 55000, fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (linesDataInitial) {
      if(linesDataInitial?.h_piot_get_mission_control){
        if(timelineData?.h_piot_get_mission_control_timeline){
          let temp = [...linesDataInitial?.h_piot_get_mission_control]
          temp.map((item, index) => {
            const timeline = timelineData?.h_piot_get_mission_control_timeline.find(x => x.id_equipment === item.id_line);
            temp[index].timelinestatus = timeline?.timelinestatus;
          })
          setEquipmentsArray(temp);
          setTotalLines(linesDataInitial?.h_piot_get_mission_control.length);  
        }else{
          setEquipmentsArray(linesDataInitial?.h_piot_get_mission_control);
          setTotalLines(linesDataInitial?.h_piot_get_mission_control.length);  
        }
      }
      if(linesDataInitial?.h_piot_get_mission_control_area){
        setAreasData(linesDataInitial?.h_piot_get_mission_control_area);
      }
    }
  }, [linesDataInitial, timelineData])

  const calculatePercentegeStatus = function (array) {
    var result = array.reduce(
      function (prev, cur) {
        prev[cur] = (prev[cur] || 0) + 1;
        return prev;
      }, {});

    for (var status in result) {
      result[status] /= (array.length / 100);
    }
    return result;
  }

  function cardArea(area) {
    return (
      <div className="col-12 col-sm-4 col-xl-12">
        <div className="area-card">

          <div className="area-card-header mb-2">
            <div className="row g-0">

              <div className="col-8 d-flex align-items-center">
                <h5 className="fw-bold">{area.nm_area}</h5>
              </div>

              <div className="col d-flex justify-content-end">
                <i className="ri-filter-line color-primary fs-4"></i>
              </div>

            </div>
          </div>

          <div className="area-card-body text-center mb-3">
            <p>{language?.production || "Production"}  <span className="fw-bold color-grey-medium-dark">[{area?.unit_of_measurement || "u"}]</span></p>
            <h2 className="fw-bold">{area?.net_production != null ? numbertoString(area?.net_production) : "NA"}</h2>
            {/* <p className="color-danger fw-bold fs-small">-XX% of goal</p> */}
          </div>

          <div className="area-card-footer">
            <div className="row">

              <div className="col-6 text-center">
                <p>{language?.scrap || "Scrap"}</p>
                <h5 className="fw-bold">{(area?.scrap != null || area?.gross_production != null) ? (area?.gross_production == 0 ? (0).toFixed(0) : ((area?.scrap || 0) * 100 / area?.gross_production).toFixed(1)) : "NA"}%</h5>
                <p className="fs-small color-grey">({(area?.scrap != null || area?.gross_production != null) ? numbertoString(area?.scrap) : "NA"})</p>
              </div>

              <div className="col-6 text-center">
                <p>OEE</p>
                <h5 className="fw-bold d-flex align-items-center justify-content-center">
                  {(area?.oee_area != null) ? (area?.oee_area * 100).toFixed(1) : (linesData && linesData.length == 1 ? linesData[0]?.oee_area : "NA")}%

                  {/* <i className="color-success ri-arrow-up-line"></i> */}
                </h5>
              </div>

            </div>
          </div>
        </div>
      </div>);
  }

  function generateMissionControlCard(line) {
    var statusPercentage = {};
    var timerValue = 0;

    if (line.timelinestatus) {
      statusPercentage = calculatePercentegeStatus(line.timelinestatus)
      if (line.timelinestatus.length >= 1) {
        const status = line.timelinestatus[line.timelinestatus.length - 1];
        if (status == "stopped" || status == "setup") {
          var i = line.timelinestatus.length - 1;
          while (i > 0 && line.timelinestatus[i] == status) {
            timerValue++;
            i--;
          }
        }
      }
    }
    return (
      <div className="col-12 col-lg-6">
        <div className="status-card">

          <div className="status-card-header">
            <div className="row">

              <div className="col-7">
                <div className="d-flex align-items-center">
                  <h5 className="fw-bold me-2">{line.nm_line ? line.nm_line : ""}</h5>
                  <h5 className="fs-6"><span>|</span> {line.nm_area ? line.nm_area : ""}</h5>
                </div>
              </div>

              <div className="col d-flex align-items-center justify-content-end">
                <div className="tooltips">
                  <div className={`status-circle ${line.timelinestatus ? line.timelinestatus.length >= 1 ? line.timelinestatus[line.timelinestatus.length - 1] : "unknown" : "unknown"} unplannedTODO`} time={timerValue ? secondsToDurationString(timerValue * 60) : ""}>
                    <span className="tooltips-text">
                      <p className="fs-small mb-0">{line.timelinestatus ? line.timelinestatus.length >= 1 ? statusText[line.timelinestatus[line.timelinestatus.length - 1]] : "Unknown" : "Unknown"}</p>
                    </span>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="status-card-body">

            <div className="row mb-3 g-0">
              <div className="col">
                <div className="rounded bg-grey-lighter px-3 py-1">
                  <div className="row g-0">

                    <div className="col-5">

                      <p>{language?.job || "Job"}</p>
                      <h4 className="fw-bold">{line.id_order ? line.id_order : "NA"}</h4>
                      <p className="text-uppercase">{line.nm_client ? ("" + line.nm_client + "                    ").substring(0, 20) : "NA"}</p>

                    </div>

                    <div className="col">

                      <div className="d-flex justify-content-between">
                        <div className="label fs-small" forhtml="hoursProgress">{language?.hours || 'Hours'}</div>
                        <span className="fs-small"><span className="fw-bold">{line.duration != null ? secondsToDurationString(line.duration) : "NA"}</span> /{line.expected_time ? secondsToDurationString(line.expected_time) : "NA"}</span>
                      </div>
                      <div className="progress overflow-hidden border mb-2">
                        <div id="hoursProgress" className="progress-bar" role="progressbar" style={{ width: `${(line.duration && line.expected_time) ? ((line.duration * 100) / line.expected_time) : 0}%` }} aria-valuenow={(line.duration && line.expected_time) ? ((line.duration * 100) / line.expected_time) : 0} aria-valuemin="0" aria-valuemax="100"></div>                              </div>

                      <div className="d-flex justify-content-between">
                        <div className="label fs-small" forhtml="jobProdProgress">{language?.production || "Production"}</div>
                        <span className="fs-small"><span className="fw-bold">{line.po_net_production != null ? numbertoString(line.po_net_production) : "NA"}</span> /{line.production_programmed ? numbertoString(line.production_programmed) : "NA"}</span>
                      </div>
                      <div className="progress overflow-hidden border">
                        <div id="jobProdProgress" className="progress-bar" role="progressbar" style={{ width: `${(line.po_net_production && line.production_programmed) ? line.po_net_production * 100 / line.production_programmed : 0}%` }} aria-valuenow={(line.po_net_production && line.production_programmed) ? line.po_net_production * 100 / line.production_programmed : 0} aria-valuemin="0" aria-valuemax="100"></div>                              </div>

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 ms-3 hidden-mobile">
                <h5 className="fs-6 fw-bold text-uppercase mb-2 color-grey-medium-dark">
                {language?.previous_shifts || "Previous shifts"}
                </h5>
                <div className="row g-0">
                  <div className="col text-center">
                    <h5 className="fs-medium fw-bold d-flex align-items-center justify-content-center">{line.prev1shift_netprod ? numbertoString(line.prev1shift_netprod) : "0"}
                      {/* <div className="tooltips">
                        <i className="color-success ri-arrow-up-line"></i>
                        <span className="tooltips-text">
                          <p className="fs-small">+23% of the goalTODO</p>
                          <p className="fs-small fw-bold">Goal 40,5KTODO</p>
                        </span>
                      </div> */}
                    </h5>
                    <p>{line.prev1_shift_name ? line.prev1_shift_name : "NA"}</p>
                  </div>
                  <div className="col text-center">
                    <h5 className="fs-medium fw-bold d-flex align-items-center justify-content-center">{line.prev2shift_netprod ? numbertoString(line.prev2shift_netprod) : "0"}
                      {/* <div className="tooltips">
                        <i className="color-danger fw-bold ri-arrow-down-line"></i>
                        <span className="tooltips-text">
                          <p className="fs-small">-20% of the goalTODO</p>
                          <p className="fs-small fw-bold">Goal 80,5KTODO</p>
                        </span>
                      </div> */}
                    </h5>
                    <p>{line.prev2_shift_name ? line.prev2_shift_name : "NA"}</p>
                  </div>
                </div>
              </div>
            </div>

            <h5 className="fs-6 fw-bold">{language?.current_shift || "Current Shift"}</h5>

            <hr className="color-grey mb-0 mt-1" />

            <div className="row g-1 py-2">

              <div className="col-3 text-center">
                <h5 className="fw-bold fs-6 d-flex align-items-center justify-content-center">
                  {line.curshift_netprod ? numbertoString(line.curshift_netprod) : "0"}
                  {/* <div className="tooltips">
                    <i className="color-success ri-arrow-up-line"></i>
                    <span className="tooltips-text">
                      <p className="fs-small">+23% of the goalTODO</p>
                      <p className="fs-small fw-bold">Goal 40,5KTODO</p>
                    </span>
                  </div> */}
                </h5>
                <p className="small-mobile">{language?.production || "Production"}  <span className="fw-bold color-grey-medium-dark">[{line.unit_of_measurement || "u"}]</span></p>
              </div>

              <div className="col-3 text-center">
                <h5 className="fw-bold fs-6 d-flex align-items-center justify-content-center">{(line.curshift_scrap || line.curshift_grosprod) ? (line.curshift_grosprod == 0 ? (0).toFixed(0) : ((line.curshift_scrap || 0) * 100 / line.curshift_grosprod).toFixed(1)) : "0"}%</h5>
                <p className="small-mobile">{language?.scrap || "Scrap"}</p>
              </div>

              <div className="col-3 text-center">
                <h5 className="color-attention fs-6 fw-bold d-flex align-items-center justify-content-center">{
                  line.curshift_lastspeed ?
                    line.curshift_lastspeed.toFixed(0) :
                    "NA"}/min</h5>
                <p className="small-mobile">{language?.speed || "Speed"} <span className="fw-bold color-grey-medium-dark">[{line.speed_unit_of_measurement || "u/min"}]</span></p>
              </div>

              <div className="col-3 text-center">
                <h5 className="fw-bold fs-6 d-flex align-items-center justify-content-center">{line.currshift_oee ? (line.currshift_oee * 100).toFixed(1) : "0"}%</h5>
                <p className="small-mobile">OEE</p>
              </div>

            </div>

            <hr className="color-grey m-0 mb-3" />

            <div className="row mb-3 hidden-mobile">
              <div className="col-6 border-end">

                <h5 className="fs-6 fw-bold text-uppercase mb-2 color-grey-medium-dark">
                {language?.status || "Status"}
                </h5>

                <div className="progress radius">
                  {line.timelinestatus ?
                    <>
                      <div key={0} className="progress-bar bg-success" role="progressbar" style={{ width: `${statusPercentage["running"] || 0}%` }} aria-valuenow={`${statusPercentage["running"] || 0}%`} aria-valuemin="0" aria-valuemax="100">
                        <div className="tooltips">
                          <i className="ri-play-circle-line"></i>
                          <span className="tooltips-text"><span className="fw-bold text-dark  fs-small">{`Running: ${statusPercentage["running"] ? statusPercentage["running"].toFixed(0) : "0"}%`}</span></span>
                        </div>
                      </div>
                      <div key={1} className="progress-bar bg-danger" role="progressbar" style={{ width: `${statusPercentage["stopped"] || 0}%` }} aria-valuenow={`${statusPercentage["stopped"] || 0}%`} aria-valuemin="0" aria-valuemax="100">
                        <div className="tooltips">
                          <i className="ri-pause-circle-line"></i>
                          <span className="tooltips-text"><span className="fw-bold text-dark fs-small">{`Stopped: ${statusPercentage["stopped"] ? statusPercentage["stopped"].toFixed(0) : "0"}%`}</span></span>
                        </div>
                      </div>
                      <div key={2} className="progress-bar bg-warning" role="progressbar" style={{ width: `${statusPercentage["lowSpeed"] || 0}%` }} aria-valuenow={`${statusPercentage["lowSpeed"] || 0}%`} aria-valuemin="0" aria-valuemax="100">
                        <div className="tooltips">
                          <i className="ri-dashboard-2-line"></i>
                          <span className="tooltips-text"><span className="fw-bold text-dark  fs-small">{`Low Speed: ${statusPercentage["lowSpeed"] ? statusPercentage["lowSpeed"].toFixed(0) : "0"}%`}</span></span>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <div key={0} className="progress-bar bg-unknown-1" role="progressbar" style={{ width: '30%' }} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">
                        <i className="ri-play-circle-line"></i>
                      </div>
                      <div key={1} className="progress-bar bg-unknown-2 bg-opacity-50" role="progressbar" style={{ width: '50%' }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
                        <i className="ri-pause-circle-line"></i>
                      </div>
                      <div key={2} className="progress-bar bg-unknown-3" role="progressbar" style={{ width: '20%' }} aria-valuenow="20" aria-valuemin="0" aria-valuemax="100">
                        <i className="ri-dashboard-2-line"></i>
                      </div>
                    </>
                  }
                </div>

              </div>

              <div className="col-6">

                <h5 className="fs-6 fw-bold text-uppercase mb-2 color-grey-medium-dark">
                {language?.downtime_reasons || "DOWNTIME REASONS"}
                </h5>

                <div className="progress radius mb-1">
                  {
                    line.total_stopped_time ?
                      <>
                        <div key={0} className="progress-bar bg-danger" role="progressbar" style={{ width: `${line.total_stopped_time ? line.unplanned_duration_percent ? line.unplanned_duration_percent * 100 : 0 : 100 / 3}%` }} aria-valuenow={line.total_stopped_time ? line.unplanned_duration_percent ? line.unplanned_duration_percent * 100 : 0 : 100 / 3} aria-valuemin="0" aria-valuemax="100"></div>
                        <div key={1} className="progress-bar bg-danger-2" role="progressbar" style={{ width: `${line.total_stopped_time ? line.planned_duration_percent ? line.planned_duration_percent * 100 : 0 : 100 / 3}%` }} aria-valuenow={line.total_stopped_time ? line.planned_duration_percent ? line.planned_duration_percent * 100 : 0 : 100 / 3} aria-valuemin="0" aria-valuemax="100"></div>
                        <div key={2} className="progress-bar bg-danger-3" role="progressbar" style={{ width: `${line.total_stopped_time ? line.change_over_duration_percent ? line.change_over_duration_percent * 100 : 0 : 100 / 3}%` }} aria-valuenow={line.total_stopped_time ? line.change_over_duration_percent ? line.change_over_duration_percent * 100 : 0 : 100 / 3} aria-valuemin="0" aria-valuemax="100"></div>
                      </> :
                      <>
                        <div key={0} className="progress-bar bg-unknown-1" role="progressbar" style={{ width: `${30}%` }} aria-valuenow={30} aria-valuemin="0" aria-valuemax="100"></div>
                        <div key={1} className="progress-bar bg-unknown-2" role="progressbar" style={{ width: `${30}%` }} aria-valuenow={30} aria-valuemin="0" aria-valuemax="100"></div>
                        <div key={2} className="progress-bar bg-unknown-3" role="progressbar" style={{ width: `${40}%` }} aria-valuenow={40} aria-valuemin="0" aria-valuemax="100"></div>
                      </>
                  }
                </div>
                <div className="tooltips">
                  <p>{language?.unplanned_downtimes || "Unplanned stops"}: <span className="fw-bold">{(line.unplanned_duration_percent * 100).toFixed(0) || "0"}%</span></p>
                  <span className="tooltips-text"><span className="text-dark fs-small">{language?.unplanned_downtimes || "Unplanned stops"}:<span className="fw-bold text-dark  fs-small"> {secondsToDurationString(line?.unplanned_duration || 0)}</span></span></span>
                </div>
              </div>
            </div>

          </div>





          <div className="status-card-footer hidden-mobile">
            <p className="text-nowrap fw-bold color-grey-medium-dark me-2">{language?.last_24_hours || "last 24h"}</p>
            <div className="w-100">
              <div className="progress timeline">
                {
                  line.timelinestatus ? line.timelinestatus.map(
                    (item, index) => {
                      return (
                        <div className={`progress-bar ${item}`} role="progressbar" style={{ width: `${100 / line.timelinestatus.length}%` }} aria-valuenow={100 / line.timelinestatus.length} aria-valuemin="0" aria-valuemax="100" />
                      )
                    }
                  ) : (
                    <></>
                    // <ChartItem style={{ className: "tooltip" }} key={1} color={colors["unknown_3"]} width={(100)}></ChartItem>
                  )
                }

                {/* <div className="progress-bar running" role="progressbar" style={{ width: '3%' }} aria-valuenow="3" aria-valuemin="0" aria-valuemax="100"></div>
                <div className="progress-bar low-speed" role="progressbar" style={{ width: '1.4%' }} aria-valuenow="1.4" aria-valuemin="0" aria-valuemax="100"></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (linesLoadingInitial) { return <Loading /> }
  return (
    <div>
      <div id='info-modal' className='col-lg-8' style={{width:"100%", height: "100%"}}>

      <Breadcrumb/>
              
      <Box sx={{ml:2,mr:2,mt:2,display:'flex',justifyContent:'space-between'}}>
        <Box sx={{display:'flex'}}>
          <Typography variant="body" sx={{fontWeight:800, fontSize:38 }}>
          {language?.mission_control || 'Mission Control' }
          </Typography>
          

          
          <Box sx={{ml:2, mt:2}}>
            <EntityFilter hideShift={true}  hideSector={true}/>
          </Box>

        </Box>
       

        <IconButton color="primary" onClick={handleClickOpen}>
          <ErrorOutlineIcon />
        </IconButton>
        <Dialog maxWidth="md" open={open} onClose={handleClose} >
          <DialogContent>
            <img src={where !== 'pt-BR' ? LegendaMissionControlIngles : LegendaMissionControlG} width="800" alt='Legenda'/>
          </DialogContent>
        </Dialog>


      </Box>


      <div className="row gy-4 m-0">
        
        <div className="col-12 col-xl-2">
          <div className="row gy-4 mobile-overflow">
            {areasData.map((area, index_area) => {
              return (cardArea(area));
            })}
          </div>
        </div>

        <div className="col-12 col-xl-10">
          <div className="row gy-4">
            {equipmentsArray.map((line, index_line) => {
                // if (index_line >= (currentPage - 1) * cardsPerPage && index_line < (currentPage) * cardsPerPage) {
                  return (generateMissionControlCard(line));
                // } else { return; }
              })
            }
          </div>
        </div>
     
      </div>
      
      </div>

    </div>
  );
}