import { gql } from '@apollo/client';


export const OEE_STATUS = gql`
  query OeeStatus($time_grain: String, $nav_level: String, $is_shift_filtered: Boolean = false, $in_ids_shifts: String, $in_id_sites: String, $in_id_equipments: String, $in_id_enterprise: Int, $in_id_areas: String , $in_end_time: timestamptz, $in_begin_time: timestamptz) {
    h_piot_oee_score_fix1:h_piot_oee_score_fix1a(args: {in_begin_time: $in_begin_time, in_end_time: $in_end_time, in_id_areas: $in_id_areas, in_id_enterprise: $in_id_enterprise, in_id_equipments: $in_id_equipments, in_id_sites: $in_id_sites, in_ids_shifts: $in_ids_shifts, is_shift_filtered: $is_shift_filtered, nav_level: $nav_level, time_grain: $time_grain}) {
      id_enterprise
      nav_name
      oee_componentes
      oee_info
      oee_timeline
      shift
    }
  }
`;


export const OEE_PROGRESS = gql`
  query OeeProgress($begin_time: timestamptz, $end_time: timestamptz, $in_id_areas: String, $in_id_enterprise: Int, $time_grain: String, $in_id_shifts: String, $in_id_sites: String, $in_id_equipments: String, $is_shift: Boolean, $nav_level: String) {
  h_piot_oee_progress_data_teste1(args: {in_begin_time: $begin_time, in_end_time: $end_time, in_id_areas: $in_id_areas, in_id_enterprise: $in_id_enterprise, in_id_equipments: $in_id_equipments, in_id_sites: $in_id_sites, in_ids_shifts: $in_id_shifts, is_shift_filtered: $is_shift, nav_level: $nav_level, time_grain: $time_grain}) {
    cd_equipment
    cd_shift
    jsonb_agg
  }
}
`;

export const Oee_Func = gql`
  query func($in_id_areas: String = "{30}", $in_id_sites: String = "{30}", $in_id_equipments: String = "{30}", $begin_time: timestamptz, $end_time: timestamptz, $in_ids_shifts: String, $in_id_enterprise: Int ) {
  h_piot_oee_score_lines(args: {begin_time: $begin_time, end_time: $end_time, in_id_sites: $in_id_sites, in_id_equipments: $in_id_equipments, in_id_enterprise: $in_id_enterprise, in_id_areas: $in_id_areas, in_ids_shifts: $in_ids_shifts, }) {
    id_enterprise
    nm_equipment
    oee
    oee_desempenho
    oee_disponibilidade
    oee_qualidade
    oee_timeline
    operational_time
    prod_possible
    running_time
    scrap
    total_prod
  }
}`;


  export const OEEMonthQuery = gql`
  query MyQuery {
    uns_equipment_current_month (order_by: {equipment: {Area: {nm_area: asc}, nm_equipment: asc}}){
      begin_time
      end_time
      oee
      oee_a
      oee_p
      oee_q
      available_time
      running_time
      ideal_production
      net_production
      gross_production
      scrap
      id_equipment
      equipment {
        Area {
          id_enterprise
          id_site
          nm_area
          id_area
        }
        id_area
        id_enterprise
        id_site
        nm_equipment
      }
    }
  }
  `;

  export const OEEDayQuery = gql`
  query MyQuery {
    uns_equipment_current_day (order_by: {equipment: {Area: {nm_area: asc}, nm_equipment: asc}}, where: {equipment: {tp_equipment: {_eq: 3}}}){
      begin_time
      end_time
      oee
      oee_a
      oee_p
      oee_q
      available_time
      running_time
      ideal_production
      net_production
      gross_production
      scrap
      id_equipment
      equipment {
        Area {
          id_enterprise
          id_site
          nm_area
          id_area
        }
        id_area
        id_enterprise
        id_site
        nm_equipment
      }
    }
  }
  `;

  export const OEEWeekQuery = gql`
  query MyQuery {
    uns_equipment_current_week (order_by: {equipment: {Area: {nm_area: asc}, nm_equipment: asc}}, where: {equipment: {tp_equipment: {_eq: 3}}}){
      begin_time
      end_time
      oee
      oee_a
      oee_p
      oee_q
      available_time
      running_time
      ideal_production
      net_production
      gross_production
      scrap
      id_equipment
      equipment {
        Area {
          id_enterprise
          id_site
          nm_area
          id_area
        }
        id_area
        id_enterprise
        id_site
        nm_equipment
      }
    }
  }
  `;

  export const OEEShiftQuery = gql`
  query MyQuery {
    uns_equipment_current_shift (order_by: {equipment: {Area: {nm_area: asc}, nm_equipment: asc}}, where: {equipment: {tp_equipment: {_eq: 3}}}){
      begin_time
      end_time
      oee
      oee_a
      oee_p
      oee_q
      available_time
      running_time
      ideal_production
      net_production
      gross_production
      scrap
      id_equipment
      equipment {
        Area {
          id_enterprise
          id_site
          nm_area
          id_area
        }
        id_area
        id_enterprise
        id_site
        nm_equipment
      }
    }
  }
  `;