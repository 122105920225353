import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import {ReactComponent as Logo} from '../../assets/white-logo.svg'
import User from './components/User'
import Configuration from './components/Configuration'
import { AppBar, Box, Toolbar, } from '@mui/material';

const AppBarPer = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#131822',
  maxHeight: 48,
}));

export default function Header() {

  return (
    <Box sx={{ flexGrow: 1, }}>
      <AppBarPer position="static"
        // position="sticky" position="fixed"
        >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' ,}}>

          <Box></Box>

          <Box sx={{ mt:-2, ml:10}}>
            <Logo width='100px' height='24px'/>
          </Box>

          <Box sx={{  display: 'flex',justifyContent: 'space-between',}}>
            <Configuration />
            <User />
          </Box>

        </Toolbar>
      </AppBarPer>
    </Box>
  );
}
