import React from 'react';
import {Card, Typography, Box, } from '@mui/material';
import {roundNumber, getLanguage} from '../../../utils/utils.js';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

export default function InfoUp({ data, equipment, info, dataProd, dataObjetivos, restTime, status, areaName, }) {
  const size = 1100
  const language = getLanguage();

  let tempoFalta = data?.uns_equipment_current_job?.[0]?.current_expected_time;

  const colorStatus = () => {
    let color = "#407CCC";
    data?.uns_equipment_current_job?.[0]?.current_expected_time <= 7200 ? color ="#EB5757": color = "#407CCC";
    return color;
  } 
  const ordem = data?.uns_equipment_current_job?.[0]?.target
  const prod = data?.uns_equipment_current_job?.[0]?.net_production
  
  const nameStatus = (tempoFalta) => {
    if ( ((prod*100)/ordem)>100 ) {
      return (language?.overproduction || "OVERPRODUCTION");
    } else if (tempoFalta <= 7200 ){
      return (language?.prepar_for_setup || "PREPARE FOR SETUP") ;
    } else {
      return "";
    }
    // console.log( (prod*100)/ordem );
  }

  const timeForm = ( num ) => {
    const dia = num/86400;
    const restoDia = num % 86400;

    const hora = restoDia/3600;
    const restoHora = restoDia%3600;
    
    const minutos = restoHora/60;

    return (
      (Math.trunc(dia) !== 0 ? Math.trunc(dia) + "d  " : "") +
      (Math.trunc(hora) !== 0 ? Math.trunc(hora) + "h  " : "") +
      (Math.trunc(minutos) !== 0 ? Math.trunc(minutos) +  "min " : "") 
    )
  };
  
  return (
    <Box sx={{ display: 'flex', gap:2, }}>
      
      <Card sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height:190, width:'15%' }} elevation={0}>
        <Typography variant="h1" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize:72, }}>
          { equipment?.equipments?.[0]?.nm_equipment }
        </Typography>
      </Card>

      <Card sx={{ display: 'flex', gap:2, width:'85%', height:190 }} elevation={0}>
        
        <Box sx={{ml:2}}>
          <Box sx={{display:'flex', flexDirection:'row', gap:2, ml:1, mt:1.5}}>
            <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 32, mt:1 }} >
              {language?.job || 'Job'}
            </Typography>
            <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 40, color:'#407CCC'}} >
              {data?.uns_equipment_current_job?.[0]?.id_order}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', gap:2, mt:3.5,  mb:-2 }}>
            <Box sx={{ml:1}}>
              <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 24}} >
                {language?.client || 'Client'}
              </Typography>
              <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 40, color:'#407CCC'}} >
                {data?.uns_equipment_current_job?.[0]?.nm_client}
              </Typography>
            </Box>

            <Box sx={{color:'black', height:70, width:'1px', backgroundColor:'#acaeb0', ml:1, mr:1}} />
            
            <Box sx={{mr:7}}>
              <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 24}} >
                {language?.product || 'Product'}
              </Typography>
              <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 36, color:'#407CCC', mt:.5}} >
                { data?.uns_equipment_current_job?.[0]?.nm_product?.length >= 10 ?
                 data?.uns_equipment_current_job?.[0]?.nm_product?.slice(0,10)+"." :
                 data?.uns_equipment_current_job?.[0]?.nm_product }
              </Typography>
            </Box>
          </Box>  
        </Box>

        <Box >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p:1, m:1, }}>
            <Box>
              <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 24}} >
                {language?.order_size || 'Order Size'}
              </Typography>
              <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 48, color:'#407CCC'}} >
                { roundNumber(data?.uns_equipment_current_job?.[0]?.target) }
              </Typography>
            </Box>
            <Box>
              <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 24}} >
                {language?.produced || 'Produced'}
              </Typography>
              <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 48, color:'#407CCC'}} >
                { roundNumber(data?.uns_equipment_current_job?.[0]?.net_production) } {" | "}
                { (data?.uns_equipment_current_job?.[0]?.net_production/data?.uns_equipment_current_job?.[0]?.target * 100).toFixed(1) } 
                %
              </Typography>
            </Box>
            <Box>
              <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 24}} >
                {language?.remaining || 'Remaining'}
              </Typography>
              <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 48, color:colorStatus}} >
                { roundNumber(data?.uns_equipment_current_job?.[0]?.target - data?.uns_equipment_current_job?.[0]?.net_production) }
                  <AccessTimeOutlinedIcon sx={{mx:1}}/>
                  { timeForm(data?.uns_equipment_current_job?.[0]?.current_expected_time) }
              </Typography>
            </Box>
          </Box>

          <Box sx={{ maxWidth: 1100, minHeight: 40, display: 'flex',}}>
          {/* já produzido */}
            <Typography sx={{
                width: ((prod*100)/ordem) * size,
                height: 42, fontSize: 24,
                backgroundColor: colorStatus,
                borderTopLeftRadius: 5, borderBottomLeftRadius:5,
                borderTopRightRadius: Math.round( ((prod*100)/ordem) )>=100 ? 5 : 0 ,
                borderBottomRightRadius: Math.round( ((prod*100)/ordem) )>=100 ? 5 : 0 ,
                display:'flex', justifyContent:'center', 
              }} variant="h6" color="#FFFFFF" >
                { nameStatus(data?.uns_equipment_current_job?.[0]?.current_expected_time) }
               </Typography> 
             
          {/* faltando produzir */}
             <Typography  
              sx={{ 
                width: Math.round((prod*100)/ordem)<=100? (100-((prod*100)/ordem))*size : 0 , height: 42, opacity: 0.6,
                backgroundColor: colorStatus,
                borderBottomRightRadius: 5, borderTopRightRadius: 5,
              }} variant="h6"color={colorStatus} opacity={0.6} > {" "} </Typography>
          </Box>
          
        </Box>

      </Card>

    </Box>
  );
}
