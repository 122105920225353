import './style.css';
import 'font-awesome/css/font-awesome.min.css';
import React from 'react';
import Header from '../Header';
import CompanyLogo from "../../assets/CompanyLogo.svg"
import { Link } from 'react-router-dom';
import { usePromiseTracker } from "react-promise-tracker";
import Footer from '../Footer';
// import DowntimeUploadModal from '../DowntimeUploadModal';
import EventsTab from "../EventsTab";
import { getLanguage } from '../../utils/utils';
import { Box, } from '@mui/material';

export default function LayoutReact(menuData, languageCode, enterpriseLogo) {
  const language = getLanguage();

  const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
    return (
      promiseInProgress &&
      <div class="spinner-grow text-light" role="status"><span class="visually-hidden">Loading...</span></div>
    )
  }

  return (
    <div className="layout">
      {/* <EventsTab/> */}

      <input id="collapse-button" type="checkbox" name="collapse-button" className="ghost controller"></input>
      <label htmlFor="collapse-button" className="collapse-wrapper">
        <Box sx={{ mt:.5, }}>
          <div className="collapse-button">
            <span></span>
            <span></span>
          </div>
        </Box>
      </label>

      <Header />
      
      <div className="layout-content">

        {/* <DowntimeUploadModal /> */}


        <div className="layout-aside">
          <div className="accordion pt-3" id="flush">
            <div className="wrapper">
              {menuData.menuData.map((menuCategory, menuCategory_index) => {
                return menuCategory.map((menuItem, menuItem_index) => {
                  return (
                    <div className={`accordion-item ${menuItem.hidden ? 'd-none' : ''}`}>
                      <h2 className="accordion-header" id={`heading${menuCategory_index}${menuItem_index}`}>
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${menuCategory_index}${menuItem_index}`} aria-expanded="true" aria-controls={`collapse${menuCategory_index}${menuItem_index}`}>
                          <span><i className={`${menuItem.icon ? menuItem.icon : ''}`}></i></span>
                          <span>{menuItem.label[menuData.languageCode]}</span>
                        </button>
                      </h2>
                      <div id={`collapse${menuCategory_index}${menuItem_index}`} className="accordion-collapse collapse" data-bs-parent="#flush" aria-labelledby={`heading${menuCategory_index}${menuItem_index}`} >
                        <div className="accordion-body">
                          <div className="list-group">
                            {
                              menuItem.children ?
                                menuItem.children.map((menuSubItem, menuSubItem_index) => {
                                  if (menuSubItem.URL) {
                                    if (menuSubItem.embedded_URL) {
                                      return (
                                        <Link
                                          className={`${menuSubItem.hidden ? 'd-none' : ''}`}
                                          to={{
                                            pathname: menuSubItem.URL,
                                            state: { embedded_URL: menuSubItem.embedded_URL, customFilters: menuSubItem.customFilters, embedded_URL_auth_email: menuSubItem.embedded_URL_auth_email }
                                          }}>
                                          <span className="list-group-item list-group-item-action">{menuSubItem.label[menuData.languageCode]}</span>
                                        </Link>
                                      );
                                    }
                                    
                                    
                                    
                                    else if (menuSubItem.reportId) {
                                      return (
                                        <Link
                                          className={`${menuSubItem.hidden ? 'd-none' : ''}`}
                                          to={{
                                            pathname: menuSubItem.URL,
                                            state: { reportId: menuSubItem.reportId, dataset: menuSubItem.dataset, customFilters: menuSubItem?.customFilters, embedded_URL_auth_email: menuSubItem?.embedded_URL_auth_email }
                                          }}>
                                          <span className="list-group-item list-group-item-action">{menuSubItem.label[menuData.languageCode]}</span>
                                        </Link>
                                      );
                                    }  
                                    
                                    
                                    
                                    
                                    else {
                                      return (
                                        <Link
                                          className={`${menuSubItem.hidden ? 'd-none' : ''}`}
                                          to={menuSubItem.URL}
                                        >
                                          <span className="list-group-item list-group-item-action">{menuSubItem.label[menuData.languageCode]}</span>
                                        </Link>
                                      );
                                    }
                                  } else {
                                    return (
                                      <span href="#" className={`list-group-item list-group-item-action ${menuSubItem.hidden ? 'd-none' : ''}`}>{menuSubItem.label[menuData.languageCode]}</span>
                                    );
                                  }
                                })
                                : <></>}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
                )
              }
              )}
            </div>

            <div className="company-logo">
              <img alt="" style={{ width: "100%" }} src={menuData.enterpriseLogo || CompanyLogo} className="image" />
            </div>
          </div>
        </div>

        <div className="layout-main">
          <div style={{ minHeight: 'calc(100vh - 150px)' }}>
            {menuData.children}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}
