import React from 'react';
import {Card, CardContent, Typography, Box } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import {roundNumber} from '../../../utils/utils.js';
import moment from 'moment';

export default function InfoUp({ info, dataProd, dataObjetivos, restTime, status, areaName, }) {
  let infos = info?.h_piot_overview_i_get_job_info?.[0];

  function colorStatus(status){
    let color = "#318F29";
    if (status === "lowSpeed") return color ="#FD9729";
    if (status === "stopped") return color ="#C13939";
    return color;
  } 

  // console.log(dataProd?.uns_equipment_current_shift?.[0])
  
  return (
    <Box
      sx={{
        flexGrow: 1 ,
        display: 'flex',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: 2, mx:1, height:100,
        gridTemplateRows: 'auto',
        gridTemplateAreas: 
          `"1 1 1 1 2 2 3"`, 
      }}>
      
      <Card sx={{gridArea: '1',display:'flex', width:'45%', mx:1, alignItems:'center',
        justifyContent:'space-between', }} elevation={3}>
        <Typography variant="h1" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize:40, ml:3 }}>
          { areaName?.sites?.[0]?.nm_site.includes("JAPERI") ? areaName?.sites?.[0]?.nm_site.slice(-3) : areaName?.sites?.[0]?.nm_site }
          {/* { areaName?.areas?.[0]?.nm_site } */}
        </Typography>
        <Typography variant="h1" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize:40, }}>
          { info?.equipments?.[0]?.nm_equipment }
        </Typography>
        <Typography variant="h1" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize:40, mr:2 }}>
          OP: { infos?.id_order } | { roundNumber(infos?.order_size) }
        </Typography>
      </Card>
        
      <Card sx={{ gridArea: '2', width:'40%', }} elevation={3} >
        <CardContent sx={{ maxWidth: 1, alignItems: 'fles-start',display: 'flex', justifyContent: 'space-around',   }} >
          
          <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 32, mt:1 }} >
            Refugo
          </Typography>

          <Box sx={{mt:-1}}>
            <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 24}} >
              Meta
            </Typography>
            <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 40, color:'#4175F5'}} >
              { dataObjetivos?.scrap_targets?.[0]?.vl_shift ? dataObjetivos?.scrap_targets?.[0]?.vl_shift * 100 : "0" } %
            </Typography>
          </Box>
          
          <Box sx={{mt:-1}}>
            <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 24}} >
              Turno Anterior
            </Typography>
            <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 40, color:'#4175F5'}} >
              {/* scrap /gross */}
              { isNaN(dataProd?.uns_equipment_current_shift?.[0]?.prev1_scrap/dataProd?.uns_equipment_current_shift?.[0]?.prev1_gross_production) 
                ?  "0"
                : ((dataProd?.uns_equipment_current_shift?.[0]?.prev1_scrap/dataProd?.uns_equipment_current_shift?.[0]?.prev1_gross_production).toFixed(3) * 100).toFixed(2) } %
            </Typography>
          </Box>
          
          <Box sx={{mt:-1}}>
            <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 24}} >
              Turno Atual
            </Typography>
            <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 40, color:'#4175F5'}} >
              { isNaN(dataProd?.uns_equipment_current_shift?.[0]?.scrap/dataProd?.uns_equipment_current_shift?.[0]?.gross_production) 
                || dataProd?.uns_equipment_current_shift?.[0]?.scrap/dataProd?.uns_equipment_current_shift?.[0]?.gross_production < 0 
                ?  "0" 
                : ((dataProd?.uns_equipment_current_shift?.[0]?.scrap/dataProd?.uns_equipment_current_shift?.[0]?.gross_production).toFixed(3) * 100).toFixed(2) } %
            </Typography>
          </Box>


          {/* <Box sx={{ maxWidth: 1, display: 'flex', alignItems: 'center' }}>  
            <Typography 
              sx={{ width: Math.round(20)*size, maxHeight: 26, backgroundColor:'#0984E3',
                borderTopLeftRadius: 3, borderBottomLeftRadius:3,
                }} variant="h6" color="#0984E3">{ Math.round(20) } % </Typography> 
          
            <Typography 
              sx={{ width: Math.round(80)*size, maxHeight: 26, backgroundColor:'#C4C4C4',
                borderBottomRightRadius: 3, borderTopRightRadius: 3,
                // borderTopLeftRadius: Math.round(planned)===0 ? 3 : 0 ,
                // borderBottomLeftRadius: Math.round(planned)===0 ? 3 : 0 ,
                }} variant="h6" color="#C4C4C4">{ Math.round(80) } % </Typography>
          </Box> */}

        </CardContent>
      </Card>    
      
      <Card sx={{ gridArea: '3',width:'15%' , display:'flex',alignItems:'center',
        justifyContent:'space-evenly', }} elevation={3} >
        
        <Typography variant="h1" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 48,}} >
          { moment().format('HH:mm ') }
        </Typography>
        <CircleIcon  sx={{ fontSize: 70, }} htmlColor={colorStatus(status?.h_piot_get_mission_control_timeline?.[0]?.timelinestatus.slice(-1)?.[0] ) } /> 

      </Card>

    </Box>
  );
}
