import React, { useContext, useState, useRef } from "react";
import './breadcrumb.css';
import '../../components/PackDropdown/pack-dropdown.css';
import { useQuery, useSubscription } from '@apollo/client';
import { VariablesContext } from '../../Context/VariablesContext';
import { BreadcrumbQuery } from './Query';


export default function Site() {
  const { site, setSite, setEnterprise, nm_enterprise } = useContext(VariablesContext);
  const { loading, error, data } = useQuery(BreadcrumbQuery);

  const handleEnterpriseButton = e => {
    setSite(null);
  };

  return (
    <div className="pack-dropdown">
      <div className="pack-dropdown border-start px-2">
        <label className="btn btn-link fw-bold rounded-0 " onClick={() => handleEnterpriseButton()}>
        {nm_enterprise===null?"HOME":nm_enterprise.toLocaleUpperCase()}
        {/* {nm_enterprise.toUpperCase()} */}
        </label>
      </div>
    </div>
    );
}