import React from 'react';

import styled from 'styled-components';

export const Container = styled.div`
    width: ${(props) => props.width}px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
        background: #EAEAED;
        border: none;
        color: var(--grey);
        border-radius: 4px;
        width: 40px;
    }
    .filter-button-active {
        background: var(--primary) !important;
        color: var(--white) !important;
    }
    span {
        color: var(--grey);
        font-weight: bold;
    }
    .filter-span-active {
        color: var(--primary) !important;
    }
`;

const FilterButtonContainer = ({ width, children }) => {
    return(
        <Container width={width}>
            {children}
        </Container>
    );
};

export default FilterButtonContainer;