import React, {useEffect} from 'react';
import { BsChevronRight } from 'react-icons/bs';
import { Card, Title, Value, Content, Item } from '../styles';
import { roundNumber } from '../../../../../utils/utils';

function Shift({data, monthData, dayData }) {
  let production = roundNumber(data?.net_production);
  let productionMonth = roundNumber(monthData?.net_production);
  let productionDay = roundNumber(dayData?.net_production);
  let oeeShift = roundNumber(data?.oee);
  let oeeMonth = monthData?.oee;
  let shiftScrapPercent = 0;
  if(data){
    if(data.gross_production>0){
      shiftScrapPercent=(data.scrap/data.gross_production)*100;
    }
  }

  if (data?.current_shift_scrap_percentage < 0) scrapPercent = 0;

  return (
      <Card width={424} height={432}>
          <Content width="50%" height="100%" direction="column">
              <Item direction="column" top={20}>
                <Title size={24}>Produção Turno</Title>
                <Value size={54} color="#4175F5"> {production}</Value>
                <Value size={20} color="#C13939"> {data?(data.target>0?data.net_prodction*100/data.target:"- "):"- "}% da meta</Value>
              </Item>
              <Item direction="column" top={10}>
                <Title size={24}>OEE Turno</Title>
                <Item noPadding>
                    <Value size={54} color="#BDBDBD"> {(oeeShift*100)?.toFixed()} %</Value> 
                </Item>
              </Item>
              <Item direction="column" top={10}>
                <Title size={24}>Refugo Turno</Title>
                <Item noPadding>
                    <Value size={54} color="#C13939"> {shiftScrapPercent?.toFixed()} %</Value> 
                </Item>
                 
              </Item>

          </Content>

          <Content width="50%" height="100%" direction="column">
              <Item direction="column" top={10}>
                <Title size={20}>Produção Dia</Title>
                <Value size={48} color="#4175F5"> {productionDay}</Value>
                <Value noPadding size={20} color="#C13939" top={10}> {dayData?(dayData.target>0?(dayData.net_production*100/dayData.target).toFixed(1):"- "):"- "}% da meta</Value>
              </Item>
              <Item direction="column" top={10}>
                <Title size={20}>OEE Mês</Title>
                <Item noPadding>
                    <Value size={54} color="#BDBDBD"> {(oeeMonth*100).toFixed()} %</Value> 
                </Item>
              </Item>
              <Item direction="column" top={10}>
                <Title size={20}>Produção Mês</Title>
                <Item noPadding>
                    <Value size={54} color="#4175F5"> {productionMonth} </Value> 
                </Item>
                <Value noPadding size={20} color="#C13939"> {monthData?(monthData.target>0?(monthData.net_production*100/monthData.target).toFixed(1):"- "):"- "}% da meta</Value>

              </Item>
              

          </Content>
          
      </Card>
  );
}

export default Shift;