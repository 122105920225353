import { gql } from "@apollo/client";

/*export const SET_REPLACE_JOB = gql`
mutation ReplaceJob($id_production_order: Int, $new_id: Int) {
    update_production_orders_runtime(where: {id_production_order: {_eq: $id_production_order}}, _set: {id_production_order: $new_id, recalc_needed: true}) {
      affected_rows
      returning {
        id_production_order
      }
    }
  }
  
`*/

export const SET_REPLACE_JOB = gql`
mutation SwitchJobNumber($id_production_order: Int, $new_id: Int) {
  piot_switch_po_number(args: {in_new_production_order: $new_id, in_production_order_running: $id_production_order}) {
    id_production_order
  }
}

`;