import React, { useContext, useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { VariablesContext } from '../../Context/VariablesContext';
import { getLanguage } from '../../utils/utils';

function GrainSelector() {
    const language = getLanguage();
    const { grain, setGrain } = useContext(VariablesContext);
    const [state, setState] = useState(grain);

  const handleChange = (event, newValue) => {
    if (newValue === grain) return;
    setState(newValue);
    setGrain(newValue);
  };
  return (
    <ToggleButtonGroup
      value={state}
      color="primary"
      exclusive
      onChange={handleChange}
      sx={{ border: '1px solid #407CCC', borderRadius: '15px', height: '32px' }}
    >
      <ToggleButton sx={{ 
          borderTopLeftRadius: '15px', borderBottomLeftRadius: '15px',
          height: '32px',
          width: '100px'
          }} value="WEEK"> {language?.weekly || 'Weekly'}</ToggleButton>
      <ToggleButton sx={{ 
          borderTopRightRadius: '15px', borderBottomRightRadius: '15px',
          height: '32px',
          width: '100px'
          }} value="DAY">{language?.daily || 'Daily'} </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default GrainSelector;