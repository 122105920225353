import * as React from 'react';
import { useContext } from "react";
import './style.css';
import { DateRangePickerComponent, PresetsDirective, PresetDirective } from '@syncfusion/ej2-react-calendars';
import moment from 'moment';
import { dateRange } from '../../utils/utils';

import { VariablesContext } from '../../Context/VariablesContext';
import { getLanguage } from '../../utils/utils';

export default function Filter() {
  const language = getLanguage();
  const today_start = moment().startOf('day').format('YYYY-MM-DD HH:mm')
  const today_end = moment().endOf('day').startOf('day').format('YYYY-MM-DD HH:mm')
  const weekStart = moment().startOf('week').format('YYYY-MM-DD HH:mm');
  const weekEnd = moment().endOf('week').startOf('day').format('YYYY-MM-DD HH:mm');
  const monthStart = moment().startOf('month').format('YYYY-MM-DD HH:mm')
  const monthEnd = moment().endOf('month').startOf('day').format('YYYY-MM-DD HH:mm')
  const lastMonthStart = moment().subtract(1,'months').startOf('month').format('YYYY-MM-DD HH:mm')
  const lastMonthEnd = moment().subtract(1,'months').endOf('month').startOf('day').format('YYYY-MM-DD HH:mm')
  const yearStart = moment().startOf('year').format('YYYY-MM-DD HH:mm');
  const yearEnd = moment().endOf('year').startOf('day').format('YYYY-MM-DD HH:mm');

  const { ts_start, ts_end, setTs_start, setTs_end, setRange } = useContext(VariablesContext);

  return (
      <div className='datepicker-control-section'>
        <DateRangePickerComponent
          strictMode={true}
          // min={}
          startDate={moment(ts_start).toDate()} 
          endDate={moment(ts_end).toDate()}
          format='dd/MM/yy'
          change={(e)=>
            {
             const range = dateRange(moment(e.startDate).format('YYYY-MM-DD HH:mm'), e?.daySpan);
             setRange(range);
             setTs_start(moment(e.startDate).format('YYYY-MM-DD HH:mm')); 
             setTs_end(moment(e.endDate).endOf('day').format('YYYY-MM-DD HH:mm'));
            }
          }
          // enablePersistence={true}
          id="filter-period" 
          showClearButton={false} 
          allowEdit={false}  
          placeholder='Period' >
          <PresetsDirective>
            <PresetDirective label={`${language?.today || 'Today' }`} start={today_start} end={today_end}></PresetDirective>
            <PresetDirective label={`${language?.this_week || 'This Week'}`} start={weekStart} end={weekEnd}></PresetDirective>
            <PresetDirective label={`${language?.this_month || 'This Month'}`} start={monthStart} end={monthEnd}></PresetDirective>
            <PresetDirective label={`${language?.last_month || 'Last Month'}`} start={lastMonthStart} end={lastMonthEnd}></PresetDirective>
            {/* <PresetDirective label="Last Year" start={yearStart} end={yearEnd}></PresetDirective> */}
          </PresetsDirective>
        </DateRangePickerComponent>
      </div>
  );
}