import React from 'react';

import { Card, Content, Title, Value } from '../styles';

function Line({ name }) {
  return (
    <Card width={272} height={184}>  
        <Content direction="column" justify="center" align="center" width="100%">
            <Title size={48}>Linha</Title>
            <Value size={36} color="#4175F5"> { name } </Value>
        </Content>
    </Card>
  );
}

export default Line;