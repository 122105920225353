import React, { useContext } from 'react';
import moment from 'moment';
import { userLocale, getLanguage } from '../../utils/utils';
import { VariablesContext } from '../../Context/VariablesContext';

const XTick = (props) => {
  const language = getLanguage();
  const { grain } = useContext(VariablesContext);
  if (props.index === 0 && props.hideFirstTick) return null;
  const isDay = (props?.dateRange === 'today' || props?.dateRange === 'day') ? true : false;
  const isWeek = (props?.dateRange === 'month' && grain === 'WEEK') ? true : false; 
  const locale = userLocale();
  moment().locale(locale.tag);

  if (isDay) {
    let hour = moment(props.payload.value).format(locale.hour);
    return (
      <text style={{ fontWeight: 'bold' }} x={props.x} y={props.y}>
        <tspan style={{ fontWeight: 'bold' }} x={props.x} dy="15" textAnchor="middle" >
          {hour}{(!locale.isAmPm ? 'h' : '')}
        </tspan>
      </text>
    );
  }

 

  if (isWeek) {
    return (
      <text style={{ fontWeight: 'bold' }} x={props.x} y={props.y}>
        <tspan x={props.x} dy="15" textAnchor="middle">
          {language?.week || 'Week'} {moment(props.payload.value).utc().format('WW').toUpperCase()}
        </tspan>
        <tspan x={props.x} dy="22" textAnchor="middle" fill="#666">
          {moment(props.payload.value).utc().format('DD/MM')}
        </tspan>
      </text>
    );
  }

  return (
    <text style={{ fontWeight: 'bold' }} x={props.x} y={props.y}>
      <tspan x={props.x} dy="15" textAnchor="middle">
        {moment(props.payload.value).utc().format('ddd').toUpperCase()}
      </tspan>
      <tspan x={props.x} dy="22" textAnchor="middle" fill="#666">
        {moment(props.payload.value).utc().format('DD/MM')}
      </tspan>
    </text>
  );

};

export default XTick;
