import React, { useRef, useState, useEffect } from 'react';
import { SidebarComponent } from '@syncfusion/ej2-react-navigations';
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import './style.css';
import { auth } from "../../firebase";
import { withRouter } from 'react-router-dom';
// import { useHistory } from "react-router-dom"
import { gql, useQuery } from '@apollo/client';

import * as Icons from 'react-icons/ri';

import { Link } from 'react-router-dom';

function SideBar(props) {
  // const [languageCode, setLanguageCode]=useState("en-US");
  const [sideBarIsClosed, setSideBarIsClosed] = useState(false);
  // const [enterpriseLogo, setEnterpriseLogo] = useState(null)

  var dockBar = useRef(null);
  var sideBarTreeView = useRef(null);
  const enableDock = true;
  const dockSize = '72px';
  const width = '300px';

  useEffect(() => { dockBar.isOpen = false; setSideBarIsClosed(true) }, [])

  //TODO:move to graphql file
  const ExampleGql = gql`
  query MyQuery {
    users {
      user_menu,
      languages,
      Enterprise {
        basic_menu,
        custom_menu,
        logo_url
      }
    }
  }
  `;

  var languageCode = "en-US";
  var rawMenuData = { menu: [] };
  var enterpriseLogo = ""
  const { loading, error, data } = useQuery(ExampleGql);

  if (data) {
    if (data.hasOwnProperty("users")) {
      if (data.users.length > 0) {
        if (data.users[0].hasOwnProperty("Enterprise")) {
          if (data.users[0].Enterprise.hasOwnProperty("logo_url")) {
            enterpriseLogo = data.users[0].Enterprise.logo_url;
          }
          if (data.users[0].Enterprise.hasOwnProperty("basic_menu")) {
            if (data.users[0].Enterprise.basic_menu.hasOwnProperty("basic")) {
              rawMenuData.menu.push(data.users[0].Enterprise.basic_menu.basic);
            }
            if (data.users[0].Enterprise.basic_menu.hasOwnProperty("landingpage_code")) {
              rawMenuData.landingpage_code = data.users[0].Enterprise.basic_menu.landingpage_code;
            }
          }
          if (data.users[0].Enterprise.hasOwnProperty("custom_menu")) {
            if (data.users[0].Enterprise.custom_menu.hasOwnProperty("custom_enterprise")) {
              rawMenuData.menu.push(data.users[0].Enterprise.custom_menu.custom_enterprise);
            }
            if (data.users[0].Enterprise.custom_menu.hasOwnProperty("landingpage_code")) {
              rawMenuData.landingpage_code = data.users[0].Enterprise.custom_menu.landingpage_code;
            }
          }
        }
        if (data.users[0].hasOwnProperty("user_menu")) {
          if (data.users[0].user_menu.hasOwnProperty("custom_user")) {
            rawMenuData.menu.push(data.users[0].user_menu.custom_user);
          }
          if (data.users[0].user_menu.hasOwnProperty("landingpage_code")) {
            rawMenuData.landingpage_code = data.users[0].user_menu.landingpage_code;
          }
        }
        if (data.users[0].hasOwnProperty("languages")) {
          if (data.users[0].languages) {
            languageCode = data.users[0].languages;
          }
        }
      }
      else {
        handleLogout()
      }
    }
  }

  var menuArray = [];
  if (rawMenuData) {
    if (rawMenuData.menu) {
      for (var rawMenuGroup in rawMenuData.menu) {
        if (rawMenuData.menu[rawMenuGroup].length > 0) {
          if (rawMenuData.menu[rawMenuGroup][0].hasOwnProperty("hidden")) {
            if (rawMenuData.menu[rawMenuGroup][0].hidden) {
              if (rawMenuData.menu[rawMenuGroup][0].hasOwnProperty("children")) {
                if (rawMenuData.menu[rawMenuGroup][0].children) {
                  rawMenuData.menu[rawMenuGroup][0].children.forEach((child) => {
                    if (!child.hasOwnProperty("hidden") || (child.hasOwnProperty("hidden") && !child.hidden)) {
                      child.hidden = true;
                    }
                  }
                  );
                }
              }
            }
          }
        }
        menuArray = JSON.parse(JSON.stringify(menuArray)).concat(JSON.parse(JSON.stringify(rawMenuData.menu[rawMenuGroup])));
      }
    }
  }

  const orderMenuItems = function (a, b) {
    var index_a = 0, index_b = 0;

    if (a.hasOwnProperty("order_index")) {
      if (a.order_index) {
        index_a = a.order_index
      } else {
        index_a = 1000;
      }
    } else {
      index_a = 1000;
    }

    if (b.hasOwnProperty("order_index")) {
      if (b.order_index) {
        index_b = b.order_index
      } else {
        index_b = 1000;
      }
    } else {
      index_b = 1000;
    }

    if (index_a < index_b) {
      return -1;
    }
    if (index_b < index_a) {
      return 1;
    }
    return 0;
  }

  const filterHiddenMenu = function (menuItems) {
    const result = []
    for (const parent of menuItems) {
      if (parent.hasOwnProperty("hidden") && parent.hidden) {
        continue
      } else if (parent.children) {
        result.push({
          ...parent,
          children: filterHiddenMenu(parent.children).sort(orderMenuItems),
        })
      } else {
        result.push(parent)
      }
    }
    return result.sort(orderMenuItems)
  }



  var fields = {
    dataSource: filterHiddenMenu(menuArray),
    id: 'code',
    cssClass: "icon",
    child: 'children'
  };

  function nodeTemplate(data) {

    var iconComponent = <div></div>
    if (data.icon) {
      iconComponent = React.createElement(Icons[data.old_icon], { className: "sidemenu-icons", size: 25 })
    }
    var hide = false;
    if (data.hasOwnProperty("hidden")) {
      if (data.hidden) {
        hide = true;
      }
    }

    if (!hide) {
      if (data.URL) {

        if (data.embedded_URL) {
          return (
            <Link to={{
              pathname: data.URL,
              state: { embedded_URL: data.embedded_URL, embedded_URL_auth_email: data.embedded_URL_auth_email }
            }}>
              <li className="sidebar-item">
                {/* <span className="e-icons settings"></span> */}

                {/* { data.icon?React.createElement(Icons[data.icon], { class: "e-icons" }):<div></div>} */}
                {iconComponent}
                <span className="e-text" style={!data.nodeChild ? { marginLeft: "17px" } : {}} title='${data.nodeText}'>{data.label[languageCode]}</span>
              </li>
            </Link>
          );
        } else {
          return (
            <Link to={data.URL}>
              <li className="sidebar-item">
                {/* <span className="e-icons settings"></span> */}
                {iconComponent}

                <span className="e-text" style={!data.nodeChild ? { marginLeft: "17px" } : {}} title={`${data.nodeText}`}>{data.label[languageCode]}</span>
              </li>
            </Link>
          );
        }
      } else {
        return (
          <li className="sidebar-item">
            {/* <span className="e-icons settings"></span> */}
            {iconComponent}
            <span className="e-text" style={!data.nodeChild ? { marginLeft: "17px" } : {}} title='${data.nodeText}'>{data.label[languageCode]}</span>
          </li>
        );
      }
    } else {
      return <div style={{ display: "none" }}></div>
    }
  }

  const currentUrl = window.location.pathname;
  const currentUrlSplited = currentUrl.split('/');
  if (currentUrl === "/") {
    console.log(currentUrl);
    if (rawMenuData) {
      if (rawMenuData.landingpage_code) {
        for (var menuGroup in rawMenuData.menu) {
          if (rawMenuData.menu[menuGroup].code === rawMenuData.landingpage_code) {
            if (rawMenuData.menu[menuGroup].hasOwnProperty('embedded_URL')) {
              if (rawMenuData.menu[menuGroup].embedded_URL) {
                console.log({data: rawMenuData.menu[menuGroup].URL, test: '1'})
                props.history.push(rawMenuData.menu[menuGroup].URL, { embedded_URL: rawMenuData.menu[menuGroup].embedded_URL });
              }
            }
            else {
              console.log({ data: rawMenuData.menu[menuGroup].URL, test: '2'});
              props.history.push(rawMenuData.menu[menuGroup].URL);
            }
          }
          rawMenuData.menu[menuGroup].forEach((menuCategory) => {
            if (menuCategory.hasOwnProperty('children')) {
              if (menuCategory.children) {
                menuCategory.children.forEach((menuSubCategory) => {
                  if (menuSubCategory.code === rawMenuData.landingpage_code) {
                    if (menuSubCategory.hasOwnProperty('embedded_URL')) {
                      if (menuSubCategory.embedded_URL) {
                        console.log({ data: menuSubCategory.URL, test: '3'});
                        props.history.push(menuSubCategory.URL, { embedded_URL: menuSubCategory.embedded_URL });
                      }
                    }
                    else {
                      console.log({ data: menuSubCategory.URL, test: '4' });
                      props.history.push(menuSubCategory.URL);
                    }
                  }
                });
              }
            }
          });
        }
      }
    }
  } else if (currentUrlSplited[1] === "navigate") {
    if (currentUrlSplited.length >= 3) {
      if (rawMenuData) {
        if (currentUrlSplited[2]) {
          for (var menuGroup in rawMenuData.menu) {
            for (var page in rawMenuData.menu[menuGroup]) {
              if (rawMenuData.menu[menuGroup][page].code === currentUrlSplited[2]) {



                if (rawMenuData.menu[menuGroup][page].hasOwnProperty('embedded_URL')) {
                  if (rawMenuData.menu[menuGroup][page].embedded_URL) {
                    if (rawMenuData.menu[menuGroup][page].hasOwnProperty('embedded_URL_auth_email')) {
                      props.history.push(rawMenuData.menu[menuGroup][page].URL, { embedded_URL: rawMenuData.menu[menuGroup][page].embedded_URL, embedded_URL_auth_email: rawMenuData.menu[menuGroup][page].embedded_URL_auth_email });
                    } else {
                      props.history.push(rawMenuData.menu[menuGroup][page].URL, { embedded_URL: rawMenuData.menu[menuGroup][page].embedded_URL });
                    }
                  }
                }
                else {
                  props.history.push(rawMenuData.menu[menuGroup][page].URL);
                }
              }
              rawMenuData.menu[menuGroup].forEach((menuCategory) => {
                if (menuCategory.hasOwnProperty('children')) {
                  if (menuCategory.children) {
                    menuCategory.children.forEach((menuSubCategory) => {
                      if (menuSubCategory.code === currentUrlSplited[2]) {
                        if (menuSubCategory.hasOwnProperty('embedded_URL')) {
                          if (menuSubCategory.embedded_URL) {
                            if (menuSubCategory.hasOwnProperty('embedded_URL_auth_email')) {
                              props.history.push(menuSubCategory.URL, { embedded_URL: menuSubCategory.embedded_URL, embedded_URL_auth_email: menuSubCategory.embedded_URL_auth_email });
                            } else {
                              props.history.push(menuSubCategory.URL, { embedded_URL: menuSubCategory.embedded_URL });
                            }
                          }
                        }
                        else {
                          props.history.push(menuSubCategory.URL);
                        }
                      }
                    });
                  }
                }
              });
            }
          }
        }
      }
    }
  }

  return (
    <div>
      <div className="control-section">
        <div className="col-lg-12 col-sm-12 col-md-12" id="sidebar-section">
          <div id="wrapper">
            <SidebarComponent
              style={{ backgroundColor: "#E3E2E7" }}
              enablePersistence={true}
              // target=".testDashboard"
              zIndex={1000}
              onClose={() => { sideBarTreeView.collapseAll(); }}
              id="dockSidebar"
              ref={Sidebar => dockBar = Sidebar}
              width={width}
              enableDock={enableDock}
              dockSize={dockSize}
              
            // closeOnDocumentClick={true}
            >
              <div className="sideMenuHeader">
                <span id="hamburger" className="e-icons menu default" style={{ color: "white", zIndex: 99999999 }} onClick={() => toggleClick()}></span>
                {/* <div> */}
                      <span id="hamburger" className="e-icons expand" onClick={() => toggleClick()}></span>
                    {/* </div> */}
              </div>
              <div className="dock sideMenuItemContent"
                onClick={()=>{
                  if(!dockBar.isOpen){
                    dockBar.show();
                    sideBarTreeView.expandAll();
                    setSideBarIsClosed(false);
                    sideBarTreeView.disabled=false;
                  }
                }
              }>
                <ul>
                  <div style={{ marginBottom: "15px" }}>
                    
                  </div>
                  <TreeViewComponent
                    className='sidebarTreeView'
                    cssClass='sidebar-item'
                    nodeTemplate={nodeTemplate}
                    id='main-treeview'
                    ref={TreeViewComponent => sideBarTreeView = TreeViewComponent}
                    fields={fields}
                    expandOn='Click'
                  />
                </ul>
              </div>

              <div className="footer" >
                <img
                  className="enterpriseLogo"
                  src={enterpriseLogo}
                  alt="Enterprise Logo"
                  hidden={sideBarIsClosed}
                  width={200}
                />
              </div>

            </SidebarComponent>

            <div id="main-content container-fluid col-md-12 ">
              {props.children}
            </div>
          </div>
        </div>
      </div >

    </div>
  );




  function handleLogout() {
    auth.signOut().then(() => {
      localStorage.clear();
      this.props.history.push("/");
    }).catch((error) => {
    });
  }

  // open / close the sidebar
  function toggleClick() {
    // dockBar.toggle();

    if (dockBar.isOpen) {
      dockBar.hide();
      sideBarTreeView.collapseAll();
      sideBarTreeView.disabled=true;
      setSideBarIsClosed(true);
    }
    else {
      dockBar.show();
      sideBarTreeView.expandAll();
      setSideBarIsClosed(false);
      sideBarTreeView.disabled=false;
    }
  }
}

export default withRouter(SideBar);