import { gql } from '@apollo/client';

export const TIMELINE = gql`
    query GET_EVENTS_TIMELINE($job: Int, $enterprise: Int, $area: String, $site: String, $line: String) {
        h_piot_get_events_timeline_from_po_2(args: {_id_production_order: $job, in_id_enterprise: $enterprise, in_ids_areas: $area, in_ids_equipments: $line, in_ids_sites: $site}, limit: 80) {
        cd_category
        cd_machine
        cd_subcategory
        change_over
        duration
        id_equipment
        status
        ts_end
        ts_event
        txt_downtime_notes
    }
}
`;

export const GET_JOBS = gql`
query GET_JOBS($line: Int) {
  production_orders(where: {id_equipment: {_eq: $line}}) {
    id_order
    id_production_order
  }
}

`