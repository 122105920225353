import React from 'react';
import { Box, Typography } from '@mui/material';
import { getLanguage } from '../../../utils/utils';

const language = getLanguage();

const SubHeader = () => (
    <Box sx={{ width: '100%', height: '64px', border: '1px solid  #BDBDBD', display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ ml: '43px', color: '#4D4D4D', fontWeight: 700, fontSize: '32px' }}>
            {language?.settings ||'Settings'}
        </Typography>
    </Box>
)

export default SubHeader;