import React, { useState, useEffect, useContext } from "react";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Typography, Dialog, DialogTitle, DialogContent, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import { VariablesContext } from '../../../Context/VariablesContext';
import { getLanguage } from '../../../utils/utils';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="bottom-start" arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFFFF',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 650,
    border: '1px solid #AEAEAE',
  },
}));

export function TooltipDowntimeReasons({ children, name, totalTime, occ, avg, lista, translate }) {
  const language = getLanguage();
  const [data, setData] = useState([1,2]);
  const { line, } = useContext(VariablesContext);
  
  useEffect(() => {

    function groupBy(array, f) {
      let groups = {};
      array?.forEach(function(o) {
        var group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
      });
      return Object.keys(groups).map(function(group) {
        return groups[group];
      })
    }

    let listClean = [];
    var listaCleanAgrupada = groupBy(lista, function(item) {
      return [item?.nm_equipment];
      }).map((data) => {
      return {
        duration_total: data?.reduce((total, valor) => total + valor?.duration_total, 0),
        num_occurence: data?.reduce((total, valor) => total + valor?.num_occurence, 0),
        avg_time: data?.reduce((total, valor) => total + valor?.avg_time, 0),
        itens: data
      };
    });
    for (var i in listaCleanAgrupada) {
      let listaDown = {
        cd_category: listaCleanAgrupada[i]?.itens[0]?.cd_category ,
        cd_machine: listaCleanAgrupada[i]?.itens[0]?.cd_machine ,
        nm_equipment: listaCleanAgrupada[i]?.itens[0]?.nm_equipment ,
        duration_total: listaCleanAgrupada[i]?.duration_total,
        num_occurence: listaCleanAgrupada[i]?.num_occurence,
        avg_time: listaCleanAgrupada[i]?.avg_time,
      }
      listClean.push(listaDown);
    }
    // console.log(lista)

    if (listClean?.length <= 5) {
      setData(listClean) 
    } else {

      let newData = listClean?.sort(function(a,b) {
        return (porcentNumberData(a?.duration_total)) < (porcentNumberData(b?.duration_total)) ? 1 : (porcentNumberData(a?.duration_total)) > (porcentNumberData(b?.duration_total)) ? -1 : 0;
      });

      let resto = newData?.slice(4)
      let occ = resto?.map(item => item?.num_occurence).reduce((prev, curr) => prev + curr, 0)
      let durTotal = resto?.map(item => item?.duration_total).reduce((prev, curr) => prev + curr, 0)
      let avgTime = (resto?.map(item => item?.avg_time).reduce((prev, curr) => prev + curr, 0))/resto?.length
     
      let outros = {
        "cd_category": language?.others || 'Others',
        "avg_time": avgTime,
        "num_occurence": occ,
        "duration_total": durTotal,
      }

      let tratado = newData?.slice(0,4)
      tratado?.push(outros)

      setData(tratado)
    } 

  },[children, name, totalTime, occ, avg, lista]);

  const timeForm = ( num ) => {
    const dia = num/86400;
    const restoDia = num % 86400;

    const hora = restoDia/3600;
    const restoHora = restoDia%3600;
    
    const minutos = restoHora/60;

    const restoMinutos = restoHora%60;

    return (
      (Math.trunc(dia) !== 0 ? Math.trunc(dia) + "d  " : "") +
      (Math.trunc(hora) !== 0 ? Math.trunc(hora) + "h  " : "") +
      (Math.trunc(minutos) !== 0 ? Math.trunc(minutos) +  "min " : "") +
      (Math.trunc(restoMinutos) !== 0 ? Math.trunc(restoMinutos) + "seg" : "")
    )
  };
  
  const porcentNumberData = (num) => {
    const total = data?.map(item => item?.duration_total).reduce((prev, curr) => prev + curr, 0);
    return Math.round((num*100)/total);
  }

  return(
  <>
    <HtmlTooltip placement="top"
      title={
        <>
          <DialogTitle id="dialog-title" sx={{mx:-2}} >
            {name}
          </DialogTitle>
          
          <DialogContent sx={{ display: 'flex', justifyContent: 'space-between', mb:-4, mx:-2 }}>
            <Typography > {translate?.total_time || "Total Time"} : </Typography>
            <Typography sx={{fontWeight:700}} > { timeForm(totalTime) } </Typography>
          </DialogContent>

          <DialogContent sx={{ display: 'flex', justifyContent: 'space-between', mb:-4, mx:-2 }}>
            <Typography  > {translate?.occurrences || "Occurrences"} : </Typography>
            <Typography sx={{fontWeight:700}} > {occ} </Typography>
          </DialogContent>

          <DialogContent sx={{ display: 'flex', justifyContent: 'space-between', mb:-1, mx:-2}}>
            <Typography  > {translate?.avg_duration || "Avg. Duration"} : </Typography>
            <Typography sx={{fontWeight:700}} > {timeForm(avg)} </Typography>
          </DialogContent>

          <TableContainer >
            <Table size="small">
              
              <TableHead>
                <TableRow>
                  <TableCell align="left"> 
                  { line===undefined || line?.length===0 ? translate?.line||"Line" : translate?.machine||"Machine" } </TableCell>
                  <TableCell align="center"> % </TableCell>
                  <TableCell align="right">{translate?.count||"Count"}</TableCell>
                  <TableCell align="right">{translate?.total_duration||"Total Duration"}</TableCell>
                  <TableCell align="right">{translate?.avg_duration||"Avg. Duration"}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data?.map((item) => (
                  <TableRow key={item?.cd_category}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      { line===undefined || line?.length===0 
                        ? item?.nm_equipment
                        : item?.cd_machine ? item?.cd_machine : translate?.microstop||"Microstop" 
                       } </TableCell>
                    <TableCell align="center">{ porcentNumberData(item?.duration_total) }</TableCell>
                    <TableCell align="center">{ item?.num_occurence }</TableCell>
                    <TableCell align="center">{ timeForm(item?.duration_total) }</TableCell>
                    <TableCell align="center">{ timeForm(item?.avg_time) }</TableCell>
                  </TableRow>
                ))}
              </TableBody>

            </Table>
          </TableContainer>
        </> 
      }>

        {children}

      </HtmlTooltip>
    </>
  );
}
