import React, { useState, useEffect } from 'react';
import { getLanguage, roundNumber } from '../../../utils/utils';
import CircleIcon from '@mui/icons-material/Circle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ReferenceLine, Label, } from 'recharts';
import {Box, Typography, } from '@mui/material';

function Chart({ data, dataArr12H, target }) {
  const language = getLanguage();
  const [meta, setMeta] = useState('');
  const [avg, setAvg] = useState('');

  useEffect(() => {
      let sumData12h = dataArr12H?.reduce((a, b) => a + b, 0);
      let media = sumData12h / dataArr12H?.length
      setAvg(Math.round(media))
      setMeta(target?.production_targets?.[0]?.vl_hour)
  }, [dataArr12H, target])
  
  const DataFormater = (number) => {
   
    return roundNumber(number)
  }
  
  
  return (
        <Box >
            <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 36, ml:2, mt:1,}} >
                {language?.last || 'Last '} 12h 
            </Typography>
            
            <ResponsiveContainer aspect={2.8} width="100%" height="100%" >
              <BarChart width={500} height={300} data={data} barGap={0}
                margin={{ top: 30, right: 25, left: 5, bottom: 5 }} >
                    
                  <CartesianGrid strokeDasharray="1 1" vertical={false}/>

                  <XAxis dataKey="time" tick={{stroke:'#2D3748', strokeWidth:.8, fontSize:'20' }} />

                  <YAxis
                    tickFormatter={DataFormater}
                    yAxisId="left" orientation="left" stroke="#2D3748" 
                  // domain={[0, 'dataMax + 100']}unit=' un'
                    tick={{ strokeWidth:.8, fontSize:'20', fontWeight:700 }}>
                      {/* <Label value={language?.production || 'Prod.'} offset={20} position="top" fontSize='16' fontWeight={1000} fontFamily='Source Sans Pro' fill='#2D3748' /> */}
                  </YAxis>

                    {/* <YAxis yAxisId="right" orientation="right" stroke="#EB5757" 
                     unit=' un'
                    //  domain={[80, 200]}  
                    //  ticks={[5, 10, 15, 20, 180]}  
                    tick={{ strokeWidth:.8, fontSize:'20', fontWeight:700,  }}>
                        <Label value={language?.scrap || 'Scrap'} offset={16} position="top" fontSize='16' fontWeight={1000} fontFamily='Source Sans Pro' fill='#2D3748' />
                    </YAxis> */}

                <Bar yAxisId="left" barSize={28} dataKey="production" fill="#407CCC" radius={[5, 5, 0, 0]} />
                <Bar yAxisId="left" barSize={28} dataKey="scrap" fill="#EB5757" radius={[5, 5, 0, 0]} />
                    
                <ReferenceLine yAxisId='left' y={meta} stroke="#318F29" strokeWidth={2} isFront={true} ifOverflow="extendDomain" />
                <ReferenceLine yAxisId='left' y={avg} stroke="black" strokeDasharray="3 3"  ifOverflow="extendDomain" />
              </BarChart>
            </ResponsiveContainer>

            <Box sx={{display:'flex', justifyContent: 'center', mr:13 }}>
                <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 20, ml:14 }} >
                    <CircleIcon sx={{ color:'#407CCC', fontSize: 20 }}/> {language?.production || 'Prod.'}
                </Typography>
                <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 20, ml:14 }} >
                    <CircleIcon sx={{ color:'#EB5757', fontSize: 20 }}/> {language?.scrap || 'Scrap'}
                </Typography>
                <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 20, ml:14 }} >
                    <MoreHorizIcon/> {language?.avg_prod || 'Avg. Prod.'}
                </Typography>
                <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 20, ml:14 }} >
                    <HorizontalRuleIcon sx={{ color:'#318F29', fontSize: 30 }} /> {language?.target || 'Target'}
                </Typography>
            </Box>
            
        </Box>
    );
}

export default Chart;