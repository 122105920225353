import React, { useState } from 'react';
import { Card, CardContent, Typography, Box, Table, TableBody, TableCell, 
  TableHead, TableRow,
} from '@mui/material'

export default function TableEvent({data}) {
  const [dense, setDense] = useState(false);
  let type = {fontFamily: "Source Sans Pro", fontWeight: 1000, fontSize: 20} ;
  let treatData = data?.slice(0,4) ;

  function getColor(row) {
    let color = "#333333";
    //nÃo justificada
    if (row === "notJustified") color = "#FD9729";
    //está parado
    if (row === "runningStop") color = "#C13939";
    return color;
  }
  // console.log(data)
  return (
    <Card sx={{ mx:1, }} elevation={3} >

        <CardContent sx={{display:'flex', alignContent: 'flex-start' }}>
            <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 36,  mb:-2}} >
                Últimas Eventos 
            </Typography>
        </CardContent>
       
       <CardContent>
            <Table sx={{ minWidth: 650 }}  size={dense ? 'small' : 'medium'} aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" sx={type} >Início</TableCell>
                    <TableCell align="left" sx={type} >Fim</TableCell>
                    <TableCell align="left" sx={type} >Duração</TableCell>
                    <TableCell align="left" sx={type} >Categoria</TableCell>
                    <TableCell align="left" sx={type} >Sub Categoria</TableCell>
                    <TableCell align="left" sx={type} >Máquina</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                   
                  {treatData?.map((row) => (
                    <TableRow key={row.name} sx={{ color: getColor(row?.colorcolumn), '&:last-child td, &:last-child th': { border: 0 },fontFamily: "Source Sans Pro", fontWeight: 900, fontSize: 20,}} >

                        <TableCell sx={{ color: getColor(row?.colorcolumn), fontFamily: "Source Sans Pro", fontWeight: 900,   fontSize: 20 }}component="th" scope="row">
                          {row?.start}
                        </TableCell>
                        <TableCell sx={{color: getColor(row?.colorcolumn), fontFamily: "Source Sans Pro", fontWeight: 900, fontSize: 20 }} align="left">
                          {row?.end} 
                        </TableCell>
                        <TableCell sx={{color: getColor(row?.colorcolumn),fontFamily: "Source Sans Pro", fontWeight: 900, fontSize: 20 }} align="left">
                          {row?.duration}
                        </TableCell>
                        <TableCell sx={{color: getColor(row?.colorcolumn),fontFamily: "Source Sans Pro", fontWeight: 900, fontSize: 20 }} align="left">
                          {row?.reason}
                        </TableCell>
                        <TableCell sx={{ color: getColor(row?.colorcolumn),fontFamily: "Source Sans Pro", fontWeight: 900, fontSize: 20 }} align="left">
                          {row?.sub_category}
                        </TableCell>
                        <TableCell sx={{color: getColor(row?.colorcolumn),fontFamily: "Source Sans Pro", fontWeight: 900, fontSize: 20 }} align="left">
                          {row?.machine}
                        </TableCell>
                    </TableRow>
                    ))}

                </TableBody>
            </Table>
       </CardContent>

    </Card>
  )
};
