import { gql } from '@apollo/client';

export const MissionControlGqlSubscriptionViewTimeline = gql`
subscription MyQuery {
  c35_v_dashboard_timeline {
    dispositivo
    json_agg
    last_status
    last_status_since
    last_ocorrencia
  }
}
`;

export const MissionControlGqlSubscriptionProducao = gql`
subscription MyQuery {
  c35_dashboard_producao_24h (order_by: {dia: asc}){
    op
    cliente
    diaanterior
    qtdperda
    qtdprevista
    qtdproduzida
    tipodispositivo
    statustarefa
    dispositivo
    tmpprevmin
    tmprealmin
    turnoanterior
  }
}
`;

// export const MissionControlGqlSubscriptionTimeline = gql`
// subscription MyQuery {
// c35_dashboard_timeline_24h {
//     dispositivo
//     varvalue
//   }
// }  
// `;

export const MissionControlGqlSubscriptionViewShiftsData = gql`
subscription MyQuery {
  c35_v_shifts_data (order_by: {nm_equipment: asc, rank_shift: asc}){
    dia
    id_enterprise
    nm_equipment
    qtdperda
    qtdproduzida
    rank_shift
    speed
    tipodispositivo
    turno
    qtdmetaturno
    velmetaturno
    qtdperdakg
    oee
    oee_area
  }
}  
`;

export const MissionControlGqlSubscriptionViewStoppedTime = gql`
subscription MyQuery {
  c35_v_stopped_time {
    id_enterprise
    nm_equipment
    planned_percent
    unplanned_percent
  }
}  
`;

export const MissionControlGqlSubscribeToAll = gql`
subscription MyQuery {
 enterprises {
  C35_Dash_Prod(order_by: {dispositivo: asc, dia: desc, diaanterior: desc, turno: desc}) {
    dia
    cliente
    diaanterior
    qtdperda
    qtdperdakg
    qtdprevista
    qtdproduzida
    statustarefa
    tipodispositivo
    tmpprevmin
    tmprealmin
    turnoanterior
    dispositivo
    op
    qtdproduzidaiot
    oee
    oee_area
  }
  C35_Dash_Paradas {
    duracao
    fim
    op
    operacao
    planejada
  }
  C35_V_Dash_Timeline {
    dispositivo
    json_agg
    last_status
    last_status_since
    last_ocorrencia
  }
  C35_V_Shift {
    dia
    id_enterprise
    nm_equipment
    qtdperda
    qtdproduzida
    rank_shift
    speed
    tipodispositivo
    turno
    qtdmetaturno
    velmetaturno
    qtdperdakg
    oee
    oee_area
  }
  C35_V_Stopped_Time {
    dispositivo
    id_enterprise
    nm_equipment
    planned_percent
    unplanned_percent
    planned_duration
    unplanned_duration
      }
  } 
}  
`;

export const MissionControlGqlQuery = gql`
query MyQuery {
  equipments(where: {_and: [{tp_equipment: {_eq: 3}}]}) {
    id_equipment
    nm_equipment
    id_enterprise
    id_area
    Area{
      nm_area
    }
    custom
  }
  c35_v_dashboard_timeline {
    dispositivo
    json_agg
    last_status
    last_status_since
    last_ocorrencia
  }
  c35_dashboard_paradas_24h {
    duracao
    fim
    op
    operacao
    planejada
  }
  c35_dashboard_producao_24h(order_by: {dispositivo: asc, dia: desc, diaanterior: desc, turno: desc}) {
    dia
    op
    cliente
    diaanterior
    qtdperda
    qtdperdakg
    qtdprevista
    qtdproduzida
    tipodispositivo
    statustarefa
    dispositivo
    tmpprevmin
    tmprealmin
    turnoanterior
    qtdproduzidaiot
    oee
    oee_area
  }
  c35_v_shifts_data (order_by: {nm_equipment: asc, rank_shift: asc}){
    dia
    id_enterprise
    nm_equipment
    qtdperda
    qtdproduzida
    rank_shift
    speed
    tipodispositivo
    turno
    qtdmetaturno
    velmetaturno
    qtdperdakg
    oee
    oee_area
  }
  c35_v_stopped_time {
    id_enterprise
    nm_equipment
    planned_percent
    unplanned_percent
    planned_duration
    unplanned_duration
  }
}  
`;