import React, { Component, createContext } from "react";
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { GetAvailableAssets } from './Query';

var ts_start = moment().startOf('month').format('YYYY-MM-DD hh:mm');
var ts_end = moment().endOf('month').format('YYYY-MM-DD hh:mm');


export const FilterContext = createContext({
    site: [],
    area: [],
    line: [],
    shift: [],
    grain: 'day',
    range: 'month',
    initialized: false,
    ts_start: ts_start,
    ts_end: ts_end
});

class FilterProvider extends Component {
    state = {
        site: [],
        area: [],
        line: [],
        shift: [],
        grain: 'day',
        range: 'month',
        initialized: false,
        ts_start: ts_start,
        ts_end: ts_end
    };

    componentDidMount = () => {

    };

    setFilter = (property, value) => {
        this.setState({ [property]: value });
        if (property === 'range') {
            switch (value) {
                case 'day':
                    ts_start = moment().startOf('day').format('YYYY-MM-DD HH:mm');
                    // ts_end = moment().add(1, 'd').startOf('day').format('YYYY-MM-DD HH:mm');
                    ts_end = moment().endOf('day').format('YYYY-MM-DD HH:mm');
                    this.setState({ ["ts_start"]: ts_start });
                    this.setState({ ["ts_end"]: ts_end });
                    break;
                case 'week':
                    ts_start = moment().startOf('week').format('YYYY-MM-DD HH:mm');
                    // ts_end = moment().add(1, 'week').startOf('week').format('YYYY-MM-DD HH:mm');
                    ts_end = moment().endOf('week').format('YYYY-MM-DD HH:mm');
                    this.setState({ ["ts_start"]: ts_start });
                    this.setState({ ["ts_end"]: ts_end });
                    break;
                case 'month':
                    ts_start = moment().startOf('month').format('YYYY-MM-DD HH:mm');
                    // ts_end = moment().add(1, 'M').startOf('month').format('YYYY-MM-DD HH:mm');
                    ts_end = moment().endOf('month').format('YYYY-MM-DD HH:mm');
                    this.setState({ ["ts_start"]: ts_start });
                    this.setState({ ["ts_end"]: ts_end });
                    break;
                case 'year':
                    ts_start = moment().startOf('year').format('YYYY-MM-DD HH:mm');
                    // ts_end = moment().add(1, 'y').startOf('year').format('YYYY-MM-DD HH:mm');
                    ts_end = moment().endOf('year').format('YYYY-MM-DD HH:mm');
                    this.setState({ ["ts_start"]: ts_start });
                    this.setState({ ["ts_end"]: ts_end });
                    break;
            }
        }
    }

    appendFilter = (property, value) => {
        this.setState({ [property]: [...this.state[property], value] });
    }

    // Drop element from state
    dropFilter = (property, value) => {
        this.setState({ [property]: this.state[property].filter(item => item !== value) });
    }



    render() {
        return (
            <FilterContext.Provider value={{ state: this.state, setFilter: this.setFilter, appendFilter: this.appendFilter }}>
                {this.props.children}
            </FilterContext.Provider>
        );
    }
}
export default FilterProvider;