import React, {useContext} from 'react';
import { VariablesContext } from '../../../Context/VariablesContext';
import { numbertoString, secondsToDurationString } from '../../../utils/utils';
import moment from 'moment';
import { getLanguage } from '../../../utils/utils';

export default function OeeScore({ equipment }) {
  const language = getLanguage();
  const statusText = {
    running: 'Running',
    lowSpeed: 'Low Speed',
    stopped: 'Stopped'
  };
  const low = 0.3;
  const high = 0.8; 
    
  const { ts_start, ts_end } = useContext(VariablesContext);
  
  return (
    <>
      <div className="col-12 col-lg-4">
        <div className="status-card mt-4">

          <div className="status-card-header">
            <div className="row">
              <div className="col-10">
                <div className="d-flex align-items-center">
                  <h5 className="fw-bold me-2">{equipment.nav_name ? equipment.nav_name : ""}</h5>
                  
                  {/* <p> - {equipment?.shift=="null" ? "" : equipment?.shift}</p> */}
                </div>
                <p style={{ color: '#BDBDBD' }}>{language?.data_measured ||'Data measured every 24 hours' }</p>
              </div>

              {/* <div className="col d-flex align-items-center justify-content-end">
                <div className="tooltips">
                  <div className={`status-circle ${equipment.timelinestatus ? equipment.timelinestatus.length >= 1 ? equipment.timelinestatus[equipment.timelinestatus.length - 1] : "unknown" : "unknown"} unplannedTODO`}>
                    <span className="tooltips-text">
                      <p className="fs-small mb-0">{equipment.timelinestatus ? equipment.timelinestatus.length >= 1 ? statusText[equipment.timelinestatus[equipment.timelinestatus.length - 1]] : "Unknown" : "Unknown"}</p>
                    </span>
                  </div>
                </div>
              </div> */}

            </div>
          </div>

          <div className="status-card-body d-flex align-items-center flex-column">

            <h2 className="fw-bold">
              {equipment.oee_componentes.oee ? (equipment.oee_componentes.oee * 100).toFixed(1) : 0}%
            </h2>
            <h6 className="mb-2">
              Total OEE Score
            </h6>

            <div className={`progress custom mb-3 ${equipment.oee_componentes.oee < 0.75 ? (equipment.oee_componentes.oee < 0.5 ? "bg-danger-transp" : "bg-warning-transp") : "bg-success-transp"}`}>
              <div id="oeeProgress" className={`progress-bar ${equipment.oee_componentes.oee < 0.75 ? (equipment.oee_componentes.oee < 0.5 ? "bg-danger" : "bg-warning") : "bg-success"}`} role="progressbar" style={{ width: `${equipment.oee_componentes.oee ? (equipment.oee_componentes.oee * 100).toFixed(1) : 0}%` }} aria-valuenow={equipment.oee_componentes.oee ? (equipment.oee_componentes.oee * 100).toFixed(1) : 0} aria-valuemin="0" aria-valuemax="100"></div>
            </div>

            <div className="grid-container three-to-one-mobile mb-3">
              <div className="grid-item">

                <p className="fw-bold mb-2"> {language?.availability || 'Availability'}</p>

                <div className="grid-container grid-two-custom gapless">

                  <div className="grid-item hide-mobile">
                    <div className={`progress-vertical custom progress-bar-vertical ${equipment.oee_componentes.oee_a < 0.75 ? (equipment.oee_componentes.oee_a < 0.5 ? "bg-danger-transp" : "bg-warning-transp") : "bg-success-transp"}`}>
                      <div id="oeeProgress" className={`progress-bar ${equipment.oee_componentes.oee_a < 0.75 ? (equipment.oee_componentes.oee_a < 0.5 ? "bg-danger" : "bg-warning") : "bg-success"}`} role="progressbar" style={{ height: `${equipment.oee_componentes.oee_a ? (equipment.oee_componentes.oee_a * 100).toFixed(1) : 0}%` }} aria-valuenow={equipment.oee_componentes.oee_a ? (equipment.oee_componentes.oee_a * 100).toFixed(1) : 50} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>

                  <div className="grid-item">
                    <h3 className="fw-bold m-0">
                      {equipment.oee_componentes.oee_a ? (equipment.oee_componentes.oee_a * 100).toFixed(1) : 0}%
                    </h3>
                    <div className="grid-container grid-two-mobile grid-one gapless">
                      <div className="grid-item">
                        <p className="fs-xsmall fw-bold">
                          {language?.possible || 'Possible'}:
                        </p>
                        <p>
                          {secondsToDurationString(equipment.oee_info.available_time ? (equipment.oee_info.available_time) : 0)}
                        </p>

                      </div>
                      <div className="grid-item">
                        <p className="fs-xsmall fw-bold">
                          {language?.producing || 'Producing'}:
                        </p>
                        <p>
                          {secondsToDurationString(equipment.oee_info.running_time ? (equipment.oee_info.running_time) : 0)}
                        </p>

                      </div>
                    </div>
                    <div className={`hide-desktop progress custom mb-3 ${equipment.oee_info.running_time < 0.75 ? (equipment.oee_info.running_time < 0.5 ? "bg-danger-transp" : "bg-warning-transp") : "bg-success-transp"}`}>
                      <div id="oeeProgress" className={`progress-bar ${equipment.oee_info.running_time < 0.75 ? (equipment.oee_info.running_time < 0.5 ? "bg-danger" : "bg-warning") : "bg-success"}`} role="progressbar" style={{ width: `${equipment.oee_info.running_time ? (equipment.oee_info.running_time * 100).toFixed(1) : 0}%` }} aria-valuenow={equipment.oee_info.running_time ? (equipment.oee_info.running_time * 100).toFixed(1) : 0} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="grid-item">

                      <p className="fw-bold mb-2">{language?.performance || 'Performance'}</p>

                      <div className="grid-container grid-two-custom gapless">
                        <div className="grid-item">
                          <div className={`progress-vertical custom progress-bar-vertical overflow-hidden mb-2 ${equipment.oee_componentes.oee_p < 0.75 ? (equipment.oee_componentes.oee_p < 0.5 ? "bg-danger-transp" : "bg-warning-transp") : "bg-success-transp"}`}>
                            <div id="oeeProgress" className={`progress-bar ${equipment.oee_componentes.oee_p < 0.75 ? (equipment.oee_componentes.oee_p < 0.5 ? "bg-danger" : "bg-warning") : "bg-success"}`} role="progressbar" style={{ height: `${equipment.oee_componentes.oee_p ? (equipment.oee_componentes.oee_p * 100).toFixed(1) : 0}%` }} aria-valuenow={equipment.oee_componentes.oee_p >= 1 ? (equipment.oee_componentes.oee_p * 100).toFixed(1) : 50} aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </div>
                        <div className="grid-item">
                          <h3 className="fw-bold m-0">
                            {equipment.oee_componentes.oee_p ? (equipment.oee_componentes.oee_p * 100).toFixed(1) : 0}%
                          </h3>
                          <p className="fs-xsmall fw-bold">
                            {language?.possible || 'Possible'}:
                          </p>
                          <p>
                            {numbertoString(equipment.oee_info.prod_possible || 0)}
                          </p>
                          <p className="fs-xsmall fw-bold">
                            {language?.total_produced || 'Total Produced'}:
                          </p>
                          <p>
                            {numbertoString(equipment.oee_info.total_prod || 0)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="grid-item">

                      <p className="fw-bold mb-2">{language?.quality || 'Quality'}</p>

                      <div className="grid-container grid-two-custom gapless">
                        <div className="grid-item">
                          <div className={`progress-vertical custom progress-bar-vertical overflow-hidden mb-2 ${equipment.oee_componentes.oee_q < 0.75 ? (equipment.oee_componentes.oee_q < 0.5 ? "bg-danger-transp" : "bg-warning-transp") : "bg-success-transp"}`}>
                            <div id="oeeProgress" className={`progress-bar ${equipment.oee_componentes.oee_q < 0.75 ? (equipment.oee_componentes.oee_q < 0.5 ? "bg-danger" : "bg-warning") : "bg-success"}`} role="progressbar" style={{ height: `${equipment.oee_componentes.oee_q ? (equipment.oee_componentes.oee_q * 100).toFixed(1) : 0}%` }} aria-valuenow={equipment.oee_componentes.oee_q ? (equipment.oee_componentes.oee_q * 100).toFixed(1) : 50} aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </div>
                        <div className="grid-item">
                          <h3 className="fw-bold m-0">
                            {equipment.oee_componentes.oee_q ? (equipment.oee_componentes.oee_q * 100).toFixed(1) : 0}%
                          </h3>
                          <p className="fs-xsmall fw-bold">
                            {language?.production || 'Production'}:
                          </p>
                          <p className=" d-flex">
                            {numbertoString(equipment.oee_info.total_prod || 0)}
                          </p>
                          <p className="fs-xsmall fw-bold">
                            {language?.scrap || 'Scrap'}:
                          </p>
                          <p className=" d-flex">
                            {numbertoString(equipment.oee_info.scrap || 0)}
                          </p>
                        </div>
                      </div>
                    </div>

              {/* <div className="grid-item">

                <p className="fw-bold mb-2">Disponibilidade</p>

                <div className="grid-container grid-two-custom gapless">

                  <div className="grid-item hide-mobile">
                    <div className={`hide-desktop progress-vertical custom progress-bar-vertical ${equipment.oee < 0.75 ? (equipment.oee < 0.5 ? "bg-danger-transp" : "bg-warning-transp") : "bg-success-transp"}`}>
                      <div id="oeeProgress" className={`progress-bar ${equipment.oee < 0.75 ? (equipment.oee < 0.5 ? "bg-danger" : "bg-warning") : "bg-success"}`} role="progressbar" style={{ height: `${equipment.oee ? (equipment.oee * 100).toFixed(1) : 0}%` }} aria-valuenow={equipment.oee ? (equipment.oee * 100).toFixed(1) : 50} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>

                  <div className="grid-item">
                    <h3 className="fw-bold m-0">
                      {equipment.oee ? (equipment.oee * 100).toFixed(1) : 0}%
                    </h3>
                    <div className="grid-container grid-two-mobile grid-one gapless">
                      <div className="grid-item">
                        <p className="fs-xsmall fw-bold">
                          Possível:
                        </p>
                        <p>
                          {secondsToDurationString(equipment.running_time ? (equipment.running_time) : 0)}
                        </p>

                      </div>
                      <div className="grid-item">
                        <p className="fs-xsmall fw-bold">
                          Produzindo:
                        </p>
                        <p>
                          {secondsToDurationString(equipment.running_time ? (equipment.running_time) : 0)}
                        </p>

                      </div>
                    </div>
                    <div className={`hide-desktop progress custom mb-3 ${equipment.oee < 0.75 ? (equipment.oee < 0.5 ? "bg-danger-transp" : "bg-warning-transp") : "bg-success-transp"}`}>
                      <div id="oeeProgress" className={`progress-bar ${equipment.oee < 0.75 ? (equipment.oee < 0.5 ? "bg-danger" : "bg-warning") : "bg-success"}`} role="progressbar" style={{ width: `${equipment.oee ? (equipment.oee * 100).toFixed(1) : 0}%` }} aria-valuenow={equipment.oee ? (equipment.oee * 100).toFixed(1) : 0} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>

                </div>
              </div>

              <div className="grid-item">

                <p className="fw-bold mb-2">Disponibilidade</p>

                <div className="grid-container grid-two-custom gapless">

                  <div className="grid-item hide-mobile">
                    <div className={`hide-desktop progress-vertical custom progress-bar-vertical ${equipment.oee < 0.75 ? (equipment.oee < 0.5 ? "bg-danger-transp" : "bg-warning-transp") : "bg-success-transp"}`}>
                      <div id="oeeProgress" className={`progress-bar ${equipment.oee < 0.75 ? (equipment.oee < 0.5 ? "bg-danger" : "bg-warning") : "bg-success"}`} role="progressbar" style={{ height: `${equipment.oee ? (equipment.oee * 100).toFixed(1) : 0}%` }} aria-valuenow={equipment.oee ? (equipment.oee * 100).toFixed(1) : 50} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>

                  <div className="grid-item">
                    <h3 className="fw-bold m-0">
                      {equipment.oee ? (equipment.oee * 100).toFixed(1) : 0}%
                    </h3>
                    <div className="grid-container grid-two-mobile grid-one gapless">
                      <div className="grid-item">
                        <p className="fs-xsmall fw-bold">
                          Possível:
                        </p>
                        <p>
                          {secondsToDurationString(equipment.running_time ? (equipment.running_time) : 0)}
                        </p>

                      </div>
                      <div className="grid-item">
                        <p className="fs-xsmall fw-bold">
                          Produzindo:
                        </p>
                        <p>
                          {secondsToDurationString(equipment.running_time ? (equipment.running_time) : 0)}
                        </p>

                      </div>
                    </div>
                    <div className={`hide-desktop progress custom mb-3 ${equipment.oee < 0.75 ? (equipment.oee < 0.5 ? "bg-danger-transp" : "bg-warning-transp") : "bg-success-transp"}`}>
                      <div id="oeeProgress" className={`progress-bar ${equipment.oee < 0.75 ? (equipment.oee < 0.5 ? "bg-danger" : "bg-warning") : "bg-success"}`} role="progressbar" style={{ width: `${equipment.oee ? (equipment.oee * 100).toFixed(1) : 0}%` }} aria-valuenow={equipment.oee ? (equipment.oee * 100).toFixed(1) : 0} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>

                </div>
              </div> */}


            </div>
          </div>
          <div className="status-card-footer">
            <p className="text-nowrap fw-bold color-grey-medium-dark me-2">{moment(ts_start).format('DD/MM')}</p>
            <div className="w-100 me-2">
              <div className="progress timeline">
                
                {
                  equipment.oee_timeline ? equipment.oee_timeline.map(
                    (item, index) => {
                      return (
                        <div 
                        className={`${item<=high?(item<=low?"light":"medium"):"hard"}`}  
                        // className={`progress-bar ${item}`}  
                        role="progressbar" 
                        style={{ width: `${100 / equipment.oee_timeline.length}%` }} 
                        aria-valuenow={100 / equipment.oee_timeline.length} 
                        aria-valuemin="0" 
                        aria-valuemax="100" />
                      )
                    }
                  ) : (
                    <div className={`progress-bar ${"bg-unknown-3"}`} role="progressbar" style={{ width: `${100}%` }} aria-valuenow={100} aria-valuemin="0" aria-valuemax="100" />
                  )
                }

              </div>
            </div>
            <p className="text-nowrap fw-bold color-grey-medium-dark me-2">{moment(ts_end).format('DD/MM')}</p>

          </div>
        </div>
        
      </div>

  {/*
        <div className="col-12 col-lg-8">
          <div className="status-card mt-4">

            <div className="status-card-header">
              <h2 className="fw-bold me-2">{equipment.equipment.nm_equipment ? equipment.equipment.nm_equipment : ""}</h2>
            </div>

            <div className="status-card-body">
              <div className="row gx-5">
                <div className="col-4">
                  <h6 className="mb-2">
                    Total OEE Score
                  </h6>
                  <h1 className="title-large pb-4">
                    {equipment.oee ? (equipment.oee * 100).toFixed(1) : 0}%
                  </h1>

                  <div className={`progress custom mb-3 ${equipment.oee < 0.75 ? (equipment.oee < 0.5 ? "bg-danger-transp" : "bg-warning-transp") : "bg-success-transp"}`}>
                    <div id="oeeProgress" className={`progress-bar ${equipment.oee < 0.75 ? (equipment.oee < 0.5 ? "bg-danger" : "bg-warning") : "bg-success"}`} role="progressbar" style={{ width: `${equipment.oee ? (equipment.oee * 100).toFixed(1) : 0}%` }} aria-valuenow={equipment.oee ? (equipment.oee * 100).toFixed(1) : 0} aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
                <div className="col-8">
                  <div className="grid-container mb-3">
                    <div className="grid-item">

                      <p className="fw-bold mb-2">Disponibilidade</p>

                      <div className="grid-container grid-two-custom gapless">

                        <div className="grid-item">
                          <div className={`progress-vertical custom progress-bar-vertical ${equipment.oee < 0.75 ? (equipment.oee < 0.5 ? "bg-danger-transp" : "bg-warning-transp") : "bg-success-transp"}`}>
                            <div id="oeeProgress" className={`progress-bar ${equipment.oee < 0.75 ? (equipment.oee < 0.5 ? "bg-danger" : "bg-warning") : "bg-success"}`} role="progressbar" style={{ height: `${equipment.oee ? (equipment.oee * 100).toFixed(1) : 0}%` }} aria-valuenow={equipment.oee ? (equipment.oee * 100).toFixed(1) : 50} aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </div>

                        <div className="grid-item">
                          <h3 className="fw-bold m-0">
                            {equipment.oee ? (equipment.oee * 100).toFixed(1) : 0}%
                          </h3>
                          <p className="fs-xsmall fw-bold">
                            Possível:
                          </p>
                          <p>
                            {secondsToDurationString(equipment.running_time ? (equipment.running_time) : 0)}
                          </p>
                          <p className="fs-xsmall fw-bold">
                            Produzindo:
                          </p>
                          <p>
                            {secondsToDurationString(equipment.running_time ? (equipment.running_time) : 0)}
                          </p>
                        </div>

                      </div>
                    </div>
                    <div className="grid-item">

                      <p className="fw-bold mb-2">oee</p>

                      <div className="grid-container grid-two-custom gapless">
                        <div className="grid-item">
                          <div className={`progress-vertical custom progress-bar-vertical overflow-hidden mb-2 ${equipment.oee_desempenho < 0.75 ? (equipment.oee < 0.5 ? "bg-danger-transp" : "bg-warning-transp") : "bg-success-transp"}`}>
                            <div id="oeeProgress" className={`progress-bar ${equipment.oee_desempenho < 0.75 ? (equipment.oee_desempenho < 0.5 ? "bg-danger" : "bg-warning") : "bg-success"}`} role="progressbar" style={{ height: `${equipment.oee_desempenho ? (equipment.oee_desempenho * 100).toFixed(1) : 0}%` }} aria-valuenow={equipment.oee_desempenho >= 1 ? (equipment.oee_desempenho * 100).toFixed(1) : 50} aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </div>
                        <div className="grid-item">
                          <h3 className="fw-bold m-0">
                            {equipment.oee_desempenho ? (equipment.oee_desempenho * 100).toFixed(1) : 0}%
                          </h3>
                          <p className="fs-xsmall fw-bold">
                            Possível:
                          </p>
                          <p>
                            {numbertoString(equipment.ideal_production || 0)}
                          </p>
                          <p className="fs-xsmall fw-bold">
                            Total Produzido:
                          </p>
                          <p>
                            {numbertoString(equipment.total_prod || 0)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="grid-item">

                      <p className="fw-bold mb-2">Qualidade</p>

                      <div className="grid-container grid-two-custom gapless">
                        <div className="grid-item">
                          <div className={`progress-vertical custom progress-bar-vertical overflow-hidden mb-2 ${equipment.oee_qualidade < 0.75 ? (equipment.oee_qualidade < 0.5 ? "bg-danger-transp" : "bg-warning-transp") : "bg-success-transp"}`}>
                            <div id="oeeProgress" className={`progress-bar ${equipment.oee_qualidade < 0.75 ? (equipment.oee_qualidade < 0.5 ? "bg-danger" : "bg-warning") : "bg-success"}`} role="progressbar" style={{ height: `${equipment.oee_qualidade ? (equipment.oee_qualidade * 100).toFixed(1) : 0}%` }} aria-valuenow={equipment.oee_qualidade ? (equipment.oee_qualidade * 100).toFixed(1) : 50} aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </div>
                        <div className="grid-item">
                          <h3 className="fw-bold m-0">
                            {equipment.oee_qualidade ? (equipment.oee_qualidade * 100).toFixed(1) : 0}%
                          </h3>
                          <p className="fs-xsmall fw-bold">
                            Produção:
                          </p>
                          <p className=" d-flex">
                            {numbertoString(equipment.total_prod || 0)}
                          </p>
                          <p className="fs-xsmall fw-bold">
                            Refugo:
                          </p>
                          <p className=" d-flex">
                            {numbertoString(equipment.scrap || 0)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="status-card-footer">
              <p className="text-nowrap fw-bold color-grey-medium-dark me-2">xx/xx</p>
              <div className="w-100 me-2">
                <div className="progress timeline">
                {
                    equipment.timelinestatus ? equipment.timelinestatus.map(
                      (item, index) => {
                        return (
                          <div className={`progress-bar ${item}`} role="progressbar" style={{ width: `${100 / equipment.timelinestatus.length}%` }} aria-valuenow={100 / equipment.timelinestatus.length} aria-valuemin="0" aria-valuemax="100" />
                        )
                      }
                    ) : (
                      <div className={`progress-bar ${"bg-unknown-3"}`} role="progressbar" style={{ width: `${100}%` }} aria-valuenow={100} aria-valuemin="0" aria-valuemax="100" />
                    )
                  } 

                </div>
              </div>
              <p className="text-nowrap fw-bold color-grey-medium-dark me-2">xx/xx</p>

            </div>
          </div>

        </div> */}
    </>
  );
}