import React, { useState, useEffect, useRef } from 'react';
import {Grid, Select, MenuItem, InputLabel, FormControl, Divider, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
//import Select from "react-select";
import { useMutation, useLazyQuery } from '@apollo/client';
import { EDIT_EVENT } from '../mutations';
import { GET_DOWNTIME_REASONS } from '../queries';
import { getLanguage } from '../../../utils/utils';
import { useSnackbar } from 'notistack';

export default function DialogEdit(props) {
    const { isOpen, handleClose, data, update, isSectorView } = props
    const language = getLanguage();
    const { enqueueSnackbar } = useSnackbar();

    const [reasons, setReasons] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [machine, setMachines] = useState([]);
    const [currentMachine, setSelectedMachine] = useState(null);
    const [currentCategory, setSelectedCategory] = useState(null);
    const [currentSubCategory, setSelectedSubcategory] = useState(null);
    const noteRef = useRef(null);


    const [editEvent, { loading: editEventLoading, error: editEventError }] = useMutation(EDIT_EVENT, {
        onCompleted: () => {
            handleClose();
            enqueueSnackbar(`${language?.success || 'Success'}`, { variant: 'success' });
            update();
        },
        onError: () => {
            handleClose();
            enqueueSnackbar(`${language?.an_error_occured || 'Something went wrong!'}`, { variant: 'error' });
        }

    })

    const [getDowntimesReasons, { data: dataDowntimeReasons, loading: loadingReasons }] = useLazyQuery(GET_DOWNTIME_REASONS,
        {
            onCompleted: () => {
                const machineOptions = dataDowntimeReasons?.equipments?.[0]?.downtime_reasons?.map((item) => (
                    {
                        value: item?.code,
                        label: item?.code
                    }
                ))
                setMachines(machineOptions);
                let selectedMachine = dataDowntimeReasons?.equipments?.[0]?.downtime_reasons?.filter((item) => item?.code === data?.cd_machine)
                const reasonsOptions = selectedMachine?.[0]?.categories?.map((item) => ({
                    value: item?.name?.['en-US'],
                    label: item?.description?.['en-US'],
                    planned_downtime: item?.planned_downtime,
                    change_over: item?.change_over,
                    idle: item?.idle
                }));
                setReasons(reasonsOptions);

                const selectedCategory = selectedMachine?.[0]?.categories?.filter((item) => item?.name?.['en-US'] === data?.cd_category)
                const subcategoriesOptions = selectedCategory?.[0]?.subcategories?.map((item) => ({
                    value: item?.name?.['en-US'],
                    label: item?.name?.['en-US'],
                    planned_downtime: item?.planned_downtime,
                    change_over: item?.change_over,
                    idle: item?.idle
                }));
                setSubcategories(subcategoriesOptions);

            }, fetchPolicy: 'no-cache'
        }
    );


    const onSubmit = event => {
        event.preventDefault();
        let categoryLabel = reasons?.filter((item) => item?.value === currentCategory?.value);
        let subcategoryLabel = subcategories?.filter((item) => item?.value === currentSubCategory?.value);
        let formData = {
            id_event: data?.id_equipment_event,
            category: currentCategory?.value,
            subcategory: currentSubCategory?.value,
            machine: currentMachine?.value,
            txt_category: categoryLabel?.[0]?.label,
            txt_subcategory: subcategoryLabel?.[0]?.label,
            idle: subcategoryLabel?.[0]?.idle ? subcategoryLabel?.[0]?.idle : categoryLabel?.[0]?.idle,
            planned_downtime: subcategoryLabel?.[0]?.planned_downtime ? subcategoryLabel?.[0]?.planned_downtime   : categoryLabel?.[0]?.planned_downtime,
            change_over: subcategoryLabel?.[0]?.change_over ? subcategoryLabel?.[0]?.change_over : categoryLabel?.[0]?.change_over,
            notes: noteRef.current?.value
        }
        let emptyFields = false;

        for (const [key, value] of Object.entries(formData)) {
            if (value === undefined || value === null) {
                if (key !== 'subcategory' && key !== 'txt_subcategory') {
                    emptyFields = true;
                }
            }
        }

        if (emptyFields) {
            enqueueSnackbar('Fill in the remaining information', { variant: 'error' });
            return;
        }

        editEvent({
            variables: {
                id_event: data?.id_equipment_event,
                category: currentCategory?.value,
                subcategory: currentSubCategory?.value,
                machine: currentMachine?.value,
                txt_category: currentCategory?.label,
                txt_subcategory: currentSubCategory?.label,
                notes: noteRef.current?.value,
                planned_downtime: formData?.planned_downtime,
                change_over: formData?.change_over,
                idle: formData?.idle
            }
        })
        // window.location.reload();


    }

    useEffect(() => {
        setSelectedMachine({ value: data?.cd_machine, label: data?.cd_machine });
        setSelectedCategory({ value: data?.cd_category, label: data?.cd_category });
        setSelectedSubcategory({ value: data?.cd_subcategory, label: data?.cd_subcategory });
        if (data?.id_equipment) {
            getDowntimesReasons({ variables: { _eq: isSectorView ? data?.id_sector : data?.id_equipment } })
        }
    }, [data]);



  return(
    <Dialog open={isOpen} onClose={handleClose} fullWidth="sm" maxWidth="sm" >
        <DialogTitle > {language?.edit_downtime ||  'Edit Downtime' } </DialogTitle>
        <DialogTitle sx={{ mt:-1, }}> {language?.youre_about_to_edit || "You're about to edit the following downtime"}  </DialogTitle>
        <Typography sx={{ ml: 3, mb:1, }}> PO {data?.id_order} | {data?.nm_equipment} | {data?.cd_shift} </Typography>
        <form onSubmit={(e) => onSubmit(e) } >
            <DialogContent>
                
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                    {/*
                    <Select 
                        name='machine'
                        value={currentMachine}
                        onChange={(e) => {
                            let selectedMachine = dataDowntimeReasons?.equipments?.[0]?.downtime_reasons?.filter((item) => item?.code === e.value)
                            const reasonsOptions = selectedMachine?.[0]?.categories?.map((item) => ({
                                value: item?.name?.['en-US'],
                                label: item?.description?.['en-US']
                            }));
                            setReasons(reasonsOptions)
                            setSelectedMachine({value: e.value, label: e.label});
                            setSelectedCategory(null);
                            setSelectedSubcategory(null)
                            
                          } }
                        options={machine}
                    />*/}
                    <FormControl fullWidth>
                      <InputLabel id='edit-downtime-machine-label'> {language?.machine || 'Machine'} </InputLabel>
                      <Select
                          labelId="edit-downtime-machine-label"
                          id="edit-downtime-machine"
                          value={currentMachine?.value}
                          label="machine"
                          onChange={(e) => {
                            console.log(e)
                            let selectedMachine = dataDowntimeReasons?.equipments?.[0]?.downtime_reasons?.filter((item) => item?.code === e.target.value)
                            const reasonsOptions = selectedMachine?.[0]?.categories?.map((item) => ({
                                value: item?.name?.['en-US'],
                                label: item?.description?.['en-US'],
                                planned_downtime: item?.planned_downtime,
                                change_over: item?.change_over,
                                idle: item?.idle
                            }));
                            setReasons(reasonsOptions)
                            setSelectedMachine({value: e.target.value });
                            setSelectedCategory(null);
                            setSelectedSubcategory(null)
                            
                          } }
                          sx={{ width: '100%', background: '#fff' }}
                        >
                          {
                            machine?.map((item) => (
                              <MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>
                            ))
                          }
                      </Select>
                    </FormControl>
                       
                    </Grid>
                    <Grid item xs={6}>{/*
                    <Select 
                        value={currentCategory}
                        options={reasons}
                        name="category"
                        onChange={(e) => {
                                let selectedMachineReason = dataDowntimeReasons?.equipments?.[0]?.downtime_reasons?.filter((item) => item?.code === currentMachine?.value)
                                const selectedCategory = selectedMachineReason?.[0]?.categories?.filter((item) => item?.name?.['en-US'] === e.value)
                                // console.log(e.value);
                                const subcategoriesOptions = selectedCategory?.[0]?.subcategories?.map((item) => ({
                                    value: item?.name?.['en-US'],
                                    label: item?.name?.['en-US']
                                }));
                                setSubcategories(subcategoriesOptions);
                                setSelectedCategory({ value: e.value, label: e.label });
                                setSelectedSubcategory(null);
                              } }
                        />*/}
                        <FormControl fullWidth>
                          <InputLabel id='edit-downtime-category-label'> {language?.category || 'Category'} </InputLabel>
                          <Select
                              labelId="edit-downtime-category-label"
                              id="edit-downtime-category"
                              value={currentCategory?.value}
                              label="Category"
                              onChange={(e) => {
                                let selectedMachineReason = dataDowntimeReasons?.equipments?.[0]?.downtime_reasons?.filter((item) => item?.code === currentMachine?.value)
                                const selectedCategory = selectedMachineReason?.[0]?.categories?.filter((item) => item?.name?.['en-US'] === e.target.value)
                                // console.log(e.value);
                                const subcategoriesOptions = selectedCategory?.[0]?.subcategories?.map((item) => ({
                                    value: item?.name?.['en-US'],
                                    label: item?.name?.['en-US'],
                                    planned_downtime: item?.planned_downtime,
                                    change_over: item?.change_over,
                                    idle: item?.idle
                                }));
                                setSubcategories(subcategoriesOptions);
                                setSelectedCategory({ value: e.target.value });
                                setSelectedSubcategory(null);
                              } }
                              sx={{ width: '100%', background: '#fff' }}
                            >
                              {
                                reasons?.map((item) => (
                                  <MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>
                                ))
                              }
                          </Select>

                        </FormControl>
                        
                    </Grid>
                    <Grid item xs={6}>{/*
                    <Select 
                        name="subcategory"
                        value={currentSubCategory}
                        options={subcategories}
                        onChange={(e) => setSelectedSubcategory({ value: e.value, label: e.label })}
                        />*/}
                        <FormControl fullWidth>
                          <InputLabel id='edit-downtime-sub-label'> {language?.sub_category || 'Sub category'} </InputLabel>
                          <Select
                            labelId="edit-downtime-sub-label"
                            id="edit-downtime-sub"
                            value={currentSubCategory?.value}
                            label="SubCategory"
                            onChange={(e) => setSelectedSubcategory({ value: e.target.value })}
                            sx={{ width: '100%', background: '#fff' }}
                          >
                            {
                              subcategories?.map((item) => (
                                <MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>
                              ))
                            }
                        </Select>
                        </FormControl>
                        
                    </Grid>
                    <Grid item xs={12}>
                        
                        <TextField
                            inputRef={noteRef}
                            name="notes"
                            label={language?.notes || 'Notes'}
                            multiline
                            rows={4}
                            defaultValue={data?.txt_downtime_notes}
                            fullWidth
                        />
                        
                    </Grid>
                </Grid>
            </DialogContent>
            <Divider sx={{ mt:1, }}/>
                <DialogActions sx={{ m:2, }}>
                    <Button 
                        sx={{ borderRadius:2, px:3, color: '#000', background: '#EAEAED', '&:hover' : { background: '#EAEAED' } }}
                        variant="contained" disableElevation onClick={() => { handleClose();} }
                        size="small"
                    >
                        {language?.cancel || 'Cancel'}
                    </Button>

                    <Button sx={{ borderRadius: 2, px: 4 }} size="small" variant="contained" type="submit">
                        {language?.save || 'Save'}
                    </Button>
                </DialogActions>
            </form>

        </Dialog>
    )
}