import { gql } from '@apollo/client';

export const GET_TARGETS = gql`
query getTargets($id_equipment: Int!) {
  production_targets(where: {id_equipment: {_eq: $id_equipment}}) {
    equipment {
      nm_equipment
    }
    id_equipment
    vl_day
    vl_month
    vl_week
  }
  scrap_targets(where: {id_equipment: {_eq: $id_equipment}}) {
    vl_day
    id_equipment
  }
}
`;

