import { gql } from '@apollo/client';

export const GetAvailableAssets = gql`
query MyQuery {
  areas {
    id_area
    nm_area
    id_site
  }
  sites {
    id_site
    nm_site
  }
  equipments(where: {tp_equipment: {_eq: 3}}) {
    id_area
    id_site
    id_equipment
    nm_equipment
  }
}
`;