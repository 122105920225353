import React, { useState, useRef } from 'react';
import moment from 'moment';
import 'moment/locale/pt-br';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Typography from '@mui/material/Typography';
import { Grid, Box } from '@mui/material';
import { Nav, NavItem } from './styles';

function eventType(type) {
  let icon = <QueryBuilderOutlinedIcon />;
  switch (type) {
      case 'change_over':
              icon = <ChangeCircleOutlinedIcon />
          break;
  
      default:
          break;
  }
  return icon;
}

function TimeLine({ data }) {
  moment.locale('en');
  const userLanguage = localStorage.getItem('user_language')?.toLowerCase();
  const [activeItem, setActive] = useState(0);

  let events = [];

  const eventsPerDay = data?.map((item) => ( 
    {
      cd_category: item?.cd_category,
      cd_machine: item?.cd_machine,
      cd_subcategory: item?.cd_subcategory,
      change_over: item?.change_over,
      duration: item?.duration,
      id_equipment: item?.id_equipment,
      status: item?.status,
      ts_event: item?.ts_event,
      ts_end: item?.ts_end,
      txt_downtime_notes: item?.txt_downtime_notes,
      ts_index: moment(item.ts_event).format('YYYY-MM-DD') 
    }  
    ));

  const eventsDate = eventsPerDay?.reduce((objeto, elementoAtual) => {
    const grupo = elementoAtual['ts_index'];
    if (!objeto.hasOwnProperty(grupo)) {
      objeto[grupo] = [];
    }
    objeto[grupo].push(elementoAtual);
    return objeto;
  }, {});


  if (eventsDate) {
    events = Object.keys(eventsDate).map((key) => (
      {
        ts_value: key,
        events: eventsDate[key]
      }
    ));
  }

  
  return (
    <Grid container>
      <Grid item xs={4} sx={{ height: 'calc(100vh - 322px)', overflowY: 'scroll' }}>
        <Nav>
          {
            events?.map((item, index) => (
              <NavItem key={index} href={`#ts-${index}`} active={activeItem === index} onClick={() => setActive(index)} >
                  <span>{ moment(item?.ts_value).locale(userLanguage).format('MMMM DD, YYYY') }</span>
                  <p>{moment(item?.ts_value).locale(userLanguage).format('dddd')}</p>
              </NavItem>
            ))
          }
        </Nav>

      </Grid>
      

      <Grid item xs={8} sx={{ height: 'calc(100vh - 322px)', overflowY: 'scroll' }} >
        
        {
          events?.map((item, index) => (
               <div id={index}>
                <Box key={index} id={`ts-${index}`} sx={{ display: 'flex', marginLeft: '19px', alignItems: 'center'}}>
                  <Typography sx={{ marginRight: 2, fontWeight: 'bold', color: '#BDBDBD', fontSize: '16px' }}> {moment(item.ts_value).locale(userLanguage).format('MMMM DD')} </Typography>
                  <Box sx={{ width: '70%', height: 0, border: '1px dashed #D8D7DD' }} />
                </Box>
              {
                item?.events?.map((event, index) => (
                    <Timeline sx={{ ml: -50  }} position='right'>
                      <TimelineItem>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot color="primary">
                            {eventType(event.change_over)}
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                          <Typography sx={{ fontFamily: 'Source Sans Pro', fontSize: '16px', fontWeight: 600, color: '#BDBDBD' }}>
                            {moment(event?.ts_event).format('HH:mm:ss')}
                          </Typography>
                          <Typography sx={{
                            color: '#4175F5',
                            fontFamily: 'Source Sans Pro',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '18px'
                          }}>{event?.cd_category} - {event?.cd_subcategory}</Typography>
                          <Typography>{event.txt_downtime_notes}</Typography>
                        </TimelineContent>
                      </TimelineItem>
                    </Timeline>

                ))
              }
            </div> 
            
          ))
        }
      </Grid>
      
    </Grid>
  );
}

export default TimeLine;