import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { roundNumber } from '../../../../../utils/utils';
import { getLanguage } from '../../../../../utils/utils';


function DefaultTargetTable({ data }) {
  const language = getLanguage();
  const rows = [
    {
      id: data?.production_targets[0]?.id_equipment,
      line: data?.production_targets[0]?.equipment?.nm_equipment,
      scrap: data?.scrap_targets[0]?.vl_day,
      daily: data?.production_targets[0]?.vl_day,
      weekly: data?.production_targets[0]?.vl_week,
      monthly: data?.production_targets[0]?.vl_month,
    }
  ];

  return (
    <TableContainer sx={{ mt: '25px' }}>
      <Table sx={{ minWidth: 600 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell> {language?.line || 'Line'}</TableCell>
            <TableCell align="center">{language?.scrap || 'Scrap'}</TableCell>
            <TableCell align="center">{language?.p_daily || 'P. Daily'}</TableCell>
            <TableCell align="center">{language?.p_weekly || 'P. Weekly'}</TableCell>
            <TableCell align="center">{language?.p_monthly || 'P. Monthly'}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.line}
              </TableCell>
              <TableCell align="center">{row.scrap}%</TableCell>
              <TableCell align="center">{roundNumber(row.daily)}</TableCell>
              <TableCell align="center">{roundNumber(row.weekly)}</TableCell>
              <TableCell align="center">{roundNumber(row.monthly)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DefaultTargetTable;