import { gql } from '@apollo/client';

export const SET_PRODUCTION_TARGET = gql`
mutation setProductionTarget(
  $id_enterprise: Int!
  $id_equipment: Int!
  $vl_day: Int!
) {
  h_piot_set_production_target(
    args: {
      in_id_enterprise: $id_enterprise
      in_id_equipment: $id_equipment
      in_target_day: $vl_day
      proportional: true
    }
  ) {
    id_equipment
    vl_day
    vl_hour
    vl_month
    vl_week
  }
}
`;

export const SET_SCRAP_TARGET = gql`
mutation setScrapTarget(
  $id_enterprise: Int!
  $id_equipment: Int!
  $vl_day: Int
) {
  h_piot_set_scrap_target(
    args: {
      in_id_enterprise: $id_enterprise
      in_id_equipment: $id_equipment
      in_target_day: $vl_day
      proportional: true
    }
  ) {
    vl_month
    vl_day
    vl_week
    vl_hour
    vl_shift
    id_equipment
  }
}
`
