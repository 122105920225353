import React from 'react';
import {AppBar, Box,} from '@mui/material';
import {ReactComponent as Logo} from '../../../assets/white-logo.svg';

export default function Header() {

  return (
    <Box sx={{ flexGrow: 1, }}>
      <AppBar position="static" >
          <Box sx={{
            display:'flex',
            justifyContent:'center',
            alignItems: 'center',
            background: '#131822',
            width: '100%', 
            // height: '64px',
            height: '50px',
          }}>

            <Logo height="26px" />
            
          </Box>
      </AppBar>
    </Box>
  );
};
