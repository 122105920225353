import React, { useState, useEffect, useContext } from 'react';
import { VariablesContext } from '../../Context/VariablesContext';
import ShiftFilter from '../../components/ShiftFilter';
import EquipmentFilter from '../../components/EquipmentFilter';
import AreaFilter from '../../components/AreaFilter';
import SiteFilter from '../../components/SiteFilter';
import SectorFilter from '../../components/SectorFilter';

function EntityFilter(props) {

    const { enterprise, site, area, line, visualizationLevel } = useContext(VariablesContext);

    const styleHidden = { display: 'none' };
    const styleVisible = {};

    return (
        <div className="d-flex">
            <div style={site ? styleHidden : styleVisible}><SiteFilter /></div>
            <div style={area ? styleHidden : styleVisible}><AreaFilter /></div>
            <div style={line ? styleHidden : styleVisible}><EquipmentFilter /></div>
            <div style={props?.hideSector ? styleHidden : styleVisible}><SectorFilter /></div>
            <div style={props?.hideShift ? styleHidden : styleVisible}><ShiftFilter /></div>
        </div>
    );
}

export default EntityFilter;
