import React, { useState } from 'react'
import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  Redirect
} from "react-router-dom";

import { Box, } from '@mui/material';

// components
import Header from './components/Header';
import SubHeader from './components/SubHeader';
import Footer from '../../components/Footer';
import Spacer from '../../components/Spacer';
import { NavItem, SideNavigation } from './styles';

// utils functions
import { getLanguage } from '../../utils/utils';

// pages
import Targets from './Pages/Targets';
import ProductionOrders from './Pages/ProductionOrders';


function Settings() {
  const language = getLanguage();
  let { path, url } = useRouteMatch();
  const [active, setActive] = useState(0);

  const routes = [{
      name: `${language.targets || 'Targets'}`,
      path: `${url}/targets`,
    },
    /*{
      name: `${language.production_orders || 'Production Orders'}`,
      path: `${url}/production-orders`,
    }*/
  ];
  
  return (
    <Box sx={{ backgroundColor: '#F1F3F5', minHeight: '100vh',
      display: 'grid',
      gridTemplateColumns: 'repeat(12, 1fr)',
      gridTemplateRows: '50px 64px auto 100px',
      gridTemplateAreas: 
        `"h h h h h h h h h h h h"
        "t t t t t t t t t t t t"
        "m m c c c c c c c c c c"
        "m m f f f f f f f f f f"`,
    }}>

      <Box sx={{ gridArea: 'h', }}>
        <Header />
      </Box>

      <Box sx={{ gridArea: 't', }}> 
        <SubHeader />
      </Box>

      <Box sx={{ gridArea: 'm', }}> 
        <SideNavigation>
          <ul>
            {
              routes.map((item, index) => (
                <NavItem key={index} active={active === index}>
                  <Link className='custom-link' to={item.path} onClick={() => setActive(index)}> 
                    {item.name} 
                  </Link>
                </NavItem>
              ))
            }
          </ul>
        </SideNavigation>
      </Box>

      <Box sx={{ gridArea: 'c', background: '#F9F9F9' }}> 
        <Switch>
          { /** when path is /settings redirect to the first sub route */ }
          <Route exact path={path}>
            <Redirect to={routes[0].path} />
          </Route>

          <Route path={`${path}/targets`}>
            <Targets />
          </Route>
              
          <Route path={`${path}/production-orders`}>
            <ProductionOrders />
          </Route>
        </Switch>

      </Box>

      <Box sx={{ gridArea: 'f', display: 'flex', alignItems: 'flex-end', background: '#F9F9F9' }}>
        <Footer />
      </Box>

    </Box> 
  )
  
}

export default Settings;
