import React from 'react';
import './style.css';
import Header from '../../components/Header'

import SideBar from '../../components/SideBar'

export default function Navigate() {
    return (
        <div>
            <Header />
            <SideBar>
                <div style={{display: "block", overflow: "auto"}}>
                    <h1>Loading page...</h1>
                </div>
            </SideBar>
        </div>
    );
}