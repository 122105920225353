import { gql } from '@apollo/client';

export const GET_ENTERPRISE_FEATURES = gql`
  query EnterpriseFeatures {
    enterprises {
      id_enterprise
      nm_enterprise
    }
    sites(order_by: {nm_site: asc})  {
      id_site
      nm_site
    }
    areas(order_by: {nm_area: asc})  {
      nm_area
      id_area
      id_site
    }
    equipments(where: {tp_equipment: {_eq: 3}}, order_by: {nm_equipment: asc}) {
      id_area
      id_site
      nm_equipment
      id_equipment
    }
    sectors: equipments(where: {tp_equipment: {_eq: 2}}, order_by: {nm_equipment: asc}) {
      id_area
      id_site
      nm_equipment
      id_equipment
      id_parentequipment
    }
    shifts{
      cd_shift
      id_shift
    }
 }`;