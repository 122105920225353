import React, { useState, useContext, useEffect } from 'react';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import '../../components/PackDropdown/pack-dropdown.css';
import '../../components/Breadcrumb/breadcrumb.css';

import './style.css'
import { VariablesContext } from '../../Context/VariablesContext';

import Select from 'react-select';
import { getLanguage } from '../../utils/utils';

export default function ShiftFilter() {
    const { shiftOptions, filterShift, setFilterShift } = useContext(VariablesContext);
    const language = getLanguage();

    useEffect(() => {
        console.log(filterShift)
    },[filterShift]);

    const handle = (value) => {
        console.log(value);
        // setSelected(value)

        const cleanData = []
        const iterator = value.values();
        for (const value of iterator) {
            cleanData.push(value.value);
        }
        console.log(cleanData);
        setFilterShift(cleanData)
        // console.log();

    }

    const handleChange = (e) => {
    //    let temp = filterShift;
       const temp = [...filterShift]
        if (e?.target?.checked) {
            if (!filterShift.includes(e?.target?.value)) {
                temp.push(e?.target?.value);
                setFilterShift(temp);
            }
        }
        else {
            if (filterShift.includes(e?.target?.value)) {
                temp.splice(temp.indexOf(e?.target?.value), 1);
                setFilterShift(temp);
            }
        }
    }




    let temp=[]

    shiftOptions?.map((shift) => {
        temp.push({
            label: shift.cd_shift,
            value: shift.id_shift
        })});




    return (
        <>
{/* Design correto */}
            {/* <div id="shiftSelector" class="list-group mx-0" onSelect={handle}>
                {shiftOptions.length > 0 ? shiftOptions.map((shift, index) => {
                    return (
                        <label class="list-group-item d-flex gap-2" onClick={()=>alert("AAAAAAAAAAAAAAA")}>
                            <input class="form-check-input flex-shrink-0" type="checkbox" id={shift.id_shift} name={shift.id_shift} value={shift.id_shift} checked={isChecked} onChange={()=>setIsChecked(!isChecked)} />
                            <span>
                                {shift.cd_shift}
                            </span>
                        </label>
                    )
                }) : <li key={0}><p>No shift available</p></li>}
            </div> */}






{/* < Select
                // defaultValue={[colourOptions[2], colourOptions[3]]}
                closeMenuOnSelect={false}
                isMulti
                name="shift"
                options={temp}
                className="basic-multi-select"
                classNamePrefix="select"
                // styles={customStyles}
                onChange={handle}
            /> */}






            <div id="shiftSelector" className='list-group mx-0 rounded bg-transparent'>
                <ul className="rounded p-0 border">
                    {shiftOptions?.length > 0 ? shiftOptions.map((shift, index) => {
                        return (
                            <li key={index} className="list-group-item d-flex gap-2">
                                <CheckBoxComponent label={shift.cd_shift}
                                    value={shift.id_shift}
                                    checked={filterShift.includes(shift.id_shift)}
                                    cssClass="e-info e-round form-check-input flex-shrink-0 width-fit-content"
                                    name="shift"
                                    onChange={handleChange}
                                />
                            </li>
                        )
                    }) : <li key={0}><p> { language?.no_shifts_available || 'No shifts available' } </p></li>}
                </ul>
            </div>
            
            <DropDownButtonComponent
                // beforeOpen={event => beforeOpen(event)}
                target="#shiftSelector"
                cssClass="align-items-center border mx-2"
                className="d-flex rounded e-grey"
            >
                <div className="element-selector shift-selector">
                    <p><i className="ri-filter-line" size={"1em"}></i> { language?.filter_shift || 'Filter Shift' } </p>
                </div>
            </DropDownButtonComponent>
        </>
    );
};