import React, { useState, useEffect, useContext, useTimer } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import LayoutReact from '../LayoutReact'
import { MenuInfoQuery, GET_LANGUAGE } from './Query'
import { handleLogout } from "../../firebase";

import { UserContext } from '../../providers/UserProvider'

function PageModel(props) {
  const { isReady, user } = useContext(UserContext);
  const currentUrl = useLocation().pathname;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isReady && !user.hasOwnProperty("uid")) {
        if (currentUrl !== "/") {
          props.history.push(`/login?redirect=${currentUrl.slice(1)}`);
        } else {
          props.history.push('/login');
        }
      }

    }, 1000);
    return () => clearTimeout(timer);

  }, [isReady, currentUrl, props, user]);

  const [languageCode, setLanguageCode] = useState("en-US");
  const [enterpriseLogo, setEnterpriseLogo] = useState("");
  const [rawMenuData, setRawMenuData] = useState({ menu: [] });
  const [menuArray, setMenuArray] = useState([]);

  const { loading: menuInfoLoading, error: menuInfoError, data: menuInfoData } = useQuery(MenuInfoQuery, {
    onCompleted: (menuInfoData) => {
      menuInfoData?.users?.[0]?.user_name ? localStorage.setItem("piot_username", menuInfoData?.users?.[0]?.user_name) : null;
    }
  });

  const [getLanguage] = useLazyQuery(GET_LANGUAGE, {
    onCompleted: (data) =>
      localStorage.setItem(
        "language_packs",
        JSON.stringify(data?.language_packs?.[0]?.language_pack_desktop)
      ),
  });

  useEffect(() => {
    if (menuInfoData) {
      if (menuInfoData.hasOwnProperty("users")) {
        if (menuInfoData.users.length > 0) {
          var temp_rawMenuData = { menu: [] };
          if (menuInfoData.users[0].hasOwnProperty("Enterprise")) {
            if (menuInfoData.users[0].Enterprise.hasOwnProperty("logo_url")) {
              setEnterpriseLogo(menuInfoData.users[0].Enterprise.logo_url);
            }
            
            if (menuInfoData.users[0].Enterprise.hasOwnProperty("basic_menu")) {
              if (menuInfoData.users[0].Enterprise.basic_menu.hasOwnProperty("basic")) {
                temp_rawMenuData.menu.push(menuInfoData.users[0].Enterprise.basic_menu.basic);
              }
              if (menuInfoData.users[0].Enterprise.basic_menu.hasOwnProperty("landingpage_code")) {
                temp_rawMenuData.landingpage_code = menuInfoData.users[0].Enterprise.basic_menu.landingpage_code;
              }
            }
            if (menuInfoData.users[0].Enterprise.hasOwnProperty("custom_menu")) {
              if (menuInfoData.users[0].Enterprise.custom_menu.hasOwnProperty("custom_enterprise")) {
                temp_rawMenuData.menu.push(menuInfoData.users[0].Enterprise.custom_menu.custom_enterprise);
              }
              if (menuInfoData.users[0].Enterprise.custom_menu.hasOwnProperty("landingpage_code")) {
                temp_rawMenuData.landingpage_code = menuInfoData.users[0].Enterprise.custom_menu.landingpage_code;
              }
            }
          }
          if (menuInfoData.users[0].hasOwnProperty("user_menu")) {
            if (menuInfoData.users[0].user_menu.hasOwnProperty("custom_user")) {
              temp_rawMenuData.menu.push(menuInfoData.users[0].user_menu.custom_user);
            }
            if (menuInfoData.users[0].user_menu.hasOwnProperty("landingpage_code")) {
              temp_rawMenuData.landingpage_code = menuInfoData.users[0].user_menu.landingpage_code;
            }
          }
          if (menuInfoData.users[0].hasOwnProperty("languages")) {
            if (menuInfoData.users[0].languages) {
              setLanguageCode(menuInfoData.users[0].languages);
              localStorage.setItem("user_language", menuInfoData.users[0].languages);
              getLanguage({ variables: { language: menuInfoData.users[0]?.languages }  })
            }
          }
          setRawMenuData(temp_rawMenuData);
        }
        else {
          handleLogout()
        }
      }
    }
  }, [menuInfoData]);

  useEffect(() => {
    if (rawMenuData) {
      if (rawMenuData.menu) {
        var temp_menuArray = [];
        for (var rawMenuGroup in rawMenuData.menu) {
          if (rawMenuData.menu[rawMenuGroup].length > 0) {
            if (rawMenuData.menu[rawMenuGroup][0].hasOwnProperty("hidden")) {
              if (rawMenuData.menu[rawMenuGroup][0].hidden) {
                if (rawMenuData.menu[rawMenuGroup][0].hasOwnProperty("children")) {
                  if (rawMenuData.menu[rawMenuGroup][0].children) {
                    rawMenuData.menu[rawMenuGroup][0].children.forEach((child) => {
                      if (!child.hasOwnProperty("hidden") || (child.hasOwnProperty("hidden") && !child.hidden)) {
                        child.hidden = true;
                      }
                    }
                    );
                  }
                }
              }
            }
          }
          const temp_menuArray_2 = JSON.parse(JSON.stringify(menuArray)).concat(JSON.parse(JSON.stringify(rawMenuData.menu[rawMenuGroup]))).sort(orderMenuItems)
          temp_menuArray.push(temp_menuArray_2);
        }
        temp_menuArray = temp_menuArray.sort(orderMenuItems)
        setMenuArray(temp_menuArray);
      }
    }
  }, [rawMenuData])

  const orderMenuItems = function (a, b) {
    var index_a = 0, index_b = 0;

    if (a.hasOwnProperty("order_index")) {
      if (a.order_index) {
        index_a = a.order_index
      } else {
        index_a = 1000;
      }
    } else {
      index_a = 1000;
    }

    if (b.hasOwnProperty("order_index")) {
      if (b.order_index) {
        index_b = b.order_index
      } else {
        index_b = 1000;
      }
    } else {
      index_b = 1000;
    }

    if (index_a < index_b) {
      return -1;
    }
    if (index_b < index_a) {
      return 1;
    }
    return 0;
  }

  //   const filterHiddenMenu = function (menuItems) {
  //     const result = []
  //     for (const parent of menuItems) {
  //       if (parent.hasOwnProperty("hidden") && parent.hidden) {
  //         continue
  //       } else if (parent.children) {
  //         result.push({
  //           ...parent,
  //           children: filterHiddenMenu(parent.children).sort(orderMenuItems),
  //         })
  //       } else {
  //         result.push(parent)
  //       }
  //     }
  //     return result.sort(orderMenuItems)
  //   }

  // const currentUrl = window.location.pathname;
  const currentUrlSplited = currentUrl.split('/');
  useEffect(() => {
    if (currentUrl === "/") {
      if (rawMenuData) {
        if (rawMenuData.landingpage_code) {
          for (var menuGroup in rawMenuData.menu) {
            if (rawMenuData.menu[menuGroup].code === rawMenuData.landingpage_code) {
              if (rawMenuData.menu[menuGroup].hasOwnProperty('embedded_URL')) {
                if (rawMenuData.menu[menuGroup].embedded_URL) {
                  console.log("teste1")
                  props.history.push(rawMenuData.menu[menuGroup].URL, { embedded_URL: rawMenuData.menu[menuGroup].embedded_URL });
                }
              }
              else {
                console.log("teste2")
                props.history.push(rawMenuData.menu[menuGroup].URL);
              }
            }
            rawMenuData.menu[menuGroup].forEach((menuCategory) => {
              if (menuCategory.hasOwnProperty('children')) {
                if (menuCategory.children) {
                  menuCategory.children.forEach((menuSubCategory) => {
                    if (menuSubCategory.code === rawMenuData.landingpage_code) {

                      if (menuSubCategory.hasOwnProperty('embedded_URL')) {
                        if (menuSubCategory.embedded_URL) {
                          if (menuSubCategory.hasOwnProperty('embedded_URL_auth_email')) {
                            console.log("teste3")
                            props.history.push(menuSubCategory.URL, { embedded_URL: menuSubCategory.embedded_URL, embedded_URL_auth_email: menuSubCategory.embedded_URL_auth_email });
                          } else {
                            console.log("teste4")
                            props.history.push(menuSubCategory.URL, { embedded_URL: menuSubCategory.embedded_URL });
                          }
                        }
                      }
                      else {
                        console.log("teste5")
                        // props.history.push("/login");
                        props.history.push(menuSubCategory.URL);
                      }
                    }
                  })
                }
              }
            });
          }
        }
      }
    } else if (currentUrlSplited[1] === "navigate") {
      if (currentUrlSplited.length >= 3) {
        if (rawMenuData) {
          if (currentUrlSplited[2]) {
            for (var menuGroup in rawMenuData.menu) {
              for (var page in rawMenuData.menu[menuGroup]) {
                if (rawMenuData.menu[menuGroup][page].code === currentUrlSplited[2]) {



                  if (rawMenuData.menu[menuGroup][page].hasOwnProperty('embedded_URL')) {
                    if (rawMenuData.menu[menuGroup][page].embedded_URL) {
                      if (rawMenuData.menu[menuGroup][page].hasOwnProperty('embedded_URL_auth_email')) {
                        props.history.push(rawMenuData.menu[menuGroup][page].URL, { embedded_URL: rawMenuData.menu[menuGroup][page].embedded_URL, embedded_URL_auth_email: rawMenuData.menu[menuGroup][page].embedded_URL_auth_email });
                      } else {
                        props.history.push(rawMenuData.menu[menuGroup][page].URL, { embedded_URL: rawMenuData.menu[menuGroup][page].embedded_URL });
                      }
                    }
                  }
                  else {
                    props.history.push(rawMenuData.menu[menuGroup][page].URL);
                  }
                }
                rawMenuData.menu[menuGroup].forEach((menuCategory) => {
                  if (menuCategory.hasOwnProperty('children')) {
                    if (menuCategory.children) {
                      menuCategory.children.forEach((menuSubCategory) => {
                        if (menuSubCategory.code === currentUrlSplited[2]) {
                          if (menuSubCategory.hasOwnProperty('embedded_URL')) {
                            if (menuSubCategory.embedded_URL) {
                              if (menuSubCategory.hasOwnProperty('embedded_URL_auth_email')) {
                                props.history.push(menuSubCategory.URL, { embedded_URL: menuSubCategory.embedded_URL, embedded_URL_auth_email: menuSubCategory.embedded_URL_auth_email });
                              } else {
                                props.history.push(menuSubCategory.URL, { embedded_URL: menuSubCategory.embedded_URL });
                              }
                            }
                          }
                          else {
                            props.history.push(menuSubCategory.URL);
                          }
                        }
                      });
                    }
                  }
                });
              }
            }
          }
        }
      }
    }
  }, [currentUrlSplited, props.history, currentUrl, rawMenuData])


  if (window.location.pathname.split('/')?.[1] == 'overview'  || window.location.pathname.split('/')?.[1] == 'overview30' || window.location.pathname.split('/')?.[1] == 'OverviewGranado'|| window.location.pathname.split('/')?.[1] == 'OverviewNew' || window.location.pathname.split('/')?.[1] == 'settings' ) {
    return (<>{props.children}</>);
  } else {

    return (
      <LayoutReact menuData={menuArray} languageCode={languageCode} enterpriseLogo={enterpriseLogo}>
        {props.children}
      </LayoutReact>
    );

  }

}

export default withRouter(PageModel);