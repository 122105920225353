import React, { useContext } from "react";
import { VariablesContext } from '../../Context/VariablesContext';
import { useHistory, useLocation } from "react-router-dom";
import './breadcrumb.css';
import '../../components/PackDropdown/pack-dropdown.css';
import Filter from '../Filter';
import Line from './line';
import Area from './area';
import Site from './site';
import Sector from './sector';
import Enterprise from './enterprise';

export default function Breadcrumb() {
  const { setLine, area, site } = useContext(VariablesContext);
  const history = useHistory();
  let location = useLocation();
  // let onde = location.pathname.includes('NewMissionControl');

  return (
    <div className="header-breadcrumb border-bottom">

      <div className="wrapper breadcrumbClass">

        {/* Home */}
        <div className="" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

          <label className="btn btn-link fw-bold px-2" onClick={() => { history.push('/Home'); }}>
            <i className="ri-home-4-fill fa-lg"></i>
          </label>

          <label className="btn-link px-2"
             style={{textDecoration: "none"}}
          >
            <Enterprise />
          </label>

          <i className="ri-arrow-right-s-line me-2"></i>

          <Site />

          <i className="ri-arrow-right-s-line me-2"></i>

          {(site == undefined || site == "") ? <div /> : <Area />}

          {(area == undefined || area == "") ? <div /> : <i className="ri-arrow-right-s-line me-2"></i>}
          {(area == undefined || area == "") ? <div /> : <Line />}

        </div>

        {location.pathname.includes('MissionControl')||location.pathname.includes('NewMissionControl')?<div/>:<Filter />}

      </div>

    </div>
  );
}