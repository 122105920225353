import React, { useState, useRef, useEffect } from 'react';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { Grid, Divider, InputLabel, Select, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, FormControl } from '@mui/material';
//import Select from "react-select";
import { useMutation, useLazyQuery } from '@apollo/client';
import { GET_DOWNTIME_REASONS } from '../queries';
import { DOWNTIME_SPLIT } from '../mutations';
import { getLanguage } from '../../../utils/utils';
import { useSnackbar } from 'notistack';
import moment, { now } from 'moment';

function DialogTrim({ isOpen, handleClose, data, update, isSectorView }) {
  const language = getLanguage();
  const { enqueueSnackbar } = useSnackbar();

  const [reasons, setReasons] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [machine, setMachines] = useState([]);
  const [currentMachine, setSelectedMachine] = useState(null);
  const [currentCategory, setSelectedCategory] = useState(null);
  const [currentSubCategory, setSelectedSubcategory] = useState(null);
  const [currentCategoryOptions, setSelectedCategoryOptions] = useState(null);
  const [start, setStart] = useState(moment(data?.ts_event,'DD-MM-YYYY HH:mm'));
  const [minDate, setMinDate] = useState(moment(data?.ts_event, 'DD-MM-YYYY HH:mm'));
  const [maxDate, setMaxDate] = useState(moment(data?.ts_end, 'DD-MM-YYYY HH:mm'));
  const [dateFormat, setDateFormat] = useState('DD/MM/YYYY HH:mm');
  const noteRef = useRef(null);


  const [getDowntimesReasons, { data: dataDowntimeReasons, loading: loadingReasons }] = useLazyQuery(GET_DOWNTIME_REASONS,
    {
      onCompleted: () => {
        const machineOptions = dataDowntimeReasons?.equipments?.[0]?.downtime_reasons?.map((item) => (
          {
            value: item?.code,
            label: item?.code
          }
        ))
        setMachines(machineOptions);

      }, fetchPolicy: 'no-cache'
    }
  );

  const [dwtSplit, { data: dwtSplitData, loading: dwtSplitLoading, error: dwtSplitError }] = useMutation(DOWNTIME_SPLIT,

    {
      onCompleted: () => {
        handleClose();
        enqueueSnackbar(`${language?.success || 'Success'}`, { variant: 'success' });
        update();
      },
      onError: () => {
        handleClose();
        enqueueSnackbar(`${language?.an_error_occured || 'Something went wrong!'}`, { variant: 'error' });
      },
      fetchPolicy: 'no-cache'
    }
  );

  const onSubmit = event => {
    event.preventDefault();
    let eventStart = data?.ts_event;
    let eventEnd = data?.ts_end;
    let formData = {
      _id_equipment_event: data?.id_equipment_event,
      _planned_downtime: currentCategoryOptions?.planned,
      _notes: noteRef?.current?.value,
      _idle: currentCategoryOptions?.idle,
      _change_over: currentCategoryOptions?.change_over,
      _cd_subcategory: currentSubCategory?.value,
      _cd_machine: currentMachine?.value,
      _cd_category: currentCategory?.value,
      _begin_time: moment(start).format('DD-MM-YYYY HH:mm')
    };
    let emptyFields = false;
    console.log(formData);

    for (const [key, value] of Object.entries(formData)) {
      if (value === undefined) {
        if (key !== '_cd_subcategory') {
          emptyFields = true;
        }
      }
    }

    if (emptyFields) {
      enqueueSnackbar('Fill in the remaining information', { variant: 'error' });
      return;
    }

    if (moment(start).format('DD-MM-YYYY HH:mm') <= eventEnd && moment(start).format('DD-MM-YYYY HH:mm') >= eventStart) {
      dwtSplit({
        variables:
        {
          _id_equipment_event: data?.id_equipment_event,
          _planned_downtime: currentCategoryOptions?.planned,
          _notes: noteRef?.current?.value,
          _idle: currentCategoryOptions?.idle,
          _change_over: currentCategoryOptions?.change_over,
          _cd_subcategory: currentSubCategory.value,
          _cd_machine: currentMachine.value,
          _cd_category: currentCategory.value,
          _begin_time: moment(start).utc().format('YYYY-MM-DD HH:mm')
        }
      })

    } else {
      enqueueSnackbar('Start time must be after the last downtime started!', { variant: 'error' });
    }

  }

  useEffect(() => {
    let format = ['en-US'].includes(localStorage.getItem('user_language')) ? 'MM/DD/YYYY hh:mma' : 'DD/MM/YYYY HH:mm';
    setDateFormat(format);
    setStart(moment(data?.ts_event, 'DD-MM-YYYY HH:mm'));
    setMinDate(moment(data?.ts_event, 'DD-MM-YYYY HH:mm'));
    setMaxDate(moment(data?.ts_end, 'DD-MM-YYYY HH:mm'));
    if (data?.id_equipment) {
      getDowntimesReasons({ variables: { _eq: isSectorView ? data?.id_sector : data?.id_equipment } })
    }

  }, [data]);

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth="sm" maxWidth="sm" >
      <DialogTitle > {language?.split_downtime || 'Trim Downtime'} </DialogTitle>
      <DialogTitle sx={{ mt: -2, }}> {language?.youre_about_to_split || "You're about to split the following downtime:"}  </DialogTitle>

      <Typography sx={{ mb: 2, ml: 3 }}> PO {data?.id_order} | {data?.nm_equipment} | {data?.cd_shift} </Typography>
      <DialogTitle sx={{ mt: -2, }}> {language?.end_time || "End Time:"}  </DialogTitle>
      <Typography sx={{ mb: 2, ml: 3 }}>  {moment(data?.ts_end, 'DD-MM-YYYY HH:mm').format(dateFormat)} </Typography>
      {/* <Typography sx={{ mb: 2, ml: 3 }}>  {data?.ts_end} </Typography> */}
      <DialogTitle sx={{ mt: -2, }}> {language?.fill_information || 'Fill in the remaining information here'} </DialogTitle>
      <form onSubmit={(e) => onSubmit(e)} >
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              {/* <Typography >{language?.time_start || 'Start Time'} </Typography> */}
              <DateTimePicker
                value={start}
                inputFormat={dateFormat}
                ampm={dateFormat==='MM/DD/YYYY hh:mma'?true:false}
                ampmInClock={true}
                onChange={(newValue) => setStart(newValue)}
                label={language?.time_start || 'Start Time'}
                renderInput={(params) => <TextField fullWidth {...params} />}
                minDateTime={minDate}
                maxDateTime={maxDate}
              />
            </Grid>
            <Grid item xs={6}>
              {/*
                      <Select 
                          name='machine'
                          value={currentMachine}
                          onChange={(e) => {
                              let selectedMachine = dataDowntimeReasons?.equipments?.[0]?.downtime_reasons?.filter((item) => item?.code === e.value)
                              const reasonsOptions = selectedMachine?.[0]?.categories?.map((item) => ({
                                  value: item?.name?.['en-US'],
                                  label: item?.description?.['en-US']
                              }));
                              setReasons(reasonsOptions)
                              setSelectedMachine({value: e.value, label: e.label});
                              setSelectedCategory(null);
                              setSelectedSubcategory(null)
                              
                            } }
                          options={machine}
                      /> */}
              <FormControl fullWidth>
                <InputLabel id="cut-downtime-machine-label">{language?.machine || 'Machine'}</InputLabel>
                <Select
                  labelId="cut-downtime-machine-label"
                  id="cut-downtime-machine"
                  value={currentMachine?.value}
                  label="machine"
                  onChange={(e) => {
                    let selectedMachine = dataDowntimeReasons?.equipments?.[0]?.downtime_reasons?.filter((item) => item?.code === e.target.value)
                    const reasonsOptions = selectedMachine?.[0]?.categories?.map((item) => ({
                      value: item?.name?.['en-US'],
                      label: item?.description?.['en-US']
                    }));
                    setReasons(reasonsOptions)
                    setSelectedMachine({ value: e.target.value });
                    setSelectedCategory(null);
                    setSelectedSubcategory(null)

                  }}
                >
                  {
                    machine?.map((item) => (
                      <MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>

            </Grid>
            <Grid item xs={6}>{/*
                    <Select 
                        value={currentCategory}
                        options={reasons}
                        name="category"
                        onChange={(e) => {
                                let selectedMachineReason = dataDowntimeReasons?.equipments?.[0]?.downtime_reasons?.filter((item) => item?.code === currentMachine?.value)
                                const selectedCategory = selectedMachineReason?.[0]?.categories?.filter((item) => item?.name?.['en-US'] === e.value)
                                const subcategoriesOptions = selectedCategory?.[0]?.subcategories?.map((item) => ({
                                    value: item?.name?.['en-US'],
                                    label: item?.name?.['en-US']
                                }));
                                setSubcategories(subcategoriesOptions);
                                setSelectedCategory({ value: e.value, label: e.label });
                                setSelectedSubcategory(null);
                                setSelectedCategoryOptions( { idle: selectedCategory?.[0]?.idle, planned: selectedCategory?.[0]?.planned_downtime, change_over: selectedCategory?.[0]?.change_over} )
                              } }
                        />*/}
              <FormControl fullWidth>
                <InputLabel id="cut-downtime-category-label">{language?.category || 'Category'}</InputLabel>
                <Select
                  labelId="cut-downtime-category-label"
                  id="cut-downtime-category"
                  value={currentCategory?.value}
                  label="Category"
                  onChange={(e) => {
                    let selectedMachineReason = dataDowntimeReasons?.equipments?.[0]?.downtime_reasons?.filter((item) => item?.code === currentMachine?.value)
                    const selectedCategory = selectedMachineReason?.[0]?.categories?.filter((item) => item?.name?.['en-US'] === e.target.value)
                    // console.log(e.value);
                    const subcategoriesOptions = selectedCategory?.[0]?.subcategories?.map((item) => ({
                      value: item?.name?.['en-US'],
                      label: item?.name?.['en-US']
                    }));
                    setSubcategories(subcategoriesOptions);
                    setSelectedCategory({ value: e.target.value });
                    setSelectedSubcategory(null);
                    setSelectedCategoryOptions({ idle: selectedCategory?.[0]?.idle, planned: selectedCategory?.[0]?.planned_downtime, change_over: selectedCategory?.[0]?.change_over })
                  }}
                  sx={{ width: '100%', background: '#fff' }}
                >
                  {
                    reasons?.map((item) => (
                      <MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>

            </Grid>
            <Grid item xs={6}>
              {/*
                    <Select 
                        name="subcategory"
                        value={currentSubCategory}
                        options={subcategories}
                        onChange={(e) => setSelectedSubcategory({ value: e.value, label: e.label })}
                        />*/}
              <FormControl fullWidth>
                <InputLabel id="cut-downtime-sub-label">{language?.sub_category || 'Sub category'}</InputLabel>
                <Select
                  labelId="cut-downtime-sub-label"
                  id="cut-downtime-sub"
                  value={currentSubCategory?.value}
                  label="SubCategory"
                  onChange={(e) => setSelectedSubcategory({ value: e.target.value })}
                  sx={{ width: '100%', background: '#fff' }}
                >
                  {
                    subcategories?.map((item) => (
                      <MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>

            </Grid>
            <Grid item xs={12}>

              <TextField
                inputRef={noteRef}
                name="notes"
                multiline
                label={language?.notes || 'Notes'}
                rows={4}
                fullWidth
              />

            </Grid>
          </Grid>
        </DialogContent>
        <Divider sx={{ mt: 1, }} />
        <DialogActions sx={{ m: 2, }}>
          <Button
            sx={{ borderRadius: 2, px: 3, color: '#000', background: '#EAEAED', '&:hover': { background: '#EAEAED' } }}
            variant="contained" disableElevation onClick={() => { handleClose(); }}
            size="small"
          >
            {language?.cancel || 'Cancel'}
          </Button>

          <Button sx={{ borderRadius: 2, px: 4 }} size="small" variant="contained" type="submit">
            {language?.save || 'Save'}
          </Button>
        </DialogActions>
      </form>

    </Dialog>
  );
}

export default DialogTrim;