import React from 'react';
import { GridToolbarContainer, GridToolbarColumnsButton } from '@mui/x-data-grid';
import {  RiEditLine  } from "react-icons/ri"; 

import ExportCsv from './ExportCsv';
import { Box, IconButton, Typography } from '@mui/material';
import { getLanguage } from '../../../utils/utils';


function CustomToolbar(props) {
  const language = getLanguage();
  return (
    <GridToolbarContainer sx={{ justifyContent: 'space-between', mb: '40px' }}>
      <Typography sx={{ fontWeight: 'bold', color: '#4D4D4D', fontSize: '32px' }}>{language?.production_orders || 'Production Orders'}</Typography>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box>
            <GridToolbarColumnsButton  sx={{ bgcolor: '#EAEAED', width: '140px', borderRadius: '8px', color: '#333333' }} />
        </Box>
        <Box sx={{ ml: '44px' }}>
            <ExportCsv data={props.data} />
        </Box>

        <Box sx={{ ml: '44px' }}>
            <IconButton onClick={props.onClick}>
                <RiEditLine />
            </IconButton>
        </Box>
      </Box>
    
    </GridToolbarContainer>
  );
}

export default CustomToolbar;