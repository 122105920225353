import { gql } from '@apollo/client';

export const PRODUCTION_ORDERS = gql`
query GET_PRODUCTION_ORDERS($id_equipment: Int, $ts_start: timestamptz, $ts_end: timestamptz) {
  h_piot_production_orders_merged(where: {id_equipment: {_eq: $id_equipment}, ts_start: {_gte: $ts_start, _lte: $ts_end}}, order_by: {ts_start: desc}) {
    cd_equipment
    gross_production
    id_equipment
    id_order
    id_production_order
    net_production
    nm_client
    nm_product
    production_ordered
    status
    ts_end
    ts_start
  }
}
`;

export const JOB_DETAILS = gql`
    query GET_JOB_DETAILS($id_order: bigint) {
        production_orders(where: {id_production_order: {_eq: $id_order}}) {
            id_production_order
            product {
            nm_product
            txt_product
            }
            client {
            nm_client
            }
            equipment {
            nm_equipment
            production_speed
            minimum_ideal_performance_threshold
            }
            ts_start
            gross_production
            net_production
            oee
            running_time
            stopped_time
            ideal_production
            txt_production_order_description
            txt_production_order_notes
            speed
            id_order
            ts_end
    }
}
`;

export const TIMELINE = gql`
    query GET_TIMELINE($id_order: Int) {
        h_piot_get_events_timeline_from_po(args: {_id_production_order: $id_order}, limit: 50) {
            cd_category
            cd_machine
            cd_subcategory
            change_over
            duration
            id_enterprise
            id_equipment
            status
            ts_end
            ts_event
            txt_downtime_notes
        }
    }
`;




export const PRODUCTION_ORDERS_NEW = gql`
query GET_PRODUCTION_ORDERS($ts_end: timestamp, $ts_start: timestamp, $id_enterprise: Int, $areas: String, $equipments: String, $shifts: String, $sites: String, $teams: String) {
  h_piot_production_orders_with_runtimes2(args: {_tsend: $ts_end, _tsstart: $ts_start, in_id_enterprise: $id_enterprise, in_ids_areas: $areas, in_ids_equipments: $equipments, in_ids_shifts: $shifts, in_ids_sites: $sites, in_ids_teams: $teams}, order_by: {status: asc, ts_start: desc}) {
    gross_production
    id_order
    id_equipment
    id_production_order
    net_production
    nm_client
    nm_equipment
    nm_product
    production_ordered
    production_final
    ts_end
    ts_start
    status
    runtimes
  }
}
`;

export const GET_JOB_LIST = gql`query getJobList($nm_equipment: String!) {
  production_orders(where: {status: {_in: [1, 4]}, equipment: {nm_equipment: {_eq: $nm_equipment}}}) {
    id_order
    id_production_order
    product {
      nm_product
    }
    status
    client {
      nm_client
    }
    ts_start
    ts_end
  }
}
`