import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  width: ${(props) => (props.isVertical) ? '100%': props.size+'px'};
  height: ${(props) => (props.isVertical) ? props.size+'px' : '1px' };

`;


function Spacer({ size, isVertical }) {
  return <Container size={size} isVertical={isVertical} />;
}

export default Spacer;