import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/firestore';

const FirebaseConfig = {
  apiKey: 'AIzaSyCRK02fBbgho-VSQrjt5bIZzzVdoIgpRGo',
  authDomain: 'fbpackiot.firebaseapp.com',
  databaseURL: 'https://fbpackiot.firebaseio.com',
  projectId: 'fbpackiot',
  storageBucket: 'fbpackiot.appspot.com',
  messagingSenderId: '969023787798'
};

firebase.initializeApp(FirebaseConfig);

export const auth = firebase.auth();




export const handleLogout = function () {
  auth.signOut().then(() => {
    localStorage.clear();
    this.props.history.push("/");
  }).catch((error) => {
  });
}