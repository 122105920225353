import React from 'react';
import { Box, Grid, Skeleton } from '@mui/material';

function LoadingTotalProduction() {
  return (
    <Box>
      <Grid container sx={{ mt: 3 }} >
        <Skeleton variant='rectangular' width={'100%'} height={450}/>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '90%', mt: 1 }}>
              <Skeleton variant='text' width={300} />
          </Box>
      </Grid>

        <Grid container sx={{ mt: 6 }} >
          <Grid item xs={6}>
            <Skeleton variant='text' width={100} />
            <Skeleton variant='text' width={'90%'} height={60} />
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '90%' }}>
              <Skeleton variant='text' width={100} />
            </Box>
          </Grid>

          <Grid item xs={6}>
              <Skeleton variant='text' width={100} />
              <Skeleton variant='text' width={'90%'} height={60} />
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '90%' }}>
                <Skeleton variant='text' width={100} />
              </Box>
          </Grid>

      </Grid>
    </Box>
  );
}

export default LoadingTotalProduction;