import moment from 'moment';
import React from 'react';
import Spacer from '../../../components/Spacer';
import { roundNumber, isInvalidNumber, userLocale } from '../../../utils/utils';
import { getLanguage } from '../../../utils/utils';

const StaticTooltip = ({ chartData, dateRange }) => {
    const language = getLanguage();
    const locale = userLocale();

    let displayTitle = '';

    if (!chartData) return null;
    function validateNumber(number) {
        let validNumber = number
        if(isInvalidNumber(number)) validNumber = 0;
        if(number > 100) validNumber = 100;

        return validNumber;
    }

    let lastDot = chartData?.[chartData?.length - 1];
    let currentDot = {};
    chartData.forEach((item) => {
        if (item.net_production !== null) {
          currentDot = item;
        }
      });
      console.log(currentDot)

    let toGoal = (currentDot?.net_production) / lastDot?.target * 100;
    //let scrapPercent = ((currentDot?.scrap / (currentDot?.net_production + currentDot?.scrap)) * 100);
    let scrapPercent = (currentDot?.scrap_acc) * 100 / currentDot?.net_production;
    let toGoalColor = 'value-default';
    let dateRangeGoalTitle = '';
    let dateRangeGoal = lastDot?.target;
    let projected = lastDot?.trend_line;
    let projectedGoal = (lastDot?.trend_line) / lastDot?.target * 100;
    let projectedGoalColor = 'value-dafault';
    let toGoalText = language?.of_goal || 'of goal';
    let projectedGoalText = language?.of_goal || 'of goal';
    


    toGoal =  validateNumber(toGoal);
    scrapPercent = validateNumber(scrapPercent);
    projectedGoal = validateNumber(projectedGoal);

    let toGoalFormat = '';
    let projectedFormat = '';
      
    console.log(toGoal);
    if (toGoal >= 100) {
        toGoalColor = 'value-success';
        toGoalFormat = toGoal.toFixed() + '%';
    } else {
        toGoalColor = 'value-danger';
        toGoalFormat = toGoal.toFixed() + '%';
    }

    if (projectedGoal >= 100) {
        projectedGoalColor = 'value-success';
        projectedFormat = projectedGoal.toFixed() + '%';
    } else {
        projectedGoalColor = 'value-danger';
        projectedFormat = projectedGoal.toFixed() + '%';

    }

    if (dateRange === 'month') {
        displayTitle =  language?.this_month ||`This Month`;
        dateRangeGoalTitle = language?.monthly_goal || 'Monthly Goal';
    }
    if (dateRange === 'last month') {
        displayTitle = language?.last_month || 'Last Month';
        dateRangeGoalTitle = language?.monthly_goal || 'Monthly Goal'
    }
    
    if (dateRange === 'today') {
        displayTitle =  `${language?.today || 'Today' } - ${moment().format(locale.hour)}${(!locale.isAmPm) ? 'h' : ''}`;
        dateRangeGoalTitle = language?.daily_goal || 'Daily Goal'
    }

    if (dateRange === 'week') {
        displayTitle =  language?.this_week || `This Week`;
        dateRangeGoalTitle = language?.weekly_goal || 'Weekly Goal'
    }


    return (
        <div className="static-tooltip">
            <Spacer size={10} isVertical />
            <span className="tooltip-title m-3"> {displayTitle} </span>

            <div className="tooltip-column m-1">
                <div className="tooltip-items">
                    <span>{language?.total_production || 'Total Production'}</span>
                    <span className="tooltip-item-value">{roundNumber(currentDot?.net_production)}</span>
                    <span className={`tooltip-value ${toGoalColor}`}>{toGoalFormat} {toGoalText}</span>
                </div>

                <div className="tooltip-items">
                    <span> {language?.scrap || 'Scrap'}</span>
                    <span className="tooltip-item-value">{scrapPercent?.toFixed()}%</span>
                    <span className="tooltip-value value-default">({roundNumber(currentDot?.scrap_acc)})</span>
                </div>
                
                <div className="tooltip-divider" />

                <div className="tooltip-items">
                    <span>{dateRangeGoalTitle}</span>
                    <span className="tooltip-item-value"> {roundNumber(dateRangeGoal)}</span>
                    <span style={{ visibility: 'hidden' }} className="tooltip-value value-default">(23,5K)</span>
                </div>

                <div className="tooltip-items">
                    <span>{language?.projected || 'Projected'}</span>
                    <span className="tooltip-item-value">{ roundNumber(projected) }</span>
                    <span className={`tooltip-value ${projectedGoalColor}`}>{projectedFormat} {projectedGoalText}</span>
                </div>
                
            </div>
        </div>
    )
  
};
export default StaticTooltip;
