import React, { useContext, useState } from 'react'
import api from '../../../services/api';
import { getLanguage } from '../../../utils/utils';
import { UserContext } from "../../../providers/UserProvider.jsx";
import {
  Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, Typography, IconButton,
  MenuItem
}  from '@mui/material';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FolderIcon from '@mui/icons-material/Folder';
import CloseIcon from '@mui/icons-material/Close';

import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'

export const StyledDiv = styled.div.attrs({
  className: "dropzone"
})`
  border: 1px dashed #407CCC;
  background-color: #f7f9ff;
  border-radius: 4px;
  cursor: pointer;
  transition: height 0.2s ease;
  ${props => props.isDragActive && dragActive};
  ${props => props.isDragReject && dragReject};
`;

export default function PoRegistration() {
  const [open, setOpen] = useState(false);
  const user = useContext(UserContext);
  const language = getLanguage();
  const [fileList, setFileList] = useState();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFileList();
    setError();
    setSuccess();
  };

  async function handleUploadPoCsv() {
    // console.log(fileList?.[0]?.file)
    const data = new FormData();
    data.append("file", fileList?.[0]?.file);
    let successfull = false;
    try {
      await api.post('/upload/po_csv_validation', data,
        {
          headers: { "Content-type": "multipart/form-data", 'uid': user.user.uid },
        });
      successfull = true;
    } catch (err) {
      // console.log(err.response.data);
      if (err.response.data.keyword == "unauthorized"){
        setError( language?.unauthorized  || "User unauthorized " )
      } else if(err.response.data.keyword == "unknown"){
        setError( language?.not_save  || "Not able to save " )
      } else {
        let errorType = err.response.data.keyword + "_error";
        let element_error = err.response.data.element_error;
        let error_value = err.response.data.error_value;
  
        // setError(err.response.data.message)
        setError(
          (language?.filed_error + ": " || "Error in the field: ") +
          element_error + " ( " + error_value + " )" + " - " + 
          (language?.[errorType] || "Invalid value")
        )
      }
      
    }
    if (successfull) {
      setSuccess(true)
      await new Promise(res => setTimeout(res, 3000));
      handleClose()
    }
  }

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    maxFiles:1, 
    accept:'.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values',
    onDrop: acceptedFiles => {
      const fileOk = acceptedFiles.map(file => ({
        file, 
        progress:0,
        uploaded:false, 
        error:null
      }))
    setFileList( fileOk );
    setError();
  }
  });
    
  // console.log(fileList?.[0]?.file)
  const fileDescription = (filee) => (<>
    <Box sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridTemplateAreas: 
          `"img name name name"
          "img below below below "
          "low low low low"`, mt:5,
        color: error!==undefined ? '#C13939' : success===true? '#318F29' : '#407CCC',
      }}>
      <InsertDriveFileIcon sx={{ gridArea:"img", fontSize:35 }}/>  
        <Typography variant="h6" sx={{fontFamily:"Source Sans Pro", gridArea:"name", fontSize:16, ml:-11}}>
          {filee?.[0]?.file?.path }
        </Typography>
        <Box sx={{ maxWidth:500, height:3,  borderRadius:10,gridArea:"below",ml:-11, 
          backgroundColor:error!==undefined ? '#C13939' : success===true? '#318F29' : '#407CCC', }}></Box>
      <Typography variant="h6" sx={{fontFamily:"Source Sans Pro", fontSize:16, gridArea:"low", mt:1, ml:1 }}>
        { error }
        { success ? language?.this_file || 'The file was successfully uploaded!' : <></> }
      </Typography>
    </Box>
  </>
  );

  return (
    <div>
      <MenuItem onClick={handleClickOpen} >
        {language?.settings || 'Settings'} { language?.po_upload || '(Upload PO)' }
      </MenuItem>
      
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}>
        <DialogTitle>
          { language?.po_registration || "PO Registration"}
            <IconButton onClick={handleClose}
              sx={{
                position: 'absolute',right: 8, top: 8,
                color: (theme) => theme.palette.grey[500],
              }}> <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{pb:13}}>
          <Typography variant="h6" sx={{fontFamily:"Source Sans Pro", gridArea:"name", fontSize:24, fontWeight:1000, mb:3, ml:-1, mt:1 }}>
          {language?.upload_here ||"Upload your CVS file here:"}
          </Typography>
    
          <StyledDiv className="container">
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <Grid container direction="column" alignItems="center" sx={{mb:4, mt:2}}>
                  <FolderIcon sx={{ fontSize:100, color:'#407CCC', alignContent:'center',}}/>
                  <p>{language?.drag_drop ||"Drag & drop here, or Click to Select."}</p>
                </Grid>
              </div>
          </StyledDiv>

          <aside>
            <Box sx={{}}> { fileList ? fileDescription(fileList) :<></>} </Box>
          </aside>

        </DialogContent>
        <DialogActions>
          <Button variant="outlined" size="small" sx={{m:1}} onClick={handleClose}>{language?.close || 'Close'}</Button>
          <Button variant="outlined" size="small" sx={{m:1}} onClick={handleUploadPoCsv} disabled={fileList?false:true}>{language?.save||'Save'} </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
