import styled from 'styled-components';

export const EditRowContainer = styled.button`
    background: #DEE8F5;
    width: 100px;
    height: 33px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;

    div {
        margin-left: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    span {
        margin-right: 3px;
    }
`;