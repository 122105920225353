// http://gql2.packiot.com/v1/graphql

import { auth } from '../firebase';
import { split, ApolloLink, Observable } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { ApolloClient, InMemoryCache } from '@apollo/client';


const httpLink = new HttpLink({
  uri: `http://gql2.packiot.com/v1/graphql`
});

const wsLink = new WebSocketLink({
  uri: `ws://gql2.packiot.com/v1/graphql`,
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: async() => {
      let token = await auth.currentUser.getIdToken();
      return {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      };
    },
  }
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const request = async (operation) => {
  if(auth.currentUser){
  let token = await auth.currentUser.getIdToken();
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : ''
    }
  });}
};

const requestLink = new ApolloLink((operation, forward) =>
  new Observable((observer) => {
    let handle;
    Promise.resolve(operation)
    .then((oper) => request(oper))
    .then(() => {
      handle = forward(operation).subscribe({
        next: observer.next.bind(observer),
        error: observer.error.bind(observer),
        complete: observer.complete.bind(observer),
      });
    })
    .catch(observer.error.bind(observer));

    return () => {
      if (handle) handle.unsubscribe();
    };
  })
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, extensions }) => {
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${extensions.code}`
      );
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

const defaultOptions = {
  watchQuery: {
    errorPolicy: 'ignore',
  },
  query: {
    errorPolicy: 'all',
  },
};

export const client = new ApolloClient({
  link: ApolloLink.from([
    requestLink,
    errorLink,
    link
  ]),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions
});
