import React, { useState, useContext, useEffect } from 'react';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import '../../components/PackDropdown/pack-dropdown.css';
import '../../components/Breadcrumb/breadcrumb.css';

import './style.css'
import { VariablesContext } from '../../Context/VariablesContext';
import { getLanguage } from '../../utils/utils';

export default function EquipmentFilter() {
    const { lineOptions, filterLine, setFilterLine, filterArea, site, area } = useContext(VariablesContext);
    const [itemsToDisplay, setItemsToDisplay] = useState([]);
    const language = getLanguage();

    useEffect(() => {
        if (lineOptions) {
            let temp = [...lineOptions];

            temp = temp?.filter(item => {
                return (filterArea.includes(item.id_area) && (area ? area == item.id_area : true) && (site ? site == item.id_site : true));
            });

            setItemsToDisplay(temp);

        }

    }, [lineOptions, filterArea, area, site]);

    const handleChange = (e) => {
        //    let temp = filterShift;
        const temp = [...filterLine]
        if (e?.target?.checked) {
            if (!filterLine.includes(e?.target?.value)) {
                temp.push(e?.target?.value);
                setFilterLine(temp);
            }
        }
        else {
            if (filterLine.includes(e?.target?.value)) {
                temp.splice(temp.indexOf(e?.target?.value), 1);
                setFilterLine(temp);
            }
        }
    }


    // let temp = []

    // lineOptions?.map((line) => {
    //     temp.push({
    //         label: line.nm_equipment,
    //         value: line.id_equipment
    //     })
    // });

    return (
        <>
            {/* Design correto */}
            {/* <div id="shiftSelector" class="list-group mx-0">
                {itemsToDisplay.length > 0 ? itemsToDisplay.map((shift, index) => {
                    return (
                        <label class="list-group-item d-flex gap-2" onClick={()=>alert("AAAAAAAAAAAAAAA")}>
                            <input class="form-check-input flex-shrink-0" type="checkbox" id={shift.id_shift} name={shift.id_shift} value={shift.id_shift} checked={true} onChange={()=>setIsChecked(!isChecked)} />
                            <span>
                                {shift.nm_equipment}
                            </span>
                        </label>
                    )
                }) : <li key={0}><p>No shift available</p></li>}
            </div> */}


            <div id="shiftSelector" className='list-group mx-0 rounded bg-transparent'>
                <ul className="rounded p-0 border">
                    {itemsToDisplay.length > 0 ? itemsToDisplay
                        .map((line, index) => {
                            // alert(JSON.stringify(line))
                            return (
                                <li key={index} className="list-group-item d-flex gap-2">
                                    <CheckBoxComponent label={line.nm_equipment}
                                        value={line.id_equipment}
                                        checked={filterLine.includes(line.id_equipment)}
                                        cssClass="e-info e-round form-check-input flex-shrink-0 width-fit-content"
                                        name="line"
                                        onChange={handleChange}
                                    />
                                </li>
                            )
                        }) : <li key={0}><p> {language?.no_lines_available || 'No lines available'}</p></li>}
                </ul>
            </div>

            <DropDownButtonComponent
                // beforeOpen={event => beforeOpen(event)}
                target="#shiftSelector"
                cssClass="align-items-center border mx-2"
                className="d-flex rounded e-grey"
            >
                <div className="element-selector shift-selector">
                    <p><i className="ri-filter-line" size={"1em"}></i> { language?.filter_line || 'Filter Line'} </p>
                </div>
            </DropDownButtonComponent>
        </>
    );
};