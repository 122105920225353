import React from 'react';

import { numbertoString } from '../../../utils/utils';
// import from ''
import './style.css';
import { getLanguage } from '../../../utils/utils';

export default function AreaCard(area) {
  const language = getLanguage();
  let line = '';
  if (area?.lines?.length > 1) {
    line = language?.lines || 'Lines';
  } else {
    line = language?.line || 'Line';
  }

  return (
    <div className="area-card">

      <div className="area-card-header mb-2">
        <div className="row g-0">

          <div className="col-8 d-flex align-items-center">
            <h5 className="m-0 fw-bold">{area.nm_area}</h5>
          </div>

          <div className="col d-flex justify-content-end">
            <span>{area?.lines?.length} {line} </span>
          </div>

        </div>
      </div>

      <div className="area-card-body text-center mb-3">
        <div class={`grid-container 
                  ${area?.lines?.length < 16 ? "grid-four " : ""}
                  ${area?.lines?.length < 25 ? "grid-five " : ""}
                  ${area?.lines?.length < 36 ? "grid-six " : ""}
                  ${area?.lines?.length >= 36 ? "grid-sseven " : ""}
                  }`}>
          {area?.lines?.map((line, index) => {
            return (<div class={`grid-home-line grid-item bg-${line?.status ? line.status : "unknown"}`} title={line.nm_equipment}></div>)
          })}

          {/* <div class="grid-item bg-success" title="line"></div>
                    <div class="grid-item bg-attention" title="line"></div>
                    <div class="grid-item bg-success" title="line"></div>
                    <div class="grid-item bg-attention" title="line"></div>
                    <div class="grid-item bg-orange" title="line"></div>
                    <div class="grid-item bg-success" title="line"></div>
                    <div class="grid-item bg-danger" title="line"></div>
                    <div class="grid-item bg-attention" title="line"></div> */}
        </div>
      </div>




      <div className="area-card-footer border-top pt-2">
        <div className="row g-0">

          <div className="col-4 text-center">
            <p className="fs-medium">{language?.production || 'Production'} <span className="fw-bold color-grey-medium-dark">[u]</span></p>
            <h5 className="fw-bold">{numbertoString(area?.net)}</h5>
            {/* <p className="color-danger fw-bold fs-small">-11% of goal</p> */}
          </div>

          <div className="col-4 text-center">
            <p className="fs-medium">{language?.scrap || 'Scrap'}</p>
            <h5 className="fw-bold d-flex align-items-center justify-content-center">
              {area?.gross ? ((area?.scrap) * 100 / area?.gross).toFixed(0) : 0}%
            </h5>
          </div>

          <div className="col-4 text-center">
            <p className="fs-medium">OEE</p>
            <h5 className="fw-bold d-flex align-items-center justify-content-center">
              {(area?.oee ? area.oee * 100 : 0).toFixed(0)}%
            </h5>
          </div>

        </div>
      </div>
    </div>
  );
}


{/* <div className="area-card">

<div className="area-card-header mb-2">
  <div className="row g-0">

    <div className="col-8 d-flex align-items-center">
      <h5 className="m-0 fw-bold">Area 1</h5>
    </div>

    <div className="col d-flex justify-content-end">
      <span>9 lines</span>
    </div>

  </div>
</div>

<div className="area-card-body text-center mb-3">
  <div class="grid-container grid-six">
    <div class="grid-item bg-success" title="line"></div>
    <div class="grid-item bg-attention" title="line"></div>
    <div class="grid-item bg-success" title="line"></div>
    <div class="grid-item bg-danger" title="line"></div>
    <div class="grid-item bg-orange" title="line"></div>
    <div class="grid-item bg-danger" title="line"></div>
    <div class="grid-item bg-success" title="line"></div>
    <div class="grid-item bg-orange" title="line"></div>
  </div>
</div> */}

{/* <div className="area-card-footer border-top pt-2">
  <div className="row g-0">

    <div className="col-4 text-center">
      <p className="fs-medium">Production <span className="fw-bold color-grey-medium-dark">[u]</span></p>
      <h5 className="fw-bold">438,75K</h5>
      <p className="color-danger fw-bold fs-small">-11% of goal</p>
    </div>

    <div className="col-4 text-center">
      <p className="fs-medium">Scrap</p>
      <h5 className="fw-bold d-flex align-items-center justify-content-center">
        80%
      </h5>
    </div>

    <div className="col-4 text-center">
      <p className="fs-medium">OEE</p>
      <h5 className="fw-bold d-flex align-items-center justify-content-center">
        80%
      </h5>
    </div>

  </div>
</div>
</div> */}


{/* <div className="area-card">

                <div className="area-card-header mb-2">
                  <div className="row g-0">

                    <div className="col-8 d-flex align-items-center">
                      <h5 className="m-0 fw-bold">Area 1</h5>
                    </div>

                    <div className="col d-flex justify-content-end">
                      <span>9 lines</span>
                    </div>

                  </div>
                </div>

                <div className="area-card-body text-center mb-3">
                  <div class="grid-container grid-seven">
                    <div class="grid-item bg-success" title="line"></div>
                    <div class="grid-item bg-success" title="line"></div>
                    <div class="grid-item bg-danger" title="line"></div>
                    <div class="grid-item bg-success" title="line"></div>
                    <div class="grid-item bg-orange" title="line"></div>
                    <div class="grid-item bg-success" title="line"></div>
                    <div class="grid-item bg-attention" title="line"></div>
                    <div class="grid-item bg-orange" title="line"></div>
                  </div>
                </div>

                <div className="area-card-footer border-top pt-2">
                  <div className="row g-0">

                    <div className="col-4 text-center">
                      <p className="fs-medium">Production <span className="fw-bold color-grey-medium-dark">[u]</span></p>
                      <h5 className="fw-bold">438,75K</h5>
                      <p className="color-danger fw-bold fs-small">-11% of goal</p>
                    </div>

                    <div className="col-4 text-center">
                      <p className="fs-medium">Scrap</p>
                      <h5 className="fw-bold d-flex align-items-center justify-content-center">
                        80%
                      </h5>
                    </div>

                    <div className="col-4 text-center">
                      <p className="fs-medium">OEE</p>
                      <h5 className="fw-bold d-flex align-items-center justify-content-center">
                        80%
                      </h5>
                    </div>

                  </div>
                </div>
              </div> */}


{/* <div className="area-card">

<div className="area-card-header mb-2">
  <div className="row g-0">

    <div className="col-8 d-flex align-items-center">
      <h5 className="m-0 fw-bold">Area 1</h5>
    </div>

    <div className="col d-flex justify-content-end">
      <span>9 lines</span>
    </div>

  </div>
</div>

<div className="area-card-body text-center mb-3">
  <div class="grid-container grid-four">
    <div class="grid-item bg-danger" title="line"></div>
    <div class="grid-item bg-success" title="line"></div>
    <div class="grid-item bg-attention" title="line"></div>
    <div class="grid-item bg-success" title="line"></div>
    <div class="grid-item bg-danger" title="line"></div>
    <div class="grid-item bg-success" title="line"></div>
    <div class="grid-item bg-success" title="line"></div>
    <div class="grid-item bg-orange" title="line"></div>
  </div>
</div>

<div className="area-card-footer border-top pt-2">
  <div className="row g-0">

    <div className="col-4 text-center">
      <p className="fs-medium">Production <span className="fw-bold color-grey-medium-dark">[u]</span></p>
      <h5 className="fw-bold">438,75K</h5>
      <p className="color-danger fw-bold fs-small">-11% of goal</p>
    </div>

    <div className="col-4 text-center">
      <p className="fs-medium">Scrap</p>
      <h5 className="fw-bold d-flex align-items-center justify-content-center">
        80%
      </h5>
    </div>

    <div className="col-4 text-center">
      <p className="fs-medium">OEE</p>
      <h5 className="fw-bold d-flex align-items-center justify-content-center">
        80%
      </h5>
    </div>

  </div>
</div>
</div> */}