import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';

import { Card, Content, Item, Title, Spacer, Legend } from '../styles';

function Chart({ data }) {

  return (
      <Card width={1397} height={432}>
          <Content width="100%" height="100%" direction="column">
              <Item top={10}>
                  <Title size={40}>Últimas 12h</Title>
              </Item>
              <Spacer size={10} />

              <Item width="100%" height="280px" noPadding>
                    <BarChart
                        width={1340}
                        height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 2" vertical={false} />
                        <XAxis dataKey="time" />
                        <YAxis />
                        <Bar dataKey="production" fill="#4175F5" />
                        <Bar dataKey="scrap" fill="#C13939" />
                    </BarChart>
              </Item>

              <Item top={10} direction="row" justify="center">
                  <Legend color="#4175F5"> <div className="circle"></div> Produzido </Legend>
                  <Legend color="#C13939"> <div className="circle"></div> Refugo </Legend>
              </Item>

          </Content>
      </Card>
  );
}

export default Chart;