import React, { useContext, useEffect, useState } from "react";
import '../../components/StatusCard/status-card.css';
import '../../components/AreaCard/area-card.css';
import '../../components/Progress/progress.css';
import '../../components/Tooltip/tooltip.css';
import '../../components/Grid/grid.css';
import '../../components/Breadcrumb/breadcrumb.css';
//import bootstrap from 'bootstrap';
import '../../components/Scrollbar/scrollbar.css';
import '../../components/PackDropdown/pack-dropdown.css';
import '../../components/Container/container.css';
import '../../components/CSSGrid/css-grid.css';
import '../../components/Tabs/tabs.css';
import '../../components/Helpers/helpers.css';
import '../../components/Icon/icon.css';
import '../../components/ComboSearch/combo-search.css';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import LegendaHome from '../../assets/LegendaHome.svg';
import { getLanguage } from '../../utils/utils';
import EventsTab from "../../components/EventsTab";
import moment from 'moment';

import HomeBreadCrumb from "../../components/Breadcrumb/HomeBreadCrumb";

import { GET_HOME_DATA } from './Query';
import { useQuery, useSubscription, useLazyQuery } from '@apollo/client';
import { VariablesContext } from '../../Context/VariablesContext';

import AreaCard from './components/AreaCard';

import { Typography, Box, Grid, Paper, } from "@mui/material"
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Home() {
  const language = getLanguage();
  const [goodMorning, setGoodMorning] = useState(
    (
      moment().hours() > 6) ? (moment().hours() > 12) ? `${language?.good_afternoon || 'Good afternoon'}` : 
      `${language?.good_morning || 'Good morning'}` : 
      `${language?.good_evening || 'Good evening'}`
    );
  const [username, setUsername] = useState(localStorage.getItem('piot_username'));
  const { enterprise } = useContext(VariablesContext);
  const [showInfo, setShowInfo] = useState(false);
 
  const matches = useMediaQuery('(min-width:1440px)', { noSsr: true });
  let tamanho = matches===true? 'row' : 'column';


  // TODO: hardcoded
  const [selectedSiteTab, setSelectedSiteTab] = useState();

  const [getHomeData, { loading, error, data }] = useLazyQuery(GET_HOME_DATA, {
    onCompleted: (data) => {
      if (data?.h_piot_home?.[0]?.sites?.length > 0) {
        setSelectedSiteTab(data?.h_piot_home?.[0]?.sites[0].id_site);
      }
      // alert(data)
    }
  });

  useEffect(() => {
    if(enterprise){getHomeData({ variables: { in_id_enterprise: enterprise } });}
  }, [enterprise]);

  return (
    <div>
        
      <EventsTab />

      <Box sx={{flexGrow: 1, backgroundColor:"#D8D7DD", 
          display:"grid", alignItems:"center", minHeight:matches===true?285:243, }}>
        <Grid container spacing={3} sx={{ flexDirection:tamanho, }} >
          
          <Grid item xs={ matches===true?4:12 }>
            <Box sx={{ ml:7, alignSelf:"center" }}>
                <Typography variant="h1" sx={{fontWeight:500, fontSize:50, }}>
                  {goodMorning}{username ? `, ${username}` : ""}!
                </Typography>
                {/* <h1 style={{fontSize:"48px"}} class="fw-bold m-0 title-large">Good {goodMorning}{username ? `, ${username}` : ""}!</h1> */}
                {/* <span>{`(min-width:600px) matches: ${ matches }`}</span>; */}
            </Box>
          </Grid>

          <Grid item xs={8} sx={{display: 'grid', alignItems: "center", ml:matches===true?0:7,  }} >
            <Box>
              <p class="fs-medium color-grey">{language?.select_fields_below_see_detailed || 'Select the fields below to see more detailed information'}</p>
              <HomeBreadCrumb />
            </Box>
          </Grid>
        
        </Grid>
      
      </Box>


      <div id='info-modal' className='col-lg-8' style={{ width: "100%", height: "100%" }}>
        <DialogComponent id="modalDialog" isModal={true} content={`<div><img src=${LegendaHome} width="500" alt='Legenda'/></div>`} overlayClick={() => { setShowInfo(false) }} target='#info-modal' visible={showInfo} animationSettings={{ effect: 'None' }}></DialogComponent>

        {/* <Box sx={{backgroundColor:"#D8D7DD",display:"flex", alignItems:"center", minHeight:285,}}> */}
        {/* <div class="header-container grid-container grid-custom-header" > */}
          {/* <Box sx={{ ml:'80px',mt:'50px',mb:'50px',mr:"160px", alignSelf:"center" }}> */}
            {/* <div class="grid-item"> */}
              {/* <Typography variant="h1" sx={{fontWeight:500, fontSize:64}}> */}
                {/* Good {goodMorning}{username ? `, Gabriel` : ""}! */}
              {/* </Typography> */}
              {/* <h1 style={{fontSize:"48px"}} class="fw-bold m-0 title-large">Good {goodMorning}{username ? `, ${username}` : ""}!</h1> */}
              {/* <h1 style={{fontSize:"48px"}} class="fw-bold m-0 title-large">Good {goodMorning}{username ? `, Gabriel` : ""}!</h1> */}
            {/* </div> */}
          {/* </Box> */}

          {/* <div class="grid-item"> */}
            {/* <p class="fs-medium color-grey">Select the fields below to see more detailed information</p> */}
            
            {/* <HomeBreadCrumb /> */}

          {/* </div> */}

        {/* </Box> */}

        <div class="d-flex align-items-center ps-5 p-4">
          <h2 class="mt-0 border-end me-3 pe-3"> {language?.this_is_your_factory || 'This is how your factory is today'}</h2>
          <p class="fs-medium-20 fw-bold color-primary not-allowed">{language?.today_since_shift_1 || 'Today since shift 1'}</p>
        </div>





        <ul class="nav nav-tabs ps-5" id="myTab" role="tablist">
          {data?.h_piot_home?.[0]?.sites?.map((site, index) => {
            return (
              <li class="nav-item" role="presentation" onClick={
                () => { setSelectedSiteTab(site.id_site) }
              }>
                <button class={`nav-link ${selectedSiteTab == site.id_site ? "active" : ""}`} id="namerica-tab" data-bs-toggle="tab" data-bs-target="#namerica" type="button" role="tab" aria-controls="namerica" aria-selected="true">{site.nm_site}</button>
              </li>
            )
          })}

          {/* <li class="nav-item" role="presentation">
          <button class="nav-link" id="samerica-tab" data-bs-toggle="tab" data-bs-target="#samerica" type="button" role="tab" aria-controls="samerica" aria-selected="false">S. America</button>
        </li> */}

          <div class="icon-info" onClick={() => { setShowInfo(true) }} >
            <i class="ri-information-line"></i>
          </div>
        </ul>



        <div class="tab-content" id="myTabContent">
          <div class="tab-pane  ps-5 p-4 show active" id="namerica" role="tabpanel" aria-labelledby="namerica-tab"> {/*  Class: fade */}
            <div class="row">

              {
                data?.h_piot_home?.[0]?.sites
                  ?.filter((site) => (selectedSiteTab == site.id_site))
                  ?.[0]
                  ?.areas
                  ?.map((area, index) => {
                    return (<div class="col-12 col-md-6 col-lg-4 col-xl-3 my-3">{AreaCard(area)}</div>);
                  })
              }

            </div>
          </div>

          {/* <div class="tab-pane fade" id="samerica" role="tabpanel" aria-labelledby="samerica-tab">...</div> */}
        </div>

      </div>
      {/* </EventsTab> */}
    </div>
  );
}
