import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { withRouter } from "react-router";

// In devellopment
import Settings from '../Settings';
import Overview from '../Overview';
import SinglePeriod from '../SinglePeriod';

// Production
import Login from '../Login';
import Navigate from '../Navigate';
import Home from '../Home';
import MissionControl from '../MissionControl';
import DowntimesSector from '../DowntimesSector';
import OEE from '../OEE';
import ProductionOrders from '../ProductionOrders';

// import ReportsPowerBI from '../ReportsPowerBI';
import ReportsPowerBI from '../ReportsPowerBi';
import MissionControlUNS from '../MissionControlUNS';
import TotalProductionUNS  from '../TotalProductionUNS';
import OverviewGranado from '../OverviewGranado';

// Custom pages
import C30Overview from '../CustomPages/C30/C30Overview';
import MCViniCamargo from '../CustomPages/C35/C35MissionControl'

function Routes() {
    return (
        <Switch>
            <Route path="/navigate/:pageCode" component={Navigate}></Route>
            <Route exact path="/Login" component={Login}></Route>

              {/* Real Pages */}
              <Route exact path="/home" component={Home}></Route>
              <Route exact path="/NewMissionControl" component={MissionControl}></Route>
              <Route path="/downtimes" ><DowntimesSector isSectorView={false}/></Route>
              <Route path="/downtimes-sector" ><DowntimesSector isSectorView={true}/></Route>
              <Route path="/oee" component={OEE}></Route>
              <Route path="/production-orders" component={ProductionOrders}></Route>
              <Route path="/total-production" component={TotalProductionUNS}></Route>
              <Route path="/single-period" component={SinglePeriod}></Route>
              <Route path="/settings" component={Settings}></Route>
              <Route path="/overview/:lineId" component={Overview}></Route>
              
              <Route path="/report" component={ReportsPowerBI}></Route>
              <Route path="/reports" component={ReportsPowerBI}></Route>

              {/* Custom Pages */}
              <Route path="/MissionControl35" component={MCViniCamargo}></Route>
              <Route path="/overview30/:lineId" component={C30Overview}></Route>
              <Route path="/OverviewGranado/:lineId" component={OverviewGranado}></Route>
              
              {/* Pages in devellopment */}
              <Route path="/MissionControl" component={MissionControlUNS}></Route>
        </Switch>
    );
}

export default withRouter(Routes);