import React, { } from 'react';
import { Box, Card, CardContent, Typography, } from '@mui/material';
import {TooltipDowntimeReasons as Tooltip} from './TooltipDowntimeReasons';
import CircleIcon from '@mui/icons-material/Circle';

export default function Resume({ data, translate }) { 
  const size = 2000

  function groupBy(array, f) {
    let groups = {};
    array?.forEach(function(o) {
       var group = JSON.stringify(f(o));
       groups[group] = groups[group] || [];
       groups[group].push(o);
    });
    return Object.keys(groups).map(function(group) {
       return groups[group];
    })
  }

  let dado = data?.downtimes_per_category;

  // DADOS DO CHANGEOVERS
  const changeOversList = dado?.filter(element => element?.change_over===true);
  // console.log( data )

  let totalTimeChangeOvers = changeOversList?.map(element => element?.duration_total)?.reduce((total, numero) => total + numero, 0);
  let OccChangeovers = changeOversList?.map(element => element?.num_occurence)?.reduce((total, numero) => total + numero, 0);
  let AvgChangeovers = Math.round( totalTimeChangeOvers/OccChangeovers )
  let tooltipListaChangeOver = changeOversList;
  tooltipListaChangeOver?.forEach(element =>{ element.name = element.cd_category });
  
  // DADOS DE LISTA PLANEJADA
  const plannedList = dado?.filter(element => element.planned_downtime===true && element?.change_over===false);
 
  let totalTimePlanned = plannedList?.map(element => element.duration_total)?.reduce((total, numero) => total + numero, 0);
  let OccPlanned = plannedList?.map(element => element.num_occurence)?.reduce((total, numero) => total + numero, 0);
  let AvgPlanned = Math.round( totalTimePlanned/OccPlanned )
  let tooltipListaPlanned = plannedList;
  tooltipListaPlanned?.forEach(element =>{ element.name = element.cd_category });

  // // LISTA SEM REPETIÇÃO DE DADOS PLANEJADOS
  // let listPlannedClean = [];
  // var projectIguaisPlanejados = groupBy(tooltipListaPlanned, function(item) {
  //   return [item.cd_category];
  //   }).map((data) => {
  //   return {
  //     duration_total: data.reduce((total, valor) => total + valor.duration_total, 0),
  //     num_occurence: data.reduce((total, valor) => total + valor.num_occurence, 0),
  //     avg_time: data.reduce((total, valor) => total + valor.avg_time, 0),
  //     itens: data
  //   };
  //  });
   
  //  for (var i in projectIguaisPlanejados) {
  //   let projetosPlanejados = {
  //     cd_category: projectIguaisPlanejados[i].itens[0].cd_category ,
  //     duration_total: projectIguaisPlanejados[i].duration_total,
  //     num_occurence: projectIguaisPlanejados[i].num_occurence,
  //     avg_time: projectIguaisPlanejados[i].avg_time,
  //   }
  //   listPlannedClean.push(projetosPlanejados);
  // }
  // // console.log(listPlannedClean)


  // DADOS DE LISTA NÃO PLANEJADA
  
  const unplannedList = dado?.filter(element => element.planned_downtime===false && element?.change_over===false);
  
  // console.log(unplannedList.filter(element => element?.change_over===true))
  let totalTimeUnplanned = unplannedList?.map(element => element.duration_total)?.reduce((total, numero) => total + numero, 0);
  let OccUnplanned = unplannedList?.map(element => element.num_occurence)?.reduce((total, numero) => total + numero, 0);
  let AvgUnplanned = Math.round( totalTimeUnplanned/OccUnplanned )
  let tooltipListaUnplanned = unplannedList;
  tooltipListaUnplanned?.forEach(element =>{ element.name = element.cd_category });

  // // LISTA SEM REPETIÇÃO DE DADOS NÃO PLANEJADOS
  let listUnplannedClean = [];
  var projectIguaisUnplanned = groupBy(tooltipListaUnplanned, function(item) {
    return [item.cd_category];
    }).map((data) => {
    return {
      duration_total: data.reduce((total, valor) => total + valor.duration_total, 0),
      num_occurence: data.reduce((total, valor) => total + valor.num_occurence, 0),
      avg_time: data.reduce((total, valor) => total + valor.avg_time, 0),
      itens: data
    };
   });
   
   for (var i in projectIguaisUnplanned) {
    let projetosUnplanneds = {
      cd_category: projectIguaisUnplanned[i].itens[0].cd_category ,
      duration_total: projectIguaisUnplanned[i].duration_total,
      num_occurence: projectIguaisUnplanned[i].num_occurence,
      avg_time: projectIguaisUnplanned[i].avg_time,
    }
    listUnplannedClean.push(projetosUnplanneds);
  }
  // console.log(listUnplannedClean)

 // DADOS DO GERAIS

 const planned = totalTimePlanned ? (totalTimePlanned / data?.duration_total) * 100 : 0;
 const unplanned = totalTimeUnplanned ? (totalTimeUnplanned / data.duration_total) * 100 : 0;
 const changeovers = totalTimeChangeOvers ? (totalTimeChangeOvers / data.duration_total) * 100 : 0;

  return(
    <Card>
      <Typography variant="h6" sx={{ mt:2, ml:3, mb:-1 }}>
        { translate?.downtime_ranking ?? `Downtime's Ranking`}
      </Typography>

      <CardContent >
        <Box sx={{ maxWidth: size, minHeight: 43, display: 'flex', alignItems: 'center' }}>  

        { Math.round(planned) !== 0 ? 
          <Tooltip 
            translate={translate}
            name={translate?.planned || "Planned"}
            totalTime={totalTimePlanned}
            occ={OccPlanned}
            avg={AvgPlanned}
            // lista={listPlannedClean}
            lista={tooltipListaPlanned}
          >
            <Box sx={{ display: 'grid',
              width: isNaN(planned) || Math.round(planned)===0 ? 0 
                : Math.round(planned) <= 10 ? 10*size 
                : Math.round(planned)*size,
             }} >
               <Box sx={{ mx:'auto' }} >
                  <Typography variant="subtitle1" >
                    { planned.toFixed(1) }% 
                  </Typography>
               </Box>
              <Box sx={{
                  height:16, display: 'flex', alignItems: 'center',
                  justifyContent: 'center', backgroundColor:'#0984E3',
                  borderTopLeftRadius: 10, borderBottomLeftRadius:10,
                }}> </Box>
            </Box>
          </Tooltip>
          : <></> }

        { Math.round(unplanned) !== 0 ? 
          <Tooltip 
            translate={translate}
            name={translate?.unplanned || "Unplanned"}
            totalTime={totalTimeUnplanned}
            occ={OccUnplanned}
            avg={AvgUnplanned}
            // lista={listUnplannedClean}
            // lista={tooltipListaUnplanned}
            lista={unplannedList}
            placement="bottom-start"
          >
            <Box sx={{ display: 'grid',
              width: isNaN(unplanned) || Math.round(unplanned)===0 ? 0 
                : Math.round(unplanned) <= 10 ? 10*size
                : Math.round(unplanned)*size,
             }} >
              <Box sx={{ mx:'auto' }} >
                <Typography variant="subtitle1">
                  { unplanned.toFixed(1) }%  
                </Typography>
              </Box>
             
              <Box 
                sx={{
                  height: 16, display: 'flex', alignItems: 'center',
                  justifyContent: 'center', backgroundColor:'#EB5757',
                  borderBottomRightRadius: Math.round(changeovers)===0 ? 10 : 0,
                  borderTopRightRadius: Math.round(changeovers)===0 ? 10 : 0,
                  borderTopLeftRadius: Math.round(planned)===0 ? 10 : 0,
                  borderBottomLeftRadius: Math.round(planned)===0 ? 10 : 0,
                  }}> </Box>
            </Box>
          </Tooltip>
        : <></> }
          
        { Math.round(changeovers) !== 0 ? 
          <Tooltip 
            translate={translate}
            name={translate?.change_overs || "Change Overs"}
            totalTime={totalTimeChangeOvers}
            occ={OccChangeovers}
            avg={AvgChangeovers}
            lista={tooltipListaChangeOver}
          >
            <Box sx={{ display: 'grid',
              width: isNaN(changeovers) || Math.round(changeovers)===0 ? 0 
                : Math.round(changeovers) <= 10 ? 10*size
                : Math.round(changeovers)*size,
           }} >
                <Box sx={{ mx:'auto' }} >
                  <Typography variant="subtitle1"> 
                    { Math.round(changeovers) !== 0 ? changeovers.toFixed(1) + "%": "" } 
                  </Typography>
                </Box>
              <Box sx={{
                  height: 16, display: 'flex', alignItems: 'center',
                  justifyContent: 'center', backgroundColor:'#E9CD3D',
                  borderTopRightRadius: 10, borderBottomRightRadius:10 ,
                  }}> </Box>
            </Box>
          </Tooltip>
        : <></> }

        </Box>
      </CardContent>

      
      <CardContent sx={{ display:'flex', justifyContent: 'center', mt:-.8  }}>
      { Math.round(planned) !== 0 ? 
        <Typography sx={{ mb: -1, mr:2 }} color="text.secondary">
          <CircleIcon sx={{ color:'#0984E3', mr:1 }} />
           {translate?.planned || "Planned"}
        </Typography>
      : <></> }

      { Math.round(unplanned) !== 0 ? 
        <Typography sx={{ mb: -1, mr:2 }} color="text.secondary">
          <CircleIcon sx={{ color:'#EB5757', mr:1 }} />
          {translate?.unplanned || "Unplanned"} </Typography>
        : <></> }

      { Math.round(changeovers) !== 0 ? 
        <Typography sx={{ mb: -1, mr:2 }} color="text.secondary">
          <CircleIcon sx={{ color:'#E9CD3D', mr:1 }} />
           {translate?.change_over || "Changeovers"} 
        </Typography>
      : <></> }

      </CardContent>

    </Card>
  );
}
