import React, { useEffect, useState } from "react";

import '../StatusCard/status-card.css';
import '../Progress/progress.css';
import '../Tooltip/tooltip.css';
import '../Grid/grid.css';
import '../Scrollbar/scrollbar.css';

import { numbertoString, roundNumber, secondsToDurationString } from '../../utils/utils';

const calculatePercentegeStatus = function (array) {
    var result = array.reduce(
        function (prev, cur) {
            prev[cur] = (prev[cur] || 0) + 1;
            return prev;
        }, {});

    for (var status in result) {
        result[status] /= (array.length / 100);
    }
    return result;
}

export default function C35MissionControlLineCard(props) {
    const [timerOn, setTimerOn] = useState(false);
    const [timerTime, setTimerTime] = useState(0);
    const [statusTimerBeginTime, setStatusTimerBeginTime] = useState(new Date());

    useEffect(() => {
        if (timerOn) {
            const statusTimer = setInterval(() => {
                setTimerTime(Date.now() - statusTimerBeginTime)
            }, 1000);
            return () => {
                clearInterval(statusTimer);
            }
        }
    }, [timerOn])

    const line = props.lineData;
    const lineProductionData = props.productionData;
    const shiftsData = props.shiftsData;
    let lineTimeline = props.timeLines;
    const stoppedTime = props.stoppedTimeData;
    let statusDescription = "";

    const shiftFiltered = shiftsData.filter((shift) => {
        return (shift.rank_shift == 1)
    });

    let currentShift = {
        "nm_equipment": null,
        "qtdperda": 0,
        "qtdperdakg": 0,
        "qtdproduzida": 0,
        "qtdproduzidaiot": 0,
        "qtdmetaturno": 0,
        "velmetaturno": 0,
        "rank_shift": 0,
        "speed": null,
        "tipodispositivo": null,
        "turno": null,
        "oee":0
    }
    if (shiftFiltered.length > 0) {
        currentShift = shiftFiltered[0];
    }

    const prev1ShiftFiltered = shiftsData.filter((shift) => {
        return (shift.rank_shift == 2)
    });
    let prev1Shift = {
        "turno": null,
        "qtdproduzida": 0,
        "qtdproduzidaiot": 0,
    }
    if (prev1ShiftFiltered.length > 0) {
        prev1Shift = prev1ShiftFiltered[0];
    }

    const prev2ShiftFiltered = shiftsData.filter((shift) => {
        return (shift.rank_shift == 3)
    });
    let prev2Shift = {
        "turno": null,
        "qtdproduzida": 0,
        "qtdproduzidaiot": 0,
    }
    if (prev2ShiftFiltered.length > 0) {
        prev2Shift = prev2ShiftFiltered[0];
    }

    const index_of_currentOP = lineProductionData.findIndex((data) => {
        return (data.statustarefa == 'Iniciada')
    })

    var code_currentOP = null;
    if (index_of_currentOP >= 0) {
        code_currentOP = lineProductionData[index_of_currentOP].op;
    }

    var lineProductionDataSliced = []
    if (index_of_currentOP >= 0) {

        lineProductionDataSliced = lineProductionData.slice(0, index_of_currentOP + 1);
    }

    var lineProductionDataFilteredByOP = [];
    if (lineProductionDataSliced.length > 0) {
        lineProductionDataFilteredByOP = lineProductionDataSliced.filter((data) => {
            return (data.op == code_currentOP)
        })
    }

    if (lineProductionDataFilteredByOP.findIndex((data) => { return (data.statustarefa == 'Finalizada' || data.statustarefa == 'Suspensa') }) >= 0) {
        code_currentOP = null;
    }
    const lineProductionDataCurrentOP = lineProductionData.filter((data) => {
        return (data.op == code_currentOP)
    })

    const currentOrder = lineProductionDataCurrentOP.reduce(
        function (prev, cur) {
            if (prev['op'] != null) {
                if (prev['op'] == cur['op']) {
                    prev['op'] = cur['op'];
                    prev['cliente'] = cur['cliente'];
                    prev['qtdprevista'] = (cur['qtdprevista'] || 0);
                    prev['tmpprevmin'] = cur['tmpprevmin'];
                    prev['tmprealmin'] = (cur['tmprealmin'] || 0) + prev['tmprealmin'];
                    prev['qtdperda'] = (cur['qtdperda'] || 0) + prev['qtdperda'];
                    prev['qtdperdakg'] = (cur['qtdperdakg'] || 0) + prev['qtdperdakg'];
                    prev['qtdproduzida'] = (cur['qtdproduzida'] || 0) + prev['qtdproduzida'];
                    prev['qtdproduzidaiot'] = (cur['qtdproduzidaiot'] || 0) + prev['qtdproduzidaiot'];
                }
            }
            else {
                prev['op'] = cur['op'];
                prev['cliente'] = cur['cliente'];
                prev['qtdprevista'] = (cur['qtdprevista'] || 0);
                prev['tmpprevmin'] = cur['tmpprevmin'];
                prev['tmprealmin'] = cur['tmprealmin'];
                prev['qtdperda'] = (cur['qtdperda'] || 0) + prev['qtdperda'];
                prev['qtdperdakg'] = (cur['qtdperdakg'] || 0) + prev['qtdperdakg'];
                prev['qtdproduzida'] = (cur['qtdproduzida'] || 0) + prev['qtdproduzida'];
                prev['qtdproduzidaiot'] = (cur['qtdproduzidaiot'] || 0) + prev['qtdproduzidaiot'];
            }
            return prev;
        }, {
        "op": null,
        "cliente": null,
        "qtdperda": 0,
        "qtdperdakg": 0,
        "qtdproduzida": 0,
        "qtdproduzidaiot": 0,
        "tmpprevmin": 0,
        "tmprealmin": 0,
        "qtdprevista": 0
    })

    const statusText = {
        running: 'Running',
        lowSpeed: 'Low Speed',
        stopped: 'Stopped',
        setup: 'Setup'
    }

    var statusPercentage = {};
    var statusSince = null;


    if (lineTimeline) {
        if (lineTimeline.hasOwnProperty('last_ocorrencia')) {
            statusDescription = lineTimeline.last_ocorrencia;
        }
        if (lineTimeline.hasOwnProperty('json_agg')) {
            if (statusTimerBeginTime.getTime() != new Date(lineTimeline.last_status_since).getTime()) {
                setStatusTimerBeginTime(new Date(lineTimeline.last_status_since));
            }
            lineTimeline = lineTimeline.json_agg;
            statusPercentage = calculatePercentegeStatus(lineTimeline);
        }        
    }

    var timerValue = 0;

    if (lineTimeline) {
        if (lineTimeline.length >= 1) {
            const status = lineTimeline[lineTimeline.length - 1];
            if (status == "stopped" || status == "setup") {

                if (!timerOn) {
                    setTimerOn(true);
                }
                var i = lineTimeline.length - 1;
                while (i > 0 && lineTimeline[i] == status) {
                    timerValue++;
                    i--;
                }
            } else {
                if (timerOn) {
                    setTimerOn(false);
                }
            }
        }
    }

    const hoursPercentage = (currentOrder.tmpprevmin ? (currentOrder.tmprealmin / currentOrder.tmpprevmin) : 0) * 100;
    const productionPercentage = ((currentOrder.qtdproduzida && currentOrder.qtdprevista) ? (currentOrder.qtdproduzida / currentOrder.qtdprevista) : 0) * 100;
    const productionPace = (currentOrder.qtdproduzida * currentOrder.tmpprevmin) / (currentOrder.qtdprevista * currentOrder.tmprealmin);

    const currentshiftGoalPercentage = (currentShift.qtdmetaturno > 0 ? (currentShift.qtdproduzida / currentShift.qtdmetaturno) : 0) * 100;
    const prev1shiftGoalPercentage = (prev1Shift.qtdmetaturno > 0 ? (prev1Shift.qtdproduzida / prev1Shift.qtdmetaturno) : 0) * 100;
    const prev2shiftGoalPercentage = (prev2Shift.qtdmetaturno > 0 ? (prev2Shift.qtdproduzida / prev2Shift.qtdmetaturno) : 0) * 100;

    return (
        <div className="col-12 col-lg-6">
            <div className="status-card">

                <div className="status-card-header">
                    <div className="row">

                        <div className="col-7">
                            <div className="d-flex align-items-center">
                                <h5 className="fw-bold me-2">{line.nm_equipment ? line.nm_equipment : ""}</h5>
                                {/* <h5 className="fs-6"><span>|</span> {line.nm_area ? line.nm_area : ""}</h5> */}
                            </div>
                        </div>

                        <div className="col d-flex align-items-center justify-content-end">
                            <div className="tooltips">
                                {/* <div className={`status-circle ${lineTimeline ? lineTimeline.length >= 1 ? lineTimeline[lineTimeline.length - 1] : "unknown" : "unknown"} unplannedTODO`} time={timerValue ? "" + ( (timerTime).toString() ) + secondsToDurationString(timerValue * 60) : ""}> */}
                                <div className={`status-circle ${lineTimeline ? lineTimeline.length >= 1 ? lineTimeline[lineTimeline.length - 1] : "unknown" : "unknown"} unplannedTODO`} time={timerOn? (statusDescription&&statusDescription!="null"?statusDescription:"") +" "+secondsToDurationString(timerTime / 1000, "seconds"):""}>
                                    <span className="tooltips-text">
                                        <p className="fs-small mb-0">{lineTimeline ? lineTimeline.length >= 1 ? statusText[lineTimeline[lineTimeline.length - 1]] : "Unknown" : "Unknown"}</p>
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


                <div className="status-card-body">

                    <div className="row mb-3 g-0">
                        <div className="col">
                            <div className="rounded bg-grey-lighter px-3 py-1">
                                <div className="row g-0">

                                    <div className="col-5">

                                        <p>Job</p>
                                        <h4 className="fw-bold">{currentOrder.op ? currentOrder.op : "NA"}</h4>
                                        <p className="text-uppercase">{currentOrder.cliente ? ("" + currentOrder.cliente + "                    ").substring(0, 20) : "NA"}</p>
                                    </div>

                                    <div className="col">

                                        <div className="d-flex justify-content-between">
                                            <div className="label fs-small" forhtml="hoursProgress">Hours</div>
                                            <span className="fs-small"><span className="fw-bold">{currentOrder.tmprealmin ? secondsToDurationString(currentOrder.tmprealmin * 60) : "NA"}</span> /{currentOrder.tmpprevmin ? secondsToDurationString(currentOrder.tmpprevmin * 60) : "NA"}</span>
                                        </div>






                                        <div className="tooltips" style={{ display: "block" }}>
                                            <div className="progress border mb-2">
                                                <div id="hoursProgress" className={`progress-bar ${productionPace < 1 ? (productionPace < 0.9 ? "bg-danger" : "bg-warning") : ""}`} role="progressbar" style={{ width: `${(currentOrder.tmprealmin && currentOrder.tmpprevmin) ? ((currentOrder.tmprealmin * 100) / currentOrder.tmpprevmin) : 0}%` }} aria-valuenow={(currentOrder.tmprealmin && currentOrder.tmpprevmin) ? ((currentOrder.tmprealmin * 100) / currentOrder.tmpprevmin) : 0} aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            {currentOrder.qtdproduzida ?
                                                <span className="tooltips-text">
                                                    {productionPace < 1 ?
                                                        <span className="fs-small">Produção deve atrasar <span className="fw-bold">{productionPace > 0 ? secondsToDurationString(((currentOrder.tmpprevmin / productionPace) - currentOrder.tmpprevmin) * 60) : "NA"}</span></span> :
                                                        <span className="fs-small">Produção dentro  do prazo.</span>
                                                    }
                                                </span> : <></>}
                                        </div>

                                        <div className="d-flex justify-content-between">
                                            <div className="label fs-small" forhtml="jobProdProgress">Job production</div>
                                            <span className="fs-small"><span className="fw-bold">{currentOrder.qtdproduzida != null ? numbertoString(currentOrder.qtdproduzida) : "NA"}</span> /{currentOrder.qtdprevista ? numbertoString(currentOrder.qtdprevista) : "NA"}</span>
                                        </div>



                                        <div className="tooltips" style={{ display: "block" }}>
                                            <div className="progress border mb-2">
                                                <div id="jobProdProgress" class={`progress-bar ${productionPercentage > 90 ? (productionPercentage >= 100 ? "bg-danger" : "bg-warning") : ""}`} role="progressbar" style={{ width: `${(currentOrder.qtdproduzida && currentOrder.qtdprevista) ? currentOrder.qtdproduzida * 100 / currentOrder.qtdprevista : 0}%` }} aria-valuenow={(currentOrder.qtdproduzida && currentOrder.qtdprevista) ? currentOrder.qtdproduzida * 100 / currentOrder.qtdprevista : 0} aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>

                                            {productionPercentage > 90 ? (productionPercentage >= 100 ?
                                                <span className="tooltips-text"><span className="label fs-small">{`Produzido ${(productionPercentage - 100).toFixed(1)}% a mais que o planejado.`}</span></span> :
                                                <span className="tooltips-text"><span className="label fs-small">{`Produção se aproximando do planejado.`}</span></span>
                                            ) : <></>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 ms-3 hidden-mobile">
                            <h5 className="fs-6 fw-bold text-uppercase mb-2 color-grey-medium-dark">
                                Previous shifts
                            </h5>
                            <div className="row g-0">
                                <div className="col text-center">
                                    <h5 className="fs-medium fw-bold d-flex align-items-center justify-content-center">{prev1Shift.qtdproduzida ? numbertoString(prev1Shift.qtdproduzida) : "NA"}
                                        {prev1shiftGoalPercentage ?
                                            <div className="tooltips">
                                                <i className={prev1shiftGoalPercentage < 100 ? "color-danger ri-arrow-down-line" : "color-success ri-arrow-up-line"}></i>
                                                <span className="tooltips-text">
                                                    <p className="fs-small">{(prev1shiftGoalPercentage - 100).toFixed(1)}% of the goal</p>
                                                    <p className="fs-small fw-bold">Goal {prev1Shift.qtdmetaturno ? numbertoString(prev1Shift.qtdmetaturno) : "NA"}</p>
                                                </span>
                                            </div> : <></>}
                                    </h5>
                                    <p>{prev1Shift.turno ? "Turno " + prev1Shift.turno : "NA"}</p>
                                </div>
                                <div className="col text-center">
                                    <h5 className="fs-medium fw-bold d-flex align-items-center justify-content-center">{prev2Shift.qtdproduzida ? numbertoString(prev2Shift.qtdproduzida) : "NA"}
                                        {prev2shiftGoalPercentage ?
                                            <div className="tooltips">
                                                <i className={prev2shiftGoalPercentage < 100 ? "color-danger ri-arrow-down-line" : "color-success ri-arrow-up-line"}></i>
                                                <span className="tooltips-text">
                                                    <p className="fs-small">{(prev2shiftGoalPercentage - 100).toFixed(1)}% of the goal</p>
                                                    <p className="fs-small fw-bold">Goal {prev2Shift.qtdmetaturno ? numbertoString(prev2Shift.qtdmetaturno) : "NA"}</p>
                                                </span>
                                            </div> : <></>}
                                    </h5>
                                    <p>{prev2Shift.turno ? "Turno " + prev2Shift.turno : "NA"}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h5 className="fs-6 fw-bold">Current Shift</h5>

                    <hr className="color-grey mb-0 mt-1" />

                    <div className="row g-1 py-2">

                        <div className="col-3 text-center">
                            <h5 className="fw-bold fs-6 d-flex align-items-center justify-content-center">
                                {currentShift.qtdproduzida != null ? numbertoString(currentShift.qtdproduzida) : "NA"}
                                {currentshiftGoalPercentage ?
                                    <div className="tooltips">
                                        <i className={currentshiftGoalPercentage < 100 ? "color-danger ri-arrow-down-line" : "color-success ri-arrow-up-line"}></i>
                                        <span className="tooltips-text">
                                            <p className="fs-small">{(currentshiftGoalPercentage - 100).toFixed(1)}% of the goal</p>
                                            <p className="fs-small fw-bold">Goal {currentShift.qtdmetaturno ? numbertoString(currentShift.qtdmetaturno) : "NA"}</p>
                                        </span>
                                    </div> : <></>}
                            </h5>
                            <p small-mobile>Production <span className="fw-bold color-grey-medium-dark">[{line.unit_of_measurement || "m"}]</span></p>
                        </div>

                        <div className="col-3 text-center">
                            <h5 className="fw-bold fs-6 d-flex align-items-center justify-content-center">{(currentShift.qtdperda != null && currentShift.qtdproduzida != null) ?
                                (((currentShift.qtdproduzida || 0) + (currentShift.perda || 0) != 0) ? (currentShift.qtdperda) / ((currentShift.qtdproduzida || 0) + (currentShift.perda || 0)) * 100 : 0).toFixed(1) :
                                "NA"}%</h5>
                            <p small-mobile>Scrap</p>
                        </div>

                        <div className="col-3 text-center">
                            <h5 className="color-attention fw-bold fs-6 d-flex align-items-center justify-content-center">{
                                currentShift.speed != null ?
                                    currentShift.speed.toFixed(0) :
                                    "NA"}/min</h5>
                            <p small-mobile>Speed <span className="fw-bold color-grey-medium-dark">[{line.speed_unit_of_measurement || "m/min"}]</span></p>
                        </div>

                        <div className="col-3 text-center">
                            <h5 className="fw-bold fs-6 d-flex align-items-center justify-content-center">{console.log(JSON.stringify(currentShift))}{currentShift.oee!=null ? (currentShift.oee * 100).toFixed(1) : "NA"}%</h5>
                            <p small-mobile>OEE</p>
                        </div>

                    </div>

                    <hr className="color-grey m-0 mb-3" />

                    <div className="row mb-3  hidden-mobile">
                        <div className="col-6 border-end">

                            <h5 className="fs-6 fw-bold text-uppercase mb-2 color-grey-medium-dark">
                                Timeline
                            </h5>

                            <div className="progress radius">
                                {lineTimeline ?
                                    <>



                                        <div key={0} className="progress-bar bg-success tooltips" role="progressbar" style={{ width: `${statusPercentage["running"] || 0}%` }} aria-valuenow={`${statusPercentage["running"] || 0}%`} aria-valuemin="0" aria-valuemax="100">
                                            <div className="tooltips"><i className="ri-play-circle-line"></i>
                                                <span className="tooltips-text"><span className="fw-bold text-dark  fs-small">{`Running: ${statusPercentage["running"] ? statusPercentage["running"].toFixed(0) : "0"}%`}</span></span>
                                            </div>
                                        </div>





                                        <div key={1} className="progress-bar bg-danger" role="progressbar" style={{ width: `${statusPercentage["stopped"] || 0}%` }} aria-valuenow={`${statusPercentage["stopped"] || 0}%`} aria-valuemin="0" aria-valuemax="100">
                                            <div className="tooltips">
                                                <i className="ri-pause-circle-line"></i>
                                                <span className="tooltips-text"><span className="fw-bold text-dark fs-small">{`Stopped: ${statusPercentage["stopped"] ? statusPercentage["stopped"].toFixed(0) : "0"}%`}</span></span>
                                            </div>
                                        </div>



                                        <div key={2} className="progress-bar bg-warning" role="progressbar" style={{ width: `${statusPercentage["lowSpeed"] || 0}%` }} aria-valuenow={`${statusPercentage["lowSpeed"] || 0}%`} aria-valuemin="0" aria-valuemax="100">
                                            <div className="tooltips">
                                                <i className="ri-dashboard-2-line"></i>
                                                <span className="tooltips-text"><span className="fw-bold text-dark  fs-small">{`Low Speed: ${statusPercentage["lowSpeed"] ? statusPercentage["lowSpeed"].toFixed(0) : "0"}%`}</span></span>
                                            </div>
                                        </div>



                                        <div key={3} className="progress-bar setup" role="progressbar" style={{ width: `${statusPercentage["setup"] || 0}%` }} aria-valuenow={`${statusPercentage["setup"] || 0}%`} aria-valuemin="0" aria-valuemax="100">
                                            <div className="tooltips"><i className="ri-settings-5-line"></i>
                                                <span className="tooltips-text"><span className="fw-bold text-dark  fs-small">{`Setup: ${statusPercentage["setup"] ? statusPercentage["setup"].toFixed(0) : "0"}%`}</span></span>
                                            </div>
                                        </div>


                                    </>
                                    :
                                    <>
                                        <div key={0} className="progress-bar bg-unknown-1" role="progressbar" style={{ width: '25%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                            <i className="ri-play-circle-line"></i>
                                        </div>
                                        <div key={1} className="progress-bar bg-unknown-2" role="progressbar" style={{ width: '25%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                            <i className="ri-pause-circle-line"></i>
                                        </div>
                                        <div key={2} className="progress-bar bg-unknown-3" role="progressbar" style={{ width: '25%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                            <i className="ri-dashboard-2-line"></i>
                                        </div>
                                        <div key={3} className="progress-bar bg-unknown-2" role="progressbar" style={{ width: '25%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                            <i className="ri-settings-5-line"></i>
                                        </div>
                                    </>
                                }
                            </div>

                        </div>

                        <div className="col-6">

                            <h5 className="fs-6 fw-bold text-uppercase mb-2 color-grey-medium-dark">
                                Downtime reasons
                            </h5>

                            <div className="progress radius mb-1">
                                {
                                    stoppedTime != null ?
                                        <>
                                            <div key={0} className="progress-bar bg-danger" role="progressbar" style={{ width: `${stoppedTime != null ? stoppedTime.unplanned_percent ? stoppedTime.unplanned_percent * 100 : 0 : 100 / 2}%` }} aria-valuenow={stoppedTime != null ? stoppedTime.unplanned_percent ? stoppedTime.unplanned_percent * 100 : 0 : 100 / 2} aria-valuemin="0" aria-valuemax="100"></div>
                                            <div key={1} className="progress-bar bg-danger-2" role="progressbar" style={{ width: `${stoppedTime != null ? stoppedTime.planned_percent ? stoppedTime.planned_percent * 100 : 0 : 100 / 2}%` }} aria-valuenow={stoppedTime != null ? stoppedTime.planned_percent ? stoppedTime.planned_percent * 100 : 0 : 100 / 2} aria-valuemin="0" aria-valuemax="100"></div>
                                            {/* <div key={2} className="progress-bar bg-danger-3" role="progressbar" style={{ width: `${line.total_stopped_time ? line.change_over_duration_percent ? line.change_over_duration_percent * 100 : 0 : 100 / 3}%` }} aria-valuenow={line.total_stopped_time ? line.change_over_duration_percent ? line.change_over_duration_percent * 100 : 0 : 100 / 3} aria-valuemin="0" aria-valuemax="100"></div> */}
                                        </> :
                                        <>
                                            <div key={0} className="progress-bar bg-unknown-1" role="progressbar" style={{ width: `${30}%` }} aria-valuenow={30} aria-valuemin="0" aria-valuemax="100"></div>
                                            <div key={1} className="progress-bar bg-unknown-2" role="progressbar" style={{ width: `${30}%` }} aria-valuenow={30} aria-valuemin="0" aria-valuemax="100"></div>
                                            {/* <div key={2} className="progress-bar bg-unknown-3" role="progressbar" style={{ width: `${40}%` }} aria-valuenow={40} aria-valuemin="0" aria-valuemax="100"></div> */}
                                        </>
                                }
                            </div>
                            <div className="tooltips">
                                <p>Unplanned stops: <span className="fw-bold">{(stoppedTime?.unplanned_percent * 100).toFixed(0)}%</span></p>
                                <span className="tooltips-text"><span className="text-dark fs-small">Unplanned stopped time:<span className="fw-bold text-dark  fs-small"> {secondsToDurationString(stoppedTime?.unplanned_duration * 60 || 0)}</span></span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="status-card-footer hidden-mobile">
                    <p className="text-nowrap fw-bold color-grey-medium-dark me-2">last 24h</p>
                    <div className="w-100">
                        <div className="progress timeline">
                            {
                                lineTimeline ? lineTimeline.map(
                                    (item, index) => {
                                        return (
                                            <div className={`progress-bar ${item}`} role="progressbar" style={{ width: `${100 / lineTimeline.length}%` }} aria-valuenow={100 / lineTimeline.length} aria-valuemin="0" aria-valuemax="100" />
                                        )
                                    }
                                ) : (
                                    // <></>
                                    <div className={`progress-bar ${"bg-unknown-3"}`} role="progressbar" style={{ width: "100%" }} aria-valuenow={100} aria-valuemin="0" aria-valuemax="100" />
                                    // <ChartItem style={{ className: "tooltip" }} key={1} color={colors[""]} width={(100)}></ChartItem>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}