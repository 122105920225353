import React from 'react';
import {Card, Divider, Typography, Box, Grid,  } from '@mui/material'
import { experimentalStyled as styled } from '@mui/material/styles';
import moment from 'moment';

const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    [theme.breakpoints.up('xl')]: {
        padding: theme.spacing(1.8),
      },
    textAlign: 'center',
    display:'flex', 
    justifyContent: 'space-between',
    padding: theme.spacing(1),
}));

function InfoSide({ dataProd, dataMax12H, dataObjetivos, dataProjMonth, dataObjMonth, recorde12h }) {
    let padrão = { py: 2, my:-.4 };
    // console.log(dataProd)
    //PROJECAO MES
    let netProdMonth = dataProjMonth?.uns_equipment_current_month?.[0]?.net_production
    let elepstimeMonth = dataProjMonth?.uns_equipment_current_month?.[0]?.elapsed_time
    let beginTimeMonth = dataProjMonth?.uns_equipment_current_month?.[0]?.begin_time
    let endTimeMonth = dataProjMonth?.uns_equipment_current_month?.[0]?.end_time
    let durMonth = moment(endTimeMonth).diff(moment(beginTimeMonth), "seconds")

    let objeMes = ((netProdMonth* durMonth) / elepstimeMonth)?.toLocaleString('pt-BR',{ maximumFractionDigits: 0 });

    //PROJECAO TURNO
    let netProdTurno = dataProd?.uns_equipment_current_shift?.[0]?.net_production ? dataProd?.uns_equipment_current_shift?.[0]?.net_production : 0
    let elepstimeTurno = dataProd?.uns_equipment_current_shift?.[0]?.elapsed_time ? dataProd?.uns_equipment_current_shift?.[0]?.elapsed_time : 0
    let beginTimeShift = dataProd?.uns_equipment_current_shift?.[0]?.begin_time
    let endTimeShift = dataProd?.uns_equipment_current_shift?.[0]?.end_time
    let durTurno = moment(endTimeShift).diff(moment(beginTimeShift), "seconds") ? moment(endTimeShift).diff(moment(beginTimeShift), "seconds") : 0

    let objeTurno = ((netProdTurno* durTurno) / elepstimeTurno)?.toLocaleString('pt-BR',{ maximumFractionDigits: 0 });
    
    let targetShift = dataObjMonth?.oee_targets?.[0]?.vl_shift
    let valueBefore = dataProd?.uns_equipment_current_shift?.[0]?.prev1_oee
    let valueNow = dataProd?.uns_equipment_current_shift?.[0]?.oee

    function colorValidation(number, target) {
        let color = '#eeeee'
        if (number > target) {
            return color = '#318F29'
        } else if(number < target) {
            return color = '#C12727'
        } else {
            return color
        }
    }

    // console.log(recorde12h?.equipment_runtime_shift?.[0]?.net)
    return (
        <Card sx={{ml:1, height: 1, }} elevation={3} >
            <Grid container direction="column" sx={{ display: 'grid',
                    // gridTemplateRows: 'repeat(20, .7fr)' 
                }} >
                    <Box sx={{backgroundColor:"#4175F5", width: 1, mt:2, py:1,
                        justifyContent: 'center', display:'flex', }}>
                        <Typography variant="h1" sx={{fontFamily:"Source Sans Pro",fontWeight: 1000, fontSize: 24, color:"white", }} > PRODUÇÃO </Typography>
                    </Box>
                    
                    <Item sx={padrão}>
                        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontSize: 24,}} >
                            Turno Atual 
                        </Typography>
                        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro",fontWeight: 600, fontSize: 34}} >
                            { dataProd?.uns_equipment_current_shift?.[0]?.net_production?.toLocaleString('pt-BR',{ maximumFractionDigits: 0 }) }
                        </Typography>
                    </Item>
                    <Divider  />

                    <Item sx={padrão}>
                        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontSize: 24,}} >
                            Turno Anterior 
                        </Typography>
                        <Divider sx={{backgroundColor:"black"}} />
                        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro",fontWeight: 600, fontSize: 34}} >
                            { dataProd?.uns_equipment_current_shift?.[0]?.prev1_net_production?.toLocaleString('pt-BR',{ maximumFractionDigits: 0 }) }
                        </Typography>
                    </Item>
                    <Divider  />

                    <Item sx={padrão}>
                        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontSize: 24,}} >
                            Objetivo do Turno 
                        </Typography>
                        <Divider sx={{backgroundColor:"black"}} />
                        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro",fontWeight: 600, fontSize: 34}} >
                            { dataObjetivos?.production_targets?.[0]?.vl_shift?.toLocaleString('pt-BR',{ maximumFractionDigits: 0 })}
                        </Typography>
                    </Item>
                    <Divider  />

                    <Item sx={padrão}>
                        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontSize: 24,}} >
                            Projeção do Turno
                        </Typography>
                        <Divider sx={{backgroundColor:"black"}} />
                        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro",fontWeight: 600, fontSize: 34}} >
                            { isNaN(objeTurno) ? 0 : objeTurno }
                        </Typography>
                    </Item>
                    <Divider  />

                    <Item sx={padrão}>
                        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontSize: 24,}} >
                            Objetivo do Mês 
                        </Typography>
                        <Divider sx={{backgroundColor:"black"}} />
                        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro",fontWeight: 600, fontSize: 34}} >
                        { dataObjetivos?.production_targets?.[0]?.vl_month.toLocaleString('pt-BR') }
                        </Typography>
                    </Item>
                    <Divider  />

                    <Item sx={padrão}>
                        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontSize: 24,}} >
                            Projeção do Mês
                        </Typography>
                        <Divider sx={{backgroundColor:"black"}} />
                        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro",fontWeight: 600, fontSize: 34}} >
                            { objeMes }
                        </Typography>
                    </Item>
                    <Divider  />

                    <Item sx={padrão}>
                        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontSize: 24,}} >
                            Recorde - 12h 
                        </Typography>
                        <Divider sx={{backgroundColor:"black"}} />
                        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro",fontWeight: 600, fontSize: 34}} >
                            { recorde12h?.uns_equipment_current_metrics?.[0]?.production_record_shifts?.toLocaleString('pt-BR') }
                        </Typography>
                    </Item>
                    
                    <Box sx={{backgroundColor:"#4175F5", width: 1, py:1, display:'flex', justifyContent: 'center', }}>
                        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro",fontWeight: 1000, fontSize: 24, color:"white"}} >    
                            OEE 
                        </Typography>
                    </Box>

                    <Item sx={padrão}>
                        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontSize: 24,}} >
                            Turno Atual 
                        </Typography>
                        <Divider sx={{backgroundColor:"black"}} />
                        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro",fontWeight: 600, fontSize: 34, color:colorValidation(valueNow, targetShift)}} >
                            { dataProd?.uns_equipment_current_shift?.[0]?.oee ? (dataProd?.uns_equipment_current_shift?.[0]?.oee * 100).toFixed(1) : "0" } %
                        </Typography>
                    </Item>
                    <Divider  />

                    <Item sx={padrão}>
                        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontSize: 24,}} >
                            Turno Anterior 
                        </Typography>
                        <Divider sx={{backgroundColor:"black"}} />
                        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro",fontWeight: 600, fontSize: 34, color:colorValidation(valueBefore, targetShift) }} >
                            { dataProd?.uns_equipment_current_shift?.[0]?.prev1_oee ? (dataProd?.uns_equipment_current_shift?.[0]?.prev1_oee * 100).toFixed(1) : "0"} %
                        </Typography>
                    </Item>
                    <Divider  />
                    
                    <Item sx={padrão}>
                        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontSize: 24,}} >
                            Objetivo do Mês
                        </Typography>
                        <Divider sx={{backgroundColor:"black"}} />
                        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro",fontWeight: 600, fontSize: 34}} >
                            { dataObjMonth?.oee_targets?.[0]?.vl_month ? dataObjMonth?.oee_targets?.[0]?.vl_month * 100 : 0} %
                        </Typography>
                    </Item>
                    <Divider  />
                    
                    <Item sx={padrão}>
                        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontSize: 24,}} >
                            Projeção do Mês
                        </Typography>
                        <Divider sx={{backgroundColor:"black"}} />
                        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro",fontWeight: 600, fontSize: 34}} >
                            { dataProjMonth?.uns_equipment_current_month?.[0] ? (dataProjMonth?.uns_equipment_current_month?.[0]?.oee * 100).toFixed(1) : "0" } %
                        </Typography>
                    </Item>

            </Grid>
        </Card>
    );
}

export default InfoSide;