import React, { useContext } from "react";
import SignIn from "../../components/SignIn";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import DashBoard from "../DashBoard2";
import { UserContext } from "../../../src/providers/UserProvider.jsx";

function Application() {
    const user = useContext(UserContext);
    
    return (
        user ?
            <DashBoard />
            :
            <BrowserRouter>
                <Switch>
                    <Route path="/Login" exact component={SignIn}></Route>
                </Switch>
            </BrowserRouter>
    );
}
export default Application;