import React from 'react';
import {
  Box, Card, CardContent, Typography, Grid, 
 } from '@mui/material';

export default function CardTime({ total, porcent, translate, data }) { 
  const size = 2000;

  const allTime = data?.available_time;
  const stopTime = data?.duration_total;
  const runningTime = allTime - stopTime;
 
  const timeForm = ( num ) => {
    const dia = num/86400;
    const restoDia = num % 86400;

    const hora = restoDia/3600;
    const restoHora = restoDia%3600;
    
    const minutos = restoHora/60;

    const restoMinutos = restoHora%60;

    return (
      (Math.trunc(dia) !== 0 ? Math.trunc(dia) + "d  " : "") +
      (Math.trunc(hora) !== 0 ? Math.trunc(hora) + "h  " : "") +
      (Math.trunc(minutos) !== 0 ? Math.trunc(minutos) +  "min " : "") 
      // (Math.trunc(restoMinutos) !== 0 ? Math.trunc(restoMinutos) + "seg" : "")
    )
  };

  const porcentRunning = (runningTime * 100)/allTime
  const porcentDowntime = (stopTime * 100)/allTime

  return(
    <Card >
      <Grid container
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gridTemplateAreas: 
            `"title title title title"
            "total total porcent porcent"
            "last last last last"`,
        }} >

        <CardContent sx={{gridArea: 'title', display:'flex', mt:-.5 }}>
          <Typography variant="h6" component="div"> {translate.total_time } </Typography>
          <Typography variant="subtitle2" component="div" sx={{ml:1, mt:1}}>
            { allTime ? timeForm(allTime) : "-" }
          </Typography>
        </CardContent>

        <CardContent sx={{gridArea: 'total', mt:-3, mx:'auto', display:'grid', }}>
          <Typography variant="h6" component="div"> { runningTime ? timeForm(runningTime) : "-"} </Typography>
          <Typography sx={{mx:'auto'}} variant="subtitle2" color="#828282" > { porcentRunning.toFixed(1) }% </Typography>
          <Typography sx={{mx:'auto'}} variant="subtitle2" color="#828282" >  {translate.running_time ?? "Running time"}  </Typography>
        </CardContent>

        <CardContent sx={{gridArea: 'porcent', mt:-3, mx:'auto', display:'grid', }}>
          <Typography variant="h6" component="div"> { stopTime ? timeForm(stopTime) : "-" }</Typography>
          <Typography sx={{mx:'auto'}} variant="subtitle2" color="#828282" > { porcentDowntime.toFixed(1) }% </Typography>
          <Typography sx={{mx:'auto'}} variant="subtitle2" color="#828282" > {translate.stopped_time ??downtimes } </Typography>
        </CardContent>

        <CardContent sx={{gridArea: 'last', mt:-3, mb:-1.3 }}>
          <Box sx={{ maxWidth: size, display: 'flex', alignItems: 'center' }}>
             <Box sx={{ display: 'grid',
              width: isNaN(porcentRunning) || Math.round(porcentRunning)===0 
                ? 0 : Math.round(porcentRunning) <= 5
                ? 10*size : Math.round(porcentRunning)*size,
             }} >
              <Box sx={{
                  height:16, display: 'flex', alignItems: 'center',
                  justifyContent: 'center', backgroundColor:'#0984E3',
                  borderTopLeftRadius: 10, borderBottomLeftRadius:10,
                }}> </Box>
            </Box>

            <Box sx={{ display: 'grid',
                width: isNaN(porcentDowntime) || Math.round(porcentDowntime)===0 
                  ? 0 : Math.round(porcentDowntime) <= 5 
                  ? 10*size : Math.round(porcentDowntime)*size,
               }} >
              <Box 
                sx={{
                  height: 16, display: 'flex', alignItems: 'center',
                  justifyContent: 'center', backgroundColor:'#EB5757',
                  borderBottomRightRadius: 10, borderTopRightRadius: 10,
                  borderTopLeftRadius: Math.round(porcentDowntime)===0 ? 10 : 0 ,
                  borderBottomLeftRadius: Math.round(porcentDowntime)===0 ? 10 : 0 ,
                  }}> </Box>
              </Box>
            </Box>
        </CardContent>

      </Grid>

    </Card>
  );
}
