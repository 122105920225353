import React from 'react';
import { Typography, Box } from '@mui/material';
import moment from 'moment';
import { roundNumber, isInvalidNumber, getLanguage, userLocale } from '../../../utils/utils';

import { TooltipContainer, TooltipColumn, TooltipDivider, TooltipItems, TooltipTitle, TooltipResponsive } from '../styles';

const ShiftTooltip = ({ active, payload, label, chartData, dateRange }) => {
    const language = getLanguage();
    const locale = userLocale();

    let currentDot = chartData?.filter((item) => item?.ts_value_production === label);
    if (!active) return null;
    currentDot = currentDot?.[0] || {};
    if (currentDot?.net === null) return null;
    if (moment(currentDot?.ts_value_production).isAfter(moment(new Date()))) return null;
    const shifts = currentDot?.array_agg || [];
    let tooltipSize = 240;
    if (shifts.length > 1) {
        tooltipSize = 440;
    }

    let showIncomplete = false;
    let format = 'dddd DD/MM';
    let showHour = false;
    let displayDate = moment(label).utc().format(format);
    if (dateRange === 'day' || dateRange === 'today') {
        format = locale.hour;
        if (!locale.isAmPm) showHour = true;
        displayDate = moment(label).format(format);
    }
    
    if(moment(currentDot.ts_value_production).minutes() !== 0 || moment(currentDot.ts_value_production).seconds() !== 0) {
        showIncomplete = true;
    }

    
    return (
        <TooltipResponsive size={tooltipSize}>
            <Box sx={{ display: 'flex', height: '50px', width: '100%'}}>
                <TooltipTitle>
                    {displayDate}{(showHour) ? 'h' : ''}
                    <span> {showIncomplete ? `(${language?.inprogress || 'In progress'})` : ''} </span>
                </TooltipTitle>
                
            </Box>

            <TooltipColumn>
                {
                    shifts.map((shift, index) => (
                        <TooltipItems key={index}>
                            <Typography sx={{ color: '#4D4D4D' }} >{language?.shift || 'Shift'} {shift?.cd_shift}</Typography>
                            <Typography sx={{fontWeight: 'bold', fontSize: '26px' }}>
                                 {roundNumber(shift?.net)} 
                            </Typography>
                        </TooltipItems>
                    ))
                }

            </TooltipColumn>

        </TooltipResponsive>
    )
  
};
export default ShiftTooltip;
