import React, { useEffect, useState } from 'react';
import { secondsToDurationString, escapeRegExp } from '../../../utils/utils';
import { DataGridPro, GridToolbarContainer, GridToolbarColumnsButton, 
    useGridApiRef, GridActionsCellItem } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import QuickSearchToolbar from '../../../components/Search';
import LightTooltip  from '../../../components/LightTooltip';
import { Typography } from '@mui/material';

import moment from 'moment'

// import { RiSplitCellsHorizontal } from 'react-icons';
import { RiEditLine, RiScissorsCutLine,  } from 'react-icons/ri';

// import { useGridApiRef, GridActionsCellItem } from '@mui/x-data-grid-pro';

import { getLanguage } from '../../../utils/utils';

import '../../../components/Helpers/helpers.css'

import DialogEdit from './DialogEdit';
import DialogTrim from './DialogTrim';



function CustomToolbar() {
    const language = getLanguage();
    return (
      <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
        <Typography
              sx={{ flex: '1 1 100%', fontWeight: 900 }}
              variant="h4"
              id="tableTitle"
              component="div">
              { language?.downtime_details || 'Downtime Details' }
        </Typography>
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end'  }}>
            <GridToolbarColumnsButton sx={{ bgcolor: '#EAEAED', width: '140px', borderRadius: '8px', color: '#333333' }} />
            <QuickSearchToolbar />
        </Box>
      </GridToolbarContainer>
    );
}

function DowntimeTable(props) {
    const { data, update, isSectorView } = props;
    const [dataModel, setDataModel] = useState();
    const language = getLanguage();
    const [searchText, setSearchText] = useState('');
    const [openTrim, setOpenTrim] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [rows, setRows] = React.useState([]);
    const [dateFormat, setDateFormat] = useState('DD/MM/YYYY HH:mm');

    const apiRef = useGridApiRef();

    const handleClickOpenTrim = (id) => {
        setDataModel(rows[id]);
        setOpenTrim(true);
    };

    const handleCloseTrim = () => {
        setOpenTrim(false);
    };
    

    const handleClickOpenEdit = (id) => {
        setDataModel(rows[id]);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const columns = [
        { field: 'id_order', headerName: `${language?.job || 'Job'}`, width: 120 },
        {
            field: 'nm_equipment',
            headerName: `${language?.line || 'Line'}`,
            width: 170,
            editable: false,
        },
        {
            field: 'sector',
            headerName: `${language?.sector || 'Sector'}`,
            width: 170,
            editable: false,
            hide: !isSectorView,
        },
        {
            field: 'cd_shift',
            headerName: `${language?.shift || 'Shift' }`,
            width: 120,
            editable: false,
        },{
            field: 'ts_event',
            headerName: `${language?.start || 'Start'}`,
            width: 130,
            editable: false,
            valueGetter: ({ value }) => value && moment(value, 'DD/MM/YYYY HH:mm').format(dateFormat),
        },{
            field: 'ts_end',
            headerName: `${language?.end || 'End'}`,
            width: 130,
            editable: false,
            valueGetter: ({ value }) => value && moment(value, 'DD/MM/YYYY HH:mm').format(dateFormat),
        },{
            field: 'total',
            headerName: `${language?.total_time || 'Total Time'}`,
            width: 130,
            editable: false,
        },{
            field: 'cd_machine',
            headerName: 'Machine',
            width: 150,
            editable: false,
            renderCell: (params) => (
                <LightTooltip title={params?.row?.cd_machine} placement='top'>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params?.row?.cd_machine}</span>
                </LightTooltip>
            )
        },{
            field: 'cd_category',
            headerName: `${language?.category || 'Category'}`,
            width: 120,
            editable: false,
            renderCell: (params) => (
                <LightTooltip title={params?.row?.txt_category} placement='top'>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params?.row?.cd_category}</span>
                </LightTooltip>
            )
        },{
            field: 'cd_subcategory',
            headerName: `${language?.sub_category || 'Sub category'}`,
            width: 120,
            editable: false,
            renderCell: (params) => (
                <LightTooltip title={params?.row?.txt_subcategory} placement='top'>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params?.row?.cd_subcategory}</span>
                </LightTooltip>
            )
        },{
            field: 'txt_downtime_notes',
            headerName: `${language?.notes || 'Notes'}`,
            width: 260,
            editable: false,
            renderCell: (params) => (
                <LightTooltip title={params?.row?.txt_downtime_notes} placement='top'>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params?.row?.txt_downtime_notes}</span>
                </LightTooltip>
            )
        },{
            field: 'actions',
            type: 'actions',
            headerName: `${language?.actions || 'Actions'}`,
            cellClassName: 'actions',
            getActions: ({ id }) => {
    
              return [
                  <LightTooltip title={language?.edit || 'Edit'} placement='top'>
                    <GridActionsCellItem
                        icon={<RiEditLine size={19} />}
                        label="Edit"
                        className="textPrimary"
                        onClick={() => handleClickOpenEdit(id)
                            // handleClickOpenEdit(rows[id])
                            // setDataModal(rows[id]),
                        }
                        color="inherit"
                    />
                  </LightTooltip>
                ,
                <LightTooltip title={language?.split || 'Split'} placement='top'>
                    <GridActionsCellItem
                    // icon={<RiSplitCellsHorizontal size={19} />}
                    icon={<RiScissorsCutLine size={19} />}
                    label="Cut"
                    // onClick={() => console.log( rows[id] )}
                        onClick={() => handleClickOpenTrim(id)}
                        color="inherit"
                    />
                </ LightTooltip>
                ,
              ];
            },
          },
        
      ];
    
    useEffect(() => setRows(data), [data]);

    useEffect(() => {
        let format = ['en-US'].includes(localStorage.getItem('user_language')) ? 'MM/DD/YYYY hh:mma' : 'DD/MM/YYYY HH:mm';
        setDateFormat(format);
    }, []);
      

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
        const filteredRows = data.filter((row) => {
          return Object.keys(row).some((field) => {
            return searchRegex.test(row[field].toString());
          });
        });
        setRows(filteredRows);
      };

    return (
        <>
            <DialogTrim update={update} isSectorView={isSectorView} isOpen={openTrim} handleClose={handleCloseTrim} data={dataModel} />

            <DialogEdit update={update} isSectorView={isSectorView} isOpen={openEdit} handleClose={handleCloseEdit} data={dataModel}  />

            <Box sx={{ height: 700, width: '100%', '& .super-app-theme--Open': {
                        bgcolor: '#FFF4DE',
                        fontWeight: 500,
                        '&:hover': { bgcolor: '#FFF4DE' }
                        }, 
                    }}>
            <DataGridPro
                sx={{ border: 'none' }}
                apiRef={apiRef}
                // components={{ Toolbar: CustomToolbar }}
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowClassName={(params) => {
                    if (!params?.row?.cd_category) return 'super-app-theme--Open'
                 } }
                // disableSelectionOnClick
                componentsProps={{
                    toolbar: {
                      value: searchText,
                      onChange: (event) => requestSearch(event.target.value),
                      clearSearch: () => requestSearch(''),
                      apiRef
                    },
                }}
            />
            </Box>

            
        </>
    );
}


export default DowntimeTable;