import React from 'react';
import { ComposedChart, Bar, CartesianGrid, YAxis, XAxis, Tooltip, Cell, Legend, ReferenceLine, Line, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import SinglePeriodTooltip from './Tooltip';
import XTick from '../../../components/Tick/XTick';
import Ytick from '../../../components/Tick/Ytick';
import ChartLegend from '../../../components/ChartLegend';
import { getLanguage } from '../../../utils/utils';


function SinglePeriodChart({ data, dateRange }) {
    const language = getLanguage();
    let chartData = data ?? [];
    let legendKeys = [{
          label: language?.quantity_produced || 'Quantity Produced',
          color: '#4175F5',
          circle: true
        }, {
          label:  language?.average ||'Average',
          dashed: true,
          color: '#828282'
        }, {
          label: language?.production_goal || 'Production Goal',
          color: '#27AE60',
      }];
      
    // average line: sum of net from chartData array
    const average = chartData.reduce((acc, curr) => {
      return acc + curr.net;
    }, 0) / chartData.length;


    let intervalData = 'preserveStart';
    if(chartData?.length < 10) intervalData = 0;
    if(chartData?.length > 10 && chartData?.length <= 33) intervalData = 1;

    const chartSettings = { top: /*2*/40, right: /*40*/50, left: /*30*/16, bottom: /*2*/0 };
  return (
    <ResponsiveContainer  width="100%" height={490}>
        <ComposedChart width={1400} height={470} margin={chartSettings} data={chartData}>
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis
                dataKey="ts_value_production"
                tick={<XTick dateRange={dateRange} />}
                height={60}
                interval={intervalData}
              />
                        
            <YAxis
                tick={<Ytick />}
                label={{ position: 'insideLeft' }}
                axisLine={false}
            />

            <Tooltip 
                content={<SinglePeriodTooltip chartData={chartData}
                dateRange={dateRange} />} 
            />

            <Legend 
                content={<ChartLegend />}
                legendKeys={legendKeys}
            />
        
            <Bar
                dataKey='net'
                radius={[5, 5, 0, 0]}
                isAnimationActive
                animationDuration={2400}
            >
              {chartData.map((entry, index) => {
                if (moment(entry.ts_value_production).minutes() !== 0 || moment(entry.ts_value_production).seconds() !== 0) {
                  return (
                      <Cell
                          key={`cell-${index}`}
                          stroke='#407CCC'
                          fill='#C9D8FB'
                          strokeWidth="3"
                          style={{ borderRadius: '10px' }}
                          strokeDasharray="5 3"
                      />
                    );
                  } else {
                    return (<Cell key={`cell-${index}`} fill='#407CCC'  />);
                  }
                })
              }

            </Bar>
            <Line
                isAnimationActive
                animationDuration={2400}
                animationEasing="ease-out" type="linear" strokeWidth="2" 
                dot={false} dataKey="target" stroke="#27AE60" 
            />

            <ReferenceLine 
                y={average} 
                stroke="#828282"
                isFront
                strokeWidth={2}
                strokeDasharray='5 3'
            />

        </ComposedChart>
    </ResponsiveContainer>
  );
}

export default SinglePeriodChart;