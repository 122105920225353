import React from 'react';
import {AppBar, Box, IconButton } from '@mui/material';
import {ReactComponent as Logo} from '../../../assets/white-logo.svg';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { useHistory } from "react-router-dom";

function Header() {
  let history = useHistory();

  return (
    <Box sx={{ width: '100%' }}>
      <AppBar position="static" >
          <Box sx={{
            display:'flex',
            justifyContent:'center',
            alignItems: 'center',
            background: '#131822',
            width: '100%',
            height: '50px',
          }}>
            <IconButton 
              sx={{ position: 'absolute', top: 0, left: 21, color: '#fff' }}
              onClick={() => history.goBack() }
            >
              <ChevronLeftIcon fontSize='large' />
            </IconButton>
            <Logo height="26px" />
            
          </Box>
      </AppBar>
    </Box>
  );
};

export default Header;