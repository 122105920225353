//import moment from 'moment';
import React, { useContext } from 'react';
import { LineChart, CartesianGrid, YAxis, XAxis, Tooltip, Line, Legend, ResponsiveContainer } from 'recharts';
import ChartLegend from '../../../components/ChartLegend';
import StaticTooltip from './staticTooltip';
import CustomTooltip from './Tooltip';
import XTick from '../../../components/Tick/XTick';
import Ytick from '../../../components/Tick/Ytick';
import { getLanguage } from '../../../utils/utils';
import { VariablesContext } from '../../../Context/VariablesContext';
import moment from 'moment';

function Chart({ width, height, data, dateRange, isShift }) {
    const { setTs_start, setTs_end, setRange } = useContext(VariablesContext);
    const language = getLanguage();
    let chartSize = 550;
    if (window.innerWidth < 1537) chartSize = 490;

    let legendKeys = [];
    const colors = ['#4175F5', '#5927b6', '#318F29'];
      

      let chartData = data?.map((item) => (

          {
            gross_production: item.gross_production_acc,
            net_production: item.net_production_acc,
            scrap: item?.scrap,
            target: item?.target,
            trend_line: item?.trendline1,
            ts: item?.ts,
            togoal: item?.togoal,
            period_production: item?.net_production_incr,
            scrap_acc: item?.scrap_acc,
            shifts: item?.shift_net_prod,
            target_period: item?.target_period
          }
        
      ));
      chartData?.unshift({
        gross_production: 0,
        net_production: 0,
        scrap: 0,
        target: 0,
        trend_line: 0,
        ts: moment(data?.[0]?.ts).subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
        togoal: 0,
        period_production: 0,
        scrap_acc: 0,
        shifts: [],
        target_period: 0
      });

      // chartData?.pop();
      //chartData = chartData?.filter((item) => item.net_production !== null);
      let mainShifts = null;


      let intervalData = 'preserveStart';
      if(chartData?.length < 10) intervalData = 0;
      if(chartData?.length > 10 && chartData?.length <= 33) intervalData = 1;

      const chartSettings = { top: /*2*/0, right: /*40*/54, left: /*30*/1, bottom: /*2*/0 };

      if (isShift && chartData?.length) {

        let shifts = chartData?.[1]?.shifts;

        mainShifts = shifts?.map((item, index) => {
          const lineColor = colors[index];
          if(item?.shift){
            legendKeys.push({ label: `Shift ${item?.shift}`, color: colors[index]})
            return (
              <Line
                key={item?.shift}
                isAnimationActive={false}
                type="linear"
                dataKey={`shifts[${index}]net`}
                stroke={lineColor}
                connectNulls={true}
                strokeWidth="4"
                dot={false}
              />
            );
          }
        }
        );
      } else {
        legendKeys = [{
          label: language?.quantity_produced || 'Quantity Produced',
          color: '#4175F5'
        }, {
          label:  language?.trend_line ||'Trend Line',
          dashed: true,
          color: '#828282'
        }, {
          label: language?.production_goal || 'Production Goal',
          color: '#318F29'
        }];
      }
      
      function setDay(e) {
        if (e?.activeLabel === undefined) return;
        if (dateRange !== 'today' && dateRange != 'day') {
          let start = e?.activeLabel
          start = moment(start).utc().format('YYYY-MM-DD HH:mm');
          let end = moment(start).utc().format('YYYY-MM-DD HH:mm');
          setTs_start(start);
          setTs_end(end);
          setRange('day');
        }
        
      }
      
  return (
        <div className="chart-container" data-test-id="chart-total-production" >
          <ResponsiveContainer width="100%" height={chartSize}>
            <LineChart width={width} height={height} margin={chartSettings} data={chartData}
              onClick={(e) => setDay(e)}
            >
              <CartesianGrid strokeDasharray="1" vertical={false} />
              <XAxis
                dataKey="ts"
                tick={<XTick dateRange={dateRange} hideFirstTick />}
                interval={intervalData}
              />
                        
              <YAxis
                tick={<Ytick />}
                label={{ position: 'insideLeft' }}
                interval={'preserveEnd'}
                axisLine={false}
                domain={[0, 'dataMax']}
              />

              <Tooltip 
                content={<CustomTooltip chartData={chartData}
                dateRange={dateRange} isShift={isShift} />} 
               />
              <Legend 
                content={<ChartLegend />}
                legendKeys={legendKeys}
              />
              {
                (isShift) ? mainShifts : <>
                  <Line animationDuration={2400} dot={false}
                    animationEasing="ease-out" type="linear" strokeWidth="4" 
                    connectNulls={true} dataKey="net_production" stroke="#4175F5" />

                  <Line animationDuration={2400}
                    animationEasing="ease-out" type="linear" strokeWidth="4" 
                    dot={false} dataKey="target" stroke="#318F29" />

                  <Line animationDuration={2400}
                    animationEasing="ease-out" type="linear" strokeWidth="2" 
                    dot={false} strokeDasharray="3 2" dataKey="trend_line" stroke="#828282" /> 
                </>
              }
                        
            </LineChart>
                
          </ResponsiveContainer>
          {
            (dateRange != 'custom' && dateRange != 'day' && !isShift) ? 
            <StaticTooltip chartData={chartData} dateRange={dateRange} /> : ''
          }
        
        </div>
  );
}

export default Chart;