import React, { useState } from 'react';
import { List } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { getLanguage } from '../../../utils/utils';

function JobList({ data, search, handleSelect }) {
    const language = getLanguage();
    const [selected, setSelected] = useState(null)

    const filteredData = data?.filter((el) => {
        if (search === '') return el;
        if (el?.product?.nm_product.toLowerCase().includes(search)) return el;
        if (el?.client?.nm_client.toLowerCase().includes(search)) return el;
        let orderNumberString = el?.id_order.toString();
        if (orderNumberString.includes(search)) return el;
    })

  return (
    <List dense sx={{ overflow: 'auto', mt: 3 }}>
        {
            filteredData?.map((item, index) => (
                <ListItem sx={{ pl: 0 }} key={item?.id_order}>
                    <ListItemButton onClick={() => {
                        setSelected(index);
                        handleSelect(item);
                     }}
                     selected={index === selected}
                     >
                        <ListItemText primary={
                            `${language?.job?.toLocaleUpperCase() || "PO"} ${item?.id_order} - 
                            ${language?.product || "Product"} ${item?.product?.nm_product} - 
                            ${item?.client?.nm_client} 
                            `} 
                        />
                    </ListItemButton>
                </ListItem>
            ))
        }
    </List>  
  );
}

export default JobList;