import React, { useEffect, useState } from 'react';
import { auth } from "../firebase";

export const UserContext = React.createContext({ user: null });

const UserProvider = ({ children }) => {
  const [isReady, updateStatus]=useState(null);
  const [currentUser, updateCurrentUser] = useState({ stsTokenManager: { accessToken: '' } });


  useEffect(() => {
    auth.onAuthStateChanged((currentState) => {
      if (currentState) {
        updateCurrentUser({ ...currentState.toJSON(), state: auth.currentUser });
      }
      updateStatus(true);
    });
  }, []);

  return (
    <UserContext.Provider value={{isReady, user: currentUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
