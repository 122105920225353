import React from 'react';

import { roundNumber  } from '../../utils/utils';

const Ytick = (props) => {
  let value = roundNumber(props.payload.value);
  if (value < 0) value = 0;
  return (
    <text style={{ fontWeight: 'bold' }} x={props.x} y={props.y}>
      <tspan x={(props.x)} dy="4" textAnchor="end">
        {value} {props?.isPercentage ? '%' : ''}
      </tspan>
    </text>
  );
} 
export default Ytick;
