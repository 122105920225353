import React from 'react';
import { BsClock } from 'react-icons/bs';

import { roundNumber } from '../../../../../utils/utils';

import { Card, Content, Title, Value, Item } from '../styles';

function Production({ data, name }) {
    let clientFontSize = 32;
    if (data?.nm_client?.length > 16) clientFontSize = 20;
    let quantity = roundNumber(data?.order_size);
    let produced = roundNumber(data?.collected_prod);
    let remaining = roundNumber(data?.production_remaining);
    //let productionPercent = data?.job_production_percentage;

    let productionPercent = data?.collected_prod * 100 / data?.order_size;
    if (productionPercent > 100) productionPercent = 100;
    if (productionPercent < 0 || isNaN(productionPercent)) productionPercent = 0;
    let remainingPercent = 100 - productionPercent;

    if (data?.job_production_percentage < 0) productionPercent = 0;

    if (data?.production_remaining < 0) remaining = 0;

    return (
        <Card width={1548} height={184}>

            {/* <Card width={272} height={184}>   */}

            {/* </Card> */}

            <Content width="100%" height="100%" direction="column">
                <div className="row">
                    {/* <Content width="30%" direction="column" justify="center" align="center">
                        <Title size={48}>Linha</Title>
                        <Value size={36} color="#4175F5"> {name} </Value>



                    </Content> */}

                    <Content width="30%">
                        <Item align="center">
                            <Title size={40}>OP</Title> &nbsp;&nbsp;&nbsp; <Value size={40} color="#4175F5"> {data?.id_order} </Value>
                        </Item>
                    </Content>

                    <Content width="70%" justify="space-around">
                        <Item direction="column">
                            <Title size={40}>Quantidade</Title> <Value size={24} color="#4175F5"> {quantity} </Value>
                        </Item>

                        <Item direction="column">
                            <Title size={40}>Produzido</Title> <Value size={24} color="#4175F5"> {produced}  |  {productionPercent?.toFixed()}% </Value>
                        </Item>

                        <Item direction="column">
                            <Title size={40}>Restante</Title> <Value size={24} color="#4175F5"> {remaining} <BsClock size={20} /> {data?.remaining_time}</Value>
                        </Item>

                    </Content>

                </div>

                <div className="row">
                    <Content width="30%">
                        <Item direction="column">
                            <Title size={24}>Cliente</Title> <Value size={clientFontSize} color="#4175F5"> {data?.nm_client} </Value>
                        </Item>

                    </Content>

                    <Content width="70%" justify="center">
                        <Item align="center" top={20} width="90%">

                            <div className="progress overflow-hidden border" style={{ width: '100%', height: 32 }}>
                                <div id="jobProdProgress" className={`progress-bar ${productionPercent>=100?"bg-success": ""}`} role="progressbar"
                                    style={{ width: `${productionPercent}%`, background: `${productionPercent>=100?"rgb(15 131 60 / 50%)":""}`, fontSize: 24, fontWeight: 'bold' }}
                                    aria-valuenow={productionPercent} aria-valuemin="0" aria-valuemax="100">
                                    {produced}
                                </div>
                                <div id="jobProdProgress" className="progress-bar" role="progressbar"
                                    style={{ width: `${remainingPercent}%`, background: 'rgba(65, 117, 245, 0.5)', fontSize: 24, fontWeight: 'bold' }}
                                    aria-valuenow={remainingPercent} aria-valuemin="0" aria-valuemax="100">
                                    {remaining}
                                </div>
                            </div>

                        </Item>
                    </Content>
                </div>
            </Content>
        </Card>
    );
}

export default Production;