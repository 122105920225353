import React, { useContext, useRef } from "react";
import './breadcrumb.css';
import '../../components/PackDropdown/pack-dropdown.css';
import { useQuery, useSubscription } from '@apollo/client';
import { VariablesContext } from '../../Context/VariablesContext';
import { BreadcrumbQuery } from './Query';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import { getLanguage } from '../../utils/utils';

const language = getLanguage();

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    {language?.select_area || 'Select an area'}
  </Tooltip>
);

export default function area() {
  const {area, setArea, site} = useContext(VariablesContext);
  const { loading, error, data } = useQuery(BreadcrumbQuery);
  const areaRef = useRef();

  const validation = (data) => {
    // if (area == undefined) {
    //   if (data?.areas.length===1) setArea(data?.areas[0].id_area);
    // }
  };
  validation(data);

  const handleChangeArea = e => {
    // console.log(e.target.value)
    areaRef.current.checked=false;
    setArea(e.target.value);
  };

  const inputProps = {
    type: "radio",
    onChange: handleChangeArea,
    class:"list-group-item-check", 
    name:"listGroupCheckableRadios"
  };

  return (
    

      <div className="pack-dropdown me-2">

        <input id="ghost-dropdown-aluminum-trigger" type="checkbox" name="dropdown-trigger" ref={areaRef} />

        <label htmlFor="ghost-dropdown-aluminum-trigger" className="close-trigger"></label>
        <OverlayTrigger
            placement="top"
            delay={{ show: 180, hide: 150 }}
            overlay={renderTooltip}
          >
          <label htmlFor="ghost-dropdown-aluminum-trigger" className="btn btn-link fw-bold">
          
            {area>0 ? data?.areas.filter(item => item.id_area==area)?.[0]?.nm_area :
              <i className="ri-add-circle-fill fa-lg"></i> 
            }
          </label>
        </OverlayTrigger>

        <ul className="pack-dropdown-menu" aria-labelledby="dropdownid">


          <li>
            <div className="form-check px-4 mx-2">
              <label className="form-check-label  bold text-dark text-center" htmlFor={`id-0`}>
                {language?.select_area || 'Select an area'}
              </label>
            </div>
          </li>

          <form>
            <fieldset className="list-group-checkable">
              <div form-check-input >
              {data?.areas.filter(item => item.id_site==site).map((area, index_area) => {       //TODO FILTER
                return (
                    <label className="list-group-item">
                      <input 
                      className="form-check-input"
                      id="listGroupCheckableRadios3" 
                      value={area.id_area} checked={area === area.id_area} {...inputProps}/>
                      <span> {area.nm_area} </span>
                    </label>
                            
                    ) }) }
                </div>
              </fieldset>
            </form>

        </ul>
      </div>
  );
}