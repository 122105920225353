import * as React from 'react';
import { SampleBase } from './sample-base.js';
import './style.css';

import logo from '../../assets/logo.png'

import SideBar from '../../components/SideBar'

export default class DashBoard extends SampleBase {
    constructor() {
        super(...arguments);

    }

    render() {
        return (
            <div>
                <SideBar />

                <div id="main-content container-fluid col-md-12 ">
                    <div className="title">
                        <div className="logoTitle">
                            <img className="image" src={logo} height="50 px" alt="avatar" />
                        </div>


                        <div className="avatarDiv">
                            <div className="e-avatar e-avatar e-avatar-circle avatar">
                                <img className="image" src="https://media-exp1.licdn.com/dms/image/C4D0BAQHuzJj6il7oHQ/company-logo_200_200/0/1591372598595?e=2159024400&v=beta&t=rXAg__IeFaywWJ40gd-eja2o1-B_XAGbuNcar3741p8" alt="avatar" />
                            </div>
                            <div className="accountOptions">
                                <ul>
                                    <li>Profile</li>
                                    <li>Help</li>
                                    <li>Logout</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div>
                        <iframe title="Bold BI" className="embbedBI" src='https://ishikawa.boldbi.com/bi/dashboards/52952bcf-b2cb-46f0-8ab2-11b947de886d/BI%20Tests/scrapMonthBar?isembed=true' id='dashboard-frame' allowFullScreen frameBorder='0'></iframe>
                        {/* <iframe className="embbedBI" src="https://ishikawa.boldbi.com/bi/en-us/dashboards/0df4576a-c6fd-463f-8be9-20321b904643/BI%20Tests/productionMonth"></iframe> */}
                    </div>    
                </div>
            </div>
        );
    }

    // open / close the sidebar
    toggleClick() {
        this.dockBar.toggle();
    }
}
