import React, { useContext, useState, useRef } from "react";
import './breadcrumb.css';
import '../../components/PackDropdown/pack-dropdown.css';
import { useQuery, useSubscription } from '@apollo/client';
import { VariablesContext } from '../../Context/VariablesContext';
import { BreadcrumbQuery } from './Query';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import { getLanguage } from '../../utils/utils';

const language = getLanguage();

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    {language?.select_site || 'Select a site'}
  </Tooltip>
);

export default function Site() {
  const {site, setSite, setEnterprise} = useContext(VariablesContext);
  const [value, setState] = useState(null);
  const { loading, error, data } = useQuery(BreadcrumbQuery);

  const siteRef = useRef();

  const validation = (data) => {
    if (site == undefined) {
      if (data?.sites.length===1){
        setSite(data?.sites[0].id_site);
      }
    }
  };
  validation(data);


  const handleChangeSite = e => {
    // console.log(e.target.value)
    setState(e.target.value);
    setSite(e.target.value);
    // alert(JSON.stringify());
    siteRef.current.checked=false;
    setEnterprise(data?.sites.find(site => site.id_site == e.target.value).id_enterprise);
  };

  const inputProps = {
    onChange: handleChangeSite,
    class:"list-group-item-check", 
    type:"radio",
    name:"listGroupCheckableRadios"
  };

  // console.log(data?.sites.filter(item => item.id_site==30)?.[0].nm_site )

  return (

          <div className="pack-dropdown me-2">

            <input id="ghost-dropdown-namerica-trigger" type="checkbox" name="dropdown-trigger"  ref={siteRef} />

            <label htmlFor="ghost-dropdown-namerica-trigger" className="close-trigger"></label>
            <OverlayTrigger
                placement="top"
                delay={{ show: 180, hide: 150 }}
                overlay={renderTooltip}
              >
              <label htmlFor="ghost-dropdown-namerica-trigger" className="btn btn-link fw-bold">
                
                  
                  {site>0 ? data?.sites.filter(item => item.id_site==site)?.[0]?.nm_site:
                    <i className="ri-add-circle-fill fa-lg"></i> 
                  }
              </label>
            </OverlayTrigger>

            <ul className="pack-dropdown-menu" aria-labelledby="dropdownid" >
              
              <li>
                <div className="form-check px-4 mx-2">
                  <label className="form-check-label  bold text-dark text-center" htmlFor={`id-0`}>
                  {language?.select_site || 'Select a site'}
                  </label>
                </div>
              </li>

              <form>
                <fieldset className="list-group-checkable">
                  <div form-check-input>
                    {data?.sites?.map((site, index_site) => {
                      return (
                        <label className="list-group-item" >
                        <input 
                        className="form-check-input"
                        id="listGroupCheckableRadios3" 
                        value={site.id_site} checked={site === site.id_site} {...inputProps}/>
                        <span> {site.nm_site} </span>
                      </label>
                              
                      ) }) }
                    
                  </div>
                </fieldset>
              </form>

            </ul>
          </div>
 
  );
}