import React, { useState, useEffect, useContext } from 'react';
import { VariablesContext } from '../../../Context/VariablesContext';
import DownloadIcon from '@mui/icons-material/Download';
import { IconButton } from '@mui/material';

import '../style.css';
import { getLanguage, convertToDaysHoursNoSeconds, JSONToCSVConvertor } from '../../../utils/utils';

export default function DownloadCsv({ data }) {
  const [csvData, setCsvData] = useState([]);
  const language = getLanguage();


  const { filterShift, } = useContext(VariablesContext);

  useEffect(() => {
    // setCsvData([]);
    setCsvData(data);

    // if (data.length !== csvData.length) csvData?.forEach(function(item) {
    csvData?.forEach(function (item) {

      // if(item.ts_end !== undefined || item.ts_end.includes("T") ) {
      let timeEnd = item?.ts_end?.split('T');
      let timeEndFinish = timeEnd?.[1]?.split('+');
      let end = timeEnd?.[0] + " " + timeEndFinish?.[0];
      item.ts_end = end;
      // console.log( timeEndFinish?.[0] , typeof timeEnd )
      // console.log(item.ts_end)
      // }

      // if(item.ts_event !== undefined || item.ts_event.includes("T") ) {
      let timeEvent = item?.ts_event?.split('T');
      let timeEventFinish = timeEvent[1]?.split('+');
      let event = timeEvent?.[0] + " " + timeEventFinish?.[0];
      item.ts_event = event;
      // }

    })

    // console.log(csvData);

  });

  const columns = {
    id_order: language?.job||"Job",
    sector: language?.sector||"Sector",
    nm_equipment: language?.line||"Line",
    cd_shift: language?.shift || "Shift",
    ts_event: language?.start || "Start",
    ts_end: language?.end || "End",
    duration: language?.duration || "Duration",
    cd_machine: language?.machine || "Machine", 
    cd_category: language?.category_code || "Category code",
    cd_subcategory: language?.subcategory_code || "Subcategory code",
    txt_category: language?.category_description || "Category description",
    txt_subcategory: language?.subcategory_description || "Subcategory description",
    txt_downtime_notes: language?.notes || "Notes",
};

  return (
    <>
      <IconButton aria-label="delete">
        <DownloadIcon
          onClick={() => JSONToCSVConvertor(csvData?.filter((dwnt) => { if (filterShift?.length > 0) { return filterShift.includes(dwnt.id_shift) } else return true }), columns, "Downtime_Events", true)} 
          />
      </IconButton>
    </>
  );
}
