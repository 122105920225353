import React from 'react';
import { Typography, Box } from '@mui/material';
import moment from 'moment';
import { roundNumber, isInvalidNumber, getLanguage, userLocale } from '../../../utils/utils';

import { TooltipContainer, TooltipColumn, TooltipDivider, TooltipItems, TooltipTitle } from '../styles';

const SinglePeriodTooltip = ({ active, payload, label, chartData, dateRange }) => {
    const language = getLanguage();
    const locale = userLocale();

    let currentDot = chartData?.filter((item) => item?.ts_value_production === label);
    if (!active) return null;
    currentDot = currentDot?.[0] || {};
    if (currentDot?.net === null) return null;
    if (moment(currentDot?.ts_value_production).isAfter(moment(new Date()))) return null;

    let showIncomplete = false;
    let format = 'dddd DD/MM';
    let showHour = false;
    let displayDate = moment(label).utc().format(format);
    if (dateRange === 'day' || dateRange === 'today') {
        format = locale.hour;
        if (!locale.isAmPm) showHour = true;
        displayDate = moment(label).format(format);
    }
    
    if(moment(currentDot.ts_value_production).minutes() !== 0 || moment(currentDot.ts_value_production).seconds() !== 0) {
        showIncomplete = true;
    }

    function validateNumber(number) {
        let validNumber = number
        if(isInvalidNumber(number)) validNumber = 0;
        if(number > 100) validNumber = 100;

        return validNumber;
    }
    let toGoalPercentColor = '#318F29';
    let toGoalColor = '#4D4D4D';
    let toGoal = 0;
    let toGoalPercentText = language?.above_goal || 'above goal';
    let toGoalPercentFormat = '';
    let toGoalPercent = (currentDot?.net - currentDot?.target) / currentDot?.target * 100;
    let periodProduction = roundNumber(currentDot?.net);
    let scrap = roundNumber(currentDot?.scrap);
    let scrapPercent = validateNumber(currentDot?.scrap / currentDot?.net * 100);
    

    if (currentDot?.net <= currentDot?.target) {
        toGoal = roundNumber(currentDot?.target - currentDot?.net);
    } else {
        toGoal = roundNumber(currentDot?.net - currentDot?.target);
        toGoalColor = '#318F29';
        toGoal = `+${toGoal}`;
    }

    if (toGoalPercent >= 0) {
        toGoalPercentFormat = '+' + toGoalPercent.toFixed() + '%';
    } else {
        toGoalPercentColor = '#C12727';
        toGoalPercentText = language?.bellow_goal || 'bellow goal';
        toGoalPercentFormat = toGoalPercent.toFixed() + '%';
    }


    
    return (
        <TooltipContainer >
            <Box sx={{ display: 'flex', height: '50px', width: '100%'}}>
                <TooltipTitle>
                    {displayDate}{(showHour) ? 'h' : ''}
                    <span> {showIncomplete ? `(${language?.inprogress || 'In progress'})` : ''} </span>
                </TooltipTitle>
                
            </Box>

            <TooltipColumn>
                <TooltipItems>
                    <Typography sx={{ color: '#4D4D4D' }} >{language?.to_goal || 'To Goal'}</Typography>
                    <Typography sx={{fontWeight: 'bold', fontSize: '26px', color: toGoalColor }}> {toGoal} </Typography>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: toGoalPercentColor }}> {toGoalPercentFormat} {toGoalPercentText} </Typography>
                </TooltipItems>
                
                <TooltipDivider />

                <TooltipItems>
                    <Typography sx={{ color: '#4D4D4D' }}>{language?.period_production || 'Period Production'}</Typography>
                    <Typography sx={{fontWeight: 'bold', fontSize: '26px', color: '#4D4D4D' }}> {periodProduction} </Typography>
                    <Typography sx={{ visibility: 'hidden', fontWeight: 'bold', fontSize: '16px', color: '#318F29' }}> 30</Typography>
                </TooltipItems>

                <TooltipItems>
                    <Typography sx={{ color: '#4D4D4D' }} >{language?.scrap || 'Scrap'}</Typography>
                    <Typography sx={{fontWeight: 'bold', fontSize: '26px', color: '#4D4D4D' }}> {scrapPercent?.toFixed()}% </Typography>
                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: '#4D4D4D', opacity: 0.5 }}>({scrap})</Typography>
                </TooltipItems>

            </TooltipColumn>

        </TooltipContainer>
    )
  
};
export default SinglePeriodTooltip;
