import React, {useState} from 'react';
import { getLanguage } from '../../../utils/utils';
import { useHistory } from "react-router-dom";
import {
  Button, Menu, MenuItem, IconButton
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import PoRegistration from './PoRegistration';
import DowntimeUploadModal from './DowntimeUploadModal';

export default function Configuration() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const language = getLanguage();
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseC = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ mt:-2}}
      >
        <SettingsIcon sx={{ width: 24, height: 24, color:'#FFFFFF'}} />
      </IconButton>
      
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseC}
        anchorOrigin={{
          vertical: 50,
          horizontal: -80,
        }}>

        <MenuItem onClick={() => history.push('/settings')}>
          {language?.settings || 'Settings'}
        </MenuItem>

        <PoRegistration/>

        <DowntimeUploadModal />

      </Menu>
    </div>
  );
}
