import React, { useState, useContext, useEffect } from 'react';
import { Box, Typography, Select, MenuItem, TextField, Button, FormControl } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { VariablesContext } from '../../../../../Context/VariablesContext';
import InputAdornment from '@mui/material/InputAdornment';
import { useMutation, useQuery } from '@apollo/client';
import { GET_TARGETS } from '../../../queries';
import { SET_PRODUCTION_TARGET, SET_SCRAP_TARGET } from '../../../mutations';
import DefaultTargetTable from './DefaultTargetTable';
import { useSnackbar } from 'notistack';
import { getLanguage, roundNumber } from '../../../../../utils/utils';

import Spacer from '../../../../../components/Spacer';

function DefaultTarget() {
  const { siteOptions, areaOptions, lineOptions, enterprise } = useContext(VariablesContext);
  const [siteSelected, setSiteSelected] = useState(null);
  const [areaSelected, setAreaSelected] = useState(null);
  const [lineSelected, setLineSelected] = useState(null);
  const [sites, setSites] = useState([]);
  const [areas, setAreas] = useState([]);
  const [lines, setLines] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [property, setProperty] = useState(null);
  const [period, setPeriod] = useState('DAY');
  const [scrap, setScrap] = useState(0);
  const [production, setProduction] = useState(0);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const language = getLanguage();

  const { data: targetData, loading } = useQuery(GET_TARGETS, 
    { fetchPolicy: 'cache-first', variables: { id_equipment: lineSelected },
    skip: (lineSelected) ? false : true
    }
    );

  const [setScrapTarget] = useMutation(SET_SCRAP_TARGET, {
    onCompleted: () => {
      resetForm();
      enqueueSnackbar(`${language?.scrap_changes_success || 'Scrap changes saved succesfuly!'}`, { variant: 'success' });
    },
    onError: (error) => {
      enqueueSnackbar(`${language?.an_error_occured || 'Error'}`, { variant: 'error' });
    },
    refetchQueries: [GET_TARGETS, 'getTargets' ]
  });

  const [setProductionTarget] = useMutation(SET_PRODUCTION_TARGET, {
    onCompleted: () => {
      resetForm();
      enqueueSnackbar(`${language?.goal_changes_success || 'Goal changes saved succesfuly!'}`, { variant: 'success' });
    },
    onError: (error) => {
      console.log(error);
      enqueueSnackbar(`${language?.an_error_occured || 'Error'}`, { variant: 'error' });
  },
  refetchQueries: [GET_TARGETS, 'getTargets' ]
  });


  useEffect(() => {
    const siteOpt = siteOptions?.map((item) => ({
      value: item?.id_site,
      label: item?.nm_site
    }));
    setSites(siteOpt);
    
  }, [siteOptions, areaOptions, lineOptions]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const resetForm = () => {
    setProperty(null);
    //setPeriod(null);
    setScrap(0);
    setProduction(0);
    if (!buttonDisabled) setButtonDisabled(true);
  };

  const handleSiteChange = (event) => {
    const availableAreas = areaOptions?.filter((item) => item?.id_site === event.target.value);
    if (availableAreas) {
      const areaOpt = availableAreas.map((item) => ({
        value: item?.id_area,
        label: item?.nm_area
      }));
      setSiteSelected(event?.target.value)
      setAreas(areaOpt);
      setAreaSelected(null);
      setLineSelected(null);
      resetForm();

    }
  };

  const handleAreaChange = (event) => {
    const availableLines = lineOptions?.filter((item) => item?.id_area === event.target.value);
    if (availableLines) {
      const lineOpt = availableLines?.map((item) => ({
        value: item?.id_equipment,
        label: item?.nm_equipment
      }));
      setAreaSelected(event?.target.value);
      setLines(lineOpt);
      setLineSelected(null);
      resetForm();
    }
  };

  const handleLineChange = (event) => {
    setLineSelected(event?.target.value);
  };

  const handleScrapChange = (event) => {
    if (event.target.value?.length > 2) { 
      return;
    }
    setScrap(Number(event.target.value));
    if (Number(event.target.value) > 0 && lineSelected !== null) {
      setButtonDisabled(false);
    } else if (!buttonDisabled) {
      setButtonDisabled(true);
    }
  };

  const handleProductionChange = (event) => {
    setProduction(Number(event.target.value));
    if (Number(event.target.value) > 0 && lineSelected !== null) {
      setButtonDisabled(false);
    } else if (!buttonDisabled) {
      setButtonDisabled(true);
    }
  };

  const handleSubmit = () => {
    let vl_day = production;
    //let vl_day = targetData?.production_targets[0]?.vl_day;
    //let vl_week = targetData?.production_targets[0]?.vl_week;
    //let vl_month = targetData?.production_targets[0]?.vl_month;

    if (property === 'production') { 
/*
      if (period === 'DAY') {
        vl_day = production;
      }
      if (period === 'WEEK') {
        vl_week = production;
      }
      if (period === 'MONTH') {
        vl_month = production;
      }*/

     setProductionTarget({
        variables: {
          id_enterprise: enterprise,
          id_equipment: lineSelected,
          vl_day
        }
      });

    } else {
      setScrapTarget({
        variables: {
          id_enterprise: enterprise,
          id_equipment: lineSelected,
          vl_day: scrap
        }
      });
    }

  }

  function confirmMessage() {
    const line = lineOptions?.filter((item) => item?.id_equipment === lineSelected);
    const lineChanged = line?.[0]?.nm_equipment ?? '';
    let newValue = '';
    if (property === 'production') {
      newValue = roundNumber(production);
    } else {
      newValue = scrap + '%';
    }
    let successMsg = language?.confirm_goal_change || "You're about to change the line line_id goal to new_target. Are you sure about this?";
    successMsg = successMsg.replace("line_id", lineChanged?.toString());
    successMsg = successMsg.replace("new_target", newValue?.toString());
    return successMsg;
  }

  return (
    <Box sx={{ mt: 3, ml: 4, display: 'flex', flexDirection: 'column' }}>
        <Typography sx={{ color: '#4D4D4D', fontSize: '30px', fontWeight: 'bold', mb: 3 }}>{language?.default_targets || 'Default Targets'} </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <FormControl sx={{ width: "30%" }}>
            <InputLabel id="default-target-site-label">Site</InputLabel>
            <Select
              labelId="default-target-site-label"
              id="default-target-site"
              value={siteSelected}
              label="Site"
              onChange={(e) => handleSiteChange(e)}
              sx={{ width: '100%', background: '#fff' }}
            >
              {
                sites.map((item) => (
                  <MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>
                ))
              }
            </Select>
          </FormControl>

          <FormControl sx={{ width: "30%" }}>
            <InputLabel id="default-target-area-label">{language?.area || 'Area'}</InputLabel>
            <Select
              labelId="default-target-area-label"
              id="demo-simple-selec2t"
              value={areaSelected}
              label={language?.area || 'Area'}
              onChange={(e) => handleAreaChange(e)}
              sx={{ width: '100%', background: '#fff' }}
            >
              {
                areas.map((item) => ( <MenuItem key={item.label} value={item.value}>{item.label}</MenuItem> ))
              }
            </Select>
          </FormControl>

          <FormControl sx={{ width: "30%" }}>
            <InputLabel id="default-target-line-label">{language?.line || 'Line'}</InputLabel>
            <Select
              labelId="default-target-line-label"
              id="demo-simple-sele3ct"
              value={lineSelected}
              label={language?.line || 'Line'}
              onChange={(e) => handleLineChange(e)}
              sx={{ width: '100%', background: '#fff' }}
            >
              {
                lines.map((item) => ( <MenuItem key={item.label} value={item.value}>{item.label}</MenuItem> ))
              }
            </Select>
          </FormControl>

        </Box>

        <Box>
          <Spacer isVertical size={16} />
          <FormControl fullWidth>
            <InputLabel id="default-target-property-label">{language?.property || 'Property'}</InputLabel>
            <Select
              disabled={lineSelected === null}
              labelId="default-target-property-label"
              id="default-target-property"
              label={language?.property || 'Property'}
              value={property}
              onChange={(e) => setProperty(e.target.value)}
              sx={{ background: '#fff' }}
            >
              <MenuItem value={'production'}>{language?.production || 'Production'}</MenuItem>
              <MenuItem value={'scrap'}>{language?.scrap || 'Scrap'}</MenuItem>
            </Select>
          </FormControl>
          <Spacer isVertical size={10} />
          
          { 
            property === 'production' ?
            <FormControl fullWidth>
              <InputLabel id="default-target-period-label">{language?.period || 'Period'}</InputLabel>
              <Select
                disabled
                labelId="default-target-period-label"
                id="demo-simple-selects5"
                value={period}
                label={language?.period || 'Period'}
                onChange={(e) => setPeriod(e.target.value)}
                sx={{ width: '100%', background: '#fff' }}
              >
                <MenuItem value={'DAY'}>{language?.daily || 'Daily'}</MenuItem>
                <MenuItem value={'WEEK'}>{language?.weekly || 'Weekly'}</MenuItem>
                <MenuItem value={'MONTH'}>{language?.monthly || 'Monthly'}</MenuItem>
              </Select>
            </FormControl>
            :''}
          
          {
            property === 'scrap' ?
            <TextField 
              sx={{ width: '100%', mt: '16px', background: '#fff' }}
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end"> % {language?.of_total_production || 'of total production'}</InputAdornment>,
              }}
              value={scrap}
              onChange={(e) => handleScrapChange(e)}
              id="outlined-basic2" label={language?.scrap || 'Scrap'} variant="outlined" 
            />
            : '' 
          }

          {
            (property === 'production') ?
            <TextField 
              sx={{ width: '100%', mt: '16px', background: '#fff' }}
              type="number"
              id="outlined-basic5" label={language?.production || 'Production'} variant="outlined" 
              value={production}
              onChange={(e) => handleProductionChange(e)}
            />
            : '' 
          }


          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

            <Button sx={{ width: '360px', height: '45px', mt: '24px' }} 
              disabled={buttonDisabled} 
              variant="contained" 
              startIcon={<CheckCircleOutlineIcon />}
              onClick={() => handleClickOpen()}
            >
              {language?.update_goal || 'Update Goal'}
            </Button>

            { targetData && <DefaultTargetTable data={targetData} /> }

          </Box>

        </Box>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
        >
          <DialogContent>
            <DialogContentText sx={{ fontWeight: 400, fontSize: '18px', color: '#4D4D4D' }} id="alert-dialog-description">
              {confirmMessage()}
            </DialogContentText>
            
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <Button onClick={() => {
                handleClose();
                handleSubmit();
              }} autoFocus
                variant="contained"
                sx={{ width: '250px', mt: '24px' }}
              >
                {language?.update || 'Update'}
              </Button>
              <Button sx={{ mt: '16px', width: '150px' }} variant='outlined' onClick={handleClose}>{language?.cancel || 'Cancel'}</Button>
            </Box>
          </DialogContent>
      </Dialog>
    </Box>
  );
}

export default DefaultTarget;