import React from 'react';
import { Box } from '@mui/material';
import DefaultTarget from './components/DefaultTarget';

function Targets() {
  return (
    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
      <Box gridColumn="span 6">
        <DefaultTarget />
      </Box>
      <Box gridColumn="span 6">
        
      </Box>
    </Box>
  );
}

export default Targets;