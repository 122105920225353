import React, { useState, useContext, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import SpeedOutlinedIcon from '@mui/icons-material/SpeedOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Select from "react-select";
import styled from 'styled-components';
import { VariablesContext } from '../../Context/VariablesContext';
import { useLazyQuery } from '@apollo/client';
import { GET_JOBS } from './queries';
import LightTooltip from '../LightTooltip';


const FilterButton = styled.button`
  outline: none;
  width: 47px;
  height: 33px;
  background: ${(props) => props.active ? '#4175F5' : '#EAEAED'};
  color: ${(props) => props.active ? '#ffff' : '#828282'};
  border-radius: 6px;
  border: none;
  margin: 10px;
`;

function Filters({ update, language }) {
  const [filter, setFilter] = useState('all')
  const [sites, setSites] = useState([]);
  const [areas, setAreas] = useState([]);
  const [lines, setLines] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [siteSelected, setSiteSelected] = useState(null);
  const [areaSelected, setAreaSelected] = useState(null);
  const [lineSelected, setLineSelected] = useState(null);
  const [areaDisabled, setAreaDisabled] = useState(true);
  const [lineDisabled, setLineDisabled] = useState(true);
  const [jobDisabled, setJobDisabled] = useState(true);
  const { siteOptions, areaOptions, lineOptions, enterprise  } = useContext(VariablesContext);

  const [getJobs] = useLazyQuery(GET_JOBS, { 
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const jobOptions = data.production_orders?.map((item) => (
        {
          value: item.id_production_order,
          label: item.id_order
        }
      ));
      setJobs(jobOptions);
    } 
  });
  

  useEffect(() => {
    const siteOpt = siteOptions?.map((item) => ({
      value: item?.id_site,
      label: item?.nm_site
    }));
    setSites(siteOpt);
  }, [siteOptions]);

  const handleSiteChange = (event) => {
    const availableAreas = areaOptions?.filter((item) => item?.id_site === event.value);
    if (availableAreas) {
      const areaOpt = availableAreas.map((item) => ({
        value: item?.id_area,
        label: item?.nm_area
      }));
      setAreaDisabled(false);
      setSiteSelected(event?.value)
      setAreas(areaOpt);
      update({ siteSelected: event?.value, enterprise })
    }
  };

  const handleAreaChange = (event) => {
    const availableLines = lineOptions?.filter((item) => item?.id_area === event?.value);
    if (availableLines) {
      const lineOpt = availableLines?.map((item) => ({
        value: item?.id_equipment,
        label: item?.nm_equipment
      }));
      setLineDisabled(false);
      setAreaSelected(event?.value);
      setLines(lineOpt);
      update({ siteSelected, areaSelected: event?.value, enterprise });
    }
  }

  const handleLineChange = (event) => {
    setJobDisabled(false);
    setLineSelected(event.value);
    update({ siteSelected, areaSelected, lineSelected: event.value, enterprise })
    getJobs({ variables: { line: event.value } });
  }

  const handleJobChange = (event) => {
    update({ siteSelected, areaSelected, lineSelected, job: event.value, enterprise });
  }

  const updateFilter = (newFilter) => {
    if (filter === newFilter) {
      setFilter('all')
      update({ filter: 'all' });
    } else {
      setFilter(newFilter);
      update({ filter: newFilter });
    }
    
  }
  

  return (
    <Box sx={{ width: '705px', m: 3}}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Typography sx={{ textAlign: 'start' }}> {language?.select_site || 'Select a Site'}: </Typography>
          <Select 
            name="site"
            placeholder={`${language?.site || 'Site'}`}
            options={sites}
            onChange={(e) => handleSiteChange(e)}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ textAlign: 'start' }}> {language?.select_area || 'Select an Area'}: </Typography>
          <Select 
            name="area"
            placeholder={`${language?.area || 'Area'}`}
            options={areas}
            isDisabled={areaDisabled}
            onChange={(e) => handleAreaChange(e)}
          />

        </Grid>

        <Grid item xs={6}>
          <Typography sx={{ textAlign: 'start' }}> {language?.select_line || 'Select a Line'}: </Typography>
          <Select 
            name="line"
            placeholder={`${language?.line || 'Line'}`}
            options={lines}
            isDisabled={lineDisabled}
            onChange={(e) => handleLineChange(e)}
          />

        </Grid>

        <Grid item xs={6}>
          <Typography sx={{ textAlign: 'start' }}> {language?.select_job || 'Select a PO' }: </Typography>
          <Select 
            name="job"
            placeholder={language?.job || 'Production order'}
            isDisabled={jobDisabled}
            onChange={(e) => handleJobChange(e)}
            options={jobs}
          />

        </Grid>
        

      </Grid>

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
          <LightTooltip title={`Job Started`} placement='top'>
            <FilterButton onClick={() => updateFilter('job_started')} active={filter === 'job_started'} > <PlayCircleOutlineIcon /> </FilterButton>
          </LightTooltip>

          <LightTooltip title="Job Finished" placement="top">
            <FilterButton onClick={() => updateFilter('job_completed')} active={filter === 'job_completed'}> <CheckCircleOutlineIcon /> </FilterButton>
          </LightTooltip>

          <LightTooltip title="Possible issue on sensor" placement="top">
            <FilterButton onClick={() => updateFilter('issue')} active={filter === 'issue'}> <ErrorOutlineIcon /> </FilterButton>
          </LightTooltip>

          <LightTooltip title="Shift change" placement="top">
            <FilterButton onClick={() => updateFilter('shift_change')} active={filter === 'shift_change'}> <ChangeCircleOutlinedIcon /> </FilterButton>            
          </LightTooltip>

          <LightTooltip title="Low speed" placement="top">
            <FilterButton onClick={() => updateFilter('low_speed')} active={filter === 'low_speed'}> <SpeedOutlinedIcon /> </FilterButton>
          </LightTooltip>

          <LightTooltip title="High scrap" placement="top">
            <FilterButton onClick={() => updateFilter('high_scrap')} active={filter === 'high_scrap'}> <DeleteOutlinedIcon /> </FilterButton>  
          </LightTooltip>
          
          
          <LightTooltip title="Downtime" placement="top">
            <FilterButton onClick={() => updateFilter('downtime')} active={filter === 'downtime'}> <QueryBuilderOutlinedIcon /> </FilterButton>
          </LightTooltip>

          <LightTooltip title="Exceeded setup time" placement="top">
            <FilterButton onClick={() => updateFilter('setup_time')} active={filter === 'setup_time'}> <SettingsOutlinedIcon /> </FilterButton>
          </LightTooltip>
          
      </Box>

    </Box>
  );
}

export default Filters;