import React, { useContext, useEffect, useState, useRef } from 'react';
import { VariablesContext } from '../../Context/VariablesContext';
import { useQuery, useSubscription } from '@apollo/client';
import { BreadcrumbQuery } from './Query';
import './breadcrumb.css';
import {Tooltip, OverlayTrigger} from 'react-bootstrap';
import { getLanguage } from '../../utils/utils';

const language = getLanguage();

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    {language?.select_line || 'Select a line'}
  </Tooltip>
);

export default function Sector() {
  const { area, line, setLine } = useContext(VariablesContext);
  const [nm_line, setnm_line] = useState('-');
  const { loading, error, data } = useQuery(BreadcrumbQuery);
  const lineRef = useRef();


  const handleChangeLine = e => {
    // console.log(e.target.value)
    lineRef.current.checked=false;
    setLine(e.target.value)
  };

  const inputProps = {
    onChange: handleChangeLine,
    class:"list-group-item-check", 
    type:"radio",
    name:"listGroupCheckableRadios",
  };

  return (
      <div className="pack-dropdown pe-3 border-end me-3">

        <input id="ghost-dropdown-allines-trigger" type="checkbox" name="dropdown-trigger"  ref={lineRef} />

        <label htmlFor="ghost-dropdown-allines-trigger" className="close-trigger"></label>

        <OverlayTrigger
            placement="top"
            delay={{ show: 180, hide: 150 }}
            overlay={renderTooltip}
          >
          <label htmlFor="ghost-dropdown-allines-trigger" className="btn btn-link fw-bold" >
          
            {line>0 ? data?.equipments.filter(item => item.id_equipment==line)?.[0]?.nm_equipment :
            <i className="ri-add-circle-fill fa-lg"></i> 
            }
          
          </label>
        </OverlayTrigger>
        <ul className="pack-dropdown-menu" aria-labelledby="dropdownid">

          <li>
            <div className="form-check px-4 mx-2">
              <label className="form-check-label  bold text-dark text-center" htmlFor="ghost-dropdown-allines-trigger">
              {language?.select_line || 'Select a line'}
              </label>
            </div>
          </li>

            <form>
              <fieldset className="list-group-checkable">
                <div classname="form-check-input">
                {data?.equipments?.filter(item => item.id_area==area).map((equipment, index_equipment) => {
                  return (
                      
                    <label className="list-group-item" >
                      <input 
                      className="form-check-input"
                      id="listGroupCheckableRadios3" 
                      value={equipment.id_equipment} checked={line === equipment.id_equipment} {...inputProps}/>
                      <span> {equipment.nm_equipment} </span>
                    </label>
                            
                    ) }) }
                  </div>
              </fieldset>
            </form>


        </ul>
      </div>
  );
}
