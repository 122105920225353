import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import LightTooltip from '../../../components/LightTooltip';
import { getLanguage, isInvalidNumber } from '../../../utils/utils';

const Bar = styled.div`
  width: 100%;
  height: 17px;
  display: flex;
  border-radius: 8px;
`;

const Item = styled.span`
  width: ${(props) => props.size}%;
  height: 17px;
  background: ${(props) => props.color};
  position: relative;

  span {
    display: ${(props) => {
      if (props.size < 1) return 'none';
      return 'block';
    }};
    width: 54px;
    font-size: 22px;
    font-weight: bold;
    position: absolute;
    top: -40px;
    left: 50%;
  }
  ${(props) => {
    if (props.size >= 100) return 'border-top-left-radius: 8px; border-bottom-left-radius: 8px; border-top-right-radius: 8px; border-bottom-right-radius: 8px;'
  }}
  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const Circle = styled.div`
  width: ${(props) => props.small ? '4px' : '11px'};
  height: ${(props) => props.small ? '4px' : '11px'};
  background: ${(props) => props.color};
  border-radius: 50%;
  margin-right: 5px;
`;

const Legend = styled.ul`
  display: flex;
  justify-content: center;
  padding-top: 25px;

  li {
    margin: 10px 10px;
    display: flex;
    align-items: center;
    font-weight: 600;
  }
`;

const ListIcon = styled.span`
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 6px;
  border-radius: 100px;
  background: ${(props) => props.color};
`;

function validateNumber(number) {
  let validNumber = number;
  if(isInvalidNumber(number)) validNumber = 0;
  if(number > 100) validNumber = 100;

  return validNumber;
}

function BarChart({ title, legend, type, data }) {
  const language = getLanguage();
  let oeeSize = 0;
  let diff = 100;
  let goodPartsSize = 100;
  let scrapSize = 0;
  let oeeTitle = '';
  let scrapTitle = '';

  if (type === 'oee') {
    let oeeData = data?.[0]?.oee_componentes?.oee * 100;
    oeeData = validateNumber(oeeData);
    diff -= oeeData;
    oeeSize = validateNumber(oeeData);
    let oee = validateNumber(oeeData).toFixed() + '%';
    let oeeP = validateNumber(data?.[0]?.oee_componentes?.oee_p * 100).toFixed() + '%';
    let oeeA = validateNumber(data?.[0]?.oee_componentes?.oee_a * 100).toFixed() + '%';
    let oeeQ = validateNumber(data?.[0]?.oee_componentes?.oee_q * 100).toFixed() + '%';
    oeeTitle = 
    <Box sx={{ p: 2 }}> 
      <Typography sx={{ fontWeight: 'bold', fontSize: '24px', mb: 1 }}>OEE</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Circle color='#4175F5' />
        <Typography> { language?.total || 'Total' }: {oee}</Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Circle style={{ marginLeft: '3px' }} small color='#000' />
        <Typography sx={{ marginLeft: '6px' }}> { language?.performance || 'Performance' }: {oeeP}</Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Circle style={{ marginLeft: '3px' }} small color='#000' />
        <Typography sx={{ marginLeft: '6px' }}> { language?.availability || 'Availability' }: {oeeA}</Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Circle style={{ marginLeft: '3px' }} small color='#000' />
        <Typography sx={{ marginLeft: '6px' }}> { language?.quality || 'Quality' }: {oeeQ}</Typography>
      </Box>
      
    </Box>

  } else {
    scrapSize = ((data?.scrap_acc / (data?.net_production_acc + data?.scrap_acc)) * 100);
    scrapSize = validateNumber(scrapSize);
    goodPartsSize -= scrapSize;
    let goodParts = validateNumber(goodPartsSize).toFixed() + '%';
    let scrap =  validateNumber(scrapSize).toFixed() + '%';

    scrapTitle = 
    <Box sx={{ p: 2 }}> 
      <Typography sx={{ fontWeight: 'bold', fontSize: '24px', mb: 1 }}>{ language?.scrap_rate || 'Scrap Rate' }</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Circle color='#4175F5' />
        <Typography> { language?.good_parts || 'Good Parts' }: {goodParts}</Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Circle color='#C13939' />
        <Typography>{ language?.scrap || 'Scrap' }: {scrap} </Typography>
      </Box>
      
    </Box>

  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '95%', height: '170px' }}>

      <Typography sx={{ fontWeight: 'bold', fontSize: '32px', fontStyle: 'normal', mb: '45px' }} > {title} </Typography>
      {
        (type === 'oee') ? 
        <Bar>
          <LightTooltip title={oeeTitle} placement='top' followCursor>
            <Item size={oeeSize} color='#4175F5'>
              <span> { validateNumber(oeeSize).toFixed() + '%' } </span>
            </Item>
          </LightTooltip>
          
          <Item size={diff} color='#bebebe' />

        </Bar> :
        <Bar>
          
          <LightTooltip title={scrapTitle} placement='top' followCursor>
            <Item size={goodPartsSize} color='#4175F5'>
              <span>{validateNumber(goodPartsSize).toFixed() + '%'}</span>
            </Item>
          </LightTooltip>
          
          <LightTooltip title={scrapTitle} placement='top' followCursor>
            <Item size={scrapSize} color='#C13939'>
              <span> {validateNumber(scrapSize).toFixed() + '%'} </span>
            </Item>
          </LightTooltip>
          
        </Bar>
      }
      

      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', height: '50px' }}>
        <Legend>
          {
            legend?.map((item, index) => 
            <li key={index}>
              <ListIcon color={item?.color} />
              <Typography>{item?.title}</Typography>
            </li> )
          }
        </Legend>
      </Box>

    </Box>
  );
}

export default BarChart;