import React from 'react';

import {useGridSelector, useGridApiContext,
  gridDetailPanelExpandedRowsContentCacheSelector,
 } from '@mui/x-data-grid-pro';

import { IconButton } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


function CustomDetailPanelToggle(props) {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();

  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowsContentCacheSelector,
  );

  // If the value is not a valid React element, it means that the row has no detail panel.
  let hasDetail = React.isValidElement(contentCache[id]);
  //  if(row?.runtimes?.length <= 1 || row?.runtimes === undefined) hasDetail = false;

  return (
    <IconButton
      size="medium"
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? 'Close' : 'Open'}
    >
      <ExpandMoreIcon
        sx={{
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
        }}
        fontSize="inherit"
      />
    </IconButton>
  );
}

export default CustomDetailPanelToggle;