import React from 'react';
import { Typography, Box } from '@mui/material';
import { roundNumber, getLanguage } from '../../../utils/utils.js';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';

export default function Card2({ dataShift, dataMaq, dataDay, dataMaqSpeed, dataNameMaq}) {
  const language = getLanguage();
  
  const howGood = (number, target) => {
    const quanto = (number*100)/target;

    if ( quanto == 99 || quanto==101 || target === null || target === 0 ){
      return <></>
    } else if ( quanto > 100 ){
      return (
        <Box sx={{display:'flex'}}>
          <ArrowUpwardRoundedIcon fontSize='large' sx={{ color:'#318F29', alignSelf: 'center', }} />
          <Box sx={{alignSelf: 'center', display: 'flex', flexDirection: 'column',color:'#318F29',}}>
            <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 32, }} >
              {"+" + (quanto-100).toFixed(0)} %
            </Typography>
            <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 16, ml:1}} >
                of goal
            </Typography>
          </Box>
        </Box>
      )
    } else if ( quanto < 100 ){
      return (
        <Box sx={{display:'flex'}}>
          <ArrowDownwardRoundedIcon fontSize='large' sx={{ color:'#EB5757', alignSelf: 'center', }} />
          <Box sx={{alignSelf: 'center', display: 'flex', flexDirection: 'column',color:'#EB5757',}}>
            <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 32, }} >
              {"-"+ (100-quanto).toFixed(0)} %
            </Typography>
            <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 16, ml:1}} >
                of goal
            </Typography>
          </Box>
        </Box>
        )
    }
  };
  
  // console.log(dataShift?.uns_equipment_current_shift?.[0]?.target)
  // console.log(dataShift?.uns_equipment_current_shift?.[0]?.begin_time)
  // console.log(dataShift?.uns_equipment_current_shift?.[0]?.end_time)

  return (
    <Box sx={{ display:'flex', flexDirection:'column', p:1, m:1, height:430, width:430 }} >
       
       <Box >
        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 24, }} >
           {language?.gross_shift_prod || 'Gross SHIFT Production'} 
        </Typography>
        <Box sx={{display:'flex', flexDirection: 'row', }}>
          <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 64, }} >
            { roundNumber(dataShift?.uns_equipment_current_shift?.[0]?.gross_production) }
          </Typography>

          {/* { howGood(dataShift?.uns_equipment_current_shift?.[0]?.gross_production, dataShift?.uns_equipment_current_shift?.[0]?.target) } */}

        </Box>
      </Box>

      <Box sx={{mt:3}}>
        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 24, mt:1 }} >
          {/* Nome da primeira maquina da linha*/} { dataNameMaq }
        </Typography>
        <Box sx={{display:'flex', flexDirection: 'row', }}>
          <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 64, }} >
            { dataMaqSpeed?.speed ? dataMaqSpeed?.speed?.toFixed(0) : " - " }
          </Typography>
          <Typography variant="h6" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 36, mt:3, ml:1 }} >
            un/min
          </Typography>
        
          { howGood(dataMaqSpeed?.speed, dataMaqSpeed?.ideal_speed) }
        
        </Box>
      </Box>

      <Box sx={{mt:3}}>
        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 24, mt:1 }} >
          {language?.daily_prod || 'Daily Production'} 
        </Typography>
        <Box sx={{display:'flex', flexDirection: 'row', }}>
          <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 64, }} >
            { roundNumber(dataDay?.uns_equipment_current_day?.[0]?.net_production) }
          </Typography>
        
          { howGood(dataDay?.uns_equipment_current_day?.[0]?.net_production, dataDay?.uns_equipment_current_day?.[0]?.proportional_target) }
        
        </Box>
      </Box>

    </Box>
  );
}
