import React from 'react';
import PageModel from '../../components/PageModel'
import Routes from './routes';

function MainContainer() {
  return (
    <PageModel>
        <Routes />
    </PageModel>
  );
}

export default MainContainer;