import React, { useEffect, useState } from 'react';
import { Box, Fab, Typography } from '@mui/material'
import { useLazyQuery } from '@apollo/client';
import { TIMELINE } from './queries';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Drawer from '@mui/material/SwipeableDrawer';
import { getLanguage } from '../../utils/utils';


import Filters from './filters';
import TimeLine from './TimeLine';
//import EventsLoading from './eventsLoading';

  
export default function EventsTab() {
  const language = getLanguage();
  const [state, setState] = useState(false);
  const [getEvents, { data: timelineData }] = useLazyQuery(TIMELINE, { fetchPolicy: 'no-cache' });

  const toggleDrawer = (newOpen) => () => {
    setState(newOpen);
  };

  const updateQuery = (props) => {
    console.log(props)
    if (props.filter) {
      console.log(props.filter)
    } else {
      getEvents({
        variables: {
          enterprise: props.enterprise,
          site: `{${props.siteSelected || '' }}`,
          area: `{${props.areaSelected || ''}}`,
          line: `{${props.lineSelected || ''}}`,
          job: props?.job
        }
        
      })
    }
    
  }


  return(
    <>
      <Fab 
        sx={{
          position: 'absolute',
          top: 100,
          right: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
          background: '#131822',
          ':hover': { background: '#131822' }
        }}
        variant="extended"
        size="small"
      >

          <ChevronLeftIcon sx={{ mr: 1, color: '#fff' }} />
          <Typography variant='button' sx={{ color: '#fff' }} onClick={toggleDrawer(true)}>
            {language?.events || 'Events' }
          </Typography>
          
          <Drawer
            anchor={'right'}
            open={state}
            variant="persistent"
            sx={{ textTransform: 'none' }}
          > 
            <Box 
              sx={{ width: '100%', height: '48px', background: '#131822', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              onClick={toggleDrawer(false)}
            >
              <Typography sx={{ color: '#fff', fontSize: '16px', fontWeight: 600 }}>{language?.events || 'Events'} {' > '}</Typography>
            </Box>
            <Filters update={updateQuery} language={language} />
            <TimeLine data={timelineData?.h_piot_get_events_timeline_from_po_2}  />

            
          </Drawer>

      </Fab>
    </>
  )
}

