import styled from 'styled-components';

export const Nav = styled.nav`
  width: 100%;
  
`;

export const NavItem = styled.a`
  width: 100%;
  height: 77px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  text-decoration: none;
  color: #333333;
  ${(props) => props.active ? 'background: #EAEAED;' : ''}
  ${(prosps) => prosps.active ? 'border-left: 5px solid #4175F5;' : ''}
  
  &:hover {
    color: #333333;
  }

  span {
    margin-left: 21px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    text-transform: capitalize;
  }
  p {
    margin-left: 21px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    text-transform: capitalize;
  }
`;
