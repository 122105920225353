import React, { useEffect, useState, useCallback } from 'react';
import { useGridApiRef } from '@mui/x-data-grid';
import { DataGridPro, GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GRID_DETAIL_PANEL_TOGGLE_FIELD } from '@mui/x-data-grid-pro';
import LightTooltip  from '../../../components/LightTooltip';
import {  RiEditLine, RiTimeLine, RiCheckboxCircleLine, RiPauseCircleLine  } from "react-icons/ri"; 
import { useSnackbar } from 'notistack';

import { getLanguage, roundNumber } from '../../../utils/utils';
import { EditRowContainer } from '../styles';
import ModalEdit from './ModalEdit';
import { useMutation } from '@apollo/client';
import { SET_REPLACE_JOB } from '../mutations';
import { Box, Skeleton, IconButton } from '@mui/material';

import CustomDetailPanelToggle from './CustomDetailPanelToggle';
import CustomToolbar from './CustomToolbar';
import DetailPanelContent from './DetailPanelContent';

function PoTable({ data, update }) {
    const language = getLanguage();
    const [rows, setRows] = useState([]);
    const [isEdit, setEdit] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [dataModel, setDataModel] = useState([]);
    const [jobChanged, setJobChanged] = useState({});
    const apiRef = useGridApiRef();
    const { enqueueSnackbar } = useSnackbar();
    const [replaceJob, { loading }] = useMutation(SET_REPLACE_JOB, {
        onCompleted: (data) => {
            const id_changed = jobChanged?.id_order ?? '';
            let successMsg = language?.po_has_changed || "Po job_start has been modified to job_end";
            successMsg = successMsg.replace("job_start", dataModel?.row?.id_order?.toString());
            successMsg = successMsg.replace("job_end", id_changed?.toString());
            enqueueSnackbar(successMsg, { variant: 'success' });
                update();
        },
        onError: () => {
            enqueueSnackbar(`${language?.an_error_occured || "Ops! Something went wrong."}`, { variant: 'error' });
        }
    });

    const handleChangeJob = (params) => {
        setJobChanged(params);
        replaceJob({ 
            variables: { 
                id_production_order: dataModel?.row?.id_production_order,
                new_id: params?.id_production_order
            }
        });
        setEdit(false);
        setOpenEdit(false);    
    }

    function getOpStatus(jobStatus) {
        let status = "";
        let statusText = "";
        switch (jobStatus) {
            case 2:
                status = <RiTimeLine color="#407CCC" size={22} />;
                statusText = `${language?.running || 'Running'}`;
                break;
            case 3:
                status = <RiCheckboxCircleLine color='#4D4D4D' size={22} />;
                statusText = `${language?.finished || 'Finished'}`;
                break;
            case 4:
                status = <RiPauseCircleLine color='#FD9729' size={22} />;
                statusText = `${language?.paused || 'Paused'}`;
                break;
        }
        return {
            status, statusText
        }
    }

    const handleClickOpenEdit = (params) => {
        setDataModel(params);
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const columns = [
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        headerName: `${language?.details || 'Details'}`,
        disableColumnMenu: true,
        filterable: false,
        hideable: true,
        disableReorder: true,
        hideSortIcons: true,
        width: 80,
        renderCell: (params) => (
          <CustomDetailPanelToggle id={params.id} value={params.value} />
        ),
      },
        { 
            field: 'status', headerName: `${language?.status || 'Status'}`,
            flex: 1,
            minWidth: 90,
            renderCell: (params) => (
                <LightTooltip title={ getOpStatus(params?.row?.status).statusText} placement='top'>
                    <IconButton sx={{ cursor: 'unset' }}>
                        {getOpStatus(params?.row?.status).status}
                    </IconButton>
                </LightTooltip>
            )
        },
        {
            field: 'id_order',
            headerName: `${language?.job || 'Job'}`,
            editable: false,
            flex: 1,
            minWidth: 110,
            renderCell: (params) => (
                (isEdit) ? 
                <EditRowContainer onClick={() => handleClickOpenEdit(params) }>
                    <div> <span>{params?.row?.id_order}</span> <RiEditLine color='#828282' size={15} /> </div> 
                </EditRowContainer> : <span>{params?.row?.id_order}</span>
            )
        },{
            field: 'nm_client',
            headerName: `${language?.client || 'Client' }`,
            editable: false,
            flex: 1,
            minWidth: 150,
        },{
            field: 'nm_product',
            headerName: `${language?.product || 'Product'}`,
            editable: false,
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (
                <LightTooltip title={params?.row?.nm_product} placement='top'>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params?.row?.nm_product}</span>
                </LightTooltip>
            )
        },{
            field: 'production_ordered',
            headerName: `${language?.order_size || 'Order Size'}`,
            editable: false,
            flex: 1,
            minWidth: 150,
            renderCell: (params) => <span>{ roundNumber(params?.row?.production_ordered) }</span>
        },
        {
            field: 'net_production',
            headerName: `${language?.production_count || 'Production Count'}`,
            flex: 1,
            minWidth: 150,
            renderCell: (params) => <span>{ roundNumber(params?.row?.net_production) }</span>
        },
        {
            field: 'production_final',
            headerName: `${language?.production_collected || 'Production Collected'}`,
            flex: 1,
            minWidth: 150,
            renderCell: (params) => <span>{ roundNumber(params?.row?.production_final) }</span>
        },
        {
            field: 'scrap',
            headerName: `${language?.scrap || 'Scrap'}`,
            editable: false,
            flex: 1,
            minWidth: 120,
        },{
            field: 'nm_equipment',
            headerName: `${language?.line || 'Line'}`,
            editable: false,
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (
                <LightTooltip title={params?.row?.nm_equipment} placement='top'>
                    <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params?.row?.nm_equipment}</span>
                </LightTooltip>
            )
        },{
            field: 'ts_start',
            headerName: `${language?.start || 'Start'}`,
            editable: false,
            flex: 1,
            minWidth: 150,
        }
        ,{
            field: 'ts_end',
            headerName: `${language?.end || 'End'}`,
            editable: false,
            flex: 1,
            minWidth: 150,
        },{
            field: 'total',
            headerName: `${language?.duration || 'Duration'}`,
            editable: false,
            flex: 1,
            minWidth: 150,
        }
        
      ];
    
    useEffect(() => {
      if (data?.length){
        setRows(data)
      } 
    }, [data]);

    const handleClickEdit = (prevState) => {
       setEdit(!prevState);
   }
   
  const getDetailPanelContent = useCallback(({ row }) => <DetailPanelContent row={row} />, [],);
  const getDetailPanelHeight = useCallback(() => 250, []);

    return (
        <>
        <Box sx={{ height: 740, width: '100%', }}>
            <ModalEdit params={dataModel} isOpen={openEdit} handleChange={handleChangeJob} handleClose={handleCloseEdit} />
            <DataGridPro
                sx={{ border: 'none' }}
                components={{ Toolbar: CustomToolbar }}
                rows={rows}
                columns={columns}
                pageSize={10}
                pagination
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
                apiRef={apiRef}
                componentsProps={{
                    toolbar: {
                    data: data,
                    onClick: () => handleClickEdit(isEdit)
                    },
                }}
                getDetailPanelHeight={getDetailPanelHeight}
                getDetailPanelContent={getDetailPanelContent}
                localeText={{
                  columnsPanelTextFieldLabel: `${language?.find_column || 'Find column'} `,
                  columnsPanelTextFieldPlaceholder: `${language?.column_title || 'Column title'}`,
                  columnsPanelShowAllButton: `${language?.show_all || 'Show all'}`,
                  columnsPanelHideAllButton: `${language?.hide_all || 'Hide all'}`,
                  toolbarColumns: `${language?.columns || 'Columns'}`,
                }}
            />
        </Box>
    </>
    );
}

export default PoTable;
