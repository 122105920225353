import React from 'react';
import { Typography, Box } from '@mui/material';
import { roundNumber, getLanguage } from '../../../utils/utils.js';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';

export default function Card1({ data, }) {
  const language = getLanguage();

  function colorStatus(status){
    let color = "#318F29";
    if (status === "lowSpeed") return color ="#FD9729";
    if (status === "stopped") return color ="#C13939";
    return color;
  } 

  const howGood = (number, target) => {
    const quanto = (number*100)/target;

    if ( quanto == 99 || quanto==101 || target === null || target === 0 ){
      return <></>
    } else if ( quanto > 100 ){
      return (
        <Box sx={{display:'flex'}}>
          <ArrowUpwardRoundedIcon fontSize='large' sx={{ color:'#318F29', alignSelf: 'center', }} />
          <Box sx={{alignSelf: 'center', display: 'flex', flexDirection: 'column',color:'#318F29',}}>
            <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 32, }} >
              {"+" + (quanto-100).toFixed(0)} %
            </Typography>
            <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 16, ml:1}} >
                of goal
            </Typography>
          </Box>
        </Box>
      )
    } else if ( quanto < 100 ){
      return (
        <Box sx={{display:'flex'}}>
          <ArrowDownwardRoundedIcon fontSize='large' sx={{ color:'#EB5757', alignSelf: 'center', }} />
          <Box sx={{alignSelf: 'center', display: 'flex', flexDirection: 'column',color:'#EB5757',}}>
            <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 32, }} >
              {"-"+ (100-quanto).toFixed(0)} %
            </Typography>
            <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 16, ml:1}} >
                of goal
            </Typography>
          </Box>
        </Box>
        )
    }
  };

  let prodPerSeg = data?.uns_equipment_current_shift?.[0]?.net_production / data?.uns_equipment_current_shift?.[0]?.elapsed_time
  let tempoTotal = data?.uns_equipment_current_shift?.[0]?.duration
  let projectedTurno = prodPerSeg * tempoTotal

  return (
    <Box sx={{ display:'flex', flexDirection:'column', p:1, m:1, height:430, width:430 }} >
       
       <Box >
        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 24, }} >
          {language?.shift_production || 'SHIFT Production'} 
        </Typography>
        <Box sx={{display:'flex', flexDirection: 'row', }}>
          <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 64, }} >
            { roundNumber(data?.uns_equipment_current_shift?.[0]?.net_production) }
          </Typography>

          { howGood(data?.uns_equipment_current_shift?.[0]?.net_production, data?.uns_equipment_current_shift?.[0]?.proportional_target) }
         
        </Box>
      </Box>

      <Box sx={{mt:3}}>
        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 24, mt:1 }} >
          {language?.shift_scrap || 'SHIFT Scrap'} 
        </Typography>
        <Box sx={{display:'flex', flexDirection: 'row', }}>
          <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 64, }} >
            {  (((data?.uns_equipment_current_shift?.[0]?.gross_production-data?.uns_equipment_current_shift?.[0]?.net_production)/data?.uns_equipment_current_shift?.[0]?.gross_production)*100).toFixed(0) > 0
            ? (((data?.uns_equipment_current_shift?.[0]?.gross_production - data?.uns_equipment_current_shift?.[0]?.net_production)/data?.uns_equipment_current_shift?.[0]?.gross_production)*100).toFixed(0) :
          "-" } %
          </Typography>
          
          <Box sx={{alignSelf: 'center', display: 'flex', flexDirection: 'column',color:'#EB5757',ml:1}}>
            <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 32, }} >
              { roundNumber(data?.uns_equipment_current_shift?.[0]?.scrap) }
            </Typography>
            <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 700, fontSize: 16, ml:3}} >
              units
            </Typography>
          </Box>
          
        </Box>
      </Box>

      <Box sx={{mt:3}}>
        <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 24, mt:1 }} >
          {language?.shift_projected || 'Projected at end of SHIFT'} 
        </Typography>
        <Box sx={{display:'flex', flexDirection: 'row', }}>
          <Typography variant="h5" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 64, }} >
            { roundNumber( projectedTurno.toFixed(0) ) }
          </Typography>

          {/* VER daonde que vai ser retirado os dados */}
          {/* { howGood(data?.uns_equipment_current_shift?.[0]?.net_production, data?.uns_equipment_current_shift?.[0]?.target) } */}
          
        </Box>
      </Box>


    </Box>
  );
}
