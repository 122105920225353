import { gql } from '@apollo/client';

// List of downtimes
export const DOWNTIMES = gql`
query GET_DOWNTIMES ($in_ids_sites: text, $in_ids_equipments: text, $in_ids_areas: text, $_tsend: timestamp, $_tsstart: timestamp){
  h_piot_get_downtimes_equipment_level(args: {in_ids_sites: $in_ids_sites, in_ids_equipments: $in_ids_equipments, in_ids_areas: $in_ids_areas, _tsend: $_tsend, _tsstart: $_tsstart}) {
    id_equipment_event
    cd_category
    cd_machine
    cd_shift
    cd_subcategory
    duration
    id_enterprise
    id_equipment
    id_order
    ts_end
    ts_event
    txt_category
    txt_downtime_notes
    txt_subcategory
  }
}
`;

export const GET_DOWNTIME_REASONS = gql`
query MyQuery($_eq: Int) {
  equipments(where: {id_equipment: {_eq: $_eq}}) {
    downtime_reasons
  }
}
`;

export const DOWNTIME_QUERIES = gql`
query MyQuery($in_id_enterprise: Int, $in_ids_equipments: String = "{}", $in_ids_sectors: String = "{}", $in_ids_shifts: String = "{}", $in_ids_areas: String = "{}", $in_ids_sites: String = "{}", $_tsstart: timestamp, $_tsend: timestamp, $in_ids_teams: String = "{}", $sector_view: Boolean = false) {
  h_piot_get_downtimes_per_category_equipment_level: h_piot_get_downtimes_per_category_equipment_level_new_4(args: {in_id_enterprise: $in_id_enterprise, _tsend: $_tsend, _tsstart: $_tsstart, in_ids_areas: $in_ids_areas, in_ids_equipments: $in_ids_equipments, in_ids_shifts: $in_ids_shifts, in_ids_sites: $in_ids_sites, in_ids_teams: $in_ids_teams}) {
    available_time
    downtimes_per_category
    duration_justified
    duration_microstops
    duration_planned
    duration_total
    duration_unplanned
    id_enterprise
  }
  h_piot_get_downtimes_equipment_level: h_piot_get_downtimes_sector_2(args: {in_id_enterprise: $in_id_enterprise, in_ids_sites: $in_ids_sites, in_ids_equipments: $in_ids_equipments, in_ids_sectors: $in_ids_sectors, in_ids_areas: $in_ids_areas, _tsend: $_tsend, _tsstart: $_tsstart, sector_view: $sector_view}) {
    nm_equipment
    sector
    id_equipment_event
    id_shift
    id_sector
    cd_category
    cd_machine
    cd_shift
    cd_subcategory
    duration
    id_enterprise
    id_equipment
    id_order
    ts_end
    ts_event
    txt_category
    txt_downtime_notes
    txt_subcategory
  }
}
`;