import React, {useContext, useEffect} from "react";
import { ApolloProvider} from '@apollo/client';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import './components/Scrollbar/scrollbar.css';
import { LicenseInfo } from '@mui/x-license-pro';

import './App.css';
import Routes from './routes';
import { client } from "./services/graphqlConnection";
import {UserContext} from './providers/UserProvider'
import { auth } from "./firebase";
import { VariablesProvider } from './Context/VariablesContext';
import { SnackbarProvider } from 'notistack';
import Slide from '@material-ui/core/Slide';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import { ThemeProviderr } from './Context/ThemeContext';

library.add(fab, fas)

LicenseInfo.setLicenseKey(
  'f9c575676be09ace73ded8d5ed488cd3T1JERVI6NDE5MDgsRVhQSVJZPTE2ODE1ODAwMDcwMDAsS0VZVkVSU0lPTj0x',
);

function App() {
  useEffect(() => {
    auth.onAuthStateChanged((currentState) => {
      if (!currentState) {
        if(client){
          client.resetStore()
        }
      }
    });
  }, []);
  
  const {isReady, user, loggedOut} = useContext(UserContext);
  if(!isReady) return null  
  
  return (
    <ApolloProvider client={client}>
      <ThemeProviderr value="light">
        <VariablesProvider>
          <SnackbarProvider
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            TransitionComponent={Slide}
          >
            <LocalizationProvider dateAdapter={DateAdapter}> 
              <Routes />
            </LocalizationProvider>
          </SnackbarProvider>
        </VariablesProvider>
      </ThemeProviderr>
    </ApolloProvider>
  );
}

export default App;
