import { gql } from '@apollo/client';

  export const BreadcrumbQuery = gql`
  query BreadcrumbQuery {
    enterprises {
      nm_enterprise
    }
    sites(order_by: {nm_site: asc})  {
      id_site
      nm_site
      id_enterprise
    }
    areas(order_by: {nm_area: asc})  {
      nm_area
      id_area
      id_site
    }
    equipments(where: {tp_equipment: {_eq: 3}}, order_by: {nm_equipment: asc}) {
      id_area
      id_site
      nm_equipment
      id_equipment
    }
 }`;
