import React, { useState, useContext, useEffect } from 'react';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import '../../components/PackDropdown/pack-dropdown.css';
import '../../components/Breadcrumb/breadcrumb.css';

import './style.css'
import { VariablesContext } from '../../Context/VariablesContext';
import { getLanguage } from '../../utils/utils';

export default function SiteFilter() {
    const { siteOptions, filterSite, setFilterSite } = useContext(VariablesContext);
    const language = getLanguage();


    const handleChange = (e) => {
    //    let temp = filterShift;
       const temp = [...filterSite]
        if (e?.target?.checked) {
            if (!filterSite.includes(e?.target?.value)) {
                temp.push(e?.target?.value);
                setFilterSite(temp);
            }
        }
        else {
            if (filterSite.includes(e?.target?.value)) {
                temp.splice(temp.indexOf(e?.target?.value), 1);
                setFilterSite(temp);
            }
        }
    }


    return (
        <>
            <div id="siteSelector" className='list-group mx-0  rounded bg-transparent'>
                <ul className="rounded p-0 border">
                    {siteOptions.length > 0 ? siteOptions.map((site, index) => {
                        return (
                            <li key={index} className="list-group-item d-flex gap-2">
                                <CheckBoxComponent label={site.nm_site}
                                    value={site.id_site}
                                    checked={filterSite.includes(site.id_site)}
                                    cssClass="e-info e-round form-check-input flex-shrink-0 width-fit-content"
                                    name="site"
                                    onChange={handleChange}
                                />
                            </li>
                        )
                    }) : <li key={0}><p> { language?.no_sites_available || 'No sites available' } </p></li>}
                </ul>
            </div>

            <DropDownButtonComponent
                // beforeOpen={event => beforeOpen(event)}
                target="#siteSelector"
                cssClass="align-items-center border mx-2"
                className="d-flex rounded e-grey"
            >
                <div className="element-selector shift-selector">
                    <p><i className="ri-filter-line" size={"1em"}></i> { language?.filter_site || 'Filter Site' }  </p>
                </div>
            </DropDownButtonComponent>
        </>
    );
};