import React, { useState, useEffect, useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import DowntimeTable from './components/Table';
import { secondsToDurationString } from '../../utils/utils';
import { VariablesContext } from '../../Context/VariablesContext';
import Breadcrumb from '../../components/Breadcrumb';
import EntityFilter from '../../components/EntityFilters'
import { getLanguage, convertToDaysHoursNoSeconds } from '../../utils/utils';
import { Box, } from '@mui/material';

import DownloadCsv from './components/DownloadCsv';
import CardTime from './components/CardTime';
import Resume from './components/Resume';
import DowntimeReason from './components/DowntimeReason';

import {
  DOWNTIME_QUERIES,
} from './queries';
import Spacer from '../../components/Spacer';
import moment from 'moment'

function totalTime(start, end) {
  if (end) {
    const value = moment(end).diff(start, 'seconds')
    const total = convertToDaysHoursNoSeconds(`${value}`, 'seconds')
    return total;
  }
  
  return '-'
}

function formatDate(date) {
  if (date) {
    return moment(date).format('DD-MM-YYYY HH:mm')
  }
  return '-';
}

function Downtimes(props) {
  const [dataa, setDataa] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [downtimeDetails, setDowntimeDetails] = useState(null);
  const [downtimeDetailstoCSV, setDowntimeDetailstoCSV] = useState([]);
  const language = getLanguage();
  const [isSectorView, setIsSectorView] = useState(true);

  useEffect(()=>{setIsSectorView(props?.isSectorView)},[props])
  
  const [getDowntimeDataEquip, { data: dataDowntimeQueryEquip, loading: loadingDowntimeQueryEquip }] = useLazyQuery(DOWNTIME_QUERIES,
    {
      onCompleted: () => {

        if (dataDowntimeQueryEquip?.h_piot_get_downtimes_per_category_equipment_level?.lenght == 0) { 
          setDowntimeDetails([]) 
        } else {
          setDowntimeDetails(dataDowntimeQueryEquip?.h_piot_get_downtimes_per_category_equipment_level?.[0]);
        };
        
        const tableData = dataDowntimeQueryEquip?.h_piot_get_downtimes_equipment_level?.map((item, index) => (
          {
            id: index,
            id_order: item?.id_order,
            nm_equipment: item?.nm_equipment,
            sector: item?.sector,
            id_sector: item?.id_sector,
            cd_shift: item?.cd_shift,
            ts_event: formatDate(item?.ts_event),
            ts_end: formatDate(item?.ts_end),
            total: totalTime(item?.ts_event, item?.ts_end),
            cd_category: item?.cd_category,
            txt_category: item?.txt_category,
            cd_subcategory: item?.cd_subcategory,
            txt_subcategory: item?.txt_subcategory,
            cd_machine: item?.cd_machine,
            txt_downtime_notes: item?.txt_downtime_notes,
            id_equipment_event: item?.id_equipment_event,
            id_equipment: item?.id_equipment,
            id_shift: item?.id_shift
          }
        ))
        setDataa(tableData);
        
        let temp = dataDowntimeQueryEquip?.h_piot_get_downtimes_equipment_level
        setCsvData(temp);

        let temp1 = dataDowntimeQueryEquip?.h_piot_get_downtimes_per_category_equipment_level?.[0]?.downtimes_per_category
        if (temp1!==undefined) {
          temp1.forEach(element => {element.ts_start=ts_start; element.ts_end= ts_end;}); 
          setDowntimeDetailstoCSV(temp1);
        } 
    },
    fetchPolicy: 'no-cache'
    }
  );

  const { enterprise, site, area, line, filterShift, ts_start, ts_end, visualizationLevel, filterArea, filterLine, filterSector } = useContext(VariablesContext);

 const updateData = () => {
    if (site) {
      if (area) {
        if (line) {
          // Shift Filter
          getDowntimeDataEquip({ variables: { in_id_enterprise: enterprise, in_ids_shifts: `{${filterShift.toString()}}`, in_ids_sites: '{' + (site ? site : "") + '}', in_ids_equipments: '{' + (line ? line : "") + '}', in_ids_sectors: `{${filterSector.toString()}}`, in_ids_areas: '{' + (area ? area : "") + '}', _tsstart: moment.utc(ts_start), _tsend: moment.utc(ts_end), in_ids_teams: "{}", sector_view: isSectorView } });
        }
        else {
          // Lines and Shift Filters
          getDowntimeDataEquip({ variables: { in_id_enterprise: enterprise, in_ids_shifts: `{${filterShift.toString()}}`, in_ids_sites: '{' + (site ? site : "") + '}', in_ids_equipments: `{${filterLine.toString()}}`, in_ids_sectors: `{${filterSector.toString()}}`, in_ids_areas: '{' + (area ? area : "") + '}', _tsstart: moment.utc(ts_start), _tsend: moment.utc(ts_end), in_ids_teams: "{}", sector_view: isSectorView } });
        }
      }
      else {
        // Areas, Lines and Shift Filters
        getDowntimeDataEquip({ variables: { in_id_enterprise: enterprise, in_ids_shifts: `{${filterShift.toString()}}`, in_ids_sites: '{' + (site ? site : "") + '}', in_ids_equipments: `{${filterLine.toString()}}`, in_ids_sectors: `{${filterSector.toString()}}`, in_ids_areas: `{${filterArea.toString()}}`, _tsstart: moment.utc(ts_start), _tsend: moment.utc(ts_end), in_ids_teams: "{}", sector_view: isSectorView } });
      }
    } else {
      getDowntimeDataEquip({ variables: { in_id_enterprise: enterprise, in_ids_shifts: `{${filterShift.toString()}}`, in_ids_sites: '{}', in_ids_equipments: `{${filterLine.toString()}}`, in_ids_sectors: `{${filterSector.toString()}}`, in_ids_areas: `{${filterArea.toString()}}`, _tsstart: moment.utc(ts_start), _tsend: moment.utc(ts_end), in_ids_teams: "{}", sector_view: isSectorView } });
    }
  }

  useEffect(() => {
    updateData();
  }, [ts_end, ts_start, line, filterShift, filterArea, filterLine, filterSector, site, area, isSectorView, visualizationLevel]);

  useEffect(() => {
    if(downtimeDetailstoCSV?.length>0){
      let totalTime = downtimeDetailstoCSV[downtimeDetailstoCSV?.length-1].duration_total
      downtimeDetailstoCSV[downtimeDetailstoCSV?.length-1].duration_justified = totalTime
    }
    
  }, [downtimeDetailstoCSV]);

  const columns_csv = [
    { id: 'ts_start', displayName: 'Start' },
    { id: 'ts_end', displayName: 'End' },
    { id: 'cd_category', displayName: 'Category name' },
    { id: 'txt_category', displayName: 'Category Description' },
    { id: 'duration_justified', displayName: 'Duration seconds' }, 
    { id: 'planned_downtime', displayName: 'Planned' },
  ];

  return (
    <div className="container-fluid">
      {
       <>
          <Breadcrumb />
          <div className="row m-4  bt-2">

            <div>
              <div className="d-flex flex-row end justify-content-between">
                <div className="d-flex flex-row ">
                  <span style={{ lineHeight: 1, fontSize: 32 }} className="bold color-black me-3 "> { language?.downtimes || 'Downtimes' } </span>
                  <EntityFilter hideSector={!isSectorView} />
                </div>

                <div className="d-flex flex-row">
                  <Spacer size={25} />
                  <DownloadCsv  data={csvData} />
                </div>
              </div>

            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: 2.5,
                gridTemplateRows: 'auto',
                gridTemplateAreas: 
                  `"main main sidebar sidebar sidebar sidebar"
                  "re re re re re re"`,
              }}>
                
              <Box sx={{ gridArea: 'main', mt:3, }}>
               { downtimeDetails ?
                <CardTime 
                  translate={language}
                  data={downtimeDetails}
                  total={ secondsToDurationString(downtimeDetails?.duration_total || 0) }
                  porcent={ downtimeDetails?.available_time ? (((downtimeDetails?.duration_total || 0) / downtimeDetails.available_time) * 100).toFixed(1) : "NA" } />
                  : <></> }
              </Box>
              
              
              <Box sx={{ gridArea: 'sidebar', mt:3,  }}>
                { downtimeDetails ?
                 <Resume
                  translate={language}
                  data={downtimeDetails ? downtimeDetails:""} />
                : <></> }
              </Box> 
            
              <Box sx={{ gridArea: 're',}}>
              { downtimeDetails ?
                <DowntimeReason 
                translate={language} 
                data={ downtimeDetails ? downtimeDetails:"" } />
                : <></> }
                
              </Box>
            </Box>

            </div>

          </div>

          <Box sx={{px:3}} >
            {loadingDowntimeQueryEquip ? "Loading..." 
              : <DowntimeTable 
                  isSectorView={isSectorView} 
                  update={updateData} 
                  data={dataa?.filter((dwnt) => { if (filterShift?.length > 0) { return filterShift?.includes(dwnt?.id_shift) } else return true })} 
                  />}
          </Box>
        </>
      }

    </div >
  );
}

export default Downtimes;
