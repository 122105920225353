import React from 'react';
import { ComposedChart, Bar, CartesianGrid, YAxis, XAxis, Tooltip, Cell, Legend, ReferenceLine, Line, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import XTick from '../../../components/Tick/XTick';
import Ytick from '../../../components/Tick/Ytick';
import ChartLegend from '../../../components/ChartLegend';
import { getLanguage } from '../../../utils/utils';
import ShiftTooltip from './ShiftTooltip';


function SinglePeriodShiftChart({ data, dateRange }) {
    const language = getLanguage();
    let chartData = data ?? [];
    let legendKeys = [];
    let barShifts = [];
    const colors = ['rgba(65, 117, 245, 1)', 'rgba(89, 39, 182, 1)', 'rgba(245, 216, 65, 1)', 'rgba(39, 165, 182, 1)'];
    const inProgressColors = ['rgba(65, 117, 245, 0.5)', 'rgba(89, 39, 182, 0.5)', 'rgba(245, 216, 65, 0.5)', 'rgba(39, 165, 182, 0.5)'];

    if (chartData?.length) {
        let shifts = chartData?.[0]?.array_agg;
        barShifts = shifts?.map((item, index) => {
          legendKeys.push({ label: `${language?.shift || 'Shift'} ${item?.cd_shift}`, color: colors[index], circle: true });
          return (
            <Bar
                key={item?.id_shift}
                dataKey={`array_agg[${index}].net`}
                radius={[5, 5, 0, 0]}
                isAnimationActive
                animationDuration={2400} 
            >
               {chartData.map((entry, i) => {
                if (moment(entry.ts_value_production).minutes() !== 0 || moment(entry.ts_value_production).seconds() !== 0) {
                  return (
                      <Cell
                          key={`cell-${index}`}
                          stroke={colors[index]}
                          fill={inProgressColors[index]}
                          strokeWidth="3"
                          style={{ borderRadius: '10px' }}
                          strokeDasharray="5 3"
                      />
                    );
                  } else {
                    return (<Cell key={`cell-${index}`} fill={`${colors[index]}`}  />);
                  }
                })
              }
            </Bar>
          )
        });
      }


    let intervalData = 'preserveStart';
    if(chartData?.length < 10) intervalData = 0;
    if(chartData?.length > 10 && chartData?.length <= 33) intervalData = 1;

    const chartSettings = { top: /*2*/40, right: /*40*/50, left: /*30*/16, bottom: /*2*/0 };
  return (
    <ResponsiveContainer  width="100%" height={490}>
        <ComposedChart width={1400} height={470} margin={chartSettings} data={chartData}>
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis
                dataKey="ts_value_production"
                tick={<XTick dateRange={dateRange} />}
                height={60}
                interval={intervalData}
              />
                        
            <YAxis
                tick={<Ytick />}
                label={{ position: 'insideLeft' }}
                axisLine={false}
            />

            <Tooltip 
                content={<ShiftTooltip chartData={chartData}
                dateRange={dateRange} />} 
            />
            {
                barShifts
            }

            <Legend 
                content={<ChartLegend />}
                legendKeys={legendKeys}
            />

        </ComposedChart>
    </ResponsiveContainer>
  );
}

export default SinglePeriodShiftChart;