import React, { useState, useContext, useEffect } from 'react';
import { auth } from "../../firebase";
import { useHistory } from "react-router-dom"
import LoginImage from "../../assets/LoginImage.png"
import { ReactComponent as PackiotLogo } from "../../assets/packiot-dark.svg"
import './styleLoginTeste.css'
import '../Button/button.css'
// import '../Core/core.css'
import '../Helpers/helpers.css'
import '../Variables/variables.css'
import '../Box/box.css'
import '../Typography/typography.css'
import '../Form/form.css'
import { RiMailSendLine } from "react-icons/ri";
import queryString from 'query-string'
import { VariablesContext } from '../../Context/VariablesContext';

const SignIn = (props) => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passError, setPassError] = useState(false);
  const [userError, setUserError] = useState(false);
  const urlParams = queryString.parse(props.location.search);

  const { initialValues } = useContext(VariablesContext);

  const signInWithEmailAndPasswordHandler =
    (event, email, password) => {
      event.preventDefault();
      auth.signInWithEmailAndPassword(email, password)
        .then((response) => {

          if (urlParams.redirect) {
            history.push(`/${urlParams.redirect}`);
          } else {
            initialValues();
            history.push('/')
          }
          localStorage.setItem('@packiot4:token', response.user.uid);
        })
        .catch(error => {
          if (error?.code === 'auth/user-not-found') {
            setUserError(true);
          } else { 
            setUserError(false);
          }
          if (error?.code === 'auth/wrong-password') {
            setPassError(true);
          } else {
            setPassError(false);
          }
          console.error("Error signing in with password and email", error);
        });
    };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === 'userEmail') {
      setEmail(value);
    }
    else if (name === 'userPassword') {
      setPassword(value);
    }
  };

  useEffect(() => {initialValues();}, []);

  return (

    <div className="container-fluid">
      {/* took away the paddings and margin of container, height 100vh from container, rows and cols*/}
      <div className="row grid-fix">
        <div className="col-lg-6">{/* Left collumn */}

          {/* took the card class, didn't need it*/}
          <div className="content login-box full-height d-flex flex-column justify-content-center">
            <PackiotLogo className={"mb-5"} />
            <h1 className="display-3 fw-bold">Welcome back!</h1>
            <h3 className="v837_2452 mb-5">Login to see your growth.</h3>
            {/* changed custom inputs for the bootstrap ones*/}
            <div className="mb-3">
              <label htmlFor="userEmail">Email <span className="text-primary">*</span></label>
              <input
                type="email"
                className={`form-control ${(userError) ? 'is-invalid' : '' }`}
                name="userEmail"
                value={email}
                placeholder="Enter email"
                id="userEmail"
                onChange={(event) => onChangeHandler(event)}
              />
              <span id="login-user-error" style={{ visibility: `${(userError) ? 'visible' : 'hidden' }`, color: '#dc3545' }}>User not found!</span>
            </div>

            <div className="mb-2 position-relative">
              <label name="userPasswordLabel" htmlFor="userPassword">Password <span className="text-primary">*</span></label>
              <input
                type="password"
                className={`form-control ${(passError) ? 'is-invalid' : '' }`}
                name="userPassword"
                value={password}
                placeholder="Enter your password"
                id="userPassword"
                onChange={(event) => onChangeHandler(event)}
              />
              <div class="password-toggle">
                <i class="ri-eye-line"></i>
              </div>
              <span id="login-password-error" style={{ visibility: `${(passError) ? 'visible' : 'hidden' }`, color: '#dc3545' }}>Wrong password. Please, try again.</span>
            </div>

            <div className="d-flex justify-content-between mb-4">
              {/* remind me to customize checkbox*/}
              <div className="d-flex align-items-center">
                <input id="chk1" type="checkbox" name="chk" className="" />
                <label htmlFor="chk1" className="ms-2">Remember me</label>
              </div>

              <a href="#" className="fw-bold">Forgot your Password?</a>

            </div>
            {/* changed btn color to primary as set in css variables, uppercase and bold added, d-grid to make the button full width*/}
            <div className="d-grid">
              <button type="submit" className="btn btn-secondary text-uppercase fw-bold mb-4" onClick={(event) => { signInWithEmailAndPasswordHandler(event, email, password) }}>Login</button>
            </div>

            <div className="color-grey mt-2">
              <small>&copy; 2021. All rights reserved.</small>
            </div>
          </div>


        </div>


        <div className="col-lg-6 bg-secondary d-none d-lg-block">{/* Right collumn */}
          <div className="color-white mt-5 text-end me-5">
            <RiMailSendLine />
            <small className="ms-2">support@packiot.com</small>
          </div>
          <div className="d-flex align-items-center justify-content-center login-img-height-fix">
            <div>
              <img style={{ width: "100%" }} src={LoginImage} className="image" />
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};
export default SignIn;