import React, { useContext, useLayoutEffect } from 'react';
import InfoUp from './components/InfoUp';
import Card1 from './components/Card1';
import Card2 from './components/Card2';
import Chart from './components/Chart';
import ChartMontebello from './components/ChartMontbello';
import TableEvent from './components/Table';
import { ReactComponent as Logoblack } from '../../assets/logoblack.svg';

import { VariablesContext } from '../../Context/VariablesContext';
import { Box, Card, Typography } from '@mui/material';

import moment from 'moment';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';

import { UNS_EQUIPMENT_CURRENT_JOB, EQUIPMENT, UNS_EQUIPMENT_CURRENT_SHIFT, EQUIPMENTS_INFOS, UNS_EQUIPMENT_CURRENT_DAY,
  GET_PRODUCTION_CHART, GET_EVENTS , GET_TARGET, UNS_EQUIPMENT_CURRENT_METRICS, ENTERPRISE,
  
  SCRAP_TARGET,GET_JOB_INFO, GET_SHIFT_PROD, GET_DAY_PROD, GET_MONTH_PROD, PROJ_MONTH, STATUS_EQUIPMENT, AREA_NAME, OEE_OBJ_MONTH, RECORD_12H,  } from './queries';
  
export default function Overview() {
  const { lineId } = useParams();
  const { enterprise, site, area, line } = useContext(VariablesContext);
  
  const user_language = localStorage.getItem('user_language');

  // index
  let { data: enterpriseData } = useQuery(ENTERPRISE, { variables: { eq: enterprise }, pollInterval: 55000 });

  //BARRA DE CIMA
  const { data: unsJob } = useQuery(UNS_EQUIPMENT_CURRENT_JOB, { variables: { _eq: lineId }, pollInterval: 55000, fetchPolicy: 'no-cache' });
  const { data: equipment } = useQuery(EQUIPMENT, { variables: { _eq: lineId }, pollInterval: 55000, fetchPolicy: 'no-cache' });

  //CARD 1
  const { data: objetivos  } = useQuery(GET_TARGET, { variables: { _eq1: lineId }, pollInterval: 55000, fetchPolicy: 'no-cache', displayName: 'objetivos' });
  const { data: unsShift  } = useQuery(UNS_EQUIPMENT_CURRENT_SHIFT, { variables: { id: lineId }, pollInterval: 55000, fetchPolicy: 'no-cache', displayName: 'Shift' });

  const { data: eqpsInfo  } = useQuery(EQUIPMENTS_INFOS, { variables: { _eq: lineId }, pollInterval: 55000, fetchPolicy: 'no-cache',displayName: 'Month' });
  const { data: eqpsInfoDay  } = useQuery(UNS_EQUIPMENT_CURRENT_DAY, { variables: { _eq: lineId }, pollInterval: 55000, fetchPolicy: 'no-cache',displayName: 'Month' });
  const { data: eqpMetrics  } = useQuery(UNS_EQUIPMENT_CURRENT_METRICS, { variables: { _eq: eqpsInfo?.equipments?.[0]?.id_equipment }, pollInterval: 55000, fetchPolicy: 'no-cache',});
  
  //TABELA
  const { data: eventsData } = useQuery(GET_EVENTS, { variables: { id: lineId }, pollInterval: 55000, fetchPolicy: 'no-cache', displayName: 'Events' });
  
  //GRAFICO
  const { data: prodData} = useQuery(GET_PRODUCTION_CHART, { variables: { id: lineId }, pollInterval: 55000, fetchPolicy: 'no-cache' });
  let arr12H = prodData?.h_piot_overview_production_chart?.map(e => e.production);

  useLayoutEffect(()=> {
    enterpriseData?.enterprises?.[0]?.logo_url !== undefined ?? 
    setEnterpriseLogo(enterpriseData?.enterprises?.[0]?.logo_url);
  }, [])
  
  let enterpriseName = localStorage.getItem("enterprise");
  
  // console.log(enterpriseData?.enterprises?.[0]?.nm_enterprise)
  // console.log(eqpMetrics?.uns_equipment_current_metrics?.[0]?.speed)
  // console.log(eqpMetrics?.uns_equipment_current_metrics?.[0])
  return (
    <>
      <Box
        sx={{
          backgroundColor: '#F1F3F5 ',
          flexGrow: 1 ,
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: 2,
          gridTemplateRows: 'auto',
          gridTemplateAreas: 
            `"infoUp infoUp infoUp infoUp"
            "card1 card2 graph graph"
            "card1 card2 graph graph"
            "tabela tabela tabela tabela "
            "below below below below "`,
        }}>

        <Box sx={{ gridArea: 'infoUp', mt:1, mr:1, ml:1  }}>
          <InfoUp
            data={unsJob}
            equipment={equipment}
          />
        </Box>
        
        <Card sx={{ gridArea: 'card1', ml:1 }} elevation={0}>
          <Card1 data={ unsShift } />
        </Card>
        
        <Card sx={{ gridArea: 'card2', }} elevation={0}>
          <Card2
            dataShift={unsShift}
            dataNameMaq={eqpsInfo?.equipments?.[0]?.nm_equipment}
            dataMaqSpeed={eqpMetrics?.uns_equipment_current_metrics?.[0]}
            dataDay={eqpsInfoDay}
          />
        </Card>

        <Card sx={{ gridArea: 'graph', mr:1}} elevation={0}>
          {enterpriseName === "Montebello"
          ? <ChartMontebello
            data={ prodData?.h_piot_overview_production_chart }
            dataArr12H={ arr12H }
            target={objetivos} /> 
          : <Chart
            data={ prodData?.h_piot_overview_production_chart }
            dataArr12H={ arr12H }
            target={objetivos}/>
          }
        </Card>
        
        <Box sx={{ gridArea: 'tabela', }}>
          <TableEvent data={ eventsData?.h_piot_overview_i_get_events } />
        </Box>

        <Box sx={{ gridArea: 'below', height:50, display:'flex', justifyContent: 'space-between' , mb:5}}>
          <Box sx={{ mt:-.8, ml:2}}>
            {/* { logo ? <img src={logo}></img> : <Logoblack /> } */}
            <img src={enterpriseData?.enterprises?.[0]?.logo_url} width='150px'></img>
          </Box>

          <Box sx={{ display:'flex', }}>
            <Logoblack />            
            <Typography variant="h1" sx={{fontFamily:"Source Sans Pro", fontWeight: 1000, fontSize: 48, ml:10, mr:3, mt:-1.5 }} >
              { user_language === 'pt-BR' ? moment().format('HH:mm') : moment().format('hh:mm a') }
            </Typography>
          </Box>
        </Box>

      </Box> 
    </>
  )
}

