import React, { useState, useEffect, useContext } from 'react';
import { VariablesContext } from '../../../Context/VariablesContext';
import ShiftFilter from '../../ShiftFilter';
import EquipmentFilter from '../../EquipmentFilter';
import AreaFilter from '../../AreaFilter';
import { UserContext } from "../../../providers/UserProvider.jsx";
const FileDownload = require('js-file-download');
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import api from '../../../services/api';
import $ from "jquery";
import { getLanguage } from '../../../utils/utils';
import {
  Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, Typography, IconButton,
  MenuItem
}  from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function DowntimeUploadModal() {
    const language = getLanguage();
    const [open, setOpen] = useState(false);
    const { enterprise, site, area, line, visualizationLevel, lineOptions, sectorOptions } = useContext(VariablesContext);

    const handleClose = () => {
      setOpen(false);
      // setSuccess();
    };
    
    const handleClickOpen = () => {
      setOpen(true);
    };
    
    const [uploadedFiles, setUploadedFiles] = useState({ FileList: [] });
    const [waitingCSVUpload, setWaitingCSVUpload] = useState(false);
    const [csvUploadSucessfull, setCsvUploadSucessfull] = useState(null);
    const [selectedLine, setSelectedLine] = useState(null);

    const LoadingIndicator = props => {
        const { promiseInProgress } = usePromiseTracker();
        return (
          promiseInProgress &&
          <div class="spinner-grow text-light" role="status"><span class="visually-hidden">Loading...</span></div>
        )
      }

    const user = useContext(UserContext);

    const handleDowntimeDownload = () => {
        if (selectedLine) {
            api.get(`/download/downtime_csv/${selectedLine}`, {
                headers: { 'uid': user.user.uid },
                responseType: 'blob',
            }).then((response) => {
                FileDownload(response.data, 'Downtimes.csv');
            });
        } else {
            alert("Por favor, selecione uma linha");
        }
    };

    const handleDowntimeUpload = async () => {
        if (selectedLine) {
            let csvData = new window.FormData();
            setWaitingCSVUpload(true);
            csvData.append("file", uploadedFiles?.[0]);
            csvData.append("id_equipment", selectedLine);
            let successfull = false;
            try {
                await trackPromise(
                    api.post(`/upload/downtime_csv`, 
                        csvData,
                        {
                            // baseURL: 'http://localhost:3333',
                            headers: { 'uid': user.user.uid }
                        })
                );
                successfull = true;
            } catch (err) {
                console.log(err);
                alert(err);
            }
            setCsvUploadSucessfull(successfull);
            setWaitingCSVUpload(false);
            setUploadedFiles({ FileList: [] });
            if (successfull) {
                await new Promise(res => setTimeout(res, 1000));
                $('#close-csv-modal').trigger('click');
                // $('#UploadCSVForm').form.reset();
                setCsvUploadSucessfull(null);
            }

        } else {
            alert("Please select a line");
        }
    };

    const handleLineSelection = (e) => {
        console.log(e);
        console.log(e.target.value);
        setSelectedLine(e.target.value);
    };

    return (
      <>
        <MenuItem onClick={handleClickOpen} >
          {language?.settings || 'Settings'} {language?.manage_downtime || '(Manage Downtime Reasons)' }
        </MenuItem>

        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}>
          <DialogTitle>
            {language?.upload_csv_downtime || 'Upload Downtime Reasons'}:
            <IconButton onClick={handleClose}
              sx={{
                position: 'absolute',right: 8, top: 8,
                color: (theme) => theme.palette.grey[500],
              }}> <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers sx={{pb:13}}>

            <form id="UploadCSVForm">
                <select className="form-control" onChange={handleLineSelection}>
                    <option value={null}> </option>
                    {   sectorOptions?.length>0?
                        (sectorOptions?.map((line, index) => { return <option value={line.id_equipment}>{line.nm_equipment}</option> }))
                        :(lineOptions?.map((line, index) => { return <option value={line.id_equipment}>{line.nm_equipment}</option> }))
                    }
                </select>

                <div className="d-flex p-2">
                    <button type="button"  className='mx-1' 
                      style={{borderRadius: "2px", border: "1px solid black", }} id="download-csv-button" 
                      onClick={handleDowntimeDownload}>
                      {language?.csv_download || 'Download CSV'}
                    </button>

                    <input
                      className='align-middle'
                      type="file"
                      id="myFile"
                      name="filename"
                      value={uploadedFiles?.FileList}
                      onChange={(e) => { setUploadedFiles(e.target.files); }}
                      accept='.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values'
                    >
                    </input>
                </div>
            </form>
          </DialogContent>

          <DialogActions>
            {csvUploadSucessfull != null ? (csvUploadSucessfull ? <p classname=".text-success"> {language?.success || 'Success!'} </p> : <p classname=".text-danger">{language?.error || 'Ops, something went wrong!'}</p>) : <></> }

              <Button variant="outlined" size="small" sx={{m:1}} 
                onClick={handleClose} disabled={waitingCSVUpload?true:false} >
                  {language?.close || 'Close'}
              </Button>

              <Button variant="outlined" size="small" sx={{m:1}} 
                onClick={async () => handleDowntimeUpload()}
                disabled={uploadedFiles?false:true}>
                {waitingCSVUpload ? <LoadingIndicator /> : <></>}{waitingCSVUpload ? "" : `${language?.send || 'Send'}`}
              </Button>
          </DialogActions>
        </Dialog>
      </>
    );
}

export default DowntimeUploadModal;
