import { gql } from "@apollo/client";

export const GET_SINGLE_PERIOD_DATA = gql`
query GetSinglePeriodProduction(
    $ts_start: timestamptz
    $ts_end: timestamptz
    $areas: String
    $equipments: String
    $id_enterprise: Int
    $shifts: String
    $sites: String
    $grain: String
  ) {
    h_piot_single_period_equipment_chart_3(
      args: {
        in_begin_time: $ts_start
        in_end_time: $ts_end
        in_id_areas: $areas
        in_id_enterprise: $id_enterprise
        in_id_equipments: $equipments
        in_id_shifts: $shifts
        in_id_sites: $sites
        time_grain: $grain
      }
    ) {
      gross
      net
      scrap
      array_agg
      target
      ts_value_production
    }
  }
  
`;