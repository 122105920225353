import React from 'react';

import { Card, Title, Item, Content } from '../styles';

function Downtime({ data }) {
    function getColor(column) {
        let color = "#333333";
        if (column === "notJustified") color = "#FD9729";
        if (column === "runningStop") color = "#C13939";
        return color;
    }
  return (
      <Card width={1855} height={316}>
          <Content width="100%" height="100%" direction="column">
                <Item top={10}> <Title size={40}>Últimos Eventos</Title> </Item>
                <Item width="100%" direction="column">
                    <table>
                        <thead>
                            <tr>
                                <th>Inicio</th>
                                <th>Fim</th>
                                <th>Duração</th>
                                <th>Máquina</th>
                                <th>Categoria</th>
                                <th>Sub Categoria</th>
                                <th>Notas</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                data?.map((item, index) => (
                                    <tr key={index} style={{ color: getColor(item?.colorcolumn) }} >
                                        <td> {item?.start} </td>
                                        <td> {item?.end} </td>
                                        <td> {item?.duration} </td>
                                        <td> {item?.machine} </td>
                                        <td> {item?.reason} </td>
                                        <td> {item?.sub_category} </td>
                                        <td> {item?.notes} </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </Item>
          </Content>
      </Card>
  );
}

export default Downtime;