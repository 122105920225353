import React from 'react';
import styled from 'styled-components';
import { LineChart, CartesianGrid, YAxis, XAxis, Tooltip, Line, Legend, ResponsiveContainer } from 'recharts';
import XTick from '../../../components/Tick/XTick'
import Ytick from '../../../components/Tick/Ytick'
import CardLegend from '../../../components/ChartLegend/CardLegend';
import moment from 'moment';
import '../../../components/Tooltip/tooltip.css';
import { getLanguage } from '../../../utils/utils';


const Container = styled.div`
  display: none;
`;

const LegendContent = () => <Container />;
;

const CustomTooltip = ({ label, chartData }) => {
  const language = getLanguage();
  let currentDot = chartData?.filter((item) => item?.ts === label);
  currentDot = currentDot?.[0] || {};

  return (
    <div className="chart-tooltip-small">
      <span className="tooltip-title-small mt-3"> {moment(label).utc().format('dddd DD/MM')} </span>
      <div className="chart-tooltip-small-items mt-1">
        <div className="chart-tooltip-small-item">
          <div style={{ background: '#C13939' }} className="tooltip-legend-circle"></div> <strong>{language?.total || 'Total'}: </strong> &nbsp; {currentDot?.oee?.toFixed(1)}% 
        </div>
        <div className="chart-tooltip-small-item">
          <div style={{ background: '#318F29' }} className="tooltip-legend-circle"></div> <strong>{language?.availability || 'Availability'}: </strong>&nbsp; {currentDot?.oee_a?.toFixed(1)}% 
        </div>
        <div className="chart-tooltip-small-item">
          <div style={{ background: '#4175F5' }} className="tooltip-legend-circle"></div> <strong>{language?.performance || 'Performance'}: </strong>&nbsp; {currentDot?.oee_p?.toFixed(1)}% 
        </div>
        <div className="chart-tooltip-small-item">
          <div style={{ background: '#F2994A' }} className="tooltip-legend-circle"></div> <strong>{language?.quality || 'Quality'}: </strong>&nbsp; {currentDot?.oee_q?.toFixed(1)}% 
        </div>
      </div>
      
    </div>
  )
  
};

export default function OeeProgress({ data, index }) {
  const language = getLanguage();

    const chartData = data?.jsonb_agg?.map((item) => ({
      ts: item.ts,
      oee_a: item.oee_a * 100,
      oee_p: item.oee_p * 100,
      oee_q: item.oee_q * 100,
      oee: item.oee * 100
    }));
    

    let intervalData = 'preserveStart';
    if(chartData?.length < 10) intervalData = 0;
    if(chartData?.length > 10 && chartData?.length <= 33) intervalData = 1;

    const chartSettings = { top: /*2*/0, right: /*40*/4, left: /*30*/-10  , bottom: /*2*/0 };
    const legendKeys = [{
      label: `${language?.oee_total || 'OEE Total'}`,
      color: '#C13939',
      circle: true
    }, {
      label: `${language?.quality || 'Quality'}`,
      color: '#F2994A',
      circle: true
    }, {
      label: `${language?.availability || 'Availability'}`,
      color: '#318F29',
      circle: true
    }, {
      label: `${language?.performance || 'Performance'}`,
      color: '#4175F5 ',
      circle: true
    }];

  return (
    <div className="col-12 col-lg-6 mt-4">
        <div className="status-card">

          <div className="status-card-header mt-3">
            <div className="row">
              
              <div className="col-10">
                <div className="d-flex align-items-center">
                  <h5 className="fw-bold me-2"> { data?.cd_equipment } </h5>
                </div>
              </div>

            </div>
          </div>

          <div className="status-card-body mt-3 d-flex align-items-center flex-column">
            <ResponsiveContainer width="100%" height={350}>
                <LineChart margin={chartSettings} data={chartData}
                >
                        <CartesianGrid strokeDasharray="3 2" />
                        <XAxis
                            dataKey="ts"
                            tick={<XTick />}
                            interval={intervalData}
                        />
                        
                        <YAxis
                            tick={<Ytick isPercentage />}
                            label={{ position: 'insideLeft' }}
                            interval={'preserveStartEnd'}
                            axisLine={false}
                        />
                        <Tooltip content={ <CustomTooltip chartData={chartData} />} />

                        <Legend content={<LegendContent />} />

                        <Line type="linear" strokeWidth="2" dot={false} dataKey="oee" stroke="#C13939" />

                        <Line type="linear" strokeWidth="2" dot={false} dataKey="oee_a" stroke="#318F29" />

                        <Line type="linear" strokeWidth="2" dot={false} dataKey="oee_q" stroke="#F2994A" />

                        <Line type="linear" strokeWidth="2" dot={false} dataKey="oee_p" stroke="#4175F5 " />
                        
                </LineChart>
                
            </ResponsiveContainer>
          </div>

          <div className="status-card-footer mt-1 justify-content-center">
            <CardLegend legendKeys={legendKeys} />

          </div>

        </div>
      </div>
  );
}
