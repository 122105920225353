import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  // background: #F1F3F5;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const Spacer = styled.div`
  width: 100%;
  height: ${(props) => props.size}px;
`;

export const Card = styled.div`
    display: flex;
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    background: #FFFFFF;
    border-radius: 10px;
  .row {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #131822;
  width: 100%;
  height: 64px;
`;

export const Title = styled.span`
  font-size: ${(props) => props.size}px;
  font-weight: bold;
  color: #333333;
`;

export const Value = styled.span`
  font-size: ${(props) => props.size}px;
  font-weight: bold;
  color: ${(props) => props.color};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  ${(props) => props.height ? `height: ${props.height}` : ''};
  ${(props) => props.width ? `width: ${props.width}` : ''};
`;

export const Item = styled.div`
  display: flex;
  ${(props) => props.noPadding ? `` : 'padding-left: 30px'};
  ${(props) => props.top ? `padding-top: ${props.top}px` : ''};
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  ${(props) => props.height ? `height: ${props.height}` : ''};
  ${(props) => props.width ? `width: ${props.width}` : ''};
  table {
    text-align: left;
    padding-right: 68px;
  }
  th { font-size: 21px; font-weight: bold; color: #333333; border-bottom: 2px solid #ddd; padding-bottom: 6px; }
  td { font-size: 19px; padding: 5px 0 5px 0; }
  td {
    border-bottom: 1px solid #ddd;
    font-weight: bold;
  }
`;

export const Legend = styled.div`
  width: 200px;
  height: 40px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-size: 20px;
  color: #2D3748;

  .circle {
    margin-right: 6px;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    background: ${(props) => props.color};
  }
`;
