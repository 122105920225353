import React, { useState, useEffect, useRef } from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import 'font-awesome/css/font-awesome.min.css';

import C35MissionControlLineCard from '../../../../components/C35MissionControlLineCard';
import Loading from '../../../../components/Loading';

import '../../../../components/StatusCard/status-card.css';
import '../../../../components/AreaCard/area-card.css';
import '../../../../components/Progress/progress.css';
import '../../../../components/Tooltip/tooltip.css';
import '../../../../components/Grid/grid.css';
import '../../../../components/Scrollbar/scrollbar.css';

import { numbertoString, roundNumber, secondsToDurationString } from '../../../../utils/utils';

import { RiInformationLine } from "react-icons/ri";

import { MissionControlGqlQuery, MissionControlGqlSubscribeToAll, MissionControlGqlSubscriptionViewTimeline, MissionControlGqlSubscriptionProducao, MissionControlGqlSubscriptionViewShiftsData, MissionControlGqlSubscriptionViewStoppedTime } from './Query'
import { useQuery, useSubscription } from '@apollo/client';

export default function MissionControl35() {
  const [areaToFilter, setAreaToFilter] = useState([]);

  const [equipmentsArray, setEquipmentsArray] = useState([]);
  const [productionData, setProductionData] = useState([]);
  const [stoppedTimeData, setStoppedTimeData] = useState([]);
  const [shiftsData, setShiftsData] = useState([]);
  const [stopsData, setStopsData] = useState([]);
  const [timeLines, setTimeLines] = useState([]);
  const [areaData, setAreaData] = useState([]);

  const [areasData, setAreasData] = useState([]);
  const [showInfo, setShowInfo] = useState(false);

  const { loading: linesLoadingInitial, error: linesErrorInitial, data: linesDataInitial } = useQuery(MissionControlGqlQuery, { pollInterval: 55000, fetchPolicy: 'no-cache' });
  // const { loading: linesLoading, error: linesError, data: subscriptionData } = useSubscription(MissionControlGqlSubscribeToAll, { fetchPolicy: 'no-cache' });


  useEffect(() => {
    // if (subscriptionData) {
    //   if (subscriptionData.hasOwnProperty("enterprises")) {
    //     if (subscriptionData.enterprises.length > 0) {

    //       if (subscriptionData.enterprises[0].hasOwnProperty("C35_V_Dash_Timeline")) {
    //         setTimeLines(subscriptionData.enterprises[0].C35_V_Dash_Timeline)
    //       }
    //       if (subscriptionData.enterprises[0].hasOwnProperty("C35_Dash_Prod")) {
    //         setProductionData(subscriptionData.enterprises[0].C35_Dash_Prod)
    //       }
    //       if (subscriptionData.enterprises[0].hasOwnProperty("C35_V_Shift")) {
    //         setShiftsData(subscriptionData.enterprises[0].C35_V_Shift)
    //       }
    //       if (subscriptionData.enterprises[0].hasOwnProperty("C35_V_Stopped_Time")) {
    //         setStoppedTimeData(subscriptionData.enterprises[0].C35_V_Stopped_Time)
    //       }
    //     }
    //   }
    // }
    // else {
      if (linesDataInitial) {
        if (linesDataInitial.hasOwnProperty("equipments")) {
          if (linesDataInitial.equipments.length > 0) {
            var temp_equipments_array = linesDataInitial.equipments;

            temp_equipments_array.sort((a, b) => {
              if (a.hasOwnProperty('custom')) {
                if (a.custom.hasOwnProperty('order')) {
                  if (a.custom.order) {
                    if (b.hasOwnProperty('custom')) {
                      if (b.custom.hasOwnProperty('order')) {
                        if (b.custom.order) {
                          if (a.custom.order < b.custom.order) {
                            return -1;
                          } else if (a.custom.order > b.custom.order) {
                            return 1;
                          }
                          else {
                            return (a.nm_equipment < b.nm_equipment) ? -1 : (a.nm_equipment > b.nm_equipment) ? 1 : 0;
                          }
                        }
                      }
                    }
                  }
                }
              }
              return 0;
            });

            setEquipmentsArray(temp_equipments_array);
          }
        }
        if (linesDataInitial.hasOwnProperty("c35_v_dashboard_timeline")) {
          if (linesDataInitial.c35_v_dashboard_timeline.length > 0) {
            setTimeLines(linesDataInitial.c35_v_dashboard_timeline)
          }
        }
        if (linesDataInitial.hasOwnProperty("c35_dashboard_producao_24h")) {
          if (linesDataInitial.c35_dashboard_producao_24h.length > 0) {
            setProductionData(linesDataInitial.c35_dashboard_producao_24h)
          }
        }
        if (linesDataInitial.hasOwnProperty("c35_v_shifts_data")) {
          if (linesDataInitial.c35_v_shifts_data.length > 0) {
            setShiftsData(linesDataInitial.c35_v_shifts_data)
          }
        }
        if (linesDataInitial.hasOwnProperty("c35_v_stopped_time")) {
          if (linesDataInitial.c35_v_stopped_time.length > 0) {
            setStoppedTimeData(linesDataInitial.c35_v_stopped_time)
          }
        }
      }
    // }
  // }, [subscriptionData, linesDataInitial])
  }, [linesDataInitial])

  useEffect(() => {
    var holder = {};

    shiftsData.forEach(function (shift) {
      const temp = equipmentsArray.filter((data) => {
        return (data.Area.nm_area == shift.tipodispositivo)
      })
      let order;
      if (temp.length > 0) {
        if (temp[0].hasOwnProperty("custom")) {
          if (temp[0].custom.hasOwnProperty("order")) {
            order = temp[0].custom.order;
          }
        }
      }

      if (holder.hasOwnProperty(shift.tipodispositivo)) {
        if (holder[shift.tipodispositivo]['day'].getTime() == new Date(shift.dia).getTime()) {
          holder[shift.tipodispositivo]['qtdproduzida'] = holder[shift.tipodispositivo]['qtdproduzida'] + shift.qtdproduzida;
          holder[shift.tipodispositivo]['qtdprevista'] = holder[shift.tipodispositivo]['qtdprevista'] + shift.qtdprevista;
          holder[shift.tipodispositivo]['qtdperda'] = holder[shift.tipodispositivo]['qtdperda'] + shift.qtdperda;
          holder[shift.tipodispositivo]['qtdperdakg'] = holder[shift.tipodispositivo]['qtdperdakg'] + shift.qtdperdakg;
          holder[shift.tipodispositivo]['qtdmeta'] = holder[shift.tipodispositivo]['qtdmeta'] + shift.qtdmetaturno;
          if(shift?.oee_area) (holder[shift.tipodispositivo]['oee']= shift.oee_area);
        }
      } else {
        holder[shift.tipodispositivo] = {
          'tipodispositivo': shift.tipodispositivo,
          'qtdproduzida': shift.qtdproduzida,
          'qtdperda': shift.qtdperda,
          'qtdmeta': shift.qtdmetaturno,
          'qtdperdakg': shift.qtdperdakg,
          'order': order,
          'day': new Date(shift.dia),
          'oee': shift.oee_area
        }
      }
    });

    var temp_areaData = [];
    for (var key in holder) {
      temp_areaData.push(holder[key]);
    }

    if (temp_areaData.length > 0) {
      temp_areaData = temp_areaData.sort((a, b) => {
        if (a) {
          if (a.hasOwnProperty('order')) {
            if (a.order) {
              if (b.hasOwnProperty('order')) {
                if (b.order) {
                  if (a.order < b.order) {
                    return -1;
                  } else if (a.order > b.order) {
                    return 1;
                  }
                  else {
                    return (a.nm_equipment < b.nm_equipment) ? -1 : (a.nm_equipment > b.nm_equipment) ? 1 : 0;
                  }
                }
              }
            }
          }
          return 0;
        }
      });
    }
    setAreaData(temp_areaData);
  }, [shiftsData])

  function cardArea(area) {
    return (
      <div className="col-12 col-sm-4 col-xl-12" onClick={() => { if (areaToFilter.includes(area.tipodispositivo)) { setAreaToFilter([]) } else { setAreaToFilter([area.tipodispositivo]) }; }}>
        <div className="area-card">

          <div className="area-card-header mb-2">
            <div className="row g-0">

              <div className="col-8 d-flex align-items-center">
                <h5 className="fw-bold">{area.tipodispositivo}</h5>
              </div>

              <div className="col d-flex justify-content-end">
                <i className="ri-filter-line color-primary fs-4"></i>
              </div>

            </div>
          </div>

          <div className="area-card-body text-center mb-3">
            <p>Production <span className="fw-bold color-grey-medium-dark">[{area.unit_of_measurement || "m"}]</span></p>
            <h2 className="fw-bold">{area.qtdproduzida ? numbertoString(area.qtdproduzida) : "NA"}</h2>
            <p className={`${(area.qtdproduzida && area.qtdmeta) ? ((area.qtdproduzida / area.qtdmeta) < 1 ? "color-danger" : ((area.qtdproduzida / area.qtdmeta) > 1 ? "color-success" : "")) : ""} fw-bold fs-small`}>{(area.qtdproduzida && area.qtdmeta) ? ((area.qtdproduzida / area.qtdmeta) * 100).toFixed(0) : "NA"}% of goal</p>
          </div>

          <div className="area-card-footer">
            <div className="row">

              <div className="col-6 text-center">
                <p>Scrap</p>
                <h5 className="fw-bold">{(area.qtdperda || area.qtdproduzida) ? (((area.qtdperda || 0) * 100) / ((area.qtdperda || 0) + (area.qtdproduzida || 0))).toFixed(1) : "NA"}%</h5>
                <p className="fs-small color-grey">{(area.qtdperdakg || area.qntprevista) ? numbertoString(area.qtdperdakg) : "NA"}[kg]</p>
              </div>

              <div className="col-6 text-center">
                <p>OEE</p>
                <h5 className="fw-bold d-flex align-items-center justify-content-center">
                  {(area.oee) ? (area.oee * 100).toFixed(1) : "NA"}%
                  {/* {"NA"} */}
                  {/* <i className="color-success ri-arrow-up-line"></i> */}
                </h5>
              </div>

            </div>
          </div>
        </div>
      </div>);
  }

  if(linesLoadingInitial) {return <Loading /> }

  return (
    <>
      <div className="d-flex justify-content-between p-2 pb-4">
        <h1>Mission control</h1>
        <div className="wrapper">
        </div>

        <div style={{ display: "table-cell", verticalAlign: "middle" }}>
          <RiInformationLine onClick={() => setShowInfo(!showInfo)} size={20} style={{ width: "100 px", display: "table-cell", margin: "10px", verticalAlign: "middle" }} />
        </div>
      </div>
      <div>
        <div className="row gy-4 m-0">
          <div className="col-12 col-xl-2">
            <div className="row gy-4 mobile-overflow">
              {
                areaData?.filter((area) => {
                  if (areaToFilter.length == 0) { return area; }
                  else if (areaToFilter.includes(area.tipodispositivo)) { return area }
                }).map((area, index_area) => {
                  return (cardArea(area));
                })
              }
            </div>
          </div>
          <div className="col-12 col-xl-10">
            <div className="row gy-4">
              {
                equipmentsArray.filter((line) => {
                  if (areaToFilter.length == 0) { return line; }
                  else if (areaToFilter.includes(line.Area.nm_area)) { return line }
                })
                  .map((line) => {

                    var lineProductionData = productionData.filter((data) => {
                      return (data.dispositivo == line.nm_equipment)
                    })

                    if (line.nm_equipment == "Rotomec 02") {
                      lineProductionData.forEach((obj) => { obj["qtdproduzida"] = obj["qtdproduzidaiot"] || 0; })
                    };
                    if (line.nm_equipment == "Rotomec 03") {
                      lineProductionData.forEach((obj) => { obj["qtdproduzida"] = obj["qtdproduzidaiot"] || 0; })
                    };

                    const lineShiftsData = shiftsData.filter((shift) => {
                      return (shift.nm_equipment == line.nm_equipment)
                    });


                    const lineStoppedTime = stoppedTimeData.find(item => {
                      return item.nm_equipment == line.nm_equipment
                    });

                    const lineTimeline = timeLines.find(item => {
                      return item.dispositivo == line.nm_equipment
                    });

                    return (<C35MissionControlLineCard lineData={line} productionData={lineProductionData} shiftsData={lineShiftsData} timeLines={lineTimeline} stoppedTimeData={lineStoppedTime} />);
                  })
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}