import { gql } from "@apollo/client";

export const GET_JOB_INFO = gql`
query GET_JOB_INFO($id: Int) {
    h_piot_overview_i_get_job_info(args: {idequipment: $id}) {
      cd_equipment
      collected_prod
      id_order
      job_production_percentage
      nm_client
      order_size
      production_remaining
      remaining_time
    }
    equipments(where: {id_equipment: {_eq: $id}}){
      nm_equipment
    }
  }  
`;

// export const GET_SHIFT_PROD = gql`
// query GET_SHIFT_PRODUCTION($id: Int) {
//     h_piot_overview_i_shift_production(args: {idequipment: $id}) {
//       current_shift_production
//       current_shift_scrap
//       current_shift_scrap_percentage
//     }
//   }
// `;

export const GET_SHIFT_PROD = gql`
query GET_SHIFT_PRODUCTION($id: Int) {
  uns_equipment_current_shift(where: {id_equipment: {_eq: $id}}) {
    gross_production
    net_production
    oee
    proportional_target
    scrap
    target
    }
  }
`;

export const GET_MONTH_PROD = gql`
query GET_MONTH_PRODUCTION($id: Int) {
  uns_equipment_current_month(where: {id_equipment: {_eq: $id}}) {
    gross_production
    net_production
    oee
    proportional_target
    scrap
    target
    }
  }
`;

export const GET_DAY_PROD = gql`
query GET_DAY_PRODUCTION($id: Int) {
  uns_equipment_current_day(where: {id_equipment: {_eq: $id}}) {
    gross_production
    net_production
    oee
    proportional_target
    scrap
    target
    }
  }
`;

export const GET_PRODUCTION_CHART = gql`
query GET_PRODUCTION_CHART($id:Int) {
    h_piot_overview_i_production_chart(args: {idequipment: $id}, order_by: {times: asc}) {
      production
      rn
      scrap
      time
      times
    }
  }
  
`;

export const GET_EVENTS = gql`
query GET_EVENTS($id: Int) {
    h_piot_overview_i_get_events(args: {idequipment: $id}) {
      colorcolumn
      duration
      end
      machine
      notes
      reason
      start
      sub_category
    }
  }
`;