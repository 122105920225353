import { gql } from '@apollo/client';

export const MissionControlInitialQuery = gql`
query MyQuery($in_ids_sites: String, $in_ids_equipments: String, $in_ids_areas: String, $in_id_enterprise: Int) {
    h_piot_get_mission_control(args: {in_id_enterprise: $in_id_enterprise, in_ids_areas: $in_ids_areas, in_ids_equipments: $in_ids_equipments, in_ids_sites: $in_ids_sites}) {
      change_over_duration
      change_over_duration_percent
      curr_shift_name
      currshift_oee
      curshift_grosprod
      curshift_lastspeed
      curshift_netprod
      curshift_scrap
      duration
      expected_time
      id_area
      id_enterprise
      id_line
      id_order
      id_production_order
      id_site
      nm_area
      nm_client
      nm_line
      planned_duration
      planned_duration_percent
      po_net_production
      prev1_shift_name
      prev1shift_netprod
      prev2_shift_name
      prev2shift_netprod
      production_programmed
      total_stopped_time
      unplanned_duration
      unplanned_duration_percent
    }
    h_piot_get_mission_control_area(args: {in_id_areas: $in_ids_areas, in_id_enterprise: $in_id_enterprise, in_id_equipments: $in_ids_equipments, in_id_sites: $in_ids_sites}){
        gross_production
        id_area
        net_production
        nm_area
        oee_area
        oee_area_raw
        scrap
        ts_value_production
      }
  }  
  `;

export const MissionControlTimeline = gql`
query MyQuery($in_ids_sites: String, $in_ids_equipments: String, $in_ids_areas: String, $in_id_enterprise: Int) {
  h_piot_get_mission_control_timeline(args: {in_id_enterprise: $in_id_enterprise, in_ids_areas: $in_ids_areas, in_ids_equipments: $in_ids_equipments, in_ids_sites: $in_ids_sites}) {
    id_equipment
    timelinestatus
  }
}  
`;