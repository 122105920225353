import { gql } from '@apollo/client';

export const MenuInfoQuery = gql`
query MenuInfoQuery {
  users {
    user_menu,
    user_name
    languages,
    Enterprise {
      basic_menu,
      custom_menu,
      logo_url
      language_packs
    }
  }
} 
`;

export const GET_LANGUAGE = gql`
  query Language($language: String) {
    language_packs(where: { language_tag: { _eq: $language } }) {
      language_pack_desktop
    }
  }
`;
