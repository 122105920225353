import React, { createContext, useState, useEffect } from 'react';
import moment from 'moment';
import { useLazyQuery } from '@apollo/client';

import { GET_ENTERPRISE_FEATURES } from './Query';
import useLocalStorage from './hooks/useLocalStorage'

var default_start = moment().startOf('month').format('YYYY-MM-DD HH:mm')
var default_end = moment().endOf('month').format('YYYY-MM-DD HH:mm');

const VariablesContext = createContext();

function VariablesProvider({ children }) {
  const [enterprise, setEnterprise] = useState();
  const [nameEnterprise, setNameEnterprise] = useState("");
  const [site, setSite] = useState();
  const [area, setArea] = useState();
  const [line, setLine] = useState();
  const [filterArea, setFilterArea] = useState([]);
  const [filterSite, setFilterSite] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [filterLine, setFilterLine] = useState([]);
  const [lineOptions, setLineOptions] = useState([]);
  const [sectorOptions, setSectorOptions] = useState([]);
  const [filterShift, setFilterShift] = useState([]);
  const [filterSector, setFilterSector] = useState([]);
  const [shiftOptions, setShiftOptions] = useState([]);
  const [ts_start, setTs_start] = useState(default_start);
  const [ts_end, setTs_end] = useState(default_end);
  const [visualizationLevel, setVisualizationLevel] = useState("SITE");
  const [grain, setGrain] = useState("DAY");

  const [dateRange, setRange] = useState(null);

  // useEffect(() => {getEnterpriseFeatures();}, []);

  const initialValues = function(){
      getEnterpriseFeatures();
    const start = localStorage.getItem("ts_start");
    if (start && start !== "") {
      setTs_start(moment(start).format('YYYY-MM-DD HH:mm'));
      localStorage.setItem("ts_start", moment(start).format('YYYY-MM-DD HH:mm'));
    }
    else {
      setTs_start(default_start);
      localStorage.setItem("ts_start", default_start);
    }

    const end = localStorage.getItem("ts_end");
    if (end && end !== "") {
      setTs_end(moment(end).format('YYYY-MM-DD HH:mm'));
      localStorage.setItem("ts_end", moment(end).format('YYYY-MM-DD HH:mm'));
    }
    else {
      setTs_end(default_end);
      localStorage.setItem("ts_end", default_end);
    }

    const enterprise_local = localStorage.getItem("enterprise");
    // console.log(enterprise_local);
    enterprise_local != "null" ? setEnterprise(enterprise_local) : null;
    enterprise_local != "null" ? localStorage.setItem("enterprise", enterprise_local) : null;

    const site_local = localStorage.getItem("site");
    // console.log(site_local);
    site_local != "null" ? setSite(site_local) : null;
    site_local != "null" ? localStorage.setItem("site", site_local) : null;

    const line = localStorage.getItem("line");
    // console.log(line);
    line != "null" ? setLine(line) : null;
    line != "null" ? localStorage.setItem("line", line) : null;

    const area = localStorage.getItem("area");
    // console.log(typeof (area));
    area != "null" ? setArea(area) : null;
    area != "null" ? localStorage.setItem("area", area) : null;

    const visualizationLevel = localStorage.getItem("visualizationLevel");
    // console.log(visualizationLevel);
    setVisualizationLevel(visualizationLevel);
    localStorage.setItem("visualizationLevel", visualizationLevel);

    let range = localStorage.getItem("range");
    if (!range) range = 'month';
    setRange(range)
    localStorage.setItem("range", range);
  }

  useEffect(() => {
    initialValues();
  }, [])

  const [getEnterpriseFeatures,{ loading, error, data }] = useLazyQuery(GET_ENTERPRISE_FEATURES,
    {
      onCompleted: () => {

        // Enterprise
        setEnterprise(data?.enterprises?.[0]?.id_enterprise);
        // console.log(data?.enterprises?.[0]?.nm_enterprise)
        localStorage.setItem("enterprise", data?.enterprises?.[0]?.nm_enterprise)
        setNameEnterprise(data?.enterprises?.[0]?.nm_enterprise);

        // Shifts
        let temp = [];
        data?.shifts?.map((shift) => {
          temp.push(shift.id_shift);
        });
        // setFilterShift(temp);
        data?.shifts?setShiftOptions(data?.shifts):null;

        // Equipments
        temp = [];
        data?.equipments?.map((equip) => {
          temp.push(equip.id_equipment);
        });
        setFilterLine(temp);
        setLineOptions(data?.equipments);

        // Area
        temp = [];
        data?.areas?.map((area) => {
          temp.push(area.id_area);
        });
        setFilterArea(temp);
        setAreaOptions(data?.areas);

        // Sites
        temp = [];
        data?.sites?.map((site) => {
          temp.push(site.id_site);
        });
        setFilterSite(temp);
        setSiteOptions(data?.sites);
        // localStorage.clear();

        // Sectors
        temp = [];
        data?.sectors?.map((sector) => {
          temp.push(sector.id_equipment);
        });
        setFilterSector(temp);
        setSectorOptions(data?.sectors);
      },
      fetchPolicy: 'no-cache',
    }
  );

  function changeGrain(grain) {
    setGrain(grain);
  }

  function changeSite(prop) {
    setSite(prop);
    setArea();
    setLine();
    localStorage.setItem("site", prop);
    localStorage.setItem("area", "");
    localStorage.setItem("line", "");
    setVisualizationLevel("SITE");
    localStorage.setItem("visualizationLevel", "SITE");
    // console.log(`CONTEXT AREA: ${prop}`);
  };
  function changeArea(prop) {
    setArea(prop);
    setLine();
    localStorage.setItem("area", prop);
    localStorage.setItem("line", "");
    setVisualizationLevel("AREA");
    localStorage.setItem("visualizationLevel", "AREA");
    // console.log(`CONTEXT AREA: ${prop}`);
  };
  function changeLine(prop) {
    setLine(prop);
    localStorage.setItem("line", prop);
    setVisualizationLevel("EQUIPMENT");
    localStorage.setItem("visualizationLevel", "EQUIPMENT");
  };


  function changeFilterShift(prop) {
    setFilterShift(prop);
  };
  function changeFilterSite(prop) {
    setFilterSite(prop);
  };
  function changeFilterArea(prop) {
    setFilterArea(prop);
  };
  function changeFilterLine(prop) {
    setFilterLine(prop);
  };
  function changeFilterSector(prop) {
    setFilterSector(prop);
  };
  

  function changeTs_start(prop) {
    setTs_start(prop);
    localStorage.setItem("ts_start", prop);
    // console.log(`CONTEXT ts_strat: ${prop}`);
  };
  function changeTs_end(prop) {
    setTs_end(prop);
    localStorage.setItem("ts_end", prop);
    // console.log(`CONTEXT ts_end: ${prop}`);
  };
  function changeEnterprise(prop) {
    setEnterprise(prop);
    // console.log(`CONTEXT ts_end: ${prop}`);
  };

  function changeRange(prop) {
    setRange(prop);
    localStorage.setItem("range", prop);
    //console.log(`CONTEXT range: ${prop}`);
  }

  function clearContext() {
    localStorage.clear();
    setRange(null);
    setEnterprise(null);
    setSite(null);
    setArea(null);
    setLine(null);
    setFilterArea([]);
    setAreaOptions([]);
    setFilterSite([]);
    setSiteOptions([]);
    setFilterLine([]);
    setLineOptions([]);
    setFilterSector([]);
    setSectorOptions([]);
    setFilterShift([]);
    setShiftOptions([]);
    setTs_start(default_start);
    setTs_end(default_end);
    setVisualizationLevel(null);
    //console.log(`CONTEXT range: ${prop}`);
  }



  return (
    <VariablesContext.Provider
      value={{
        enterprise,
        setEnterprise: changeEnterprise,
        site,
        nm_enterprise: nameEnterprise,
        setSite: changeSite,
        area,
        setArea: changeArea,
        line,
        setLine: changeLine,
        filterArea,
        setFilterArea: changeFilterArea,
        filterSite,
        setFilterSite: changeFilterSite,
        filterLine,
        setFilterLine: changeFilterLine,
        filterSector,
        setFilterSector: changeFilterSector,
        filterShift,
        setFilterShift: changeFilterShift,
        ts_start,
        setTs_start: changeTs_start,
        ts_end,
        setTs_end: changeTs_end,
        shiftOptions,
        lineOptions,
        areaOptions,
        siteOptions,
        sectorOptions,
        visualizationLevel,
        dateRange,
        setRange: changeRange,
        grain,
        setGrain: changeGrain,
        clearContext,
        initialValues
      }}>
      {children}
    </VariablesContext.Provider>
  );
}


export { VariablesContext, VariablesProvider };
