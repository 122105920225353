import React from 'react';
import './styles.css';

const CardLegend = (props) => {
  const { legendKeys } = props;
  return (
    <React.Fragment>
      <ul style={{ textAlign: 'center' }} className="chart-legend-card">
        {legendKeys.map((entry, index) => {
          let classes = "chart-legend-icon";
          let colors = entry.color 
          let style = { backgroundColor: colors };
          if (entry.dashed) style = { border: 'none', borderBottom: `4px dashed ${colors}` };
          if (entry.circle) classes = "chart-legend-icon-circle";
          if(!entry.type){
            return(
            <li key={index} className="chart-legend-item">
              <span className={classes} style={style} />
              {entry.label}
            </li>);
          }
        })}
      </ul>
      <ul style={{ textAlign: 'center' }} className="chart-legend-card">
        {legendKeys.map((entry, index) => {
          let classes = "chart-legend-icon";
          let colors = entry.color
          if(entry.type){

            classes = "chart-legend-"+entry.type;
            colors = ''

            return(
              <li key={index} className="chart-legend-item">
                <span className={classes} style={{ backgroundColor: colors }} />
                {entry.label}
              </li>);
          }
        })}
        </ul>
    </React.Fragment>
  );
};

export default CardLegend;