import { THEMES } from '../constants';
import { lightShadows, darkShadows } from './shadows';
import "@fontsource/source-sans-pro";

export const baseOptions = {
  direction: 'ltr',
  typography: {
    button: {
      fontWeight: 600,
    },
    fontFamily: 'Source Sans Pro, Helvetica, Arial, Roboto',
    // fontFamily: 'Source Sans Pro',
    h1: {
      fontSize: 64,
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h2: {
      // fontSize: 900,
      fontSize: 40,
      fontWeight: 700,
    },
    h3: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: 1.2,
    },
    subtitle1: {
      fontSize: 24,
      lineHeight: 1.5,
    },
    body1: {
      fontSize: 18,
    },
    button: {
      lineHeight: 1.5,
    },
    overline: {
      fontWeight: 600,
    },
  },
};

export const themesOptions = {
  [THEMES.LIGHT]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e',
            },
          },
        },
      },
    },
    palette: {
      type: 'light',
      primary: {
        main: '#4175f5',
        contrastText: '#fefefe',
      },
      secondary: {
        main: '#131822',
        contrastText: '#fefefe',
      },
      background: {
        default: '#fefefe',
      },
      text: {
        primary: '#333333',
        secondary: '#bdbdbd',
        disabled: '#bdbdbd',
        hint: '#bdbdbd',
      },
      error: {
        main: '#c13939',
      },
      warning: {
        main: '#ecbc13',
        contrastText: '#333333',
      },
      info: {
        main: '#4175f5',
        contrastText: '#fefefe',
      },
      success: {
        main: '#318f29',
        contrastText: '#fefefe',
      },
      divider: 'rgba(142,142,142,0.12)',
    },
    shape: {
      borderRadius: 16,
    },
    shadows: lightShadows,
  },
  [THEMES.DARK]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
          },
        },
      },
    },
    palette: {
      background: {
        default: '#171c24',
        paper: '#222b36',
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336',
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#688eff',
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50',
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab',
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800',
      },
    },
    shape: {
      borderRadius: 16,
    },
    shadows: darkShadows,
  },
};
